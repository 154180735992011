import { SUBSCRIPTION_PLAN } from "../constant";
export const getPlans = data => {
    return {
      type: SUBSCRIPTION_PLAN.GET_SUBSCRIPTION_PLAN,
      payload: data,
    };
  };
  export const selectFreePlan = data => {
    return {
      type: SUBSCRIPTION_PLAN.SELECT_SUBSCRIPTION_PLAN,
      payload: data,
    };

  };
  export const selectPaidPlan = data => {
    return {
      type: SUBSCRIPTION_PLAN.SELECT_PAID_SUBSCRIPTION_PLAN,
      payload: data,
    };
  };
  export const getCurrentPlan = data => {
    return {
      type: SUBSCRIPTION_PLAN.GET_CURRENT_PLAN,
      payload: data,
    };
  };
  export const cancelPlan = data => {
    return {
      type: SUBSCRIPTION_PLAN.CANCEL_PLAN,
      payload: data,
    };
  };
  export const getAllPlan = data => {
    return {
      type: SUBSCRIPTION_PLAN.GET_ALL_PLAN,
      payload: data,
    };
  };
  