import { put, call } from "redux-saga/effects";
import {getFoundationPageService} from '../services/foundationService';
import { COMMON_MESSAGE, FOUNDATION_DATA } from "../constant/index";
import {generateReadableError} from '../../hooks/messages';

const clearErrorMessage = {
  type: COMMON_MESSAGE,
  data: {
    status: "",
    page: "",
    data: [],
  },
};

export function* fetchGetFoundationPage({ payload }) {
  try {
    yield put({
      type: FOUNDATION_DATA.SET_GET_FOUNDATION_PAGE_TO_STATE,
      data: null,
    });
    yield put(clearErrorMessage);
    const data = yield call(getFoundationPageService, payload);
    
    yield put({
      type: FOUNDATION_DATA.SET_GET_FOUNDATION_PAGE_TO_STATE,
      data,
    });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: "error",
        page: "foundation",
        data: generateReadableError(error),
      },
    });
  }
}


