import { Modal } from 'react-bootstrap';

const DisplayModal=(props: any) =>{
    if(props){
        var ComponentName = (props.name  ? props.name  : 'No-Name');
        var Show          = (props.show  ? props.show  : '');
        var Title         = (props.title ? props.title : '');
    }
    
    return (
        <Modal className="" {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={Show} >
            <Modal.Header closeButton className="border-0"></Modal.Header>
            <Modal.Body>
                <h4 style={{color:"#E79E1F"}}>{ComponentName === 'Messages' ? Title : ComponentName }</h4>
                <div className={ComponentName === 'Messages' ? `text-left text-break` : `text-center`}>
                      {props.children}        
                </div>
            </Modal.Body>            
        </Modal>
    );
}

export default (DisplayModal);