import Api from './apiCall';
import {API_URLS} from '../constant/index';

export const getCountryService = async params => {
    const result = await Api.get(API_URLS.GET_COUNTRIES_URL, params);
    return result;
  };

export const getStatesService = async params => {

  const countryId = params.id;
  let url = API_URLS.GET_STATES_URL;
  let newurl = url.replace("{id}", countryId);
  return await Api.get(newurl, params);
};

export const getCitiesService = async params => {
  
  const statesId = params.id;
  let url = API_URLS.GET_CITIES_URL;
  let newurl = url.replace("{id}", statesId);
  return await Api.get(newurl, params);
};

export const getCountryIdService = async params => {
  const userId = params.id;
  let url = API_URLS.GET_COUNTRY_ID_URL;
  let newurl = url.replace("{id}", userId);
  return await Api.get(newurl, params);
  };
  export const getStateIdService = async params => {
    const userId = params.id;
    let url = API_URLS.GET_COUNTRY_ID_URL;
    let newurl = url.replace("{id}", userId);
    return await Api.get(newurl, params);
  };

  export const getUserLocation = async params => {
    const userId = params;
    let url      = API_URLS.GET_LOCATION_URL;
    let newurl   = url.replace("{id}", userId);
    const result = await Api.get(newurl, '');
    return result;
  };

  export const getUserStateLocation_Service = async params => {
    const userId = params;
    let url      = API_URLS.GET_STATE_LOCATION_URL;
    let newurl   = url.replace("{id}", userId);
    const result = await Api.get(newurl, '');
    return result;
  };

  export const getUserCountryLocation_Service = async params => {
    const userId = params;
    let url      = API_URLS.GET_COUNTRY_LOCATION_URL;
    let newurl   = url.replace("{id}", userId);
    const result = await Api.get(newurl, '');
    return result;
  };
