import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { useLocation,useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Layout from "../../../layout/Admin/layout";
import { activateDeactivateUserById, getAdminUserById, } from "../../../redux/actions/admin/adminUserAction";
import { BASE_URLS } from "../../../redux/constant";
import defaultPic from '../../../assets/Admin/images/user-no-image.png';

const AdminUserProfile = (props: any) => {
  const navTo=useNavigate();
  const userProfileLocation = useLocation();
  let userId = userProfileLocation.state ? userProfileLocation.state.id : "N/A";
  const [userData, setUserData] = useState<any>({});
  
  useEffect(() => {
    if (userId) {
      props.getAdminUserById({ id: userId });
    }
  }, []);

  useEffect(() => {
    if (userId) {
      props.getAdminUserById({ id: userId });
    }
  }, [props.adminActivateDeactivateData]);

  useEffect(() => {
    if (props.adminUserDataById) {
      setUserData(props.adminUserDataById);
    }
  }, [props.adminUserDataById]);

  useEffect(() => {
    if (props.adminActivateDeactivateData) {
    }
  }, [props.adminActivateDeactivateData]);

  const handleActivate = () => {
    let payload = {
      isActive: userData && userData.isActive == 1 ? 2 : 1,
      id: userData && userData.id,
    };
    
    let buttonName =
      userData && userData.isActive == 1 ? "Deactivate" : "Activate";
    Swal.fire({
      title: `Are you sure you want to ${buttonName} this user? `,
      showDenyButton    : true,
      showCancelButton  : false,
      confirmButtonText : "YES",
      denyButtonText    : `NO`,
      confirmButtonColor: "green",
      showCloseButton   : true,
    }).then((result) => {
      if (result.isConfirmed) {
        props.activateDeactivateUserById(payload);
        setTimeout(() => {
          Swal.fire({
            title             : "User status updated successfully",
            showCancelButton  : false,
            confirmButtonText : "OK",
            confirmButtonColor: "green",
            allowOutsideClick : false,
          });
        }, 1000);
      }
    });
  };
  return (
    <div>
      <Layout>
      <div className="col-auto" >
            <Button className='backBtn float-end '  onClick={() => navTo(-1)}>Back</Button>
          </div>
        <Container>
          <div className="myProfile p-3 mb-3 bg-transparent rounded-2">
            <Container>
              <h2 className="mb-5 text-center">User Profile</h2>
              <Form>
                <Row>
                  <Col md={6}>
                    <Form.Group className="">
                      <Form.Label>Profile Picture </Form.Label>
                      <div className="admin-user-profileImage ">
                        <img style={{height:"200px",width:"200px", borderRadius:"20px"}} src={ userData && userData.userImg ? `${BASE_URLS.BASE_URL}/user-box-files/resource?key=${userData.userImg}` :  defaultPic } alt="" />
                      </div>
                    </Form.Group>
                  </Col>
                  {/* <Col md={6} className="mt-5 ms-auto">
                    <div className="activeDeactive mt-5">
                      <Form.Group className="text-center">
                        <Form.Label className={userData.isActive != 1 ? `toggle text-danger` : `toggle`}>In-Active</Form.Label>
                        
                        <Form.Label className={userData.isActive == 1 ? `toggle ms-auto text-success` : 'toggle ms-auto'}>Active</Form.Label>
                      </Form.Group>
                    </div>
                  </Col> */}
                </Row>
                <Row style={{lineHeight:"15px"}}>
                  <Col md={5}>
                    <Form.Group className="">
                      <Form.Label>User Status :</Form.Label>
                      <div className=" d-inline-block align-middle mx-2">
                          <label className="switch" htmlFor="checkbox">
                            <input type="checkbox" id="checkbox" checked={userData.isActive == 1 ? true : false} onChange={handleActivate} style={ true === true ? { cursor: "not-allowed" } : { cursor: "pointer",height:"1px" } } />
                            <div className="admin_slider round mt-3"></div>
                          </label>
                        </div>
                      <Form.Text> {userData  ? userData.isActive == 1 ? "Active" : "In-Active" : "N/A"} </Form.Text>
                    </Form.Group>
                  </Col>
                  <Col md="auto">
                    <Form.Group className="">
                      <Form.Label>UserId :</Form.Label>
                      <Form.Text> {userData && userData.id ? userData.id : "N/A"} </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={5}>
                    <Form.Group className="">
                      <Form.Label>Name :</Form.Label>
                      <Form.Text> {userData && userData.firstName && userData.lastName ? userData.firstName + " " + userData.lastName : "N/A"} </Form.Text>
                    </Form.Group>
                  </Col>
                  <Col md="auto">
                    <Form.Group className="">
                      <Form.Label>Email Address :</Form.Label>
                      <Form.Text>
                        {userData && userData.email ? userData.email : "N/A"}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={5}>
                    <Form.Group className="">
                      <Form.Label>Date of Birth :</Form.Label>
                      <Form.Text>
                        {userData && userData.dob ? userData.dob : "N/A"}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                  <Col md="auto">
                    <Form.Group className="">
                      <Form.Label>Mobile Number :</Form.Label>
                      <Form.Text>
                        {userData && userData.mobile ? userData.mobile : "N/A"}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={5}>
                    <Form.Group className="">
                      <Form.Label>Identity Number :</Form.Label>
                      <Form.Text>
                        {userData && userData.identity_number
                          ? userData.identity_number
                          : "N/A"}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                  <Col md="auto">
                    <Form.Group className="">
                      <Form.Label>Role :</Form.Label>
                      <Form.Text>
                        {userData && userData.roles ? userData.roles[0] : "N/A"}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={5}>
                    <Form.Group className="">
                      <Form.Label>Country :</Form.Label>
                      <Form.Text>
                        {userData && userData.country
                          ? userData.country.name
                          : "N/A"}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                  <Col md="auto">
                    <Form.Group className="">
                      <Form.Label>State :</Form.Label>
                      <Form.Text>
                        {userData && userData.state
                          ? userData.state.name
                          : "N/A"}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={5}>
                    <Form.Group className="">
                      <Form.Label>City :</Form.Label>
                      <Form.Text>
                        {userData && userData.city ? userData.city.name : "N/A"}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                  <Col md="auto">
                    <Form.Group className="">
                      <Form.Label>User Registration Date :</Form.Label>
                      <Form.Text>
                        {userData && userData.created
                          ? new Date(userData.created).toLocaleDateString()
                          : "N/A"}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={5}>
                    <Form.Group className="">
                      <Form.Label>Updated Date :</Form.Label>
                      <Form.Text>
                        {userData && userData.modified
                          ? new Date(userData.modified).toLocaleDateString()
                          : "N/A"}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                  <Col md="auto">
                    <Form.Group className="">
                      <Form.Label>Quotes :</Form.Label>
                      <Form.Text>
                        {userData && userData.quote ? userData.quote : "N/A"}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={5}>
                    <Form.Group className="">
                      <Form.Label>Life's Work :</Form.Label>
                      <Form.Text>
                        {userData && userData.life_work
                          ? userData.life_work
                          : "N/A"}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                  <Col md="auto">
                    <Form.Group className="">
                      <Form.Label>What I Lived for :</Form.Label>
                      <Form.Text>
                        {userData && userData.what_i_lived_for
                          ? userData.what_i_lived_for
                          : "N/A"}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Container>
          </div>
        </Container>
      </Layout>
    </div>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  getAdminUserById: (data: any) => dispatch(getAdminUserById(data)),
  activateDeactivateUserById: (data: any) =>
    dispatch(activateDeactivateUserById(data)),
});

const mapStateToProps = (state: any) => {
  return {
    adminUserDataById:
      state.AdminUserReducer && state.AdminUserReducer.adminUserDataById
        ? state.AdminUserReducer.adminUserDataById
        : null,
    adminActivateDeactivateData:
      state.AdminUserReducer &&
      state.AdminUserReducer.adminActivateDeactivateData
        ? state.AdminUserReducer.adminActivateDeactivateData
        : null,

    state,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AdminUserProfile);
