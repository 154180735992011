import Header from "./header";
import Footer from "./footer";
import AdminSidebarMenu from "./sidebar";
import "../../assets/Admin/css/admin.css";
import { Nav, Navbar, Button, Form, Container, ProgressBar, Dropdown, DropdownButton, Modal, Table} from 'react-bootstrap';
import { useState } from "react";

export default function Layout(props: any) {

  let   [classNameValue,  setClassNameValue]  = useState<boolean>(false);
  const handleDrawerOpen    = ()                 => { setClassNameValue(!classNameValue); }

  return (
    <div className="adminInnerpage">
      <Header handleDrawerOpen={handleDrawerOpen}/>
      <AdminSidebarMenu  classNameValue={classNameValue} handleDrawerOpen={handleDrawerOpen}/>
      <Container>
      <div className="adminRightWrap">
      <div className="wrapper rounded">
      {props.children}
      </div>
      </div>
      </Container>
      <Footer/>
    </div>
  );
};
