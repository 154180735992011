import { useState,  useEffect}             from "react";
import { connect }                         from "react-redux";
import { Button, Form }                    from 'react-bootstrap';
import logo                                from '../../assets/images/BB.png';
import { authoriseUserByOTP }              from '../../redux/actions/authAction';
import Footer                              from "../../layout/elements/footer";
import { Link,useLocation,useNavigate }    from "react-router-dom";
import { AuthoriseOTP }                    from '../../validations/user_authorisationOTP';
import { clearCommoneMessage }             from '../../redux/actions/boxAction';
import { getProfile }                      from "../../redux/actions/profileAction";
import { loginUser}                        from "../../redux/actions/authAction";
var CryptoJS = require("crypto-js");

const UserAuthorisation = (props:any) => {
  
  const redirectTo                                    = useNavigate();
  const [formData,            setFormData]            = useState({OTP:''});
  const [errors,              setErrors]              = useState({OTPErr:'',responseError:''});
  const [successMessage,      setSuccessMessage]      = useState({responseSuccess:''});
  const [loading,             setLoading]             = useState(true);
  const { state }                                     = useLocation();
  const [cipherText,          setCipherText]          = useState('');
  const [resendOTP_Data,      setResendOTP_Data]      = useState({});
  const [resendOTP_Flag,      setResendOTP_Flag]      = useState(false);
  
  useEffect(()=>{
    if(performance.navigation.type === 1){
      setTimeout(() =>{
        setLoading(false);
      },1000)
    }
    
    let cipherData = localStorage.getItem('encryptedData');
    if(cipherData){
      setCipherText(cipherData)
    }

    setTimeout(() =>{
      setResendOTP_Flag(true)
    },15*60*1000)
  },[])

  useEffect(() =>{
    if(cipherText){
      var bytes  = CryptoJS.AES.decrypt(decodeURIComponent(cipherText), 'Bey0ndB0xL0g!n@123');
      var originalText = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      setResendOTP_Data(originalText);
    }
  },[cipherText])

  const handleChange = (event: any) => {
    setFormData({...formData, [event.target.name]:event.target.value});
    const checkValidation = AuthoriseOTP({...formData, [event.target.name]:event.target.value});
    setErrors(checkValidation);
  };
            
  const handleSubmit = (e:any) => {
    e.preventDefault();
    const checkValidation =AuthoriseOTP(formData);
    setErrors(checkValidation);
    let isValid = Object.keys(checkValidation).length === 0;
    if(isValid){
        if(state){
            let payLoad = {
            userId   : state.id,
            OTP      : formData.OTP,
            }
            setLoading(true);
            props.authoriseUserByOTP(payLoad);
        }else{
          redirectTo('/login');
        }
    }
  }
 
 useEffect(() =>{
    if(props.message){
        if(props.message.code === 200 && props.message.status === 'success'){
            setTimeout(() =>{
                setLoading(false);
                setErrors({...errors,responseError:''});
                // setSuccessMessage({responseSuccess:props.message.message})
                setSuccessMessage({responseSuccess:'OTP sent to your registered e-mail and mobile number'});
                props.clearMessageProps(null);
            },1000);
        }else if(props.message.code === 404 && props.message.status === 'error' ){
            setTimeout(() =>{
                setLoading(false);
                setSuccessMessage({responseSuccess:''})
                setErrors({...errors,responseError:props.message.message})
                props.clearMessageProps(null);
            },1000)
        }else if(props.message.Token){
            setTimeout(() =>{
                setLoading(false);
                setErrors({...errors,responseError:''});
                setFormData({OTP:''})
                setSuccessMessage({responseSuccess:'OTP Verified, Signing-In... please wait!'})
                props.getProfile();
            },1000)
        }
    }
 },[props.message])

  useEffect(() => {
    const userData     = JSON.parse(localStorage.getItem('userData')||'{}');
    const access_token = (userData && userData.Token)?userData.Token:'';
    if(access_token){
      if (props.profileData) {
        localStorage.setItem('profileData', JSON.stringify(props.profileData));
        if(props.profileData.roles && props.profileData.roles[0] === 'Admin'){
          setTimeout(() =>{
            redirectTo('/admin')    
          },800)
        }else if (props.profileData.verified) {
          setTimeout(() =>{
            redirectTo('/homepage');    
          },800)
        } else {
          setTimeout(() =>{
            redirectTo('/edit-profile');    
          },800)
        }
      }
    }
  }, [props.profileData])
 
 const resendOTP = () =>{
    setLoading(true);
    setResendOTP_Flag(false);
    props.loginUser(resendOTP_Data);
  }

//---------------------------------------------------------------//

return (
    <>
    <div className="createAccount">
    <div className="userDetailBlock">
        <div className="formWrap align-items-center d-md-flex flex-column h-100 justify-content-center">
            <div className="text-center">
            <Link to="/">
              <img src={logo} className="App-logo" alt="logo" />
              </Link></div><br/>
            <h2 className="text-center">User Authorisation</h2>
            {loading ? <p className="lds-dual-ring" style={{margin: "-10px 0px 10px 0px"}}></p>
            :
              <>
                {(errors && !successMessage.responseSuccess && errors.responseError )&& <p className={errors && errors.responseError ? `text-danger` : `text-success`}>{errors && errors.responseError ? errors.responseError : ''}</p>}
                {successMessage && successMessage.responseSuccess && <p className="text-success">{successMessage.responseSuccess}</p>}
              </>
            }  
            <Form className="w-100">
                <Form.Group className="formRow">
                    <Form.Label>OTP</Form.Label>
                    <Form.Control type="text" name="OTP" id="authorisedOTP" maxLength={4} pattern="^[0-9]{4}$" placeholder="Enter valid otp"  onChange={handleChange} value={formData && formData.OTP ? formData.OTP : ''}/>
                    {resendOTP_Flag && <Link to={'#'} className="float-end text-light" onClick={resendOTP}>Resend OTP</Link>}
                    {errors && errors.OTPErr && <p className="text-danger">{errors && errors.OTPErr ? errors.OTPErr : '' }</p>}
                </Form.Group>
                <div className="d-flex  justify-content-between" style={!loading && (Object.keys(errors).length === 0)  ? {} :{cursor:'not-allowed'} }>
                  <Button onClick={()=>{redirectTo('/login')}} className="darkBtn"  variant="primary" type="button">CANCEL</Button>
                  <Button onClick={handleSubmit} className="yellowBtn" variant="primary" type="submit" disabled={!loading && ( Object.keys(errors).length === 0)  ? false : true}>CONTINUE</Button>
                </div>
            </Form>
        </div>
    </div>
</div>
<Footer/>
</>
  );
  }
  const mapDispatchToProps = (dispatch: any) => ({
    loginUser          : (data: any) => dispatch(loginUser(data)),
    authoriseUserByOTP : (data: any) => dispatch(authoriseUserByOTP(data)),
    getProfile         : (data: any) => dispatch(getProfile(data)),
    clearMessageProps  : (data: any) => dispatch(clearCommoneMessage(data)),
});

const mapStateToProps = (state: any) => {
    return {
      OTPResponse : (state.authReducer && state.authReducer.OTPResponse) ? state.authReducer.OTPResponse : null, 
      profileData : (state.ProfilePageReducer && state.ProfilePageReducer.profileData) ? state.ProfilePageReducer.profileData : null,
      message     : (state.CommonMessageReducer && state.CommonMessageReducer.message) ? state.CommonMessageReducer.message : null,
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(UserAuthorisation);