import { useState,  useEffect} from "react";
import { connect } from "react-redux";
import { Button, Form } from 'react-bootstrap';
import logo from '../../assets/images/BB.png';
import eyeLogo from '../../assets/images/eye.png';
import slashedEye from '../../assets/images/unseen.png';
import { otpFormValidation } from '../../validations/otpFormValidation';
import { forgotPassword,checkUserOtp,clearCommoneMessage } from '../../redux/actions/authAction';
import Footer from "../../layout/elements/footer";
import { Link,useNavigate } from "react-router-dom";

const Otp = (props:any) => {
  
  let   [eyeImg1,             seteyeImg1]             = useState(<img src={slashedEye} alt="" />);
  let   [eyeImg2,             seteyeImg2]             = useState(<img src={slashedEye} alt="" />);
  const navTo                                         = useNavigate();
  const [formData,            setFormData]            = useState({OTP:'',newPassword:'',confirmPassword:''});
  const [errors,              setErrors]              = useState({OTP:'',newPassword:'',confirmPassword:'',validNewPassword:'',validConfirmPassword:'',responseError:''});
  const [newPasswordType,     setNewPasswordType]     = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [successMessage,      setSuccessMessage]      = useState({responseSuccess:''});
  const [loading,             setLoading]             = useState(true);
  const [userEmail,           setUserEmail]           = useState<any>('');
  const [resendOTP_Flag,      setResendOTP_Flag]      = useState(false);
  
  useEffect(() =>{
    if(localStorage.getItem('email')){
      let lStorage = localStorage.getItem('email');
      setUserEmail(lStorage);
    }else{
      navTo('/forgot-password')
    }
  },[])

  const togglePassword = (params:string) => {
    if (newPasswordType === "password" && params === 'new') { // New Password
        seteyeImg1(<img src={eyeLogo} alt="" />);
        setNewPasswordType("text")
        return;
    }else if(params === 'new'){
        seteyeImg1(<img src={slashedEye} alt="" />);
        setNewPasswordType("password")
    }

    if (confirmPasswordType === "password" && params === 'confirm') { // Confirm Password
        seteyeImg2(<img src={eyeLogo} alt="" />);
        setConfirmPasswordType("text")
        return;
    }else if(params === 'confirm'){
        seteyeImg2(<img src={slashedEye} alt="" />);
        setConfirmPasswordType("password")
    }
  }

  const handleChange = (event: any) => {
    setFormData({...formData, [event.target.name]:event.target.value});
    const checkValidation = otpFormValidation({...formData, [event.target.name]:event.target.value});
    setErrors(checkValidation);
    setSuccessMessage({responseSuccess:''})
  };
            
  const handleSubmit = (e:any) => {
    e.preventDefault();
    const checkValidation =otpFormValidation(formData);
    setErrors(checkValidation);
    let isValid = Object.keys(checkValidation).length === 0;
    if(isValid){
        let payLoad = {resetOTP:formData.OTP,newPassword:formData.newPassword,confirmPassword:formData.confirmPassword}
        setLoading(true);
        props.verifyOTP_and_CreateNewPassword(payLoad);
    }
  }

  const resendOTP = () =>{
    setLoading(true);
    props.resend({"email":userEmail});
  }

  useEffect(() =>{
    if(props.message){
      if(props.message.status === 'error'){
        setLoading(false);
        setSuccessMessage({responseSuccess:''})
        setErrors({...errors,responseError:((props.message && props.message.data) ? props.message.data : '')})
      }else if(props.message.status === 'success' && props.message.page === 'check_otp'){
        setLoading(false);
        setFormData({OTP:'',newPassword:'',confirmPassword:''});
        seteyeImg1(<img src={slashedEye} alt="" />);
        seteyeImg2(<img src={slashedEye} alt="" />);
        setSuccessMessage({responseSuccess:((props.message && props.message.data && props.message.data.message) ? props.message.data.message : '')})
        props.clearCommoneMessage(null);
        setTimeout(() =>{
          navTo("/login")
        },1500)
      }else if(props.message.status === 'success' && props.message.page === 'forgot_password'){
        setLoading(false);
        setFormData({OTP:'',newPassword:'',confirmPassword:''});
        seteyeImg1(<img src={slashedEye} alt="" />);
        seteyeImg2(<img src={slashedEye} alt="" />);
        setSuccessMessage({responseSuccess:((props.message && props.message.data) ? props.message.data : '')})
        // props.clearCommoneMessage(null);
        setTimeout(() =>{
          setResendOTP_Flag(true);
        },15*60*1000)
      }
    }
  },[props.message])

//---------------------------------------------------------------//

return (
    <>
    <div className="createAccount">
    <div className="userDetailBlock">
        <div className="formWrap align-items-center d-md-flex flex-column h-100 justify-content-center">
            <div className="text-center">
            <Link to="/">
              <img src={logo} className="App-logo" alt="logo" />
              </Link></div><br/>
            <h2 className="text-center">OTP Verification</h2>
            {loading ? <p className="lds-dual-ring" style={{margin: "-10px 0px 10px 0px"}}></p>
            :
              <>
                {(errors && !successMessage.responseSuccess && (errors.validNewPassword || errors.validConfirmPassword || errors.responseError )) && <p className={errors && errors.responseError ? `text-danger` : `text-success`}>{errors && errors.responseError ? errors.responseError : 
                  <>
                    Your password must contain a minimum of:
                    <ul >
                        <li style={{listStyleType:"disc"}}>Eight characters</li>
                        <li style={{listStyleType:"disc"}}>One uppercase letter</li>
                        <li style={{listStyleType:"disc"}}>One special character (@#%^&+)</li>
                        <li style={{listStyleType:"disc"}}>One number</li>
                    </ul>
                  </>
                }</p>}
                {successMessage && successMessage.responseSuccess && <p className="text-success">{successMessage.responseSuccess}</p>}
              </>
            }
            { props && props.message && props.message.page && props.message.page !== 'check_otp' && props.message.page === 'forgot_password' &&  
            <Form className="w-100">
                <Form.Group className="formRow">
                    <Form.Label>OTP</Form.Label>
                    <Form.Control type="text" name="OTP" id="forgotPassword_OTP" maxLength={4} pattern="^[0-9]{4}$" placeholder="Enter valid otp"  onChange={handleChange} value={formData && formData.OTP ? formData.OTP : ''}/>
                    {errors && errors.OTP && <p className="text-danger">{errors && errors.OTP ? errors.OTP : '' }</p>}
                </Form.Group>
                <Form.Group className="formRow passwordHide">
                    <Form.Label>New Password</Form.Label>
                    <Form.Control type={newPasswordType} id="forgotPassword_newPassword" name="newPassword" placeholder="Enter new password"  onChange={handleChange} value={formData && formData.newPassword ? formData.newPassword: ''}/>
                    <Button className="hideIcon" onClick={()=>togglePassword('new')} style={{position:'absolute',top:'0%',marginLeft:'-15px',bottom:'inherit'}}>{eyeImg1}</Button>
                    {errors && (errors.newPassword || errors.validNewPassword) && <p className="text-danger">{errors.newPassword ? errors.newPassword : errors.validNewPassword ? errors.validNewPassword : '' }</p>}
                </Form.Group>
                <Form.Group className="formRow passwordHide">
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control type={confirmPasswordType} id="forgotPassword_confirmPassword" name="confirmPassword" placeholder="Enter confirm password"  onChange={handleChange} value={formData && formData.confirmPassword ? formData.confirmPassword :''}/>
                    <Button className="hideIcon" onClick={()=>togglePassword('confirm')} style={{position:'absolute',top:'0%',marginLeft:'-15px',bottom:'inherit'}}>{eyeImg2}</Button>
                    {resendOTP_Flag && <Link to={'#'} className="float-end text-light" onClick={resendOTP} >Resend OTP</Link>}
                    {errors && (errors.confirmPassword || errors.validConfirmPassword) && <p className="text-danger">{errors.confirmPassword ? errors.confirmPassword : errors.validConfirmPassword ? errors.validConfirmPassword : '' }</p>}
                </Form.Group>
                <div className="d-flex  justify-content-between" style={!loading && (Object.keys(errors).length === 6 || Object.keys(errors).length === 0)  ? {} :{cursor:'not-allowed'} }>
                  <Button onClick={()=>{navTo('/login')}} className="darkBtn"  variant="primary" type="button">CANCEL</Button>
                  <Button onClick={handleSubmit} className="darkBtn" variant="primary" type="submit" disabled={!loading && (Object.keys(errors).length === 6 || Object.keys(errors).length === 0)  ? false : true}>CONTINUE</Button>
                </div>
            </Form>
            }
        </div>
    </div>
</div>
<Footer/>
</>
  );
  }
  const mapDispatchToProps = (dispatch: any) => ({
    resend                          : (data: any) => dispatch(forgotPassword(data)),
    verifyOTP_and_CreateNewPassword : (data: any) => dispatch(checkUserOtp(data)),
    clearCommoneMessage             : (data: any) => dispatch(clearCommoneMessage(data)),
});

const mapStateToProps = (state: any) => {
    return {
      message           : (state.CommonMessageReducer && state.CommonMessageReducer.message) ? state.CommonMessageReducer.message : null,
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Otp);