export function changePasswordValidation(value: any) {
    const passwordRegex = /^(?!.*\s)(?=.{6,})(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+=]).*$/;

    let errors: any = {};
        
    if (value.oldPassword === '') {
        errors['oldPassword'] = 'Old password is required';
    }else if ((Object.keys(value.oldPassword).length < 8)) {
        errors['validOldPassword'] = 'Password must be 8 characters long';
    }else if (!passwordRegex.test(value.oldPassword)) {
        errors['validOldPassword'] = 'Enter a valid password';
    }

    if (value.newPassword === '') {
        errors['newPassword'] = 'New password is required';
    }else if ((Object.keys(value.newPassword).length < 8)) {
        errors['validNewPassword'] = 'Password must be 8 characters long';
    }else if (!passwordRegex.test(value.newPassword)) {
        errors['validNewPassword'] = 'Enter a valid password';
    }

    if (value.confirmPassword === '') {
        errors['confirmPassword'] = 'Confirm password is required';
    }else if ((Object.keys(value.confirmPassword).length < 8)) {
        errors['validConfirmPassword'] = 'Password must be 8 characters long';
    }else if (!passwordRegex.test(value.confirmPassword)) {
        errors['validConfirmPassword'] = 'Enter a valid password';
    }else if (value.newPassword !== value.confirmPassword) {
        errors['confirmPassword'] = 'Passwords do not match';
    }

    return errors;
}