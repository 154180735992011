import {LIST_USER,USER} from "../constant/index";

const initialState = {
    loading          : false,
    userPeopleData   : null,
    unsubscribedData : null, 
}
const UserReducer = (state = initialState, action) => {
    switch (action.type) {    
        case LIST_USER.SET_USER_PEOPLE_TO_STATE:
            return { ...state, userPeopleData: action.data, loading: false, };
        
        case USER.GET_MAIL_UNSUBSCRIBED_CONFRIMATION:
            return { ...state, unsubscribedData: action.data, loading: false, };    
            
        default:
            return state;
    }
}
export default UserReducer;    
