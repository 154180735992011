// import { lazy, Suspense } from "react";

// const PlansForNormalUsers = lazy(() => import("./plans"));
// const PlansWithPromoCode  = lazy(() => import("./plansWithpromoCode"));

import PlansForNormalUsers from "./plans";
import PlansWithPromoCode from "./plansWithpromoCode";

function PlansTemplate() {
    const loading      = <div className="text-warning text-center">Loading...</div>;
    const userData:any = localStorage.getItem('profileData');
    const MarketerID   = (JSON.parse(`${userData}`).marketerId);
    
    return (
        <>
            {
                !MarketerID ?
                    // <Suspense fallback={loading}>
                        <PlansForNormalUsers />
                    // </Suspense>
            
            :   
                MarketerID &&
                    // <Suspense fallback={loading}>    
                        <PlansWithPromoCode />    
                    // </Suspense>
            }
        </>
    )
}

export default PlansTemplate