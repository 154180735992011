import { BOX_API } from "../constant/index";

const initialState = {
  loading                    : false,
  boxCoverData               : null,
  selectedBoxCoverData       : null,
  getSearchedResultByName    : null,
  usernameByEmailData        : null,
  boxIconData                : null,
  createBoxData              : null,
  boxListData                : null,
  publicBoxListData          : null,
  receivedBoxListData        : null,
  receivedBoxDetailedData    : null,
  childReceivedBoxListData   : null,
  fileUploaded               : null,
  UploadedFilesData          : null,
  scheduledBoxData           : null,
  updateBoxData              : null,
  boxByIdData                : null,
  deleteBoxData              : null,
  listBoxDetails             : null,
  userById                   : null,
  deleteUploadedFileResponse : null,
  boxRecordsListingData      : null,
  publicBoxDetails           : null,
  uploadedCoverPhotoByUser   : null,
  createdBoxDetails          : null,
  updateRecipient            : null,
  updatedMessage             : null,
  getCoverPhotoUploadedByUser: null,
  getRelationShipList        : null,
  tempNewRecpPic             : null,
  schedulerRemoved           : null,
  releaseBoxData             : null,
}
const BoxReducer = (state = initialState, action) => {

  switch (action.type) {

    case BOX_API.SET_BOX_COVER_PHOTO_TO_STATE:
      return { ...state, boxCoverData: action.data, loading: false, };

    case BOX_API.SET_SELECTED_BOX_COVER_PHOTO_TO_STATE:
      return { ...state, selectedBoxCoverData: action.data, loading: false, };

    case BOX_API.SET_RESULT_SEARCHED_BY_NAME:
      return { ...state, getSearchedResultByName: action.data, loading: false, };

      case BOX_API.SET_USER_BY_EMAIL_TO_STATE:
      return { ...state, usernameByEmailData: action.data, loading: false, };

    case BOX_API.SET_BOX_ICON_TO_STATE:
      return { ...state, boxIconData: action.data, loading: false, };

    case BOX_API.SET_CREATE_BOX_TO_STATE:
      return { ...state, createBoxData: action.data, loading: false, };

    case BOX_API.SET_BOX_LIST_TO_STATE:
      return { ...state, boxListData: action.data, loading: false, };

    case BOX_API.SET_PUBLIC_BOX_LIST_TO_STATE:
      return { ...state, publicBoxListData: action.data, loading: false, };

    case BOX_API.SET_RECEIVED_BOX_LIST:
      return { ...state, receivedBoxListData: action.data, loading: false, };
    
    case BOX_API.SET_CHILD_RECEIVED_BOX_LIST:
      return { ...state, childReceivedBoxListData: action.data, loading: false, };  

    case BOX_API.SET_RECEIVED_BOX_DETAILS:
      return { ...state, receivedBoxDetailedData: action.data, loading: false, };  
    
    case BOX_API.UPLOAD_BOX_FILES_SUCCESS:
      return { ...state, fileUploaded: action.data, loading: false, };

    case BOX_API.SET_CREATED_BOX_DETAILS:
      return { ...state, createdBoxDetails: action.data, loading: false, };

    case BOX_API.SET_UPLOADED_FILES_DETAILS:
      return { ...state, UploadedFilesData: action.data, loading: false, };

    case BOX_API.DELETE_UPLOADED_FILES_SUCCESS:
      return { ...state, deleteUploadedFileResponse: action.data, loading: false, };
    
    case BOX_API.SET_SCHEDULE_BOX_TO_STATE:
      return { ...state, scheduledBoxData: action.data, loading: false, };
    
    case BOX_API.SET_BOX_BY_ID_TO_STATE:
      return { ...state, boxByIdData: action.data, loading: false, };

    case BOX_API.SET_UPDATE_BOX_TO_STATE:
      return { ...state, updateBoxData: action.data, loading: false, };

    case BOX_API.SET_DELETE_BOX_TO_STATE:
      return { ...state, deleteBoxData: action.data, loading: false, };

    case BOX_API.SET_USER_BY_ID_TO_STATE:
      return { ...state, userById: action.data, loading: false, };

    case BOX_API.SET_LIST_BOX_CONTENT_TO_STATE:
      return { ...state, listBoxDetails: action.data, loading: false, };

    case BOX_API.SET_BOX_RECORDS_TO_STATE:
      return { ...state, boxRecordsListingData: action.data, loading: false, };
      
    case BOX_API.SET_PUBLIC_BOX_DETAIL_CONTENT:
      return { ...state, publicBoxDetails: action.data, loading: false, };  
      
    case BOX_API.GET_UPLOADED_COVER_PHOTO_BY_USER:
      return { ...state, uploadedCoverPhotoByUser: action.data, loading: false, }; 
    
    case BOX_API.GET_RESPONSE_AFTER_RECIPIENT_UPDATE:
      return { ...state, updateRecipient: action.data, loading: false, };
    
    case BOX_API.GET_UPDATED_MESSAGE :
      return { ...state, updatedMessage: action.data, loading: false, };
      
    case BOX_API.GET_RESPONSE_AFTER_COVERPHOTO_UPLOAD:
      return { ...state, getCoverPhotoUploadedByUser: action.data, loading: false, };  
    
    case BOX_API.SAVED_AUDIO_OR_VIDEO_LINK_RESPONSE:
      return { ...state, fileUploaded: action.data, loading: false, };    

    case BOX_API.SET_RELATIONSHIP_MASTER_DATA:
      return { ...state, getRelationShipList: action.data, loading: false, };      
    
    case BOX_API.SET_UPLOAD_RECIPIENT_PROFILE_PIC:
      return { ...state, tempNewRecpPic: action.data, loading: false, };

    case BOX_API.GET_RESP_AFTER_SCHEDULER_REMOVE:
      return { ...state, schedulerRemoved: action.data, loading: false, };

    case BOX_API.GET_RELEASE_BOX_CONFIRMATION_RESPONSE:
        return { ...state, releaseBoxData: action.data, loading: false, };  
    
    default:
      return state;
  }
};

export default BoxReducer;
