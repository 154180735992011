import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams }                 from "react-router-dom";
import Layout                         from "../../layout/Layout";
import boxImg                         from "../../assets/images/no-image-available.png";
import { connect }                    from "react-redux";
import { BASE_URLS }                  from "../../redux/constant";
import { listBoxContent, getBoxById } from "../../redux/actions/boxAction";
import { Container, Dropdown, DropdownButton, Button } from "react-bootstrap";
import DisplayModal from "./uploaded_folder/display-modal";
import pdf from '../../assets/images/pdf.png';
import docx from '../../assets/images/docx.png';
import file from '../../assets/images/file.jpg';
import Axios from 'axios';
import NotAuthorized from "../frontend/error_pages/not_authorised";

var FileSaver = require('file-saver');

  const ViewDetailsList = (props: any) => {
  const redirectTo                    = useNavigate();  
  const { id }                        = useParams();
  const [contentList, setContentList] = useState<any>([]);
  const [loader,      setLoader]      = useState<boolean>(false);
  const [docLoader,   setDocLoader]   = useState<boolean>(false);
  const [modalShow,   setModalShow]   = useState<boolean>(false);
  const [modalId  ,   setModalId]     = useState<number>(0);
  const handleClose                   = () => setModalShow(false);
  const handleShow                    = (e:any) => {setModalShow(true);setModalId(e)};
  const [fileId,      setFileId]      = useState(0);
  const [authFlag,    setAuthFlag]    = useState(false);
  let boxId = id;
  
  useEffect(() => {
    if (boxId) {
      setLoader(true);
      props.listBoxContent({ 'id': boxId });
      props.getBoxById({id:boxId})
    }
  }, [boxId]);

  useEffect(() => {
    if (props.listBoxDetails && boxId) {
      setLoader(false);
      setContentList(props.listBoxDetails);
    }
  }, [props.listBoxDetails]);

  const handleDownload = (filePath:any,fileId:number) =>{
    setFileId(fileId);
    setDocLoader(true);
    const userData     = JSON.parse(localStorage.getItem('userData')||'{}');
    const access_token = (userData && userData.Token)?userData.Token:'';
    if (access_token) {
      const config = { headers: {'Authorization': 'Bearer '+access_token} }
      let url      = `${BASE_URLS.BASE_URL}/user-box-files/resource/download?key=${filePath}`;
      Axios.get(url,config).then(function (response:any) {
        const link = document.createElement('a');
        link.href  = response.data;
        document.body.appendChild(link);
        link.click();
        setDocLoader(false);
      }).catch(function (error) {
        console.log(error);
      });
    }
  }

  const extractVideoId = (url:any) => {
    let videoId               = '';
    const videoUrlPattern     = /youtube\.com\/watch\?v=([a-zA-Z0-9_-]+)/;
    const shortenedUrlPattern = /youtu\.be\/([a-zA-Z0-9_-]+)/;
    if (videoUrlPattern.test(url)) {
      videoId = url.match(videoUrlPattern)[1];// Extract video ID from standard video URL
    } else if (shortenedUrlPattern.test(url)) {
      videoId = url.match(shortenedUrlPattern)[1];// Extract video ID from shortened URL
    }
    return videoId;
  };

  useEffect(() =>{
    if(props.message && props.message.status == 'error' && props.message.page == 'box-preview'){
      if(props.message.data == 'Unauthorized'){
        setAuthFlag(true);
      }else{
        setAuthFlag(false);
      }
    }else{
      setAuthFlag(false);
    }
  },[props.message])
//------------------------------------------------------------------------------------------------//  

  return (
    <Layout>
      <div className="boxes createBox box-details">
        {authFlag ?
        <NotAuthorized/>
        :
        <Container>
          <div className={loader ? `text-center boxListFolder` : `boxListFolder`}>
            <div className="userDetails">
              <div className="img">
                <img src={props.boxByIdData && props.boxByIdData.coverPicture?`${BASE_URLS.BASE_URL}/user-box-files/resource?key=${props.boxByIdData.coverPicture}`:boxImg} alt="" />
              </div>
              <div className="userName">{props.boxByIdData && props.boxByIdData.boxName ? props.boxByIdData.boxName.toUpperCase() : ''}</div>
            </div>
            {loader ? 
              <div className="lds-dual-ring">
                <span className="text-warning">Loading... please wait!</span>
              </div>
            :
            <>
            <div className="row justify-content-end floatingBtnBlock">
              <div className="col-auto">
                {/* <div className="fliterBtn">
                  <DropdownButton id="dropdown-basic-button" title="Filter" align="end" >
                    <Dropdown.Item href="#/action-1"><img src={alphabetIcon} alt="" /> Alphabetically</Dropdown.Item>
                    <Dropdown.Item href="#/action-2"><img src={dateIcon} alt="" /> Date Created</Dropdown.Item>
                    <Dropdown.Item href="#/action-3"><img src={editedIcon} alt="" /> Last edited</Dropdown.Item>
                  </DropdownButton>
                </div> */}
                <Link to={'#'} style={{textDecoration:"none"}}>
                  <Button className="backBtn" onClick={() => redirectTo(-1)}>Back</Button>
                </Link>
              </div>
            </div>
            
            <div className="docBox">
              {contentList && contentList.length !== 0 ?
                <>
                {contentList.map((item: any, index: any) => {
                  if ( contentList && contentList[index] && contentList[index].boxFileType == "Images" ) {
                    return (
                      <>
                        <DisplayModal show={modalId == item.id && modalId !== 0 ? modalShow:false} onHide={handleClose} name={item && item.boxFileTitle ? item.boxFileTitle : '' }>
                          <img width="100%" src={`${BASE_URLS.BASE_URL}/user-box-files/resource?key=${item.boxFileName}`} alt="" />
                        </DisplayModal>
                        <div className="rowBox">
                          <div className="rowWrap">
                            <img className='rounded pointer' onClick={() => handleShow(item.id)} width="400" height="350px" src={`${BASE_URLS.BASE_URL}/user-box-files/resource?key=${item.boxFileName}`} alt="" />
                          </div>
                        </div>
                      </>
                    );
                  } else if ( contentList &&  contentList[index] && contentList[index].boxFileType == "Audio" ) {
                    return (
                      <div className="rowBox">
                        <div className="rowWrap">
                          <audio controls className="audio" style={{width:'400px'}}>
                            {/* {!item.links ? */}
                              <source src={`${BASE_URLS.BASE_URL}/user-box-files/resource?key=${item.boxFileName}`} />
                            {/* :
                              <source src={`${item.links}`} />
                            } */}
                          </audio>
                        </div>
                      </div>
                    );
                  } else if ( contentList &&  contentList[index] && contentList[index].boxFileType == "Messages") {
                    
                    return (
                      <div className="rowBox rounded msgWrap">
                        <div className="rowWrap">
                          <div className='messageBoxDetails' key={item.id}>
                            <div className="userDetailsBlock">
                              <div className="title">
                                <span className='text-light'>{item.boxFileTitle}</span>
                              </div>
                            </div>
                            <div className="messageTextView">
                              <p className="text-light" style={item.boxMessage && item.boxMessage.length > 50 ? {whiteSpace: "pre-line",height:"100px",overflowY:"scroll"} :{whiteSpace: "pre-line"}}>{item.boxMessage}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  } else if (contentList && contentList[index] && contentList[index].boxFileType == "Documents" ) {
                    var fileExt = item.boxFileName.split('.').pop();
                    return (
                      <div className="rowBox">
                        <div className="rowWrap">
                          {
                            docLoader && fileId === item.id ?
                            <span className='text-warning text-center lds-dual-ring mb-4' style={{fontSize:"14px",marginLeft: "11%",borderLeft: "1px solid",borderRight: "1px solid",borderRadius: "14px"}}>File downloading<br/>please wait...</span>
                          :
                          <img width={320} height={250} src={fileExt === 'pdf' ? pdf : fileExt === 'docx' ? docx : file } alt="" onClick={()=>handleDownload(item.boxFileName,item.id)} style={{cursor:'pointer'}}/>
                          }
                        </div>
                      </div>
                    );
                  } else if ( contentList && contentList[index] && contentList[index].boxFileType == "Videos" ) {
                    // var URL = item && item.links && item.links
                    // var vID = extractVideoId(URL);
                    return (
                      <div className="rowBox">
                        <div className="rowWrap">
                          {(item.boxFileName !== null && item.links === null) ?
                            <video width="400" height="350"  controls={true} key={item.id} className="rounded">
                              <source src={`${BASE_URLS.BASE_URL}/user-box-files/resource?key=${item.boxFileName}` }/>
                            </video>
                          :
                          <></>
                            // <iframe width="350" height="350" src={ vID ? `https://www.youtube.com/embed/${vID}` : item.links} allowFullScreen title='video'></iframe>
                          }
                        </div>
                      </div>
                    );
                  }
                })}
                </>
               :
               <p className='text-warning text-center'></p> 
              }
            </div>
            </>
            }
          </div>
        </Container>
        }
      </div>
    </Layout>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  listBoxContent : (data: any) => dispatch(listBoxContent(data)),
  getBoxById     : (data: any) => dispatch(getBoxById(data)),
});

const mapStateToProps = (state: any) => {
  return {
    listBoxDetails : state.BoxReducer && state.BoxReducer.listBoxDetails ? state.BoxReducer.listBoxDetails : null,
    boxByIdData    : state.BoxReducer && state.BoxReducer.boxByIdData    ? state.BoxReducer.boxByIdData    : null,
    message        : (state.CommonMessageReducer && state.CommonMessageReducer.message)   ? state.CommonMessageReducer.message   : null,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewDetailsList);
