import {put, call} from 'redux-saga/effects';
import {getCountryService,getStatesService,getCitiesService,getCountryIdService,getUserLocation,getUserStateLocation_Service,getUserCountryLocation_Service} from '../services/countryApiService'
import {COUNTRY_DATA, COMMON_MESSAGE} from '../constant/index';
import {generateReadableError} from '../../hooks/messages';
const clearErrorMessage = {
  type: COMMON_MESSAGE,
  data: {
    status: '',
    page: '',
    data: [],
  }}

export function* fetchCountryList({payload}) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(getCountryService, payload);
     yield put({type: COUNTRY_DATA.SET_COUNTRIES_TO_STATE, data});

  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'error',
        page: 'country api',
        data: generateReadableError(error),
      },
    });
  }
}

export function* fetchStateList({payload}) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(getStatesService, payload);
    yield put({type: COUNTRY_DATA.SET_STATES_TO_STATE, data});
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'error',
        page: 'states api',
        data: generateReadableError(error),
      },
    });
  }
}

export function* fetchCityList({payload}) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(getCitiesService, payload);
    yield put({type: COUNTRY_DATA.SET_CITIES_TO_STATE, data});
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'success',
        page: 'cities api',
        data: '',
      },
    });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'error',
        page: 'cities api',
        data: generateReadableError(error),
      },
    });
  }
}

export function* fetchCountryById({payload}) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(getCountryIdService, payload);
     yield put({type: COUNTRY_DATA.SET_COUNTRY_ID, data});
    localStorage.setItem('countryID',JSON.stringify(data))
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'error',
        page: 'country api',
        data: generateReadableError(error),
      },
    });
  }
}

export function* fetchUserLocation({payload}) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(getUserLocation, payload);
    yield put({type: COUNTRY_DATA.SET_LOCATION, data});
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'error',
        page: 'Public Box User Detail Page',
        data: generateReadableError(error),
      },
    });
  }
}

export function* fetchUserStateLocation({payload}) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(getUserStateLocation_Service, payload);
    yield put({type: COUNTRY_DATA.SET_STATE_LOCATION, data});
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'error',
        page: 'Public Box User Detail Page',
        data: generateReadableError(error),
      },
    });
  }
}

export function* fetchUserCountryLocation({payload}) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(getUserCountryLocation_Service, payload);
    yield put({type: COUNTRY_DATA.SET_COUNTRY_LOCATION, data});
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'error',
        page: 'Public Box User Detail Page',
        data: generateReadableError(error),
      },
    });
  }
}
