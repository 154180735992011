import Api from './apiCall';
import {API_URLS} from '../constant/index';

export const getRecycleBinBoxService = async params => {
    const result = await Api.get(API_URLS.GET_RECYCLE_BIN_BOXES_URL, params);
    return result;
  };

export const getRecycleBinFileService = async params => {
    const result = await Api.get(API_URLS.GET_RECYCLE_BIN_FILES_URL, params);
    return result;
};

export const restoreItemService = async params => {
  let url      = API_URLS.RESTORE_ITEM_URL;
  const result = await Api.patch(url, params);
  return result;
};

export const deleteItemPermanentlyService = async params => {
  let url      = API_URLS.DELETE_ITEM_PERMANENTLY_URL;
  const result = await Api.delete(url, params);
  return result;
};