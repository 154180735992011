import { put, call } from "redux-saga/effects";
import {getAddAdminFoundationService,
  getFoundationListService,
  deleteAdminFoundationService,
  getFoundationDetailByIdService,
  updateAdminFoundationService,
} from '../../services/admin/adminFoundationService'
import { COMMON_MESSAGE, ADMIN_FOUNDATION } from "../../constant/index";
import { generateReadableError } from "../../../hooks/messages";

const clearErrorMessage = {
  type: COMMON_MESSAGE,
  data: {
    status: "",
    page: "",
    data: [],
  },
};


//ADD_ADMIN_FOLDER function

export function* fetchAddAdminFoundation({ payload }) {
  
  try {
    yield put({
      type: ADMIN_FOUNDATION.SET_ADD_ADMIN_FOUNDATION_TO_STATE,
      data: null,
    });
    yield put(clearErrorMessage);
    const data = yield call(getAddAdminFoundationService, payload);
    
    yield put({
      type: ADMIN_FOUNDATION.SET_ADD_ADMIN_FOUNDATION_TO_STATE,
      data,
    });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: "error",
        page: "admin foundation",
        data: generateReadableError(error),
      },
    });
  }
}
//fetchGetFoundationList


export function* fetchGetFoundationList({ payload }) {

try {
  yield put({
    type: ADMIN_FOUNDATION.SET_GET_FOUNDATION_LIST_TO_STATE,
    data: null,
  });
  yield put(clearErrorMessage);
  const data = yield call(getFoundationListService, payload);
  
  yield put({
    type: ADMIN_FOUNDATION.SET_GET_FOUNDATION_LIST_TO_STATE,
    data,
  });
} catch (error) {
  yield put({
    type: COMMON_MESSAGE,
    data: {
      status: "error",
      page: "admin foundation",
      data: generateReadableError(error),
    },
  });
}
}

//fetchDeleteAdminFoundation


export function* fetchDeleteAdminFoundation({ payload }) {

  try {
    yield put({
      type: ADMIN_FOUNDATION.SET_DELETE_ADMIN_FOUNDATION_TO_STATE,
      data: null,
    });
    yield put(clearErrorMessage);
    const data = yield call(deleteAdminFoundationService, payload);
    
    yield put({
      type: ADMIN_FOUNDATION.SET_DELETE_ADMIN_FOUNDATION_TO_STATE,
      data,
    });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: "error",
        page: "admin foundation",
        data: generateReadableError(error),
      },
    });
  }
  }

  //fetchGetFoundationDetailById
  export function* fetchGetFoundationDetailById({ payload }) {

    try {
      yield put({
        type: ADMIN_FOUNDATION.SET_GET_FOUNDATION_DETAIL_BY_ID_TO_STATE,
        data: null,
      });
      yield put(clearErrorMessage);
      const data = yield call(getFoundationDetailByIdService, payload);
      
      yield put({
        type: ADMIN_FOUNDATION.SET_GET_FOUNDATION_DETAIL_BY_ID_TO_STATE,
        data,
      });
    } catch (error) {
      yield put({
        type: COMMON_MESSAGE,
        data: {
          status: "error",
          page: "admin foundation",
          data: generateReadableError(error),
        },
      });
    }
    };

    export function* fetchUpdateAdminFoundation({ payload }) {

      try {
        yield put({
          type: ADMIN_FOUNDATION.SET_UPDATE_ADMIN_FOUNDATION_TO_STATE,
          data: null,
        });
        yield put(clearErrorMessage);
        const data = yield call(updateAdminFoundationService, payload);
        
        yield put({
          type: ADMIN_FOUNDATION.SET_UPDATE_ADMIN_FOUNDATION_TO_STATE,
          data,
        });
      } catch (error) {
        yield put({
          type: COMMON_MESSAGE,
          data: {
            status: "error",
            page: "admin foundation",
            data: generateReadableError(error),
          },
        });
      }
      };