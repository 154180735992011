import { ADMIN_FOLDER_MANAGEMENT } from "../../constant";

export const addAdminFolder = data => {
  
    return {
      type: ADMIN_FOLDER_MANAGEMENT.ADD_ADMIN_FOLDER,
      payload: data,
    };
  };
  export const getFolderDetailById = data => {
    return{
      type:ADMIN_FOLDER_MANAGEMENT.GET_FOLDER_DETAIL_BY_ID,
      payload:data,
    }
  }

  export const deleteAdminFolder = data => {
    return{
      type:ADMIN_FOLDER_MANAGEMENT.DELETE_ADMIN_FOLDER,
      payload:data,
    }
  }
  export const updateAdminFolder = data => {
    return{
      type:ADMIN_FOLDER_MANAGEMENT.UPDATE_ADMIN_FOLDER,
      payload:data,
    }
  }
  