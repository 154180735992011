import { put, call } from "redux-saga/effects";
import {getAddAdminMarketerService,
  getMarketerListService,
  deleteAdminMarketerService,
  getMarketerDetailByIdService,
  updateAdminMarketerService,
  getMarketerPromoCodeService,
  getMarketerPromoCodeByIdService,
  getUserThroughMarketersService,
  getMarketerUsersByIdService,
  getUnsubscribedUserService,
  getMTHistoryService,
  downloadSubscribedCSV_Service
} from '../../services/admin/adminMarketerService'
import { COMMON_MESSAGE, ADMIN_MARKETER } from "../../constant/index";
import { generateReadableError } from "../../../hooks/messages";

const clearErrorMessage = {
  type: COMMON_MESSAGE,
  data: {
    status: "",
    page: "",
    data: [],
  },
};


//ADD_ADMIN_FOLDER function

export function* fetchAddAdminMarketer({ payload }) {
  
  try {
    yield put({
      type: ADMIN_MARKETER.SET_ADD_ADMIN_MARKETER_TO_STATE,
      data: null,
    });
    yield put(clearErrorMessage);
    const data = yield call(getAddAdminMarketerService, payload);
    
    yield put({
      type: ADMIN_MARKETER.SET_ADD_ADMIN_MARKETER_TO_STATE,
      data,
    });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: "error",
        page: "admin Marketer",
        data: generateReadableError(error),
      },
    });
  }
}
//fetchGetMarketerList


export function* fetchGetMarketerList({ payload }) {

try {
  yield put({
    type: ADMIN_MARKETER.SET_GET_MARKETER_LIST_TO_STATE,
    data: null,
  });
  yield put(clearErrorMessage);
  const data = yield call(getMarketerListService, payload);
  
  yield put({
    type: ADMIN_MARKETER.SET_GET_MARKETER_LIST_TO_STATE,
    data,
  });
} catch (error) {
  yield put({
    type: COMMON_MESSAGE,
    data: {
      status: "error",
      page: "admin Marketer",
      data: generateReadableError(error),
    },
  });
}
}

//fetchDeleteAdminMarketer


export function* fetchDeleteAdminMarketer({ payload }) {

  try {
    yield put({
      type: ADMIN_MARKETER.SET_DELETE_ADMIN_MARKETER_TO_STATE,
      data: null,
    });
    yield put(clearErrorMessage);
    const data = yield call(deleteAdminMarketerService, payload);
    
    yield put({
      type: ADMIN_MARKETER.SET_DELETE_ADMIN_MARKETER_TO_STATE,
      data,
    });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: "error",
        page: "admin Marketer",
        data: generateReadableError(error),
      },
    });
  }
  }

  //fetchGetMarketerDetailById
  export function* fetchGetMarketerDetailById({ payload }) {

    try {
      yield put({
        type: ADMIN_MARKETER.SET_GET_MARKETER_DETAIL_BY_ID_TO_STATE,
        data: null,
      });
      yield put(clearErrorMessage);
      const data = yield call(getMarketerDetailByIdService, payload);
      
      yield put({
        type: ADMIN_MARKETER.SET_GET_MARKETER_DETAIL_BY_ID_TO_STATE,
        data,
      });
    } catch (error) {
      yield put({
        type: COMMON_MESSAGE,
        data: {
          status: "error",
          page: "admin Marketer",
          data: generateReadableError(error),
        },
      });
    }
    };

    export function* fetchUpdateAdminMarketer({ payload }) {

      try {
        yield put({
          type: ADMIN_MARKETER.SET_UPDATE_ADMIN_MARKETER_TO_STATE,
          data: null,
        });
        yield put(clearErrorMessage);
        const data = yield call(updateAdminMarketerService, payload);
        
        yield put({
          type: ADMIN_MARKETER.SET_UPDATE_ADMIN_MARKETER_TO_STATE,
          data,
        });
      } catch (error) {
        yield put({
          type: COMMON_MESSAGE,
          data: {
            status: "error",
            page: "admin Marketer",
            data: generateReadableError(error),
          },
        });
      }
      };

      export function* fetchGetMarketerPromoCode({ payload }) {

        try {
          yield put({
            type: ADMIN_MARKETER.SET_GET_MARKETER_PROMOCODE_TO_STATE,
            data: null,
          });
          yield put(clearErrorMessage);
          const data = yield call(getMarketerPromoCodeService, payload);
          
          yield put({
            type: ADMIN_MARKETER.SET_GET_MARKETER_PROMOCODE_TO_STATE,
            data,
          });
        } catch (error) {
          yield put({
            type: COMMON_MESSAGE,
            data: {
              status: "error",
              page: "admin Marketer",
              data: generateReadableError(error),
            },
          });
        }
     };

    //  GET_MARKETER_PROMOCODE_BY_ID

    export function* fetchGetMarketerPromoCodeById({ payload }) {

      try {
        yield put({
          type: ADMIN_MARKETER.SET_GET_MARKETER_PROMOCODE_BY_ID_TO_STATE,
          data: null,
        });
        yield put(clearErrorMessage);
        const data = yield call(getMarketerPromoCodeByIdService, payload);
        
        yield put({
          type: ADMIN_MARKETER.SET_GET_MARKETER_PROMOCODE_BY_ID_TO_STATE,
          data,
        });
      } catch (error) {
        yield put({
          type: COMMON_MESSAGE,
          data: {
            status: "error",
            page: "admin Marketer",
            data: generateReadableError(error),
          },
        });
      }
   };

  //  /GET_USER_THROUGH_MARKETERS
  export function* fetchGetUserThroughMarketers({ payload }) {

    try {
      yield put({
        type: ADMIN_MARKETER.SET_GET_USER_THROUGH_MARKETERS_TO_STATE,
        data: null,
      });
      yield put(clearErrorMessage);
      const data = yield call(getUserThroughMarketersService, payload);
      
      yield put({
        type: ADMIN_MARKETER.SET_GET_USER_THROUGH_MARKETERS_TO_STATE,
        data,
      });
    } catch (error) {
      yield put({
        type: COMMON_MESSAGE,
        data: {
          status: "error",
          page: "admin Marketer",
          data: generateReadableError(error),
        },
      });
    }
 };

 //GET_MARKETER_USERS_BY_ID
 export function* fetchGetMarketerUsersById({ payload }) {

  try {
    yield put({
      type: ADMIN_MARKETER.SET_GET_MARKETER_USERS_BY_ID_TO_STATE,
      data: null,
    });
    yield put(clearErrorMessage);
    const data = yield call(getMarketerUsersByIdService, payload);
    
    yield put({
      type: ADMIN_MARKETER.SET_GET_MARKETER_USERS_BY_ID_TO_STATE,
      data,
    });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: "error",
        page: "admin Marketer",
        data: generateReadableError(error),
      },
    });
  }
};

export function* fetchGetUnsubscribedUser({ payload }) {

  try {
    yield put({
      type: ADMIN_MARKETER.SET_GET_UNSUBSCRIBED_USERS_TO_STATE,
      data: null,
    });
    yield put(clearErrorMessage);
    const data = yield call(getUnsubscribedUserService, payload);
    
    yield put({
      type: ADMIN_MARKETER.SET_GET_UNSUBSCRIBED_USERS_TO_STATE,
      data,
    });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: "error",
        page: "admin Marketer",
        data: generateReadableError(error),
      },
    });
  }
};

export function* fetchGetMTHistory({ payload }) {

  try {
    yield put({
      type: ADMIN_MARKETER.SET_GET_MT_HISTORY_TO_STATE,
      data: null,
    });
    yield put(clearErrorMessage);
    const data = yield call(getMTHistoryService, payload);
    
    yield put({
      type: ADMIN_MARKETER.SET_GET_MT_HISTORY_TO_STATE,
      data,
    });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: "error",
        page: "admin Marketer",
        data: generateReadableError(error),
      },
    });
  }
};

export function* downloadSubscribedCSV({ payload }) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(downloadSubscribedCSV_Service, payload);
    yield put({type: ADMIN_MARKETER.RESPONSE_DOWNLOAD_CSV, data,});
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: "error",
        page: "admin Marketer",
        data: generateReadableError(error),
      },
    });
  }
};