import { ADMIN_FOUNDATION } from "../../constant";

export const addAdminFoundation = data => {
  
    return {
      type: ADMIN_FOUNDATION.ADD_ADMIN_FOUNDATION,
      payload: data,
    };
  };
  export const getFoundationList = data => {
    return{
      type:ADMIN_FOUNDATION.GET_FOUNDATION_LIST,
      payload:data,
    }
  }
  
  export const deleteAdminFoundation = data => {
    return{
      type:ADMIN_FOUNDATION.DELETE_ADMIN_FOUNDATION,
      payload:data,
    }
  }
  
  export const getFoundationDetailById = data => {
    return{
      type:ADMIN_FOUNDATION.GET_FOUNDATION_DETAIL_BY_ID,
      payload:data,
    }
  }

export const updateAdminFoundation = data => {
  return{
    type:ADMIN_FOUNDATION.UPDATE_ADMIN_FOUNDATION,
    payload:data,
  }
}