import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Stack, Table } from 'react-bootstrap';
import { BsFillEyeFill,BsTrashFill } from 'react-icons/bs';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { getAdminUserAdminList } from '../../../../redux/actions/admin/adminUserAction';

const AdminUsers = (props:any) => {

    const [adminDataList,   setAdminDataList]   = useState([]);
    const navToUser                             = useNavigate();
    const navTo                                 = useNavigate();
   
    useEffect(()=>{
        props.getAdminList();
    },[])

    useEffect(()=>{
        if(props.adminUserAdminData){
            setAdminDataList(props.adminUserAdminData);
        }
    },[props.adminUserAdminData])
    
    const handleUserView=(e:any)=>{
        let userId=e;
        if(userId){
          navToUser('/admin-user-profile',{ state:{id:userId }})
        }
    }
    const handleUserDisable=(e:any)=>{
      let userId=e;
      Swal.fire({
        title              : `Are you sure you want to delete this user ? `,
        showDenyButton     : true,
        showCancelButton   : false,
        confirmButtonText  : "YES",
        denyButtonText     : `NO`,
        confirmButtonColor : "green",
        showCloseButton    : true,
      }).then((result) => {
        if (result.isConfirmed) {
           props.InActiveUsers(userId);
        }
        props.getAdminList();
      });
    }

    useEffect(()=>{
      if(props.disableAdminUserData)
      { props.getAdminList(); }
    },[props.disableAdminUserData])

    const handlePaymentDetails=(e:any)=>{
      let userId=e;
      navTo('/admin-user-payment-details', {state:{id:userId}})
    }

return (
    <div className='scrollBar'>
        <Table responsive>
            <thead>
                <tr>
                  <th className="text-warning text-center">S.No.</th>
                  <th className="text-warning text-center">User Name</th>
                  <th className="text-warning text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {adminDataList && adminDataList.map((list: any, index: number) => {
                  {
                    if(list.isActive==1) 
                    {
                      return (
                        <>
                          <tr>
                            <td className="text-center">{index + 1}</td>
                            <td className="text-center"> {list.firstName}  {list.lastName}</td>
                            <td className="text-center">
                              <button type="submit" className="btn btn-outline-warning m-2" onClick={()=>handleUserView(list.id)} >
                                <BsFillEyeFill />
                              </button>
                              <button type="submit" className="btn btn-outline-danger" onClick={()=>handleUserDisable(list.id)} >
                                {/* // id={list.planType} //onClick={removePlan} */}
                                <BsTrashFill/>
                              </button>
                            </td>
                          </tr>
                        </>
                      );
                    }
                  }})}
                </tbody>
              </Table>
            </div>
        )
    };

    const mapDispatchToProps = (dispatch: any) => ({
        getAdminList : (data: any) => dispatch(getAdminUserAdminList(data)),
    });
    
    const mapStateToProps = (state: any) => {
      return {
        adminUserAdminData   : (state.AdminUserReducer && state.AdminUserReducer.adminUserAdminData)   ? state.AdminUserReducer.adminUserAdminData   : null,
      };
    };
    
    export default connect(mapStateToProps, mapDispatchToProps) (AdminUsers);          