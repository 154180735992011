import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
import profileImage from '../../assets/images/no-image-available.png';
import logo from '../../assets/images/BB.png';
import cross from '../../assets/images/cross.png';
import { logoutUser } from '../../redux/actions/authAction';
import { updateUserProfile } from '../../redux/actions/profileAction';
import {BASE_URLS}  from '../../redux/constant';
import { getRecycleBinBoxes, getRecycleBinFiles } from "../../redux/actions/recycleBinAction";

 export interface MyProps{
  modalShow              ?: boolean;
  updateProfileData      ?: any;
  updateProfileImageData ?: any;
  classNameValue         ?: boolean;
  history                ?: any;
  profileData            ?: any;
  message                ?: any;
  recycleBoxData         ?: any;
  recycleFileData        ?: any;
  
  openEditProfileModal   ?: (close:boolean)=> void;
  logoutUser             ?: () => void;
  handleDrawerOpen       ?: (open:boolean) => void;
  updateUserProfile      ?: (data: any) => void;
  getProfile             ?: (data: any) => void;
  searching              ?: (data: boolean) => void;
  isSearchIconClicked    ?: (data: boolean) => void;
  getRecycleBinBoxes     ?: () =>  void;
  getRecycleBinFiles     ?: () =>  void;
}

const SidebarMenu = (props:MyProps) => {
  const navOut    = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
 
  const toggleSidebar = () => {
    props.handleDrawerOpen && props.handleDrawerOpen(false)
  }

  const handleLogo = () =>{
    props.searching && props.searching(false);
    props.isSearchIconClicked && props.isSearchIconClicked(false); 
  }

  let userData = localStorage.getItem('profileData');
 
  function handleLogout() {
    localStorage.clear();
    props.logoutUser && props.logoutUser();
    navOut('/');
    return;
  }

  function invokeRecycleBinActions(){
    props.getRecycleBinBoxes && props.getRecycleBinBoxes();
    props.getRecycleBinFiles && props.getRecycleBinFiles();
    return;
  }
  
  const [userDetails, setUserDetails] = useState<any>({})
 
    useEffect(()=>{
      if (props.profileData) {
        setUserDetails(props.profileData);
        invokeRecycleBinActions();
      }
    },[props.profileData])
   
    useEffect(()=>{
      props.classNameValue && setIsOpen(props.classNameValue);
    },[props.classNameValue])

    useEffect(()=>{
      if(props.message && props.message.status === 'success' && props.message.page === 'edit_profile_picture'){
        setUserDetails({...userDetails,userImg:props.message.data.userImg});
      }
    },[props.message])
    
//----------------------------------------------------------------------------// 

  return (
    <div className={`leftWrap ${props.classNameValue == true ? 'showNav' : ''}`} >
      {userData !== null ? <div onClick={handleLogo}><Link to="/homepage" className="app-logo-frame"><img src={logo} className="App-logo" alt="logo" width="99"/></Link></div>:''}
      <Button className="closeNav" onClick={toggleSidebar}><img src={cross} alt="" /></Button>
      <div className="navigation">
        {props && props.profileData && props.profileData.verified && props.profileData.verified === 1 ?
          <ul>
            <li className="addBtn pointer" onClick={()=>navOut("/create-box")}><Link to="/create-box">Add New Box</Link></li>
            <li className="myboxes pointer" onClick={()=>navOut("/homepage")}><Link to="/homepage">My Boxes</Link></li>
            <li className="plans pointer" onClick={()=>navOut("/select-plans")}><Link to="/select-plans">Plans</Link></li>
            
            <li className="profile">
              <Link to="/edit-profile">
                <label className="profileImg pointer">
                  <img src= {(userDetails && userDetails.userImg)?`${BASE_URLS.BASE_URL}/user-box-files/resource?key=${userDetails.userImg}`: profileImage}  alt="" />
                </label>
                Profile
              </Link>
            </li>
            
            <li className='people pointer' onClick={()=>navOut("/people")}><Link to="/people">People</Link></li>
            <li className={(props.recycleBoxData && props.recycleBoxData.length === 0) && (props.recycleFileData && props.recycleFileData.length === 0) ? `recyclebinEmpty pointer` : `recyclebinFull pointer`} onClick={()=>navOut("/recycle-bin")}><Link to="/recycle-bin">Recycle Bin</Link></li>
            <li className='password pointer' onClick={()=>navOut("/change-password")}><Link to="/change-password">Change Password</Link></li>
            <li className="terms pointer" onClick={()=>navOut("/terms-and-condition")}><Link to="/terms-and-condition">Terms &amp; Conditions</Link></li>
            <li className="logout pointer" onClick={handleLogout}><Link to="#">Logout</Link></li>
          </ul>
        :
          <ul>
            <li className="addBtn"><Link to="#">Add New Box</Link></li>
            <li className="myboxes"><Link to="#">My Boxes</Link></li>
            <li className="plans pointer"><Link to="/select-plans">Plans</Link></li>

            <li className="profile">
              <Link to="/edit-profile">
                <span className="profileImg">
                  <img src= {(userDetails && userDetails.userImg)?`${BASE_URLS.BASE_URL}/user-box-files/resource?key=${userDetails.userImg}`: profileImage}  alt="" />
                </span>
                Profile
              </Link>
            </li>
            
            <li className='people pointer'><Link to="#">People</Link></li>
            <li className='recyclebinEmpty pointer'><Link to="#">Recycle Bin</Link></li>
            <li className="password"><Link to="#">Change Password</Link></li>
            <li className="terms pointer"><Link to="/terms-and-condition">Terms &amp; Conditions</Link></li>
            <li className="logout pointer" onClick={handleLogout}><Link to="#">Logout</Link></li>
          </ul>
        }
      </div>
    </div>
  )
};

const mapDispatchToProps = (dispatch: any) => ({
  logoutUser         : ()=>dispatch(logoutUser()),
  updateUserProfile  : (data: any) => dispatch(updateUserProfile(data)),
  getRecycleBinBoxes : () => dispatch(getRecycleBinBoxes()),
  getRecycleBinFiles : () => dispatch(getRecycleBinFiles()),
});

const mapStateToProps = (state: any) => {
  return {
    profileData       : (state.ProfilePageReducer   && state.ProfilePageReducer.profileData) ? state.ProfilePageReducer.profileData : null,
    updateProfileData : (state.ProfilePageReducer   && state.ProfilePageReducer.updateProfileData) ? state.ProfilePageReducer.updateProfileData : null,
    recycleBoxData    : (state.RecycleBinReducer    && state.RecycleBinReducer.recycleBoxData)  ? state.RecycleBinReducer.recycleBoxData  : null,
    recycleFileData   : (state.RecycleBinReducer    && state.RecycleBinReducer.recycleFileData) ? state.RecycleBinReducer.recycleFileData : null,
    message           : (state.CommonMessageReducer && state.CommonMessageReducer.message)       ? state.CommonMessageReducer.message       : null,
    state
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(SidebarMenu);
