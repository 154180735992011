import { RECYCLE_BIN } from "../constant";

export const getRecycleBinBoxes = data => {
  return {
    type: RECYCLE_BIN.GET_RECYCLE_BIN_BOXES,
    payload: data,
  };
};

export const getRecycleBinFiles = data => {
  return {
    type: RECYCLE_BIN.GET_RECYCLE_BIN_FILES,
    payload: data,
  };
};

export const restoreItem = data => {
  return {
    type: RECYCLE_BIN.RESTORE_ITEM,
    payload: data,
  };
};

export const deleteItem = data => {
  return {
    type: RECYCLE_BIN.DELETE_ITEM_PERMANENTLY,
    payload: data,
  };
};
 