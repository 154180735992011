import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo                  from '../../assets/images/BB.png';
import { Button,Form } from 'react-bootstrap';
import Footer from '../../layout/elements/footer';
import {releaseBoxes} from '../../redux/actions/boxAction';
import { connect } from 'react-redux';
import { BOX_API, COMMON_MESSAGE } from '../../redux/constant';
import { BsEmojiSmile } from 'react-icons/bs';

function UserBoxRelease(props:any) {
    useEffect(() =>{
        setTimeout(() =>{
            setLoading(false);
          },1000)
    },[])

    const redirectTo            = useNavigate();
    const { state }             = useLocation();
    const [errors,  setErrors]  = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(true);
    
    const handleSubmit = (e:any) =>{
        e.preventDefault();
        let payload = {
            "boxId"          : [state.boxId],
            "recipientEmail" : state.email,
            "userId"         : state.userId
        }
        setLoading(true);
        props.releaseBoxes(payload)
    }

    useEffect(() =>{
        if(props.message && props.message.status == 'error'){
            setErrors(props.message.data);
        }
    },[props.message])

    useEffect(() =>{
        if(errors){
            setTimeout(() =>{
                setErrors('');
                props.clearMessage(null);
            },3000)
        }
    },[errors])

    useEffect(() =>{
        if(props.releaseBoxData && props.releaseBoxData.code == 200){
            setSuccess(props.releaseBoxData.message)
        }
    },[props.releaseBoxData])

    useEffect(() =>{
        if(success){
            setTimeout(() =>{
                props.clearReleaseBox(null);
            },3000)
        }
    },[success])

//----------------------------------------------------------------------//    
    
  return (
    <>
        <div className="createAccount">
            <div className="userDetailBlock">
                <div className="formWrap align-items-center d-md-flex flex-column h-100 justify-content-center">
                    <div className="text-center">
                        <Link to="/">
                        <img src={logo} className="App-logo" alt="logo" />
                        </Link>
                    </div><br/>
                    {!success ?
                        <>
                            <h2 className="text-center text-warning">Release Boxes</h2>
                            {loading ? 
                                <p className="lds-dual-ring" style={{margin: "-10px 0px 10px 0px"}}></p>
                            :
                                <>
                                    {(errors ) && <p className={`text-danger`}>{errors}</p>}
                                    <Form className="w-100">
                                        <Form.Group className="formRow">
                                            <Form.Label>For :</Form.Label>
                                            <Form.Label><h2>{state.email}</h2></Form.Label>
                                        </Form.Group>
                                        <div className="d-flex  justify-content-between" style={!loading ? {} :{cursor:'not-allowed'} }>
                                            <Button onClick={()=>{redirectTo('/login')}} className="darkBtn"  variant="primary" type="button">CANCEL</Button>
                                            <Button onClick={handleSubmit} className="yellowBtn" variant="primary" type="submit" disabled={!loading ? false : true}>Confirm</Button>
                                        </div>
                                    </Form>
                                </>
                            }
                        </>
                    :
                        success && 
                            <div className='text-center text-warning'>
                                <BsEmojiSmile style={{fontSize:'40px'}}/>
                                <p className='text-warning text-center'> THANK YOU!</p>
                                <p className={`rounded p-2 text-light text-center`} style={{fontSize:'20px',fontWeight:'700'}}>{success}</p>
                                <Button onClick={()=>{redirectTo('/login')}} className="darkBtn" >Login</Button>
                            </div>
                    }
                </div>
            </div>
        </div>
    <Footer/>
</>)}

//-------------------------------------------------------------------------//

const mapDispatchToProps = (dispatch: any) => ({
    releaseBoxes    : (data: any) => dispatch(releaseBoxes(data)),
    clearMessage    : (data: any) => dispatch({type:COMMON_MESSAGE,data}),
    clearReleaseBox : (data: any) => dispatch({type:BOX_API.GET_RELEASE_BOX_CONFIRMATION_RESPONSE,data}),
  });
  
  const mapStateToProps = (state: any) => {
    return {
      message        : (state.CommonMessageReducer && state.CommonMessageReducer.message) ? state.CommonMessageReducer.message : null,
      releaseBoxData : (state.BoxReducer && state.BoxReducer.releaseBoxData) ? state.BoxReducer.releaseBoxData : null,
    };
  };

export default connect(mapStateToProps,mapDispatchToProps)(UserBoxRelease);