import { put, call } from "redux-saga/effects";
import {
  getAddAdminFolderService,
  getDeleteAdminFolderService,
  getUpdateAdminFolderService,
  getFolderDetailByIdService,
} from "../../services/admin/adminFolderManagementService";
import { COMMON_MESSAGE, ADMIN_FOLDER_MANAGEMENT } from "../../constant/index";
import { generateReadableError } from "../../../hooks/messages";

const clearErrorMessage = {
  type: COMMON_MESSAGE,
  data: {
    status: "",
    page: "",
    data: [],
  },
};


//ADD_ADMIN_FOLDER function

export function* fetchAddAdminFolder({ payload }) {
  
  try {
    yield put({
      type: ADMIN_FOLDER_MANAGEMENT.SET_ADD_ADMIN_FOLDER_TO_STATE,
      data: null,
    });
    yield put(clearErrorMessage);
    const data = yield call(getAddAdminFolderService, payload);
    
    yield put({
      type: ADMIN_FOLDER_MANAGEMENT.SET_ADD_ADMIN_FOLDER_TO_STATE,
      data,
    });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: "error",
        page: "admin folder",
        data: generateReadableError(error),
      },
    });
  }
}

export function* fetchUpdateAdminFolder({ payload }) {
  
  try {
    yield put({
      type: ADMIN_FOLDER_MANAGEMENT.SET_UPDATE_ADMIN_FOLDER_TO_STATE,
      data: null,
    });
    yield put(clearErrorMessage);
    const data = yield call(getUpdateAdminFolderService, payload);
    yield put({
      type: ADMIN_FOLDER_MANAGEMENT.SET_UPDATE_ADMIN_FOLDER_TO_STATE,
      data,
    });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: "error",
        page: "admin folder",
        data: generateReadableError(error),
      },
    });
  }
}


export function* fetchDeleteAdminFolder({ payload }) {
  
  try {
    yield put({
      type: ADMIN_FOLDER_MANAGEMENT.SET_DELETE_ADMIN_FOLDER_TO_STATE,
      data: null,
    });
    yield put(clearErrorMessage);
    const data = yield call(getDeleteAdminFolderService, payload);
    yield put({
      type: ADMIN_FOLDER_MANAGEMENT.SET_DELETE_ADMIN_FOLDER_TO_STATE,
      data,
    });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: "error",
        page: "admin folder",
        data: generateReadableError(error),
      },
    });
  }
}

export function* fetchGetFolderDetailById({ payload }) {
  try {
    yield put({
      type: ADMIN_FOLDER_MANAGEMENT.SET_GET_FOLDER_DETAIL_BY_ID_TO_STATE,
      data: null,
    });
    yield put(clearErrorMessage);
    const data = yield call(getFolderDetailByIdService, payload);
    yield put({
      type: ADMIN_FOLDER_MANAGEMENT.SET_GET_FOLDER_DETAIL_BY_ID_TO_STATE,
      data,
    });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: "error",
        page: "admin folder",
        data: generateReadableError(error),
      },
    });
  }
}