import logo                          from "../../assets/images/BB.png";
import menu                          from "../../assets/images/hamburger.png";
import { Link, useNavigate }         from "react-router-dom";
import { connect }                   from "react-redux";
import { useEffect, useState }       from "react";
import { getCurrentPlan }            from "../../redux/actions/plansAction";
import { getProfile,getUserStorage } from "../../redux/actions/profileAction";
import { Nav, Navbar,  Button, Form, Dropdown, Container, } from "react-bootstrap";
// import BoxRecords from "../../pages/home/box-records";

const Header = (props: any) => {
  const navBackTo = useNavigate(); // Navigation constant
  const url          = window.location.pathname;
  const loginData    = JSON.parse(localStorage.getItem('userData')||'{}');
  const [searchValue, setSearchValue] = useState('');
  const [planExpired, setPlanExpired] = useState({type:'',message:''});
  
  useEffect(()=>{
    if((props.message && ((props.message.data === 'Error verifying token : invalid signature') || (props.message.data === 'Server Error')))){
      navBackTo('/login');
    }
  },[props.message])

  const handleDrawer = () => {
    // When screen size goes small, this toggle starts working
    props.handleDrawerOpen && props.handleDrawerOpen(false);
  };

  useEffect(() => {
    if(url === '/suicide-exclusion'){
      window.location.hash = "terms";
    }else{
      window.scrollTo({ top: 0,behavior: "smooth" });
    }
    // gets profile data if user logged in on component loads (only once)
    if (url !== "/" && (Object.keys(loginData).length !== 0)) {
      props.getProfile();
    }
  },[]);
  
  const [userData,setUserData] = useState(null);// profile data setup in state variable
  const [currentPlan,setCurrentPlan] = useState<any>([]);// gets current plan into this state variable
  
  useEffect(() =>{// checks if user is valid then get his/her current plan details
    if(props.profileData){
      const userVerification = checkVerified((props.profileData && props.profileData.verified) ? props.profileData.verified : 0);
      if(userVerification === true){
          setUserData(props.profileData);
          props.getCurrentPlan();
          props.getUserStorage();
        }    
    }else if(props.cancelPlanDetailsData){
      props.getCurrentPlan();
    }
  }, [props.profileData, props.cancelPlanDetailsData, props.selectPlanDetailsData]);

  const checkVerified = (e: any) => {
    // user verification method with definition and its body
    if (e === 0) {
      navBackTo("/edit-profile");
    } else {
      return true;
    }
  }

  const handleSearch = (keyword:any) =>{
    props.searching(true);
    props.isSearchIconClicked(false);
    setSearchValue(keyword);
    window.history.pushState({},"", '/box_records');
    props.search && props.search(keyword)
  }

  const handleSearchClick = (e:any, keyword:any) =>{
    e.preventDefault();
    props.isSearchIconClicked(true);
    window.history.pushState({},"", '/box_records');
    props.search && props.search(keyword)
  }

  useEffect(() => {
    // checks if current plans any choosen from user, else redirect to plan page.
    if (props.currentPlanDetailsData) {
      const isActive = props.currentPlanDetailsData && props.currentPlanDetailsData.planisActive ? props.currentPlanDetailsData.planisActive : 0;
      if ( isActive == 0 && url !== "/select-plans" && url !== "/edit-profile" && url !== "/complete-payment" ) {
        navBackTo("/select-plans");
      } else {
        setCurrentPlan(props.currentPlanDetailsData);
      }
    }
  }, [props.currentPlanDetailsData]);

  useEffect(() =>{
    if(!currentPlan){
      setPlanExpired({type:'danger',message:'Plan expired!, you have no active plans'});
    }else{
      setPlanExpired({type:'',message:''});
    }
  },[currentPlan])
//-----------------------------------------------------------------------//

  return (
    <header>
      {!userData && !localStorage.getItem("userData") ? (
        <Navbar expand="xl" variant="dark">
          <Navbar.Brand onClick={() => {props.searching(false);props.isSearchIconClicked(false);setSearchValue('')}}>
            <Link to="/" > <img src={logo} className="App-logo" alt="logo" width="99"/></Link> 
          </Navbar.Brand>
          <Button className="dotMenuBtn" onClick={handleDrawer}> <img src={menu} alt="" /> </Button>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll" className="rounded-4">
            <Nav className="me-auto my-2 my-lg-0 p-3" navbarScroll>
              <Nav.Link as={Link} to="/about_us"    className={url === "/about_us" ? "active" : ""} > About Us </Nav.Link>
              <Nav.Link as={Link} to="/foundation"  className={url === "/foundation" ? "active" : ""} > Foundation </Nav.Link>
              <Nav.Link as={Link} to="/box_records" className={url === "/box_records" || url === "/public-box-list" || url.includes('/box-record-user/') ? "active" : ""} > Box Records </Nav.Link>
            </Nav>
          </Navbar.Collapse>
          <div className="navLeft">
            <div className="searchBlock mob-search-block">
              <Form className="d-flex">
                <Form.Control type="text" id="searchBox" autoComplete="off" placeholder="Box Records" className="searchInput" aria-label="Search" value={searchValue} onChange={(e)=>{handleSearch(e.target.value);e.preventDefault()}}/>
                <Button type="submit" onClick={(e) => {handleSearchClick(e,searchValue)}} className="searchIcon"></Button>
              </Form>
            </div>
            <Link className="regBtn" to="/login">Create Profile/Sign In</Link>
          </div>
          </Navbar>  
        )
        :
        (
          <Container>
            <div className='plan-dtl-row'>
              {currentPlan && currentPlan.boxPlans && url !== '/select-plans' && url !== '/edit-profile' ?
                <>
                  {
                    currentPlan.boxPlans.planCode === 'FREE' ?
                      <>
                        <div className='plan-dtl-lbl'>
                          <span className='text-warning plan-name'>{currentPlan.boxPlans.planType}</span>
                        </div>
                      </>
                  :
                    currentPlan.boxPlans.planCode === 'FAMILY-PLUS-M' ?
                      <>
                        <div className='plan-dtl-lbl'>
                          <span className='text-warning plan-name'>{currentPlan.boxPlans.planType} - </span>
                          <span className='text-light'> Valid Upto : {new Date(currentPlan.planExpiryDate).toLocaleDateString()}</span>
                        </div>
                      </>
                  :
                    currentPlan.boxPlans.planCode === 'FAMILY-PLUS-Y' ?
                      <>
                        <div className='plan-dtl-lbl'>
                          <span className='text-warning plan-name'>{currentPlan.boxPlans.planType} - </span>
                          <span className='text-light'> Valid Upto : {new Date(currentPlan.planExpiryDate).toLocaleDateString()}</span>
                        </div>
                      </>
                  :
                      <></>
                  }
                </>
              :
                <span className={`text-${planExpired.type} mt-5 mt-md-0`}>{planExpired.message}</span>
              }
            </div>
            <div className="header-inner-frame">
              <Navbar expand="xl" variant="dark">
                <Button className="dotMenuBtn" onClick={handleDrawer}><img src={menu} alt="" /></Button>
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll" className="rounded-4">
                  <Nav className="me-auto my-2 my-lg-0 p-2" navbarScroll >
                    <Nav.Link as={Link} to="/about_us" className={url === '/about_us' ? 'active' : ''}>About Us</Nav.Link>
                    <Nav.Link as={Link} to="/foundation" className={url === '/foundation' ? 'active' : ''}>Foundation</Nav.Link>
                    <Nav.Link as={Link} to="/box_records" className={url === '/box_records' || url === '/public-box-list' || url.includes('/box-record-user/') ? 'active' : ''}>Box Records</Nav.Link>
                  </Nav>
                </Navbar.Collapse>
                <div className="navLeft">
                  <div className="searchBlock">
                    <Dropdown>
                      <Dropdown.Toggle>
                        <Form className="d-flex">
                          <Form.Control type="text" id="searchBox" autoComplete="off" placeholder="Box Records" className="searchInput" onChange={(e)=>{handleSearch(e.target.value);e.preventDefault()}} value={searchValue} aria-label="Search" />
                          <Button className="searchIcon" type="submit" onClick={(e) => {handleSearchClick(e,searchValue)}} ></Button>
                        </Form>
                      </Dropdown.Toggle>
                      {/* <Dropdown.Menu>
                       
                      <h4>Recently Viewed</h4>
                      <div className="recomendations">
                        <div className="recList">
                          <div className="image">
                            <img src={recomendImg} alt="" />
                          </div>
                          <div className="text">ABC BOX</div>
                        </div>
                        <div className="recList">
                          <div className="image">
                            <img src={recomendImg} alt="" />
                          </div>
                          <div className="text">XYZ BOX</div>
                        </div>
                      </div>
                    </Dropdown.Menu> */}
                  </Dropdown>
                </div>
              </div>
            </Navbar>
            </div>
          </Container>
        )
      }
    </header>
  );
}

const mapStateToProps = (state: any) =>{
  return{
    profileData            : (state.ProfilePageReducer   && state.ProfilePageReducer.profileData)       ? state.ProfilePageReducer.profileData      : null,
    currentPlanDetailsData : (state.PlansReducer         && state.PlansReducer.currentPlanDetailsData)  ? state.PlansReducer.currentPlanDetailsData : null,
    cancelPlanDetailsData  : (state.PlansReducer         && state.PlansReducer.cancelPlanDetailsData)   ? state.PlansReducer.cancelPlanDetailsData  : null,    
    selectPlanDetailsData  : (state.PlansReducer         && state.PlansReducer.selectPlanDetailsData)   ? state.PlansReducer.selectPlanDetailsData  : null,    
    message                : (state.CommonMessageReducer && state.CommonMessageReducer.message)         ? state.CommonMessageReducer.message        : null,
  }
};

const mapDispatchToProps = (dispatch: any) =>({
  getProfile     : (data: any) => dispatch(getProfile(data)), 
  getCurrentPlan : (data: any) => dispatch(getCurrentPlan(data)),
  getUserStorage : ()          => dispatch(getUserStorage()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
