
import {USER_PROFILE} from "../constant/index";

const initialState = {
  loading                : false,
  profileData            : null,
  editProfileData        : null,
  updateProfileData      : null,
  kinDetailsData         : null,
  updateProfileImageData : null,
  userStorage            : null,
  changePassword         : null,
  getOTP_ToEditPhoneNo   : null,
  verifyOTP_ToEditPhoneNo: null,
}

const ProfilePageReducer = (state = initialState, action) => {
  switch (action.type) {    
    case USER_PROFILE.SET_PROFILE_PAGE_TO_STATE:
      return { ...state, profileData: action.data, loading: false, };
      
    case USER_PROFILE.SET_EDIT_PROFILE_TO_STATE:
      return { ...state, editProfileData: action.data, loading: false, };
            
    case USER_PROFILE.SET_UPDATE_USER_PROFILE_TO_STATE:
      return { ...state, updateProfileData: action.data, loading: false, };

    case USER_PROFILE.SET_KIN_DETAILS_TO_STATE:
      return { ...state, kinDetailsData: action.data, loading: false, };
        
    case USER_PROFILE.SET_UPLOAD_PROFILE_IMAGE_TO_STATE:
      return { ...state, updateProfileImageData: action.data, loading: false, };

    case USER_PROFILE.SET_STORAGE:
      return { ...state, userStorage: action.data, loading: false, };

    case USER_PROFILE.RESPONSE_CHANGE_PASSWORD:
      return { ...state, changePassword: action.data, loading: false, };

    case USER_PROFILE.SET_OTP_TO_EDIT_PHONE_NO:
      return { ...state, getOTP_ToEditPhoneNo: action.data, loading: false, };
    
    case USER_PROFILE.SET_OTP_VERIFICATION_TO_EDIT_PHONE_NO:
      return { ...state, verifyOTP_ToEditPhoneNo: action.data, loading: false, };  

    default:
      return state;
    }
   };

   export default ProfilePageReducer;
