import { useState } from 'react';
import { Button, Container, Spinner } from 'react-bootstrap';
import Layout from '../../layout/Layout';
import { BASE_URLS } from '../../redux/constant';
import payfastImage from "../../assets/images/payfast.png";
import { useLocation } from 'react-router-dom';

var md5 = require('md5');

function PaymentPage() {
    
    const [isClicked, setIsClicked] = useState<boolean>(false);
    const requestPayment = () =>{
        if(isClicked){
            setIsClicked(!isClicked);
        }else{
            setIsClicked(!isClicked);
        }
    }

    const location    = useLocation();
    const amtPayble   = location.state.amount;
    const item_Name   = location.state.itemName;
    const planId      = location.state.id;
    const fname       = location.state.name_first;
    const lname       = location.state.name_last;
    const email       = location.state.email_addr;
    const userId      = location.state.usrId;
    const planToken   = location.state.planToken;
    const frequency   = location.state.frequency;
    const commonURL   = `${BASE_URLS.APP_BASE_URL}/homepage`;
    const itnURL      = `${BASE_URLS.BASE_URL}/payfast/payments`;
    const passphrase  = process.env.REACT_APP_PAYFAST_MERCHANT_PASSPHRASE;
    //Current Date------------------------Starts Here
    const nowDate     = new Date();
    const Year        = nowDate.getFullYear();
    const Month       = nowDate.getMonth()+1;
    const currDate    = nowDate.getDate();
    const Today       = Year+(Month < 10 ? '-0':'-')+Month+(currDate < 10 ? '-0' : '-')+currDate;
    //Current Date------------------------Ends Here
    const billingDate = Today
    const payfastUrl  = process.env.REACT_APP_PAYMENT_PAGE_URL;
    
    const params: any = {
        merchant_id      : process.env.REACT_APP_PAYFAST_MERCHANT_ID,
        merchant_key     : process.env.REACT_APP_PAYFAST_MERCHANT_KEY,
        return_url       : `${commonURL}`,
        cancel_url       : `${commonURL}`,
        notify_url       : `${itnURL}`,
        name_first       : `${fname}`,
        name_last        : `${lname}`,
        email_address    : `${email}`,
        m_payment_id     : `${planId}`,
        amount           : `${amtPayble}`,
        item_name        : `${item_Name}`,
        item_description : 'Subscription',
        custom_str1      : `${userId}`,
        custom_str2      : `${planToken}`,
        subscription_type: "1",
        billing_date     : `${billingDate}`,
        recurring_amount : `${amtPayble}`,
        frequency        : `${frequency}`,
        cycles           : "0",
        passphrase       : passphrase
    }
    
    const serachParams: any = new URLSearchParams(params);
    const MD5Signature: any = md5(serachParams.toString())
   
//------------------------------------------------------------------// 
    
    return (
        <div>
            <Layout >
                <div className="welcome">
                    <Container className='text-center'>
                        <form action={payfastUrl} method="POST">
                            {
                                Object.keys(params).map((item: any, key: any) => <input key={key} type="hidden" name={item} value={params[item]} />)
                            }
                            <input type="hidden" name="signature" value={MD5Signature} />
                            <div className="row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <div className="col-lg-12">
                                    <Button className='bg-light pointer payfastBtn' type="submit">
                                        <img alt="payfast" src={payfastImage} width="30%" height="30%" className={isClicked ? `blink rounded` : `rounded`} onClick={requestPayment}/>
                                    </Button>
                                    <br/>
                                    {isClicked && 
                                        <div className='mt-2'>
                                            <Spinner className='text-center text-light'/><br/>
                                            <span className='text-danger text-center'>
                                                Please do not close window or click on Back button or Refresh(F5) page
                                            </span>
                                        </div> 
                                    }
                                </div>
                            </div>
                        </form>
                    </Container>
                </div>
            </Layout>
        </div>
    )
}

export default PaymentPage


