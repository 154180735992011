import {COUNTRY_DATA} from '../constant/index';

export const countryList = data => {
  return {
    type: COUNTRY_DATA.GET_COUNTRIES,
    payload: data,
  };
};

export const stateList = data => {
  return {
    type: COUNTRY_DATA.GET_STATES,
    payload: data,
  };
};

export const cityList = data => {
  return {
    type: COUNTRY_DATA.GET_CITIES,
    payload: data,
  };
};
export const countryId = data => {
  return {
    type: COUNTRY_DATA.GET_COUNTRY_ID,
    payload: data,
  };
};
export const getLocation = data => {
  return {
    type: COUNTRY_DATA.GET_LOCATION,
    payload: data,
  };
};
export const getUserState = data => {
  return {
    type: COUNTRY_DATA.GET_STATE_LOCATION,
    payload: data,
  };
};
export const getUserCountry = data => {
  return {
    type: COUNTRY_DATA.GET_COUNTRY_LOCATION,
    payload: data,
  };
};