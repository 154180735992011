import Api from './apiCall';
import {API_URLS} from '../constant/index';

export const getPlanService = async params => {
      const result = await Api.get(API_URLS.GET_SUBSCRIPTION_PLAN_URL, params);
       return result;
  };
  export const selectPlanService = async params => {
      const result = await Api.post(API_URLS.SELECT_SUBSCRIPTION_PLAN_URL, params);
      return result;
  };
  
  export const selectPaidPlanService = async params => {
    const result = await Api.post(API_URLS.SELECT_PAID_SUBSCRIPTION_PLAN_URL, params);
    return result;
};

export const currentPlanService = async params => {
    const result = await Api.get(API_URLS.GET_CURRENT_PLAN_URL, params);
    return (result)?result:{planIsActive:0};
};
export const cancelPlanService = async params => {
     
    const userId = params.id
    let url = API_URLS.CANCEL_PLAN_URL;
    let newurl = url.replace("{id}", userId);
    const result = await Api.patch(newurl, params);
    return result;
};

export const getAllPlanService = async params => {
    const userId = params.userId
    let url = API_URLS.GET_ALL_PLAN_URL;
    let newurl = url.replace("{userId}", userId);
    const result = await Api.get(newurl, params);
    return result;
};

