import { Modal } from 'react-bootstrap';

const AdminModal=(props: any) =>{
    if(props){
        var ComponentName = (props.name  ? props.name  : '');
        var Show          = (props.show  ? props.show  : '');
    }
    
    return (
        <Modal className="" {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={Show} >
            <Modal.Header closeButton className="border-0"></Modal.Header>
            <Modal.Body>
                <h4 style={{color:"#E79E1F"}}>{ComponentName}</h4>
                <div className={`text-center`}>{props.children}</div>
            </Modal.Body>            
        </Modal>
    );
}

export default (AdminModal);