export function createPlanValidation(value: any) {

    let errors:any={};
   if(!value.pType){
    errors['pType']='Plan name is required';
   }
   if(!value.pCode){
    errors['pCode']='Plan code is required';
   }
   if(!value.pPrice){
    errors['pPrice']='Plan price is required';
   }
   if(!value.pDuration){
    errors['pDuration']='Plan Duration is required';
   }
   if(!value.pStorage){
    errors['pStorage']='Plan Storage is required';
   }
   if(!value.pPrivateBox){
    errors['pPrivateBox']='Private boxes is required';
   }
   if(!value.pPublicBox){
    errors['pPublicBox']='Public boxes is required';
   }
   if(!value.pReceivedBox){
    errors['pReceivedBox']='Recieved boxes is required';
   }
   if(!value.pRecepientPrivate){
    errors['pRecepientPrivate']='Private box recipient is required';
   }
   if(!value.pRecAccessTime){
    errors['pRecAccessTime']='Recieved box duration is required';
   }
//    if(!value.subsId){
//     errors['subsId']='Paypal subscription is required';
//    }
   if(!value.pType){
    errors['pType']='Plan type is required';
   }
   






    return errors;
}