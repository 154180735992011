import Api from "../apiCall";
import { API_URLS } from "../../constant/index";

export const getAdminBoxPlanService = async (params) => {
  const result = await Api.get(API_URLS.GET_ADMIN_BOX_PLAN_URL, params);
  return result;
};

 export const addAdminBoxPlanService = async (params) => {
  const result = await Api.post(API_URLS.ADD_ADMIN_BOX_PLAN_URL, params);
  return result;
};
export const updateAdminBoxPlanService = async (params) => {
  const userId = params.id;
  let url = API_URLS.UPDATE_ADMIN_BOX_PLAN_URL;
  let newurl = url.replace("{id}", userId);
  const result = await Api.put(newurl, params);
  return result;
}; 
export const getAdminBoxPlanByIdService = async (params) => {
  const userId = params.id;
  let url = API_URLS.GET_ADMIN_BOX_PLAN_BY_ID_URL;
  let newurl = url.replace("{id}", userId);
  const result = await Api.get(newurl, params);
  return result;
};
export const deleteAdminBoxPlanService = async (params) => {
  const userId = params.id;
  let url = API_URLS.DELETE_ADMIN_BOX_PLAN_URL;
  let newurl = url.replace("{id}", userId);
  const result = await Api.delete(newurl, params);
  return result;
}; 