import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Layout from "../../layout/Layout";
import { getPlans } from "../../redux/actions/plansAction";

var converter = require('number-to-words');

const ViewPlans = (props: any) => {
  const [planDetails, setPlanDetails] = useState([]);
  const [userDetails, setUserDetails] = useState<any>({});
  let nav=useNavigate();
  
  useEffect(() => {
    setUserDetails(props.profileData);
  }, [props.profileData]);
  
  useEffect(() => {
    props.getPlans();
  }, []);
  
  useEffect(() => {
    setPlanDetails(props.planDetailsData);
  }, [props.planDetailsData]);
   
  const navToSignup=()=>{
    nav('/login')
  }

// -----------------------------------------------------// 
  
  return (
   <>
     <Layout>
      <div className={localStorage.length ? "" : "charity_publicBox planListSpace"} style={{paddingLeft: "0", paddingRight: "0",}}>
        <div className="planContent">  
          <div className="welcome">
            <div className="plans">
              <Container>
                <div className="planList">
                  {planDetails && planDetails.map((plan: any) => {
                    return (
                      <div key={plan.id} className="listItem" style={{border: "2px solid grey",borderRadius:"20px"}}>
                        <div className="listHead">
                          <h3>{plan.planType}</h3>
                          <p><span>Membership</span></p>
                          <div className="rate " style={{paddingTop:'80px'}}>
                          <b className="text-warning">R</b>{Number(plan.planPrice).toFixed(2)}
                            <span>{plan.planDuration !== 'Unlimited' && plan.planDuration}</span>
                          </div>
                        </div>
                        <ul>
                          <li>{converter.toWords(plan.publicBoxes).charAt(0).toUpperCase() + converter.toWords(plan.publicBoxes).slice(1)} public boxes</li>
                          <li style={{lineHeight:"15px"}}>{(plan.privateBoxes !== 'Unlimited') ? converter.toWords(plan.privateBoxes).charAt(0).toUpperCase() + converter.toWords(plan.privateBoxes).slice(1) : plan.privateBoxes } private boxes
                            <br/><small style={{fontSize:"13px",color:"#E79E1F"}}>{plan.planCode === 'FREE' ? '( One recipient per box )' :'( Up to five recipients per box )'}</small>                      
                          </li>
                          <li style={{lineHeight:"15px"}}>{plan.recievedBoxes} received boxes
                            <br/><small style={{fontSize:"13px",color:"#E79E1F"}}>{plan.planCode === 'FREE' ? '( Access received box content for 12 months )' : '( Unlimited access to received box content )' }</small>
                          </li>
                          {plan.planCode !== 'FREE' && <li>{`${(Number(plan.planStorage) / 1000000000)} GB storage plan`}</li>}
                          {plan.planCode === 'FREE' ? <li>Box Content – Text Messages</li> : <li>Box Content – Text Messages, Video, Audio, Documents, Images</li>}   
                          {plan.planCode !== "FREE" && <li>Cancel Any Time</li>} 
                        </ul>
                        <div className="text-center mt-auto">
                          <Button className="chooseBtn" onClick={navToSignup}>SELECT </Button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Container>
            </div>
          </div>
        </div> 
      </div>
    </Layout>
  </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  // getProfile     : (data: any) => dispatch(getProfile(data)),
  getPlans       : (data: any) => dispatch(getPlans(data)),
  // selectFreePlan : (data: any) => dispatch(selectFreePlan(data)),
  // getCurrentPlan : (data: any) => dispatch(getCurrentPlan(data)),
  // cancelPlan     : (data: any) => dispatch(cancelPlan(data)),
  // getAllPlan     : (data: any) => dispatch(getAllPlan(data))
});

const mapStateToProps = (state: any) => {
  return {
    planDetailsData       : state.PlansReducer       && state.PlansReducer.planDetailsData ? state.PlansReducer.planDetailsData : null,
    profileData           : state.ProfilePageReducer && state.ProfilePageReducer.profileData ? state.ProfilePageReducer.profileData : null,
    // selectPlanDetailsData : state.PlansReducer       && state.PlansReducer.selectPlanDetailsData ? state.PlansReducer.selectPlanDetailsData : null,
    // message               : state.CommonMessageReducer && state.CommonMessageReducer.message ? state.CommonMessageReducer.message : null,
    // currentPlanDetailsData: state.PlansReducer && state.PlansReducer.currentPlanDetailsData ? state.PlansReducer.currentPlanDetailsData : null,
    // cancelPlanDetailsData : state.PlansReducer && state.PlansReducer.cancelPlanDetailsData ? state.PlansReducer.cancelPlanDetailsData : null,
    // allPlanDetail         : state.PlansReducer && state.PlansReducer.allPlanDetail ? state.PlansReducer.allPlanDetail : null,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewPlans);
