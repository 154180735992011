import { Button, Container } from "react-bootstrap";
import { Link } from "react-router-dom";

import worldWideWeb from "../../assets/images/world-wide-web.png";
import phoneIcon from "../../assets/images/phone.png";
// import bushSchool from "../../assets/images/bush-school.png";
// import hand from "../../assets/images/hand.png";
import Layout from "../../layout/Layout";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getFoundationPage } from "../../redux/actions/foundationAction";
import { BASE_URLS } from "../../redux/constant";

const Foundation = (props: any) => {
  let userData = localStorage.getItem("profileData");
  const [foundationData, setFoundationData] = useState<any>([]);
  const [visibleFoundationBoxes, setvisibleFoundationBoxes] = useState(4);
  // const [readMore, setReadMore] = useState("");
  // const [readMoreId, setReadMoreId] = useState(0);

  useEffect(() => {
    props.getFoundationPage();
  }, []);

  useEffect(() => {
    if (props.getFoundationPageData) {
      setFoundationData(props.getFoundationPageData);
    }
  }, [props.getFoundationPageData]);

  const LoadMoreFoundationBoxes = () => {
    setvisibleFoundationBoxes(visibleFoundationBoxes + 4);
  };
  
  // READ MORE ACTION -----------------------
  // const readMoreFunction = (value: any,id:any) => {
  //   setReadMore(value);
  //   setReadMoreId(id);
  // };

  // READ LESS ACTION -----------------------
  // const readLessFunction = () => {
  //   setReadMore("");
  // };

  return (
    <Layout>
      <div className="boxes pb-0">
        <div className={`charity ${!userData ? `charity_publicBox` : ``}`}>
          <Container className="foundationPage">
            <h2 style={{textAlign:'center',color:'#E79E1F'}} > The BeyondBox Foundation </h2>
            
            <div className="text-light">
              <p>Our purpose is to provide financial assistance to existing charities so they can continue their vital humanitarian and philanthropic work. We are proud to donate one-third of our net earnings from the BeyondBox Messaging Platform to our expanding network of partner charities. We feel privileged for the opportunity to collaborate with organizations that do incredible work in their communities and are grateful to our subscribers for supporting our initiative. With your help, we can make a significant difference and empower these charities to continue making a positive impact on the lives of those they serve. We thank you for being a part of the BeyondBox family and for helping us support these deserving causes. Together, we can build a brighter future for all.</p>
            </div>
            {foundationData && foundationData.length !== 0 ? 
              (foundationData.slice(0, visibleFoundationBoxes).map((list: any) => {
                return (
                  <div className="foundationList">
                    <div className="contentImg">
                      <img src={ list && list.foundationProfilePhoto && `${BASE_URLS.BASE_URL}/user-box-files/resource?key=${list.foundationProfilePhoto}` } alt="" />
                    </div>
                    <div className="contentDesc">
                      <h4>{list.foundationName}</h4>
                        <div className="desc-data">
                          <span className="text-light">
                            {list && list.foundationDetails}
                          </span>
                          {/* {readMore && readMoreId == list.id ? 
                            (<span className="text-light">
                              <p>{readMore}{" "}{list.foundationDetails.length > 50 && (
                                  <small className="text-warning pointer read_more_or_less" onClick={() => readLessFunction()} >
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<u>Read Less</u>
                                  </small>
                                )}
                              </p>
                            </span>) 
                          : 
                            (<span className="text-light">
                              {list.foundationDetails.length > 50 ? list.foundationDetails.substring(0, 50) + "..." : list.foundationDetails}{" "}
                              {list.foundationDetails.length > 50 && 
                                (<small className="text-warning pointer read_more_or_less" onClick={() => readMoreFunction(list.foundationDetails, list.id) }>
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<u>Read More</u>
                                </small>
                              )}
                            </span>
                          )} */}
                        </div>
                        <div className="callus">
                          <div className="mb-3">
                            <Link to="#">
                              <img className="align-middle" src={phoneIcon} alt="" />
                              {list.foundationPhone}
                            </Link>
                          </div>
                          <a href={list.foundationWebsite}  target="_blank" >
                            <img className="align-middle" src={worldWideWeb} alt="" />
                            {list.foundationWebsite}
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                })
            ) : (
              <div className="text-warning text-center">
                {/* No Foundation Yet... */}
              </div>
            )}
            <div className="text-center" style={ visibleFoundationBoxes > Object.keys(foundationData).length || visibleFoundationBoxes == Object.keys(foundationData).length ? { display: "none" } : { display: "block" } } >
              <Button className="loadMoreBtn" onClick={() => LoadMoreFoundationBoxes()} > LOAD MORE </Button>
            </div>
          </Container>
        </div>
      </div>
    </Layout>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  getFoundationPage: (data: any) => dispatch(getFoundationPage(data)),
});

const mapStateToProps = (state: any) => {
  return {
    getFoundationPageData:
      state.FoundationReducer && state.FoundationReducer.getFoundationPageData
        ? state.FoundationReducer.getFoundationPageData
        : null,
  };
};
// export default connect(mapStateToProps, mapDispatchToProps)(AdminFoundation);
export default connect(mapStateToProps, mapDispatchToProps)(Foundation);
