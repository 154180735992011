import React, { useEffect, useRef } from 'react'
import { useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';

export interface MyProps{
    src     ?: any;
    width   ?: any;
    size    ?: any;
    alt     ?: any;
    onError ?: any;
}    

const MyImage = (props:MyProps) => {
    const imgRef = useRef<HTMLImageElement>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (imgRef.current?.complete) {
            handleImageLoading();
        }
       }, []);

    const handleImageLoading = () =>{
        setLoading(false);
    }

//--------------------------------------------------------------//    

    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: props.width?props.width:"100%", }} >
            <img ref={imgRef} src={props.src} style={{ display: loading?"none":"block", width:"100%", animation: "fadeIn 0.5s",}} onLoad={handleImageLoading} onError={props.onError} alt={props.alt}/>
            <div style={{ display: loading ? "block":"none", fontSize: props.size?props.size:"10px",color: props.size?"#fff":"#E79E1F",marginTop:"30px" }} >
                <Spinner/>
                <br/>
                <small>Loading...</small>
            </div>
        </div>
    )
}

export default MyImage;