import { useEffect, useState } from "react";
import { Button, Container, Table } from "react-bootstrap"
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Layout from "../../../layout/Admin/layout"
import { getUserThroughMarkters } from "../../../redux/actions/admin/adminMarketerAction";

 const AdminMaketerAllUserList = (props:any )=>{
  const navTo = useNavigate();

    const [userDetailData, setUserDetailData] = useState<any>([]);
    useEffect(()=>{
        props.getUserThroughMarkters()
    },[])

    useEffect(()=>{
        if(props.getUserThroughMarketersData){
            setUserDetailData(props.getUserThroughMarketersData)
            
        }
    },[props.getUserThroughMarketersData])

    return (
        <>
        <Layout> 
          <div className="col-auto" >
            <Button className='backBtn float-end m-2'  onClick={() => navTo(-1)}>Back</Button>
          </div>
          <Container>
            <div className="myProfile p-3 bg-transparent">
              <div className="heading">
                <h3 className="d-flex justify-content-center" style={{color:"#E79E1F"}}> Users Through Marketers</h3>
              </div>
              <hr style={{border:'1px solid #E79E1F'}}/>
              <div className="allUserDetail scrollBar">
                <Table responsive>
                  <thead>
                    <tr>
                      <th className="text-warning text-center">S.No.</th>
                      <th className="text-warning text-center">User </th>
                      <th className="text-warning text-center">Plan Type</th>
                      <th className="text-warning text-center">Date Joined</th>
                      <th className="text-warning text-center">Marketer</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userDetailData && userDetailData.map((list: any, index: number) => {
                      {
                        if(1==1) 
                          {return (
                            <>
                              <tr>
                                <td className="text-center"> {index + 1} </td>
                                <td className="text-center"> {list.firstName +" "+ list.lastName} </td>
                                <td className="text-center"> {list.userHMpayments && list.userHMpayments[0].boxPlans.planType ? list.userHMpayments[0].boxPlans.planType  : "NA" } </td>
                                <td className="text-center"> { new Date (list.created).toLocaleDateString()} </td>
                                <td className="text-center"> { list.userBTmarketer && list.userBTmarketer.marketerName ? list.userBTmarketer.marketerName : 'NA'} </td>
                              </tr>
                            </>
                          );}
                        }
                      })}
                  </tbody>
                </Table>
              </div>
            </div>
          </Container>
        </Layout>
      </>
    )
  }

  const mapDispatchToProps = (dispatch: any) => ({
    getUserThroughMarkters: (data: any) => dispatch(getUserThroughMarkters(data)),
  });

  const mapStateToProps = (state: any) => {
    return { 
      getUserThroughMarketersData: state.AdminMarketerReducer && state.AdminMarketerReducer.getUserThroughMarketersData ? state.AdminMarketerReducer.getUserThroughMarketersData : null,
    };
};

export default connect(mapStateToProps, mapDispatchToProps) (AdminMaketerAllUserList);