import { Button, Form, Modal, ProgressBar } from "react-bootstrap";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { uploadDC } from "../../redux/actions/admin/adminBoxAction";
import Swal from "sweetalert2";

const UploadDocFilesModal = (props: any) => {

  const [result, setResult] = useState({ status: "", message: "" });
  const [fileName, setFileName] = useState("");
  const [percentage, setPercentage] = useState<any>(null);
  const [dodData, setDodData] = useState();
  const [userId, setUserId] = useState('');
  const [checkOnFile, setCheckOnFile] = useState(false);

  const [image, setImage] = useState({ preview: "", raw: "" });
  let inputRef: any;
  

  useEffect(() => {
    if (!(props.adminUserDataByIdentity.length == 0)) {
      
      setUserId(props.adminUserDataByIdentity[0].id);
      
    }
  }, [props.adminUserDataByIdentity]);

  const handleUpload = async (e: any) => {
    e.preventDefault();
    const formData = new FormData();
    if (dodData && checkOnFile) {
      formData.append("dod", dodData);
      formData.append("dod_document", image.raw);
      
      let payLoad = {
        _parts: {
          userId:userId,
          data: formData,
          onUploadProgress: (ProgressEvent: any) => {
            const { loaded, total } = ProgressEvent;
            const percent = Math.floor((loaded * 100) / total);
            setPercentage(percent);
          },
        },
      };
      props.uploadDC(payLoad);
    } else {
      if (!checkOnFile) {
        Swal.fire({
          title: "Upload File",
          showCancelButton: false,
          confirmButtonText: "OKAY",
          confirmButtonColor: "green",
          showCloseButton: true,
        });
      } else if (!dodData) {
        Swal.fire({
          title: "Enter Date of death",
          showCancelButton: false,
          confirmButtonText: "OKAY",
          confirmButtonColor: "green",
          showCloseButton: true,
        });
      }
    }
  };

  useEffect(()=>{
    
    setPercentage(null);
      setFileName("");
      setResult({
        status: "",
        message:'',
      });

  },[])
  useEffect(() => {
    if (props.adminReleaseBoxData) {
      
      setPercentage(null);
      setFileName("");
      setResult({
        status: "bg-success",
        message: ` ${props.adminReleaseBoxData.message}`,
      });
    }
  }, [props.adminReleaseBoxData]);

  const handleChange = (event: any) => {

    if (event.target) {
      if (event.target.files.length) {
        setImage({
          preview: URL.createObjectURL(event.target.files[0]),
          raw: event.target.files[0],
        });
      }
      
      setCheckOnFile(true);
    }
    setResult({ status: "", message: "" });
    setFileName(inputRef.files[0].name);
  };
  const handleDodChange = (event: any) => {

    setDodData(event.target.value);
    setResult({ status: "", message: "" });
  };

    const handleClose = (event: any) => {
      setFileName('');
      setResult({ status: "", message: "" });
    };
  //-------------------------------------------------------------------------------------//

  return (
    <Modal
      backdrop="static"
      keyboard={false}
      className="uploadModal"
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={props && props.show}
      // onHide={handleClose}
    >
      <Modal.Header
        closeButton={percentage ? false : true}
        className="border-0"
      ></Modal.Header>
      <Modal.Body 
      style={{ height: 430, width: 470 }}
      >
        <div>
          {
            // (IMAGES,VIDEOS, DOCUMENTS) BLOCKS

            <div>
              <h4>
                Upload {props.selectedMedia}
                <br />
                <small className="text-danger">
                  (pdf,jpeg)
                  {/* {
                                            props.selectedMedia === 'Documents' && (ext && ext[0] && ext[0].filesExtension ? `( ${ext[0].filesExtension} )`: '( pdf,jpeg )')
                                        } */}
                </small>
              </h4>
              <div>
                <div className="file-upload-input">
                  <input
                    className="fileInput form-control pr-3"
                    type="file"
                    id="file"
                    name={"selectedFile"}
                    accept={
                      props.selectedMedia === "Documents"
                        ? "image/jpeg, application/pdf"
                        : ""
                    }
                    ref={(refParam) => (inputRef = refParam)}
                    onChange={handleChange}
                  />
                  <strong
                    className="text-warning"
                    title={fileName}
                    style={{
                      display: "block",
                      whiteSpace: "break-spaces",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {fileName}
                  </strong>
                </div>
                <div className="dod">
                  <Form.Label style={{ color: "white" }}>
                    Date of Death *
                  </Form.Label>
                  <Form.Control
                    type="date"
                    style={{ cursor: "pointer" }}
                    name="dob"
                    placeholder="dd/mm/yyyy"
                    max={new Date().toISOString().slice(0, 10)}
                    onChange={handleDodChange}
                    required
                  />
                </div>
                <div className="file-upload-button" style={{ width: "60%" }}>
                  <Button
                    style={{
                      color: "#fff",
                      width: "200px",
                      height: "60px",
                      marginLeft: "-40px",
                    }}
                    className="uploadBtn"
                    size="lg"
                    onClick={handleUpload}
                    type="button"
                  >
                    Upload DC and Release Box
                  </Button>
                </div>
              </div>
              <br />
            </div>
          }
          {percentage === 0 || percentage ? (
            <div
              style={{ background: "rgba(231, 158, 31, 0.8)" }}
              className={`file-upload-message rounded`}
            >
              <span className="text-light blink">
                File uploading, Please wait... ({percentage}%)
              </span>
            </div>
          ) : (
            ""
          )}
          {!percentage && result && result.message && (
            <div
              id="status"
              className={`${result.status} text-light file-upload-message rounded`}
              style={{ width: "111%" }}
            >
              {" "}
              {result.message}
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};
const mapDispatchToProps = (dispatch: any) => ({
  uploadDC: (data: any) => dispatch(uploadDC(data)),
});

const mapStateToProps = (state: any) => {
  return {
    adminUserDataByIdentity:
      state.AdminUserReducer && state.AdminUserReducer.adminUserDataByIdentity
        ? state.AdminUserReducer.adminUserDataByIdentity
        : null,
    adminReleaseBoxData:
      state.AdminBoxApiReducer && state.AdminBoxApiReducer.adminReleaseBoxData
        ? state.AdminBoxApiReducer.adminReleaseBoxData
        : null,

    state,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadDocFilesModal);
