export function loginFormValidation(value: any) {
    const emregex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    let errors:any={};
    if (value.email==='') {
        errors['email'] = 'Email address is required';
    } else if (!emregex.test(value.email)) {
        errors['email'] = 'Enter a valid email address';
    }
    if (!value.password) {
        errors['password'] = 'Password is required';
    }
    return errors;


}

