import React, { useEffect, useState } from 'react';
import { Button, Stack, Table } from 'react-bootstrap';
import { BsCheckCircle, BsFillEyeFill, BsFillXOctagonFill,BsTrashFill } from 'react-icons/bs';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Layout from '../../../../layout/Admin/layout';
import { disableAdminUserById, getAdminUserList } from '../../../../redux/actions/admin/adminUserAction';

const InActiveUsers = (props:any) => {

    const [userDataList,setUserDataList]=useState([]);
    const navToUser=useNavigate();
    const navTo=useNavigate();
    useEffect(()=>{
        props.getAdminUserList();
    },[])

    useEffect(()=>{
        if(props.adminUserData){
            setUserDataList(props.adminUserData);
        }
    },[props.adminUserData])


    const handleUserView=(e:any)=>{
        let userId=e;
        if(userId){
          navToUser('/admin-user-profile',{ state:{id:userId }})
        }
    }
    const handleUserDelete=(e:any)=>{
      let userId=e;
      Swal.fire({
        title: `Are you sure you want to delete this user ? `,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "YES",
        denyButtonText: `NO`,
        confirmButtonColor: "green",
        showCloseButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
           props.disableAdminUserById(userId);
        }
      });
    }
    useEffect(()=>{
      if(props.disableAdminUserData)
      {
        props.getAdminUserList();
      }
    },[props.disableAdminUserData])

    const handleActiveView=()=>{
      navTo('/admin-user-dashboard');
    }
    return (
        <div>
            {/* <Stack direction="horizontal" gap={3}>
          <Button variant="info" className="ms-auto"  onClick={handleActiveView} >
            {" "}
            View Active Users{" "}
          </Button>{" "}
        </Stack> */}
                <Table responsive>
          <thead>
            <tr>
              <th className="text-warning text-center">S.No.</th>
              <th className="text-warning text-center">User Name</th>
              <th className="text-warning text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            {userDataList &&
              userDataList.map((list: any, index: number) => {
                {
                  if(list.isActive==2) 
                  
                {return (
                  <>
                    <tr>
                      <td className="text-center">{index + 1}</td>
                      <td className="text-center"> {list.firstName}  {list.lastName}</td>
                      <td className="text-center">
                        <button
                          type="submit"
                          className="btn btn-outline-warning m-2"
                         
                          onClick={()=>handleUserView(list.id)}
                         
                        >
                          <BsFillEyeFill />
                        </button>
                        <button
                          type="submit"
                          className="btn btn-outline-danger"
                         
                         onClick={()=>handleUserDelete(list.id)}
                          //id={list.planType}
                          //onClick={removePlan}
                        >
                          <BsTrashFill/>
                        
                        </button>
                      </td>
                    </tr>
                  </>
                );}
                  }
              })}
          </tbody>
        </Table>
        </div>
    );
};

const mapDispatchToProps = (dispatch: any) => ({
     getAdminUserList: (data: any) => dispatch(getAdminUserList(data)),
     disableAdminUserById: (data: any) => dispatch(disableAdminUserById(data)),
  });
  
  const mapStateToProps = (state: any) => {
    return {
      adminUserData:(state.AdminUserReducer && state.AdminUserReducer.adminUserData) ? state.AdminUserReducer.adminUserData : null,
     disableAdminUserData:(state.AdminUserReducer && state.AdminUserReducer.disableAdminUserData) ? state.AdminUserReducer.disableAdminUserData : null,
      state,
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps) (InActiveUsers);