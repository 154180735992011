import axios from 'axios';
var FileSaver = require('file-saver');
export default class Api {static getBaseURL() {return '';}

  static async headers(params) {
    const userData         = JSON.parse(localStorage.getItem('userData')||'{}');
    const access_token     = (userData && userData.Token)?userData.Token:'';
    let paypalPaymentToken = localStorage.getItem('paypal-token')

    if (access_token) {
      let headerData = {
        Authorization: "Bearer "+access_token,
        'Content-Type': 'application/json',
      };

      if (params && params._parts ) {
        headerData = {
          Authorization: "Bearer "+access_token,
          'Content-Type': 'multipart/form-data',
        };
      }

      if(paypalPaymentToken) {
        headerData = {
          Authorization: "Bearer "+access_token,
          'Content-Type': 'application/json',
          'paypalToken':  paypalPaymentToken
        };
      }

      return headerData;
    
    } else {
      return {
        'Content-Type': 'application/json',
      };
    }
  }

  static encodeUrlParams(url, params) {
    let paramString = '';
    if (params && params.length > 0) { 
      params.forEach((param, index) => {
        paramString +=
          index > 0
            ? '&' + Object.keys(param)[0] + '=' + Object.values(param)[0]
            : '?' + Object.keys(param)[0] + '=' + Object.values(param)[0];
      });
    }
    url += paramString;
    return url;
  }

//----------------------------------------------//
  static get(route, params) {
    return this.xhr(route, params, 'GET');
  }

  static post(route, params) {
    return this.xhr(route, params, 'POST');
  }

  static put(route, params) {
    return this.xhr(route, params, 'PUT');
  }

  static patch(route, params) {
    return this.xhr(route, params, 'PATCH');
  }

  static delete(route, params) {
    return this.xhr(route, params, 'DELETE');
  }
//----------------------------------------------//  

  static async xhr(route, params, verb) {
    let url = Api.getBaseURL() + route;
   
    if (verb === 'GET' && params) {
      url = Api.encodeUrlParams(url, params);
      params = null;
    }

    const headers = await Api.headers(params);

    let onUploadProgress = (params && params._parts ? params._parts.onUploadProgress : null );
    
    let options;
    if(onUploadProgress){
      options = Object.assign({method: verb, headers: headers, url: url,onUploadProgress},params ? {data: params._parts.data} : null);
    }else{
      options = Object.assign({method: verb, headers: headers, url: url},params ? {data: params} : null);
    }

    return axios(options).then(resp => {
      if(resp.headers['content-type'] === 'text/plain'){
        const blob = new Blob([resp.data], {
          type: "application/vnd.ms-excel;charset=utf-8"
        });
        const fileName = 'download.csv';
        FileSaver.saveAs(blob, fileName);
        throw new Error('File Saved');
      }else{
        return resp.data;
      }
    }).catch(error => {
      console.log('--error in api --',error.response.data)
      throw error;
    })
  }
}

// download excel 
// return axios(options).then(resp => {
//   const header = resp.headers;
//   if (resp.status === 200 || resp.status === 201 || resp.status === 204) {
  // if (header['content-type'] === 'text/csv; charset=utf-8') {
  // const blob = new Blob([resp.data], {
  // type: "application/vnd.ms-excel;charset=utf-8"
  // });
  // const fileName = (optional?.fileName) ? optional.fileName : 'download.csv';
  // FileSaver.saveAs(blob, fileName);
  // throw new Error('File Saved');
  // } else {
  // return resp;
  // }
  // } else {
  // throw resp;
  // }
  // }).catch(error => {
  // throw error;
  // });

  //download  excel
