export function AuthoriseOTP(value: any) {

    const regex = /^[0-9\b]+$/;
    
    let errors:any={};

    if (value.OTP==='') {
        errors['OTPErr'] = 'OTP is required';
    }
    // else if(!regex.test(value.OTP)){
    //     errors['OTPErr'] = 'Invalid input, OTP must contain only numbers';
    // }
    else if((Object.keys(value.OTP).length < 4)){
        errors['OTPErr'] = 'OTP must be 4 digits'
    }

    return errors;
}

