import Api from './apiCall';
import {API_URLS} from '../constant/index';

export const checkLoginService = async params => {
    return await Api.post(API_URLS.CHECK_LOGIN_URL, params);
};

export const checkRegisterService = async params => {
  return await Api.post(API_URLS.SAVE_ACCOUNT_URL, params);
};

export const checkTokenService = async params => {
  return await Api.post(API_URLS.TOKEN_VALIDATION, params);
};

export const uploadPhotoService = async params => {
  return await Api.post(API_URLS.UPLOAD_PHOTO_URL, params);
};

export const forgotPasswordService = async params => {
  return await Api.post(API_URLS.FORGOT_PASSWORD_URL, params);
};

export const checkOtpService = async params => {
  return await Api.put(API_URLS.CHECK_USER_OTP_URL, params);
};

export const changePasswordService = async params => {
  // return await Api.put(API_URLS.CHANGE_PASSWORD_URL, params);
};

export const getDetailsByUsername = async params => {
  const userName = params.id;
  let url = API_URLS.GET_DETAILS_USERNAME_URL;
  let newurl = url.replace("{userName}", userName);
  return await Api.get(newurl, params);
  
};

// Verify user by OTP
export const validateOTP_ToVerifyUser = async params => {
  return await Api.put(API_URLS.GET_USER_VERIFICATION_OTP_URL, params);
};

// Resend user by OTP
export const resendOTP_ToVerifyUser = async params => {
  return await Api.put(API_URLS.GET_USERVERIFICATION_RESEND_OTP_URL, params);
};

// LOGIN OTP to authorise valid user
export const authoriseServiceForUser = async params => {
  return await Api.put(API_URLS.GET_LOGIN_OTP_URL, params);
};

// LOGIN OTP to authorise valid user
export const verifyUser_service = async params => {
  return await Api.post(API_URLS.GET_VERIFY_USER_URL, params);
};