import { useEffect, useState } from "react";
import { Button, Container, Table } from "react-bootstrap";
import { connect } from "react-redux";
import { Link, useLocation,useNavigate } from "react-router-dom";
import Layout from "../../layout/Layout";
import { getPublicBoxList } from "../../redux/actions/boxAction";
import { BASE_URLS } from "../../redux/constant";
import { BsEyeFill } from "react-icons/bs";

const PublicBoxList = (props: any) => {
  const navToBox=useNavigate()
  const publicBoxLocation = useLocation();
  const [publicBoxData, setPublicBoxData] = useState([]);
  const [visibleCreateBoxes, setVisibleCreateBoxes] = useState(10);
  useEffect(() => {
    if (publicBoxLocation) {
      props.getPublicBoxList({ keyword: publicBoxLocation });
    }
  }, [publicBoxLocation]);

  useEffect(() => {
    if (props.publicBoxListData) {
      setPublicBoxData(props.publicBoxListData);
    }
  }, [props.publicBoxListData]);

  const LoadMoreCreateBoxes = () => {
    setVisibleCreateBoxes(visibleCreateBoxes + 10);
  };

  const setBoxId=(e:any)=>{
    let boxId=e;
    if(boxId){
        navToBox("/box-record-user",{state:{id:boxId}})
    }
  }

  const handleShow  = (e:any) => {
    if(e){setBoxId(e)}
   }; 

  return (
    <>
      <Layout>
        <Container>
        <div>
        <h3 className="publicBoxListHeading text-warning"> Public box details<br/>
        <small className="publicBoxListHeading text-light">
              Total Boxes : {publicBoxData.length}{""}
            </small>
            </h3>
            {publicBoxData.length !== 0 ?
            <Table responsive>
              <thead style={{position:"sticky"}}>
                <tr style={{textAlign:"center",backgroundColor:"#e79e1f"}}>
                  <th>Box Creator</th>
                  <th>Box Name</th>
                  <th>Created On</th>
                  <th>View </th>
                </tr>
              </thead>
              <tbody>
                {publicBoxData && publicBoxData.slice(0,visibleCreateBoxes).map((list: any,index:any) => { 
                  
                  return(
                    <tr style={{textAlign:"center",height:"2%"}} key={list.boxId}>
                      <td title={`${list && list.UserboxesBTuser && list.UserboxesBTuser.username }`}>{list && list.UserboxesBTuser && list.UserboxesBTuser.username.length > 25 ? list.UserboxesBTuser.username.substring(0,25)+'...' : list.UserboxesBTuser.username}</td>
                      <td title={`${list.boxName}`}>{list && list.boxName && list.boxName.length > 25 ? list.boxName.substring(0,25)+'...' : list.boxName }</td>
                      <td>{new Date(`${list.created}`).toLocaleDateString()}</td> 
                      <td>
                        <button type="button" onClick={() => handleShow(list && list.id)} className="btn btn-outline-success" value="" >
                          <BsEyeFill style={{border:"none"}}/>
                        </button>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
            :
            <p className="text-warning text-center">No public box found...</p>
            }
            <div className="text-center pt-3" style={((publicBoxData) && ((visibleCreateBoxes > publicBoxData.length) || (visibleCreateBoxes == publicBoxData.length))) ? {display:"none"} : {display:"block"}}>
                <Button className="loadMoreBtn" onClick={() => LoadMoreCreateBoxes()}>LOAD MORE</Button>
              </div> 
          </div>



          {/* <div className="publicBoxList row mb-5 justify-content-between align-items-center">
            <h2 className="publicBoxListHeading"> Public box details</h2>
            <h3 className="publicBoxListHeading">
              Total Boxes : {publicBoxData.length}{" "}
            </h3>
            <div className="boxes">
              <div className="boxList">
                <div className="row">
                  {publicBoxData && publicBoxData.length !== 0 ? (
                    publicBoxData .slice(0, visibleCreateBoxes) .map((list: any) => {
                      return (
                        <div className="col-md-3">
                          <li>
                            <Link style={{ textDecoration: "none" }} to={`/created-box/${list.id}`} state={{ from: "publicBoxList" }} >
                              <div className="image">
                                <img src={ list && list.coverPicture ? `${BASE_URLS.BASE_URL}/uploads/box-cover/default/${list.coverPicture}` : "" } alt="" />
                              </div>
                              <h5 className="publicBoxListBoxName" style={{ marginTop: "5px", marginLeft: "50px", }} >
                                {list.boxName ? list.boxName.toUpperCase() : "BOX-NAME"}{" "}
                              </h5>
                              <h5 style={{ marginTop: "-15px", marginLeft: "50px", }} >
                                {list.isPublic && list.isPublic === 1 ? "(PUBLIC)" : ""}
                              </h5>
                            </Link>
                          </li>
                        </div>
                      );
                    })
                  ) : (
                    <div className="text-warning text-center">
                      {`No Box with such name - ${publicBoxLocation.state.keyword}`}
                    </div>
                  )}
                </div>
                <div className="text-center pt-3" style={ visibleCreateBoxes > Object.keys(publicBoxData).length || visibleCreateBoxes == Object.keys(publicBoxData).length ? { display: "none" } : { display: "block" } } >
                  <Button className="loadMoreBtn" onClick={() => LoadMoreCreateBoxes()} > LOAD MORE </Button>
                </div>
              </div>
            </div>
          </div> */}
        </Container>
      </Layout>
    </>
  );
};
const mapDispatchToProps = (dispatch: any) => ({
  getPublicBoxList: (data: any) => dispatch(getPublicBoxList(data)),
});
const mapStateToProps = (state: any) => ({
  publicBoxListData:
    state.BoxReducer && state.BoxReducer.publicBoxListData
      ? state.BoxReducer.publicBoxListData
      : null,
});

export default connect(mapStateToProps, mapDispatchToProps)(PublicBoxList);
