import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row, Stack, Table, } from "react-bootstrap";
import { BsPencilFill, BsTrashFill } from "react-icons/bs";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import Layout from "../../../layout/Admin/layout";
import { addCoverPhotos, getCoverPhotoById, getCoverPhotos, removeCoverPhotos, } from "../../../redux/actions/admin/adminBoxAction";
import { BASE_URLS } from "../../../redux/constant";

const AdminBoxCover = (props: any) => {
  const [boxCoverPhotoData, setBoxCoverPhotoData] = useState([]);
  const [show,              setShow]              = useState(false);
  const handleClose                               = () => setShow(false);
  const handleShow                                = () => setShow(true);
  
  useEffect(() => {
    props.getCoverPhotos();
  }, []);
  
  useEffect(() => {
    if (props.adminBoxCoverData) {
      setBoxCoverPhotoData(props.adminBoxCoverData);
    }
  }, [props.adminBoxCoverData]);

  const [image, setImage] = useState({ preview: "", raw: "" });
  const [profileImage, setProfileImage] = React.useState("");
  const handleImageChange = (e: any) => {
    if (e.target.files.length) {
      setProfileImage(URL.createObjectURL(e.target.files[0]));
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };
  const formData = new FormData();
  const handleUpload = async (e: any) => {
    if (image && image.raw) {
      e.preventDefault();
      formData.append("imageFile", image.raw);
      props.addCoverPhotos(formData);
      setImage({
        preview: "",
        raw: "",
      });
    }
  };

  const handleClearImg = (e: any) => {
    setProfileImage("");
  };

  useEffect(()=>{
    if(props.addAdminBoxCoverData){
      props.getCoverPhotos();
      handleClose();
    }
  },[props.addAdminBoxCoverData])

  const handleRemoveCoverPhoto = (e:any) => {
    let coverPhotoId = (e);
    Swal.fire({
      title: `Are you sure you want to delete ? `,
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "YES",
      denyButtonText: `NO`,
      confirmButtonColor: "green",
      showCloseButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        props.removeCoverPhotos({id:coverPhotoId});
      }
    });
  };
  useEffect(()=>{
    if(props.removeAdminBoxCoverData)
    { props.getCoverPhotos(); }
   },[props.removeAdminBoxCoverData])

//----------------------------------------------------------------------//  

  return (
    <div>
      <Layout>
        <Modal {...props} show={show} size="lg" backdrop="static" onHide={handleClose} className="adminModal">
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "whitesmoke" }}> Add Box Cover Photo </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Container>
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3 mt-3 ">
                      <Form.Label style={{ color: "whitesmoke" }}>Add image</Form.Label>
                      <div className="imageInput">
                        <label htmlFor="upload-button">{" "} 
                          {profileImage ? 
                            (<img src={profileImage} alt="" width={"150"} height={"150"} style={{ borderRadius: "10px" }} />) 
                          : 
                            (<div className="image"><img /></div>)
                          }{" "}
                        </label>
                      </div>
                      <input type="file" id="upload-button" style={{ display: "none" }} onChange={handleImageChange} />
                      <br />
                      <Button style={{ color: "#fff" }} className="uploadBtn" size="sm" onClick={handleUpload} type="button" > Upload </Button>
                      <Button style={{ marginLeft: "1rem", color: "#fff" }} className="clearBtn" size="sm" onClick={handleClearImg} type="button">  Clear </Button>
                      {props.message && (
                        <div className={ props.message && props.message.status === "success" ? "text-success" : "text-danger" } >
                          {props.message.data}
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              </Container>
            </Form>
          </Modal.Body>
        </Modal>

        <Row direction="horizontal" gap={3}>
          <Col md={11}>
            <h3 className="ms-auto text-warning">Box Cover Photo</h3>
          </Col>
          <Col md={1} className="ms-auto">
            <Button variant="info" className="ms-auto" onClick={handleShow}> Add</Button>
          </Col>
        </Row>
        <hr style={{border:'1px solid #E79E1F'}}/>
        <div className="scrollBar">
        <Table responsive>
          <thead>
            <tr>
              <th className="text-warning text-center">S.No.</th>
              <th className="text-warning text-center" style={{width:"200px"}}>Cover Photo</th>
              <th className="text-warning text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            {boxCoverPhotoData &&
              boxCoverPhotoData.map((list: any, index: number) => {
                if( list.uploadedBy == 'Admin'){
                return (
                  <>
                    <tr>
                      <td className="text-center">{index + 1}</td>
                      <td className="text-center">
                        <img style={{width:"200px"}} src={`${BASE_URLS.BASE_URL}/user-box-files/resource?key=${list.imageFile}`} className="d-block cover-box-photo-display " />
                      </td>
                      <td className="text-center">
                        <button type="submit" className="btn btn-outline-danger" value={list.id} onClick={()=>handleRemoveCoverPhoto(list.id)} > <BsTrashFill /> </button>
                      </td>
                    </tr>
                  </>
                );
              }
              })}
          </tbody>
        </Table>
        </div>
      </Layout>
    </div>
  );
};
const mapDispatchToProps = (dispatch: any) => ({
  getCoverPhotos   : (data: any) => dispatch(getCoverPhotos(data)),
  addCoverPhotos   : (data: any) => dispatch(addCoverPhotos(data)),
  getCoverPhotoById: (data: any) => dispatch(getCoverPhotoById(data)),
  removeCoverPhotos: (data: any) => dispatch(removeCoverPhotos(data)),
});

const mapStateToProps = (state: any) => {
  return {
    adminBoxCoverData       : state.AdminBoxApiReducer    && state.AdminBoxApiReducer.adminBoxCoverData ? state.AdminBoxApiReducer.adminBoxCoverData : null,
    addAdminBoxCoverData    : state.AdminBoxApiReducer    && state.AdminBoxApiReducer.addAdminBoxCoverData ? state.AdminBoxApiReducer.addAdminBoxCoverData : null,
    getAdminBoxCoverDataById: state.AdminBoxApiReducer    && state.AdminBoxApiReducer.getAdminBoxCoverDataById ? state.AdminBoxApiReducer.getAdminBoxCoverDataById : null,
    removeAdminBoxCoverData : state.AdminBoxApiReducer    && state.AdminBoxApiReducer.removeAdminBoxCoverData ? state.AdminBoxApiReducer.removeAdminBoxCoverData : null,
    message                 : (state.CommonMessageReducer && state.CommonMessageReducer.message) ? state.CommonMessageReducer.message : null,
        
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminBoxCover);
