import { ADMIN_COUNTRY_DATA } from "../../constant/index";

const initialState = {
  loading: false,
  countryAdminData: null,
  stateAdminData: null,
  cityAdminData: null,
  addCountryAdminData: null,
  countryByIdAdminData: null,
  updateAdminCountryData: null,
  removeAdminCountryData: null,
  addStateAdminData: null,
  updateStateAdminData: null,
  removeStateAdminData: null,
  stateByIdAdminData: null,
  addCityAdminData: null,
  updateCityAdminData: null,
  removeCityAdminData: null,
  cityByIdAdminData: null,
};
const AdminCountryReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADMIN_COUNTRY_DATA.SET_ADMIN_COUNTRIES_TO_STATE:
      return {
        ...state,
        countryAdminData: action.data,
        loading: false,
      };
    case ADMIN_COUNTRY_DATA.SET_ADMIN_STATES_TO_STATE:
      return {
        ...state,
        stateAdminData: action.data,
        loading: false,
      };
    case ADMIN_COUNTRY_DATA.SET_ADMIN_CITIES_TO_STATE:
      return {
        ...state,
        cityAdminData: action.data,
        loading: false,
      };
    case ADMIN_COUNTRY_DATA.SET_ADMIN_COUNTRIES_BY_ID_TO_STATE:
      return {
        ...state,
        countryByIdAdminData: action.data,
        loading: false,
      };

    case ADMIN_COUNTRY_DATA.SET_ADD_ADMIN_COUNTRY_TO_STATE:
      return {
        ...state,
        addCountryAdminData: action.data,
        loading: false,
      };

    case ADMIN_COUNTRY_DATA.SET_UPDATE_ADMIN_COUNTRIES_TO_STATE:
      return {
        ...state,
        updateAdminCountryData: action.data,
        loading: false,
      };

    case ADMIN_COUNTRY_DATA.SET_REMOVE_ADMIN_COUNTRIES_TO_STATE:
      return {
        ...state,
        removeAdminCountryData: action.data,
        loading: false,
      };
    case ADMIN_COUNTRY_DATA.SET_ADD_ADMIN_STATES_TO_STATE:
      return {
        ...state,
        addStateAdminData: action.data,
        loading: false,
      };

    case ADMIN_COUNTRY_DATA.SET_UPDATE_ADMIN_STATES_TO_STATE:
      return {
        ...state,
        updateStateAdminData: action.data,
        loading: false,
      };
    case ADMIN_COUNTRY_DATA.SET_REMOVE_ADMIN_STATES_TO_STATE:
      return {
        ...state,
        removeStateAdminData: action.data,
        loading: false,
      };
    
      
      case ADMIN_COUNTRY_DATA.SET_ADMIN_STATE_BY_ID_TO_STATE:
      return {
        ...state,
        stateByIdAdminData: action.data,
        loading: false,
      };

      case ADMIN_COUNTRY_DATA.SET_ADD_ADMIN_CITY_TO_STATE:
        return {
          ...state,
          addCityAdminData: action.data,
          loading: false,
        };
  
      case ADMIN_COUNTRY_DATA.SET_UPDATE_ADMIN_CITY_TO_STATE:
        return {
          ...state,
          updateCityAdminData: action.data,
          loading: false,
        };
      case ADMIN_COUNTRY_DATA.SET_REMOVE_ADMIN_CITY_TO_STATE:
        return {
          ...state,
          removeCityAdminData: action.data,
          loading: false,
        };
      
        
        case ADMIN_COUNTRY_DATA.SET_ADMIN_CITY_BY_ID_TO_STATE:
        return {
          ...state,
          cityByIdAdminData: action.data,
          loading: false,
        };
    
      default:
      return state;
  }
};

export default AdminCountryReducer;
