import { Navigate } from 'react-router-dom';
import NotAuthorized from '../pages/Admin/error-pages/not_authorized';

const AdminRoutes = (props:any) => {

    const userData = JSON.parse(localStorage.getItem('userData')||'{}');
    const profileData = JSON.parse(localStorage.getItem('profileData')||'{}');
    const token    = (userData && userData.Token)?userData.Token:'';
    const role     = profileData.roles[0];
    
    if(!token){
        return <Navigate to="/login" />
    }
    
    return (role && role == 'Admin')? props.children : <NotAuthorized />;
}

export default AdminRoutes;