// Imports: Dependencies
import {combineReducers} from 'redux';

// Imports: Reducers
import AuthReducer from './authReducer';
import CommonMessageReducer from './commonMessageReducer';
import CountryReducer from './countryReducer';
import ProfilePageReducer from './profilePageReducer';
import PlansReducer from './plansReducer';
import BoxReducer from './boxReducer';
import UserReducer from './userReducer';
import RecycleBinReducer from './recycleBinReducer';
import webReducer from './webReducer';
import AdminBoxPlanReducer from './admin/adminBoxPlanReducer';
import AdminCountryReducer from './admin/adminCountryReducer';
import AdminBoxApiReducer from './admin/adminBoxApiReducer';
import AdminUserReducer from './admin/adminUserReducer';
import AdminFolderManagementReducer from './admin/adminFolderManagementReducer';
import AdminFoundationReducer from './admin/adminFoundationReducer';
import FoundationReducer from './foundationReducer';
import AdminMarketerReducer from './admin/adminMarketerReducer';

const appReducer = combineReducers({
  AuthReducer,
  CommonMessageReducer,
  CountryReducer,
  ProfilePageReducer, 
  PlansReducer,
  BoxReducer,
  UserReducer,
  RecycleBinReducer,
  webReducer,
  AdminBoxPlanReducer,
  AdminCountryReducer,
  AdminBoxApiReducer,
  AdminUserReducer   ,
  AdminFolderManagementReducer,   
  AdminFoundationReducer, 
  FoundationReducer,    
  AdminMarketerReducer,     
});

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

// Exports
export default rootReducer;

