
import {put, call} from 'redux-saga/effects';
import { getAdminBoxPlanService,getAdminBoxPlanByIdService,addAdminBoxPlanService,updateAdminBoxPlanService,deleteAdminBoxPlanService } from '../../services/admin/adminBoxPlanService';
import {COMMON_MESSAGE, ADMIN_BOX_PLAN} from '../../constant/index';
import {generateReadableError} from '../../../hooks/messages';

const clearErrorMessage = {
  type: COMMON_MESSAGE,
  data: {
    status: '',
    page: '',
    data: [],
  }}

export function* fetchAdminPlans({payload}) {
  try {
    yield put({type: ADMIN_BOX_PLAN.SET_ADMIN_BOX_PLAN_TO_STATE, data:null});
    yield put(clearErrorMessage);
    const data = yield call(getAdminBoxPlanService, payload);
   
    yield put({type: ADMIN_BOX_PLAN.SET_ADMIN_BOX_PLAN_TO_STATE, data});
    

  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'error',
        page: 'plans',
        data: generateReadableError(error),
      },
    });
  }
}


export function* fetchAdminPlansById({payload}) {
  try {
    yield put(clearErrorMessage);
    yield put({type: ADMIN_BOX_PLAN.SET_ADMIN_BOX_PLAN_BY_ID_TO_STATE, data:null});
    const data = yield call(getAdminBoxPlanByIdService, payload);
   
    yield put({type: ADMIN_BOX_PLAN.SET_ADMIN_BOX_PLAN_BY_ID_TO_STATE, data});
    

  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'error',
        page: 'plans',
        data: generateReadableError(error),
      },
    });
  }
}
export function* fetchAddAdminPlans({payload}) {
  try {
    yield put({type: ADMIN_BOX_PLAN.SET_ADD_ADMIN_BOX_PLAN_TO_STATE, data:null});
    yield put(clearErrorMessage);
    const data = yield call(addAdminBoxPlanService, payload);
   
    yield put({type: ADMIN_BOX_PLAN.SET_ADD_ADMIN_BOX_PLAN_TO_STATE, data});
    

  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'error',
        page: 'plans',
        data: generateReadableError(error),
      },
    });
  }
}
export function* fetchUpdateAdminPlans({payload}) {
  try {
    yield put({type: ADMIN_BOX_PLAN.SET_UPDATE_ADMIN_BOX_PLAN_TO_STATE, data:null});
    yield put(clearErrorMessage);
    const data = yield call(updateAdminBoxPlanService, payload);
   
    yield put({type: ADMIN_BOX_PLAN.SET_UPDATE_ADMIN_BOX_PLAN_TO_STATE, data});
    

  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'error',
        page: 'plans',
        data: generateReadableError(error),
      },
    });
  }
}
export function* fetchDeleteAdminPlans({payload}) {
  try {
    yield put({type: ADMIN_BOX_PLAN.SET_DELETE_ADMIN_BOX_PLAN_TO_STATE, data:null});
    yield put(clearErrorMessage);
    const data = yield call(deleteAdminBoxPlanService, payload);
   
    yield put({type: ADMIN_BOX_PLAN.SET_DELETE_ADMIN_BOX_PLAN_TO_STATE, data});
    

  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'error',
        page: 'plans',
        data: generateReadableError(error),
      },
    });
  }
}
