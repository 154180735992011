import { useEffect, useState } from "react";
// import Layout from "../../layout/Layout";
import { Button, Form, Container, Row, Col, InputGroup } from 'react-bootstrap';
import { changePasswordValidation } from "../../../validations/changePasswordValidation";
import eyeLogo from '../../../assets/images/eye.png';
import slashedEye from '../../../assets/images/unseen.png';
import { connect } from "react-redux";
import { changePassword } from "../../../redux/actions/profileAction";

function ChangePassword(props:any) {

    let   [eyeImg1,             seteyeImg1]             = useState(<img src={slashedEye} alt="" />);
    let   [eyeImg2,             seteyeImg2]             = useState(<img src={slashedEye} alt="" />);
    let   [eyeImg3,             seteyeImg3]             = useState(<img src={slashedEye} alt="" />);
    const [passwordType,        setPasswordType]        = useState("password");
    const [newPasswordType,     setNewPasswordType]     = useState("password");
    const [confirmPasswordType, setConfirmPasswordType] = useState("password");
    const [formData,            setFormData]            = useState({oldPassword:'',newPassword:'',confirmPassword:''});
    const [errors,              setErrors]              = useState({oldPassword:'',newPassword:'',confirmPassword:'',validOldPassword:'',validNewPassword:'',validConfirmPassword:'',responseError:''});
    const [successMessage,      setSuccessMessage]      = useState({responseSuccess:''});
    const [loading,             setLoading]             = useState(false);
    
    const togglePassword = (params:string) => {
        if (passwordType === "password" && params === 'old') { // Old Password
            seteyeImg1(<img src={eyeLogo} alt="" />);
            setPasswordType("text")
            return;
        }else if(params === 'old'){
            seteyeImg1(<img src={slashedEye} alt="" />);
            setPasswordType("password")
        }

        if (newPasswordType === "password" && params === 'new') { // New Password
            seteyeImg2(<img src={eyeLogo} alt="" />);
            setNewPasswordType("text")
            return;
        }else if(params === 'new'){
            seteyeImg2(<img src={slashedEye} alt="" />);
            setNewPasswordType("password")
        }

        if (confirmPasswordType === "password" && params === 'confirm') { // Confirm Password
            seteyeImg3(<img src={eyeLogo} alt="" />);
            setConfirmPasswordType("text")
            return;
        }else if(params === 'confirm'){
            seteyeImg3(<img src={slashedEye} alt="" />);
            setConfirmPasswordType("password")
        }
    }
    
    const handleChange = (event: any) => {
        setFormData({...formData, [event.target.name]:event.target.value});
        const checkValidation = changePasswordValidation({...formData, [event.target.name]:event.target.value});
        setErrors(checkValidation);
    };

    const handleSubmit = () =>{
        const checkValidation =changePasswordValidation(formData);
        setErrors(checkValidation);
        if(Object.keys(checkValidation).length === 0){
            let payLoad = {oldPassword:formData.oldPassword,newPassword:formData.newPassword}
            setLoading(true);
            props.changePassword(payLoad);
        }
    }

    useEffect(() =>{
        if(props.message){
            if(props.message.status === 'error'){
                setLoading(false);
                setSuccessMessage({responseSuccess:''})
                setErrors({...errors,responseError:props.message.data})
            }
        }
    },[props.message])

    useEffect(() =>{
        if(props.changePassword_res && Object.keys(errors).length === 0){
            setLoading(false);
            setFormData({oldPassword:'',newPassword:'',confirmPassword:''});
            seteyeImg1(<img src={slashedEye} alt="" />);
            seteyeImg2(<img src={slashedEye} alt="" />);
            seteyeImg3(<img src={slashedEye} alt="" />);
            setSuccessMessage({responseSuccess:(props.changePassword_res && props.changePassword_res.message ? props.changePassword_res.message : '')})
        }
    },[props.changePassword_res])

//--------------------------------------------------------------------------------------------------//

    return (
        <Container>
            <div className="changePassword">
                <div className="userDetailBlock">
                    <hr style={{border:'1px solid #E79E1F'}}/>
                    <div className="formWrap align-items-center d-md-flex flex-column justify-content-center">
                        {/* <h2 className="text-center">Change Password</h2> */}
                        {loading ? <p className="lds-dual-ring" style={{marginTop:"-40px"}}></p>
                        :
                            <>
                            {(errors && !successMessage.responseSuccess && (errors.validOldPassword || errors.validNewPassword || errors.validConfirmPassword || errors.responseError ))&& 
                                <p className={errors && errors.responseError ? `text-danger` : `text-success`}>
                                    {errors && errors.responseError ? errors.responseError.replace('Current','Old') : 
                                        <>
                                            Your password must contain a minimum of:
                                            <ul >
                                                <li style={{listStyleType:"disc",textAlign:"left"}}>Eight characters</li>
                                                <li style={{listStyleType:"disc",textAlign:"left"}}>One uppercase letter</li>
                                                <li style={{listStyleType:"disc",textAlign:"left"}}>One special character (@#%^&+)</li>
                                                <li style={{listStyleType:"disc",textAlign:"left"}}>One number</li>
                                            </ul>
                                        </>
                                    }
                                </p>
                            }
                            {Object.keys(errors).length === 0 && successMessage.responseSuccess && <p className="text-success">{successMessage.responseSuccess}</p>}
                           </> 
                        }
                        <Form className="w-100 p-3">
                            <Form.Group className="formRow passwordHide">
                                <Row>
                                    <Col md='auto' className="ms-auto">
                                        <Form.Label style={{color:'#E79E1F'}}>Old Password</Form.Label>
                                    </Col>
                                    <Col md={9}>
                                        <InputGroup>
                                            <Form.Control type={passwordType} name="oldPassword" id="changePassword_oldPassword" placeholder="Enter old password" value={formData && formData.oldPassword ? formData.oldPassword : ''} onChange={handleChange} style={{borderTopRightRadius:'5px',borderBottomRightRadius:'5px'}}/>
                                            <InputGroup.Text className="hideIcon" onClick={()=>togglePassword('old')} style={{position:'absolute',top:'0%',bottom:'0%',background:"rgba(149,149,149,0.9)",zIndex:'6'}}>{eyeImg1}</InputGroup.Text>
                                        </InputGroup>
                                    </Col>
                                </Row>
                                {errors && (errors.oldPassword || errors.validOldPassword) ? <p className="text-danger">{errors.oldPassword ? errors.oldPassword : errors.validOldPassword ? errors.validOldPassword : '' }</p> : <br/>}
                            </Form.Group>
                            
                            <Form.Group className="formRow passwordHide">
                                <Row>
                                    <Col md='auto' className="ms-auto">
                                        <Form.Label style={{color:'#E79E1F'}}>New Password</Form.Label>
                                    </Col>
                                    <Col md={9}>
                                        <InputGroup>
                                            <Form.Control type={newPasswordType} id="changePassword_newPassword" name="newPassword" placeholder="Enter new password" value={formData && formData.newPassword ? formData.newPassword : ''} onChange={handleChange} style={{borderTopRightRadius:'5px',borderBottomRightRadius:'5px'}}/>
                                            <InputGroup.Text className="hideIcon" onClick={()=>togglePassword('new')} style={{position:'absolute',top:'0%',bottom:'0%',background:"rgba(149,149,149,0.9)",zIndex:'6'}}>{eyeImg2}</InputGroup.Text>
                                        </InputGroup>
                                    </Col>
                                </Row>
                                {errors && (errors.newPassword || errors.validNewPassword) ? <p className="text-danger">{errors.newPassword ? errors.newPassword : errors.validNewPassword ? errors.validNewPassword : ''}</p> : <br/>}
                            </Form.Group>
                            
                            <Form.Group className="formRow passwordHide">
                                <Row>
                                    <Col md='auto' className="ms-auto">
                                        <Form.Label style={{color:'#E79E1F'}}>Confirm Password</Form.Label>
                                    </Col>
                                    <Col md={9}>
                                        <InputGroup>
                                            <Form.Control type={confirmPasswordType} id="changePassword_confirmPassword" name="confirmPassword" placeholder="Enter Password" value={formData && formData.confirmPassword ? formData.confirmPassword : ''} onChange={handleChange} style={{borderTopRightRadius:'5px',borderBottomRightRadius:'5px'}}/>
                                            <InputGroup.Text className="hideIcon" onClick={()=>togglePassword('confirm')} style={{position:'absolute',top:'0%',bottom:'0%',background:"rgba(149,149,149,0.9)",zIndex:'6'}}>{eyeImg3}</InputGroup.Text>
                                        </InputGroup>
                                    </Col>
                                </Row>
                                {errors && (errors.confirmPassword || errors.validConfirmPassword) && <p className="text-danger">{errors.confirmPassword ? errors.confirmPassword : errors.validConfirmPassword ? errors.validConfirmPassword : ''}</p>}
                            </Form.Group>
                        </Form>
                    </div>
                    <hr style={{border:'1px solid #E79E1F'}}/>
                    <div className="d-flex justify-content-center" style={loading ? {cursor:'not-allowed'} : {}}>
                        <Button  className={`backBtn`} variant="primary" type="button" onClick={handleSubmit} disabled={loading}>CONTINUE</Button>
                    </div>
                </div>
            </div>
        </Container>
    
  )
}

const mapDispatchToProps = (dispatch:any) =>({
    changePassword : (data: any) => dispatch(changePassword(data)),
});

const mapStateToProps = (state: any) => {
  return{
    changePassword_res : (state.ProfilePageReducer   && state.ProfilePageReducer.changePassword) ? state.ProfilePageReducer.changePassword : null,
    message            : (state.CommonMessageReducer && state.CommonMessageReducer.message)      ? state.CommonMessageReducer.message      : null,
   } 
};

export default connect(mapStateToProps,mapDispatchToProps)(ChangePassword);