export function signupFormValidation(value: any) {

    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const passwordRegex = /^(?!.*\s)(?=.{6,})(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+=]).*$/;
    const phoneRegex = /^((?:\+27|27)|0)(\d{2})-?(\d{3})-?(\d{4})$/;
    const alpharegex=/^[A-Za-z ]+$/;
    let errors:any={};
    if (value.email==='') {
        errors['email'] = 'Email address is required';
    } else if (!emailRegex.test(value.email)) {
        errors['email'] = 'Enter a valid email address';
    }
    if (value.password==='') {
        errors['password'] = 'Password is required';
    } else if(!passwordRegex.test(value.password)) {
        errors['password'] = 'Enter a valid password';
    }
    if (value.confirm_password==='') {
        errors['confirm_password'] = 'Password is required';
    } else if(!passwordRegex.test(value.confirm_password)) {
        errors['confirm_password'] = 'Enter a valid password';
    } else if (value.password!==value.confirm_password) {
        errors['confirm_password'] = 'Passwords do not match';
    }
    
    
    if(!alpharegex.test(value.username)){
        errors['username'] = 'Enter a valid name';
    } /* else  if (value.username.length<6){
        errors['username'] = 'Full name length should be atleast six characters';
    }  */
    
    if (value.countryDialCode==='') {
        errors['countryDialCode'] = 'Country code is required';
    }
    
    if (value.mobile==='') {
        errors['mobile'] = 'Phone number is required';
    } 
    // else if(!phoneRegex.test(value.mobile)) {
    //     errors['mobile'] = 'Enter a valid South Africa mobile number';
    // }

    if (value.country == 0 || value.country == '') {
        errors['country'] = 'Country is required';
    }
    if (value.state == 0 || value.state == '') {
        errors['state'] = 'State is required';
    }
    

    return errors;


}

