import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Spinner, Table } from "react-bootstrap";
import { connect } from "react-redux";
import Layout from "../../layout/Layout";
import { listUserPeople } from "../../redux/actions/userAction";
import { getRelationalData,newRecipientProfilePic,updateRecipient,getUserDetailsByEmail } from "../../redux/actions/boxAction";
import profileImage from "../../assets/images/user-no-image.png";
import { FaPencilAlt } from "react-icons/fa";
import DisplayModal from "../box/uploaded_folder/display-modal";
import { ImCross } from "react-icons/im";
import { BASE_URLS, BOX_API } from "../../redux/constant";
import { recipientEditValidationForMyPeople } from "../../validations/recipient_details_validation";

const People = (props:any) => {
  const [externalUsers, setExternalUsers] = useState([]);
  const [internalUsers, setInternalUsers] = useState([]);
  const [modalShow,     setModalShow]     = useState<boolean>(false);
  const [relationalData,setRelationalData]= useState<any[]>([]);
  const [editForm,      setEditForm]      = useState({id:0,profilePic:"",Fname:"",Lname:"",email:"",relation:0});
  const [errors,        setErrors]        = useState({commonError:""});
  const [successMsg,    setSuccessMsg]    = useState({successNotification:""});
  const [picLoader,     setPicLoader]     = useState<boolean>(false);
  const [loader,        setLoader]        = useState<boolean>(false);
  let   hiddenFileInput : any;

  useEffect(() => {
    props.listUserPeople();
    props.getRelationalData();
  }, []);

  useEffect(() =>{
    if(props.getRelationShipList && props.getRelationShipList.length !== 0){
        setRelationalData(props.getRelationShipList);
    }
  },[props.getRelationShipList])

  useEffect(() => {
    if (props.userPeopleData) {
      setExternalUsers( props.userPeopleData.externalRecipients );
      setInternalUsers( props.userPeopleData.internalRecipients );
    }
  }, [props.userPeopleData]);

  const handleUploadChange = (e:any) =>{
    const formData = new FormData();
    formData.append("recipientImage",hiddenFileInput.files[0]);
    props.editProfilePicture(formData);
    setPicLoader(true);
  }

  useEffect(()=>{
    if(props.tempNewRecpPic){
      setPicLoader(false);
      setEditForm({...editForm,profilePic:props.tempNewRecpPic.imagePath});
    }
  },[props.tempNewRecpPic])

  const handleOnChange = (e:any) =>{
    setEditForm({...editForm,[e.target.name]:e.target.value});
  }
  
  const editRecipients = (recipientsDetails:any) => {
    setModalShow(true);
    if(recipientsDetails && recipientsDetails.length != 0){
      setEditForm({...editForm,
                    id         : (recipientsDetails.id ? Number(recipientsDetails.id) : 0),
                    profilePic : (recipientsDetails.recipientImage ? recipientsDetails.recipientImage : ''),
                    Fname      : (recipientsDetails.recipientFname ? recipientsDetails.recipientFname : ''),
                    Lname      : (recipientsDetails.recipientLname ? recipientsDetails.recipientLname : ''),
                    email      : (recipientsDetails.parentEmailId  ? recipientsDetails.parentEmailId  : (recipientsDetails.recipientEmail ? recipientsDetails.recipientEmail : '' )),
                    relation   : (recipientsDetails.relationId     ? Number(recipientsDetails.relationId) : 0 )
                  })
    }
  }

  const handleSubmit = () =>{
    const validationErrors = recipientEditValidationForMyPeople(editForm);
    let isValid = Object.keys(validationErrors).length === 0;
    if(!isValid){
      setErrors(validationErrors);
    }else{
      setErrors({...errors,commonError:''});
      setLoader(true);
      props.findByEmailId({"email":editForm.email});
    }
  }

  useEffect(() =>{
    setLoader(false);
    if(props.checkDuplicate && props.checkDuplicate.length != 0){
      setErrors({...errors,commonError:"Please add this recipient via the 'Edit Box' tool."});
    }else{
      let payload = {
        args:{
          'Id':Number(editForm.id)
        },
        data:{
          'recipientImage':(editForm.profilePic ? editForm.profilePic : ''),
          'recipientFname':(editForm.Fname ? editForm.Fname : ''),
          'recipientLname':(editForm.Lname ? editForm.Lname : ''),
          'relationId'    :(editForm.relation ? Number(editForm.relation) : 0),
          'recipientEmail':(Number(editForm.relation) !== 3 ? editForm.email : ''),
          'parentEmailId' :(Number(editForm.relation) === 3 ? editForm.email : '')
        }
      }
      
      if(editForm.id){
        setLoader(true);
        props.updateRecipient(payload);
      }
    }
  },[props.checkDuplicate])

  useEffect(() =>{
    if(props.updateRcipient){
      if(props.updateRcipient.code === 200 && props.updateRcipient.status === 'Success'){
        setLoader(false);
        setSuccessMsg({...successMsg,successNotification:props.updateRcipient.message});
        props.listUserPeople();
      }
    }
  },[props.updateRcipient])

  useEffect(()=>{
    if(successMsg && modalShow){
      setLoader(false);
      setModalShow(false);
      setTimeout(() =>{
        setSuccessMsg({...successMsg,successNotification:''});
        props.clearRecipientResp(null);
      },2000)
    }
  },[successMsg])
  

  const closeRecipientPopup = () =>{
    setModalShow(false);
    setLoader(false);
    setErrors({...errors,commonError:''});
  }
//----------------------------------------------------------------------------------------// 

  return (
    <div>
      <Layout>
        <Container>
          {(internalUsers && internalUsers.length === 0) && (externalUsers && externalUsers.length === 0) ?
            <p className="text-center text-warning pt-5">
              {/* No recipients list not found... */}
            </p>   
          :
            (internalUsers && internalUsers.length !== 0 || externalUsers && externalUsers.length !== 0) &&
              <>
                <Row>
                  <Col md="auto">
                    <h5 style={{color:"#fff", fontSize:"25px", fontWeight:"800" }}>My People</h5>
                  </Col>
                  {!loader &&
                    <Col md="auto">
                      {successMsg.successNotification && <span className="bg-success text-light p-2 rounded">{successMsg.successNotification}</span>}
                    </Col>
                  }
                </Row>

                  <Table responsive className="table-background">
                    <thead>
                      <tr style={{background:"black"}}>
                        <th style={{textAlign:'center',color:"#E79E1F" }}>Profile-Image</th>
                        <th style={{textAlign:'center',color:"#E79E1F" }}>Name</th>
                        <th style={{textAlign:'center',color:"#E79E1F" }}>Surname</th>
                        <th style={{textAlign:'center',color:"#E79E1F" }}>Email / Parent Email</th>
                        <th style={{textAlign:'center',color:"#E79E1F" }}>Relation</th>
                        <th style={{textAlign:'center',color:"#E79E1F" }}>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {externalUsers&&externalUsers.map((data:any)=>{
                        let details=data;
                        
                        return( 
                          <tr key={details.id}>
                            <td style={{textAlign:'center',width:"10px" }}>
                              {details && details.recipientImage ?
                                <img height={55} width={55} style={{borderRadius:"50px"}} src={`${BASE_URLS.BASE_URL}/user-box-files/resource?key=${details.recipientImage}` } onError={({ currentTarget }:{currentTarget:any}) => {currentTarget.onerror = null; currentTarget.src=profileImage; }} alt="No Image"/>
                              :
                                <img height={55} src={profileImage}/>
                              }
                            </td>
                            <td style={{textAlign:'center',paddingTop:"22px" }}>{details.recipientFname && details.recipientFname}</td>
                            <td style={{textAlign:'center',paddingTop:"22px" }}>{details.recipientLname && details.recipientLname}</td>
                            <td style={{textAlign:'center',paddingTop:"22px",lineHeight:"18px" }}>
                              {details.recipientEmail ? details.recipientEmail : details.parentEmailId} <br/>
                              {details.parentEmailId && <span style={{color:"#E79E1F",fontSize:"15px"}}>( Parent e-mail )</span>}
                            </td>
                            <td style={{textAlign:'center',paddingTop:"22px" }}>{details.recipientsBTrelation && details.recipientsBTrelation.relationName ? details.recipientsBTrelation.relationName : 'N/A'}</td>
                            <td style={{textAlign:'center',paddingTop:"17px" }}>
                              {details.relationId != 3 &&
                              <button type="button" className="btn btn-outline-warning" onClick={() =>{editRecipients(details)}}>
                                <FaPencilAlt style={{ color: 'lightgrey',cursor:"pointer" }}/>
                              </button>
                              }
                            </td>
                          </tr>
                        )
                      })}

                      {internalUsers && internalUsers.map((data: any) => {
                        let details = data.usersBoxRecipients;
                        
                        return (
                          <tr key={data.id}>
                            <td style={{textAlign:'center',width:"10px" }}>
                              {details && details.userImg ?
                                <img height={55} width={55} style={{borderRadius:"50px"}} src={`${BASE_URLS.BASE_URL}/user-box-files/resource?key=${details.userImg}` } onError={({ currentTarget }:{currentTarget:any}) => {currentTarget.onerror = null; currentTarget.src=profileImage; }} alt="No Image"/>
                              :
                                <img height={55} src={profileImage}/>
                              }
                            </td>
                            <td style={{textAlign:'center',paddingTop:"22px" }}>{details && details.firstName}</td>
                            <td style={{textAlign:'center',paddingTop:"22px" }}>{details && details.lastName}</td>
                            <td style={{textAlign:'center',paddingTop:"22px" }}>
                              {details && details.email}<br/>
                              {data.relationId && data.relationId == 3 && <span style={{color:"#E79E1F",fontSize:"15px"}}>( Parent e-mail )</span>}
                            </td>
                            <td style={{textAlign:'center',paddingTop:"22px" }}>{data.boxRecipientsBTrelation && data.boxRecipientsBTrelation.relationName ? data.boxRecipientsBTrelation.relationName : 'N/A'}</td>
                            <td style={{textAlign:'center',paddingTop:"17px" }}>
                              {/* <button type="button" className="btn btn-outline-danger" style={{cursor:"not-allowed"}}>
                                <ImCross title="non-editable" style={{ color: 'darkpink',fontWeight:"800" }}/>
                              </button> */}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </>
              }
            </Container>
            <DisplayModal backdrop="static" show={modalShow} onHide={closeRecipientPopup} name="Update Recipient's Detail">
              <hr style={{border:'1px solid #E79E1F'}}/> 
              <Form.Group className="formRow">
                <Row>
                  <Col md={2}>
                    <Form.Label>
                    {picLoader ? 
                        <>
                            <Spinner style={{margin: "25px 25px 10px 25px",color:"#fff"}}/>
                            <small style={{fontSize:"15px",margin: "0px 0px 0px 15px",color:"#fff"}}>Loading...</small>
                        </>
                    :
                      <>
                        <img height={100} style={{borderRadius:"50px",cursor:"default"}} src={editForm && editForm.profilePic ? `${BASE_URLS.BASE_URL}/user-box-files/resource?key=${editForm.profilePic}` :  profileImage} onError={({ currentTarget }:{currentTarget:any}) => {currentTarget.onerror = null; currentTarget.src=profileImage; }} alt="No Image"/><br/>
                        <small style={{color:"#E79E1F",cursor:"pointer"}}>
                          <u>Upload</u>
                          <input type="file" style={{display:'none'}} onChange={handleUploadChange} ref={refParam => hiddenFileInput = refParam} accept="image/png, image/jpeg, image/jpg, image/gif, image/webp"/>
                        </small>
                      </>
                    }
                    </Form.Label>
                  </Col>
                  <Col md={10}>
                    <Row>
                      <Col md={6}><Form.Control name="Fname" className="mb-4 mt-2" placeholder="Name" value={editForm && editForm.Fname ? editForm.Fname : ''} onChange={handleOnChange}></Form.Control></Col>
                      <Col md={6}><Form.Control name="Lname" className="mb-4 mt-2" placeholder="Surname" value={editForm && editForm.Lname ? editForm.Lname : ''} onChange={handleOnChange}></Form.Control></Col>
                      <Col md={6}><Form.Control name="email" placeholder={`Email`} value={editForm && editForm.email ? editForm.email : ''} onChange={handleOnChange}></Form.Control></Col>
                      <Col md={6}>
                        <Form.Select name="relation" value={editForm && editForm.relation ? editForm.relation : 0} onChange={handleOnChange}>
                          <option value="0">Select relation</option>
                          {relationalData && relationalData.map((relation: any) => (
                            <option value={relation.id} key={relation.id}>
                              {relation.relationName}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Row>
                  </Col>  
                </Row>
              </Form.Group>
              <hr style={{border:'1px solid #E79E1F'}}/> 
              <Form.Group className="formRow">
                <Row>
                  <Col md="auto"><Button style={{border:"1px solid #E79E1F"}} className='backBtn' onClick={handleSubmit}>Update</Button></Col>
                  <Col md="auto"><Button  style={{border:"1px solid #E79E1F"}} className='backBtn' onClick={closeRecipientPopup}>Cancel</Button></Col>
                  {errors && errors.commonError &&
                    <Col md="auto" className='ms-auto'>
                      {errors.commonError && <span className="bg-danger text-light p-2 rounded">{errors.commonError}</span>}
                    </Col>
                  }
                  {loader &&
                    <Col md="auto" className='ms-auto'>
                      <small style={{fontSize:"15px",color:"#E79E1F"}}>Loading...</small>&nbsp;<Spinner style={{color:"#E79E1F",fontSize:"10px",height:"15px",width:"15px"}}/>
                    </Col>
                  }
                </Row>
              </Form.Group>
            </DisplayModal>
          </Layout>
        </div>
      );
    };

const mapDispatchToProps = (dispatch: any) => ({
  listUserPeople    : (data: any) => dispatch(listUserPeople(data)),
  getRelationalData : (data: any) => dispatch(getRelationalData(data)),
  editProfilePicture: (data: any) => dispatch(newRecipientProfilePic(data)),
  updateRecipient   : (data: any) => dispatch(updateRecipient(data)),
  clearRecipientResp: (data: any) => dispatch({type: BOX_API.GET_RESPONSE_AFTER_RECIPIENT_UPDATE, data}),
  findByEmailId     : (data: any) => dispatch(getUserDetailsByEmail(data)),
});

const mapStateToProps = (state: any) => {
  return {
    userPeopleData      : state.UserReducer && state.UserReducer.userPeopleData     ? state.UserReducer.userPeopleData     : null,
    getRelationShipList : state.BoxReducer  && state.BoxReducer.getRelationShipList ? state.BoxReducer.getRelationShipList : null,
    tempNewRecpPic      : state.BoxReducer  && state.BoxReducer.tempNewRecpPic      ? state.BoxReducer.tempNewRecpPic      : null,
    updateRcipient      : state.BoxReducer  && state.BoxReducer.updateRecipient     ? state.BoxReducer.updateRecipient     : null,
    checkDuplicate      : state.BoxReducer  && state.BoxReducer.usernameByEmailData ? state.BoxReducer.usernameByEmailData : null,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(People)