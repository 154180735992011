import { ADMIN_BOX_PLAN } from "../../constant/index";

const initialState = {
  loading: false,
  adminBoxPlanData: null,
  adminBoxPlanDataById: null,
  updateAdminBoxPlanData: null,
  deleteAdminBoxPlanData: null,
};
const AdminBoxPlanReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADMIN_BOX_PLAN.SET_ADMIN_BOX_PLAN_TO_STATE:
      return {
        ...state,
        adminBoxPlanData: action.data,
        loading: false,
      };
    case ADMIN_BOX_PLAN.SET_ADMIN_BOX_PLAN_BY_ID_TO_STATE:
      return {
        ...state,
        adminBoxPlanDataById: action.data,
        loading: false,
      };
    case ADMIN_BOX_PLAN.SET_ADD_ADMIN_BOX_PLAN_TO_STATE:
      return {
        ...state,
        addAdminBoxPlanData: action.data,
        loading: false,
      };

    case ADMIN_BOX_PLAN.SET_UPDATE_ADMIN_BOX_PLAN_TO_STATE:
      return {
        ...state,
        updateAdminBoxPlanData: action.data,
        loading: false,
      };
    case ADMIN_BOX_PLAN.SET_DELETE_ADMIN_BOX_PLAN_TO_STATE:
      return {
        ...state,
        deleteAdminBoxPlanData: action.data,
        loading: false,
      };
    // DELETE_ADMIN_BOX_PLAN_TO_STATE
    default:
      return state;
  }
};

export default AdminBoxPlanReducer;
