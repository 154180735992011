import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Stack,
  Table,
} from "react-bootstrap";
import { BsPencilFill, BsTrashFill } from "react-icons/bs";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import Layout from "../../../layout/Admin/layout";
import {
  addAdminStates,
  adminCountryList,
  adminStateById,
  adminStateList,
  removeAdminStates,
  updateAdminStates,
} from "../../../redux/actions/admin/adminCountryAction";

const AdminCountryStates = (props: any) => {
  const [countryData, setCountryData] = useState([]);
  const [statesData, setStatesData] = useState([]);
  const [countryId, setCountryId] = useState("");
  const [stateId, setStateId] = useState("");
  const [addStateModalShow, setAddStateModalShow] = useState(false);
 // const [addStateModalOpen, setAddStateModalOpen] = useState(false);
  const [updateStateModalShow, setUpdateStateModalShow] = useState(false);
  const [updateStateModalOpen, setUpdateStateModalOpen] = useState(false);
  const [stateValue, setStateValue] = useState({ stateName: "" });
  const [updateStateValue, setUpdateStateValue] = useState({
    updateStateName: "",
  });
  useEffect(() => {
    props.adminCountryList();
  }, []);
  useEffect(() => {
    if (props.countryAdminData) {
      setCountryData(props.countryAdminData);
    }
  }, [props.countryAdminData]);

  const handleShow = () => {
    setAddStateModalShow(true);
  };
  const handleClose = () => {
    setAddStateModalShow(false);
  };
  const selectCountry = (e: any) => {
    e.preventDefault();
    setCountryId(e && e.target.value ? e.target.value : "");
    props.adminStateList({ id: e.target.value });
  };

  const handleChange = (event: any) => {
    event.preventDefault();
    setStateValue({
      ...stateValue,
      [event.target.name]: event.target.value,
    });
    setUpdateStateValue({
      ...updateStateValue,
      [event.target.name]: event.target.value,
    });
  };
  const handleUpdate = (e: any) => {
    
    let stateId = e ;

    if (stateId) {
      setStateId(stateId);
      props.adminStateById({ id: stateId });
      setUpdateStateModalOpen(true);
    }
  };
  useEffect(() => {
    if (props.stateByIdAdminData && updateStateModalOpen) {
      var result = props.stateByIdAdminData ? props.stateByIdAdminData : "";
      updateStateValue.updateStateName = result.name ? result.name : "";
      setUpdateStateModalShow(true);
    }
  }, [props.stateByIdAdminData]);

  const handleUpdateStateClose = () => {
    setUpdateStateModalShow(false);
    setUpdateStateModalOpen(false);
  };
  const handleUpdateSubmit = (e:any) => {
    e.preventDefault();
    let payload = {
      id: 0,
      name: "",
      countryId:0
    };
    payload.id = stateId ? parseInt(stateId) : 0;
    payload.name = updateStateValue ? updateStateValue.updateStateName : "";
    payload.countryId=countryId?parseInt(countryId) : 0;
    props.updateAdminStates(payload);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    let payload = {
      name: "",
      countryId: 0,
    };

    payload.countryId = countryId?parseInt(countryId):0;
    payload.name = stateValue && stateValue.stateName;
    props.addAdminStates(payload);
  };
  useEffect(() => {
    if (props.stateAdminData) {
      setStatesData(props.stateAdminData);
    }
  }, [props.stateAdminData]);

  useEffect(() => {
    if (props.addStateAdminData) {
      let countId = countryId ? parseInt(countryId) : 0;
      props.adminStateList({ id: countId });
      handleClose();
    }
  }, [props.addStateAdminData]);
  

  useEffect(()=>{
    if(props.updateStateAdminData){
      let countId = countryId ? parseInt(countryId) : 0;
      props.adminStateList({ id: countId});
      handleUpdateStateClose();
    }
  },[props.updateStateAdminData])


  const handleDeleteState = (e: any) => {
    let stateId = e;
    Swal.fire({
      title: `Are you sure you want to delete ? `,
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "YES",
      denyButtonText: `NO`,
      confirmButtonColor: "green",
      showCloseButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        // payload.id=userId;
        // payload.isActive=0;
      props.removeAdminStates({ id: stateId });
         
      }
    });

  };
useEffect(()=>{
if(props.removeStateAdminData){
  let countId = countryId ? parseInt(countryId) : 0;
      props.adminStateList({ id: countId});
}
},[props.removeStateAdminData])
  return (
    <div>
      <Layout>
        <Modal
          {...props}
          show={addStateModalShow}
          size="lg"
          backdrop="static"
          onHide={handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "whitesmoke" }}>Add State</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmit}>
              <Container>
                <Row>
                  <Col md={6}>
                    <Form.Group
                      className="mb-3 mt-3 "
                      //controlId={data.field2}
                    >
                      <Form.Label style={{ color: "whitesmoke" }}>
                        State Name
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="stateName"
                        value={stateValue.stateName}
                        onChange={handleChange}
                        autoFocus
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Container>
              <Row className="justify-content-md-center mt-10">
                <Col className="" md="auto">
                  <Button
                    variant="primary"
                    type="submit"
                    // onClick={handleSubmit}
                    // disabled={!isValid}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
        </Modal>

        <Modal
          {...props}
          show={updateStateModalShow}
          size="lg"
          backdrop="static"
          onHide={handleUpdateStateClose}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "whitesmoke" }}>
              Update State
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleUpdateSubmit} >
              <Container>
                <Row>
                  <Col md={6}>
                    <Form.Group
                      className="mb-3 mt-3 "
                      //controlId={data.field2}
                    >
                      <Form.Label style={{ color: "whitesmoke" }}>
                        State Name
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="updateStateName"
                        value={updateStateValue.updateStateName}
                        onChange={handleChange}
                        autoFocus
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Container>
              <Row className="justify-content-md-center mt-10">
                <Col className="" md="auto">
                  <Button
                    variant="primary"
                    type="submit"
                    // disabled={!isValid}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
        </Modal>
        <Stack direction="horizontal" gap={3}>
          <Form.Select
            name="country"
            className="col-md"
            aria-label="Default select example"
            onChange={selectCountry}
          >
            <option value="0">Select Country</option>
            {countryData &&
              countryData.map((country: any) => (
                <option value={country.id} key={country.id}>
                  {country.name}{" "}
                </option>
              ))}
          </Form.Select>
          <Button variant="info" className="ms-auto" onClick={handleShow}>
            {" "}
            Add{" "}
          </Button>{" "}
        </Stack>
        <Table responsive>
          <thead>
            <tr>
              <th>S.No.</th>
              <th>State Name</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {statesData &&
              statesData.map((state: any, index: any) => {
                /*  <option value={state.id} key={state.id}>
                  {state.name}
                </option> */
                return (
                  <>
                    <tr>
                      <td>{index + 1}</td>
                      <td> {state.name}</td>
                      <td>
                        <button
                          type="submit"
                          className="btn btn-outline-warning m-2"
                          value={state.id}
                          onClick={()=>handleUpdate(state.id)}
                        >
                          <BsPencilFill />
                        </button>
                        <button
                          type="submit"
                          className="btn btn-outline-danger"
                          value={state.id}
                          //id={state.planType}
                          onClick={()=>handleDeleteState(state.id)}
                        >
                          <BsTrashFill />
                        </button>
                      </td>
                    </tr>
                  </>
                );
              })}
          </tbody>
        </Table>
      </Layout>
    </div>
  );
};
const mapDispatchToProps = (dispatch: any) => ({
  adminCountryList: (data: any) => dispatch(adminCountryList(data)),
  adminStateList: (data: any) => dispatch(adminStateList(data)),
  addAdminStates: (data: any) => dispatch(addAdminStates(data)),
  updateAdminStates: (data: any) => dispatch(updateAdminStates(data)),
  removeAdminStates: (data: any) => dispatch(removeAdminStates(data)),
  adminStateById: (data: any) => dispatch(adminStateById(data)),
});

const mapStateToProps = (state: any) => {
  return {
    countryAdminData:
      state.AdminCountryReducer && state.AdminCountryReducer.countryAdminData
        ? state.AdminCountryReducer.countryAdminData
        : null,
    message:
      state.CommonMessageReducer && state.CommonMessageReducer.message
        ? state.CommonMessageReducer.message
        : null,
    stateAdminData:
      state.AdminCountryReducer && state.AdminCountryReducer.stateAdminData
        ? state.AdminCountryReducer.stateAdminData
        : null,
    addStateAdminData:
      state.AdminCountryReducer && state.AdminCountryReducer.addStateAdminData
        ? state.AdminCountryReducer.addStateAdminData
        : null,
    updateStateAdminData:
      state.AdminCountryReducer &&
      state.AdminCountryReducer.updateStateAdminData
        ? state.AdminCountryReducer.updateStateAdminData
        : null,
    removeStateAdminData:
      state.AdminCountryReducer &&
      state.AdminCountryReducer.removeStateAdminData
        ? state.AdminCountryReducer.removeStateAdminData
        : null,
    stateByIdAdminData:
      state.AdminCountryReducer && state.AdminCountryReducer.stateByIdAdminData
        ? state.AdminCountryReducer.stateByIdAdminData
        : null,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AdminCountryStates);
