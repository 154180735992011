import {put, call} from 'redux-saga/effects';
import {getRecycleBinBoxService,getRecycleBinFileService,restoreItemService,deleteItemPermanentlyService} from '../services/recycleBinService'
import {COMMON_MESSAGE, RECYCLE_BIN} from '../constant/index';
import {generateReadableError} from '../../hooks/messages';

const clearErrorMessage = {
  type: COMMON_MESSAGE,
  data: {
    status: '',
    page: '',
    data: [],
  }}

export function* fetchRecycleBox({payload}) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(getRecycleBinBoxService, payload);
    yield put({type: RECYCLE_BIN.SET_RECYCLE_BIN_BOXES_TO_STATE, data});
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'error',
        page: 'recycle-bin',
        data: generateReadableError(error),
      },
    });
  }
}

export function* fetchRecycleFile({payload}) {
    try {
      yield put(clearErrorMessage);
      const data = yield call(getRecycleBinFileService, payload);
      yield put({type: RECYCLE_BIN.SET_RECYCLE_BIN_FILES_TO_STATE, data});
    } catch (error) {
      yield put({
        type: COMMON_MESSAGE,
        data: {
          status: 'error',
          page: 'recycle-bin',
          data: generateReadableError(error),
        },
      });
    }
  }

  export function* fetchRestoreItem({payload}) {
    try {
      yield put(clearErrorMessage);
      const data = yield call(restoreItemService, payload);
      yield put({type: RECYCLE_BIN.SET_RESTORE_ITEM_TO_STATE, data});
    } catch (error) {
      yield put({
        type: COMMON_MESSAGE,
        data: {
          status: 'error',
          page: 'recycle-bin',
          data: generateReadableError(error),
        },
      });
    }
  }

  export function* deleteItemPermanently({payload}) {
    try {
      yield put(clearErrorMessage);
      const data = yield call(deleteItemPermanentlyService, payload);
      yield put({type: RECYCLE_BIN.GET_RESPONSE_AFTER_DELETED_ITEM, data});
      yield put({
        type: COMMON_MESSAGE,
        data: {
          status: 'success',
          page: 'recycle-bin',
          data: data,
        },
      });
    } catch (error) {
      yield put({
        type: COMMON_MESSAGE,
        data: {
          status: 'error',
          page: 'recycle-bin',
          data: generateReadableError(error),
        },
      });
    }
  }