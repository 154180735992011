import { Button, Form, Container, Modal, Row, Col, Stack, Table, } from "react-bootstrap";
import Layout from "../../../layout/Admin/layout";
import { useEffect, useState } from "react";
import{BsEyeFill, BsPencilFill, BsTrashFill} from "react-icons/bs"
import { connect } from "react-redux";
import { addAdminPlans, deleteAdminPlan, getAdminPlanById, getAdminPlans, } from "../../../redux/actions/admin/adminBoxPlanAction";
import UpdatePlanModal from "./update-plan-modal";
import { createPlanValidation } from "../../../validations/adminPage/createPlan-form";
import Swal from "sweetalert2";

const AdminHome = (props: any) => {
  const [adminDetail,       setAdminDetail]     = useState([]);
  // const [planDetailId,   setPlanDetailId]    = useState({});
  const [discountedValue,   setDiscountedValue] = useState("");
  const [pPrice1,           setPPrice1]         = useState(0);
  const [pDiscount1,        setPDiscount1]      = useState(0);
  const [newPlan,           setNewPlan]         = useState({
                                                            pType               : "",
                                                            pCode               : "",
                                                            pPrice              : "",
                                                            pDuration           : "",
                                                            pStorage            : "",
                                                            pDateCreated        : "",
                                                            pPrivateBox         : "",
                                                            pPublicBox          : "",
                                                            pReceivedBox        : "",
                                                            pRecAccessTime      : "",
                                                            pPlanActive         : "",
                                                            privateRec          : 0,
                                                            subsId              : "",
                                                            marketerPlanDiscount: "",
                                                            marketerCommission  : "",
                                                            discountedPlanPrice : "",
                                                          });
  const [show,              setShow]              = useState(false);
  const [viewModalShow,     setViewModalShow]    = useState(false);
  const [viewModalOpen,     setViewModalOpen]    = useState(false);
  const [updatePlanShow,    setUpdatePlanShow]   = useState(false);
  const [updatePlanOpen,    setUpdatePlanOpen]   = useState(false);
  const [viewModalDetails,  setViewModalDetails] = useState<any>({
                                                                  planType              : "",
                                                                  planCode              : "",
                                                                  planPrice             : "",
                                                                  planDuration          : "",
                                                                  planStorage           : "",
                                                                  created               : "",
                                                                  privateBoxes          : "",
                                                                  publicBoxes           : "",
                                                                  recievedBoxes         : "",
                                                                  recievedBoxAccessTime : "",
                                                                  planisActive          : "",
                                                                  discountedPlanPrice   : "",
                                                                  marketerPlanDiscount  : "",
                                                                  marketerCommission    : "",
                                                                });
  let [isValid,             setIsValid] = useState(true);
  let [errors,              setErrors]  = useState<any>({
                                                          pType          : "",
                                                          pCode          : "",
                                                          pPrice         : "",
                                                          pDuration      : "",
                                                          pStorage       : "",
                                                          pDateCreated   : "",
                                                          pPrivateBox    : "",
                                                          pPublicBox     : "",
                                                          pReceivedBox   : "",
                                                          pRecAccessTime : "",
                                                          pPlanActive    : "",
                                                          privateRec     : "",
                                                          subsId         : "",
                                                        });
  let [checkValidation,     setCheckValidation] = useState<any>({
                                                                  pType          : false,
                                                                  pCode          : false,
                                                                  pPrice         : false,
                                                                  pDuration      : false,
                                                                  pStorage       : false,
                                                                  pDateCreated   : false,
                                                                  pPrivateBox    : false,
                                                                  pPublicBox     : false,
                                                                  pReceivedBox   : false,
                                                                  pRecAccessTime : false,
                                                                  pPlanActive    : false,
                                                                  privateRec     : false,
                                                                  subsId         : false,
                                                                });
  const handleClose = () => {
    setShow(false);
    setDiscountedValue("");
  }

  const handleShow = () => setShow(true);

  const handleUpdatePlanClose = () => {
    setUpdatePlanShow(false);
    setUpdatePlanOpen(false);
  };

  const [boxPlanIdProp, setboxPlanIdProp] = useState("");
  const handleUpdatePlanShow = (e: any) => {
    let boxPlanId = e ;
    if (boxPlanId) {
      props.getAdminPlanById({ id: boxPlanId });
      setboxPlanIdProp(boxPlanId);
      setUpdatePlanOpen(true);
    }
  };

  const handleViewModalClose = () => {
    setViewModalShow(false);
    setViewModalOpen(false);
  };

  const handleViewModalShow = (e: any) => {
    let boxPlanId = e;
    if (boxPlanId > 0) {
      props.getAdminPlanById({ id: boxPlanId });
      setViewModalOpen(true);
    }
  };

  useEffect(() => {
    props.getAdminPlans();
  }, []);

  useEffect(() => {
    if (props.adminBoxPlanData) {
      setAdminDetail(props.adminBoxPlanData);
    }
  }, [props.adminBoxPlanData]);
  
  let result: any;
  useEffect(() => {
    if (props.adminBoxPlanDataById && viewModalOpen) {
      result                                 = props.adminBoxPlanDataById   ? props.adminBoxPlanDataById   : "";
      viewModalDetails.planType              = result.planType              ? result.planType              : "";
      viewModalDetails.planCode              = result.planCode              ? result.planCode              : "";
      viewModalDetails.planPrice             = result.planPrice             ? result.planPrice             : "";
      viewModalDetails.planDuration          = result.planDuration          ? result.planDuration          : "";
      viewModalDetails.planStorage           = result.planStorage           ? result.planStorage           : "";
      viewModalDetails.created               = result.created               ? new Date(result.created).toLocaleDateString() : "";
      viewModalDetails.privateBoxes          = result.privateBoxes          ? result.privateBoxes          : "";
      viewModalDetails.publicBoxes           = result.publicBoxes           ? result.publicBoxes           : "";
      viewModalDetails.recievedBoxes         = result.recievedBoxes         ? result.recievedBoxes         : "";
      viewModalDetails.recievedBoxAccessTime = result.recievedBoxAccessTime ? result.recievedBoxAccessTime : "";
      viewModalDetails.planisActive          = result.planisActive          ? result.planisActive          : "";
      viewModalDetails.marketerPlanDiscount  = result.marketerPlanDiscount  ? result.marketerPlanDiscount  : "";
      viewModalDetails.marketerCommission    = result.marketerCommission    ? result.marketerCommission    : "";
      viewModalDetails.discountedPlanPrice   = result.discountedPlanPrice   ? result.discountedPlanPrice   : "";
      setViewModalShow(true);
    } else if (props.adminBoxPlanDataById && updatePlanOpen) {
      setUpdatePlanShow(true);
    }
  }, [props.adminBoxPlanDataById]);

  // var pPrice2=0;
  var pDiscount2=0;
  const handleChange = (event: any) => {
    if(event.target.name == 'pPrice' ){
      setPPrice1(event.target.value)
    }
  
    if(event.target.name == 'marketerPlanDiscount'){
      setPDiscount1(event.target.value)
      pDiscount2= event.target.value;
    }
  
    if(pPrice1 && pDiscount2 ){
      let disPrice = pPrice1 - ((pPrice1 * pDiscount2)/100);
      let finalValue = '' + disPrice
      setDiscountedValue(finalValue);
    }
    setCheckValidation({ ...checkValidation, [event.target.name]: true, });
    setNewPlan({ ...newPlan,[event.target.name]: event.target.value, });
  };

  useEffect(() => {
    const validationErrors = createPlanValidation(newPlan);
    isValid = Object.keys(validationErrors).length === 0;
    setIsValid(isValid);
    setErrors(validationErrors);
  }, [newPlan]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    let payload = {
      planType              : "",
      planCode              : "",
      planPrice             : "",
      privateBoxes          : "",
      publicBoxes           : "",
      recievedBoxes         : "",
      planDuration          : "",
      planStorage           : "",
      recievedBoxAccessTime : "",
      plan_id               : "",
      privateRecipients     : 0,
      marketerPlanDiscount  : "",
      marketerCommission    : "",
      discountedPlanPrice   : "",
    };
    
    payload.planType              = newPlan.pType;
    payload.planCode              = newPlan.pCode;
    payload.planPrice             = newPlan.pPrice;
    payload.privateBoxes          = newPlan.pPrivateBox;
    payload.publicBoxes           = newPlan.pPublicBox;
    payload.recievedBoxes         = newPlan.pReceivedBox;
    /* payload.totalBoxes         = newPlan.pPrivateBox+newPlan.pPublicBox; */
    payload.planDuration          = newPlan.pDuration;
    payload.planStorage           = newPlan.pStorage;
    payload.recievedBoxAccessTime = newPlan.pRecAccessTime;
    payload.privateRecipients     = newPlan.privateRec;
    payload.plan_id               = newPlan.subsId;
    payload.marketerPlanDiscount  = newPlan.marketerPlanDiscount;
    payload.marketerCommission    = newPlan.marketerCommission;
    payload.discountedPlanPrice   = discountedValue;
    
    /*payload.plan_id= newPlan.pP, */
    if (isValid) {
      props.addAdminPlans(payload)
    }
  };

  const removePlan = (e: any,name:any) => {
    let boxPlanId = e;
    let boxPlanName = name;
    if (boxPlanId > 0) {
      Swal.fire({
        title              : `Do you want to remove ${boxPlanName} plan? `,
        showDenyButton     : true,
        showCancelButton   : false,
        confirmButtonText  : "YES",
        denyButtonText     : `NO`,
        confirmButtonColor : "green",
        showCloseButton    : true,
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire("Plan removed !!", "", "success");
          props.deleteAdminPlan({ id: boxPlanId });
        }
      });
      //
    }
  };
 useEffect(() => {
if(props.deleteAdminBoxPlanData)
{
  props.getAdminPlans();
}
 }, [props.deleteAdminBoxPlanData]);

  useEffect(() => {
    if(props.updateAdminBoxPlanData)
    {
      props.getAdminPlans();
      handleUpdatePlanClose();
    }
  }, [props.updateAdminBoxPlanData]);
   
    useEffect(() => {
      if(props.addAdminBoxPlanData)
      {
        props.getAdminPlans();
        handleClose()
      }
    }, [props.addAdminBoxPlanData]);

  const modalDetail = [
    {
      field1: "Plan Name",
      field2: "pType",
    },
    {
      field1: "Plan Code",
      field2: "pCode",
    },
    {
      field1: "Plan Price (R)",
      field2: "pPrice",
    },
    {
      field1: "Plan Duration",
      field2: "pDuration",
    },
    {
      field1: "Plan Storage",
      field2: "pStorage",
    },

    /* {
      field1: "Plan Created",
      field2: "pDateCreated",
    }, */

    {
      field1: "Private Boxes",
      field2: "pPrivateBox",
    },

    {
      field1: "Public Boxes",
      field2: "pPublicBox",
    },
    {
      field1: "Received Boxes",
      field2: "pReceivedBox",
    },
    {
      field1: "Private Box Recipients",
      field2: "pRecepientPrivate",
    },
    {
      field1: "Received Boxes Duration",
      field2: "pRecAccessTime",
    },
    {
      field1: "Marketer Plan Discount",
      field2: "marketerPlanDiscount",
    },
    {
      field1: "Price After Discount (R)",
      field2: "pAfterDiscount",
    },
    {
      field1: "Marketer Commission",
      field2: "marketerCommission",
    },
    
    // {
    //   field1: "Paypal Plan Subscription Id",
    //   field2: "subsId",
    // },

    /*  {
      field1: "Plan Active",
      field2: "pPlanActive",
    }, */
  ];

//---------------------------------------------------------------------------------
  
  return (
    <>
      <UpdatePlanModal show={updatePlanShow} onHide={handleUpdatePlanClose} boxPlanIdProp={boxPlanIdProp} />
      <Layout>
        <Row>
          <Col md={8}>
            <h3 className="text-warning">Box Plans</h3>
          </Col>
          <Col md={4}>
            <Stack direction="horizontal" gap={3}>
              <Button variant="info" className="ms-auto" onClick={handleShow}>Add</Button>
            </Stack>
          </Col>
        </Row>
        <hr style={{border:'1px solid #E79E1F'}}/> 
        <div className="users scrollBar">
          <Table responsive>
            <thead>
              <tr>
                <th className="text-warning text-center">S.No</th>
                <th className="text-warning text-center">Plan Name</th>
                <th className="text-warning text-center">Plan Code</th>
                <th className="text-warning text-center">Plan Price (R)</th>
                <th className="text-warning text-center">Plan Duration</th>
                <th className="text-warning text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {adminDetail && adminDetail.map((list: any, index: number) => {
                return (
                  <>
                    <tr>
                      <td className="text-center">{index + 1}</td>
                      <td className="text-center">{list.planType}</td>
                      <td className="text-center">{list.planCode}</td>
                      <td className="text-center">{list.planPrice}</td>
                      <td className="text-center">{list.planDuration}</td>
                      <td className="text-center">
                        <button type="submit" className="btn btn-outline-success" value={list.id} onClick={() =>handleViewModalShow(list.id)} ><BsEyeFill/></button>
                        <button type="submit" className="btn btn-outline-warning m-2" value={list.id} onClick={()=>handleUpdatePlanShow(list.id)} ><BsPencilFill/></button>
                        <button type="submit" className="btn btn-outline-danger" value={list.id} id={list.planType} onClick={()=>removePlan(list.id,list.planType)} ><BsTrashFill/></button>
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </Table>
        </div>

        {/* -------------ADD PLAN MODAL---------------  */}
        <Modal show={show} size="lg" backdrop="static" onHide={handleClose} scrollable={true} className="adminModal">
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#E79E1F" }}>Add Plan</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmit}>
              <Container>
                <Row>
                  {modalDetail && modalDetail.map((data: any, index) => {
                    return (
                      <>
                        <Col md={ Object.keys(modalDetail).length - 1 == index ? 12 : 6 } >
                          { data.field1 =='Price After Discount (R)' ? 
                            <Form.Group className="mb-3 mt-3 " >
                              <Form.Label style={{ color: "whitesmoke" }}> {data.field1} </Form.Label>
                              <Form.Control type="text" name={data.field2} value={discountedValue}  onInput={( e: React.ChangeEvent<HTMLInputElement> ) => { e.target.value  = e.target.value.trimStart(); }} autoFocus disabled />
                            </Form.Group>
                          :
                            <Form.Group className="mb-3 mt-3 " >
                              <Form.Label style={{ color: "whitesmoke" }}>
                                {data.field1 == 'Marketer Plan Discount' ? `${data.field1} (%)` : data.field1 }
                              </Form.Label>
                              <Form.Control type="number" name={data.field2} onInput={( e: React.ChangeEvent<HTMLInputElement> ) => { e.target.value = e.target.value.trimStart();}} onChange={handleChange} autoFocus />
                              {checkValidation[data.field2] && errors[data.field2] && 
                                ( <p className="text-danger"> {errors[data.field2]} </p>)
                              }
                            </Form.Group>
                          }
                        </Col>
                      </>
                    );
                  })}
                </Row>
              </Container>
              <Row className="justify-content-md-center mt-10">
                <Col className="" md="auto" style={!isValid ? {cursor:"not-allowed"} : {cursor:"pointer"}}>
                  <Button className="backBtn" variant="primary" type="submit" disabled={!isValid}>
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
          {/*  <Modal.Footer></Modal.Footer> */}
        </Modal>

        <Modal show={viewModalShow} size="lg" backdrop="static" onHide={handleViewModalClose} className="adminModal">
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#E79E1F" }}>
              Plan Details
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Container>
                <Row>
                  <Col className="col-md-auto" md={6}>
                    <Form.Group className="mb-3 mt-3 ">
                      <Form.Label style={{ color: "whitesmoke" }}>
                        Plan Name
                      </Form.Label>

                      <Form.Control
                        type="text"
                        // name={data.field1}
                        /*  onChange={onChangeHandler} */
                        value={viewModalDetails.planType}
                        autoFocus
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col className="col-md-auto" md={6}>
                    <Form.Group className="mb-3 mt-3 ">
                      <Form.Label style={{ color: "whitesmoke" }}>
                        Plan Code
                      </Form.Label>

                      <Form.Control
                        type="text"
                        // name={data.field1}
                        /*  onChange={onChangeHandler} */
                        value={viewModalDetails.planCode}
                        autoFocus
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col className="col-md-auto" md={6}>
                    <Form.Group className="mb-3 mt-3 ">
                      <Form.Label style={{ color: "whitesmoke" }}>
                        Plan Price (R)
                      </Form.Label>

                      <Form.Control
                        type="text"
                        // name={data.field1}
                        /*  onChange={onChangeHandler} */
                        value={viewModalDetails.planPrice}
                        autoFocus
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col className="col-md-auto" md={6}>
                    <Form.Group className="mb-3 mt-3 ">
                      <Form.Label style={{ color: "whitesmoke" }}>
                        Plan Duration
                      </Form.Label>

                      <Form.Control
                        type="text"
                        // name={data.field1}
                        /*  onChange={onChangeHandler} */
                        value={viewModalDetails.planDuration}
                        autoFocus
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col className="col-md-auto" md={6}>
                    <Form.Group className="mb-3 mt-3 ">
                      <Form.Label style={{ color: "whitesmoke" }}>
                        Plan Storage
                      </Form.Label>

                      <Form.Control
                        type="text"
                        // name={data.field1}
                        /*  onChange={onChangeHandler} */
                        value={viewModalDetails.planStorage}
                        autoFocus
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col className="col-md-auto" md={6}>
                    <Form.Group className="mb-3 mt-3 ">
                      <Form.Label style={{ color: "whitesmoke" }}>
                        Plan Creation Date
                      </Form.Label>

                      <Form.Control
                        type="text"
                        // name={data.field1}
                        /*  onChange={onChangeHandler} */
                        value={viewModalDetails.created}
                        autoFocus
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col className="col-md-auto" md={6}>
                    <Form.Group className="mb-3 mt-3 ">
                      <Form.Label style={{ color: "whitesmoke" }}>
                        Public Box
                      </Form.Label>

                      <Form.Control
                        type="text"
                        // name={data.field1}
                        /*  onChange={onChangeHandler} */
                        value={viewModalDetails.publicBoxes}
                        autoFocus
                        disabled
                      />
                    </Form.Group>
                  </Col>

                  <Col className="col-md-auto" md={6}>
                    <Form.Group className="mb-3 mt-3 ">
                      <Form.Label style={{ color: "whitesmoke" }}>
                        Private Boxes
                      </Form.Label>
                      <Form.Control
                        type="text"
                        // name={data.field1}
                        /*  onChange={onChangeHandler} */
                        value={viewModalDetails.privateBoxes}
                        autoFocus
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col className="col-md-auto" md={6}>
                    <Form.Group className="mb-3 mt-3 ">
                      <Form.Label style={{ color: "whitesmoke" }}>
                        Recieved Box
                      </Form.Label>

                      <Form.Control
                        type="text"
                        // name={data.field1}
                        /*  onChange={onChangeHandler} */
                        value={viewModalDetails.recievedBoxes}
                        autoFocus
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col className="col-md-auto" md={6}>
                    <Form.Group className="mb-3 mt-3 ">
                      <Form.Label style={{ color: "whitesmoke" }}>
                        Rec. Box Access Time
                      </Form.Label>

                      <Form.Control
                        type="text"
                        // name={data.field1}
                        /*  onChange={onChangeHandler} */
                        value={viewModalDetails.recievedBoxAccessTime}
                        autoFocus
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  {/* new add marketers */}
                  <Col className="col-md-auto" md={6}>
                    <Form.Group className="mb-3 mt-3 ">
                      <Form.Label style={{ color: "whitesmoke" }}>
                        Marketer Plan Discount (%)
                      </Form.Label>

                      <Form.Control
                        type="text"
                        // name={data.field1}
                        /*  onChange={onChangeHandler} */
                        value={viewModalDetails.marketerPlanDiscount}
                        autoFocus
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                  <Form.Group
                    className="mb-3 mt-3 "
                    //controlId={data.field2}
                  >
                    <Form.Label style={{ color: "whitesmoke" }}>
                      Price After Discount (R)
                    </Form.Label>
                    <Form.Control
                      type="text"
                      // name="discountedValue"
                      value={viewModalDetails.discountedPlanPrice}
                      // onChange={handleChange}
                      disabled
                      autoFocus
                    />
                  </Form.Group>
                </Col>
                  <Col className="col-md-auto" md={12 }>
                    <Form.Group className="mb-3 mt-3 ">
                      <Form.Label style={{ color: "whitesmoke" }}>
                        Marketer Commission
                      </Form.Label>

                      <Form.Control
                        type="text"
                        // name={data.field1}
                        /*  onChange={onChangeHandler} */
                        value={viewModalDetails.marketerCommission}
                        autoFocus
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  {/* new add marketers */}
                  {/*  <Col className="col-md-auto" md={6}>
                    <Form.Group className="mb-3 mt-3 ">
                      <Form.Label style={{ color: "whitesmoke" }}>
                        Plan Active
                      </Form.Label>

                      <Form.Control
                        type="text"
                        // name={data.field1}
                          onChange={onChangeHandler} 
                        value={viewModalDetails.planisActive}
                        autoFocus
                        disabled
                        
                      />
                    </Form.Group>
                  </Col> */}
                </Row>
              </Container>
              <Row className="justify-content-md-center mt-10">
                <Col className="" md="auto">
                  {/*   <Button
                    variant="primary"
                    type="submit"
                    // onClick={handleSubmit}
                  >
                    Submit
                  </Button> */}
                </Col>
              </Row>
            </Form>
          </Modal.Body>
          {/*  <Modal.Footer></Modal.Footer> */}
        </Modal>
      </Layout>
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  getAdminPlans    : (data: any) => dispatch(getAdminPlans(data)),
  getAdminPlanById : (data: any) => dispatch(getAdminPlanById(data)),
  addAdminPlans    : (data: any) => dispatch(addAdminPlans(data)),
  deleteAdminPlan  : (data: any) => dispatch(deleteAdminPlan(data)),
});

const mapStateToProps = (state: any) => {
  return {
    adminBoxPlanData       : state.AdminBoxPlanReducer && state.AdminBoxPlanReducer.adminBoxPlanData ? state.AdminBoxPlanReducer.adminBoxPlanData : null,
    adminBoxPlanDataById   : state.AdminBoxPlanReducer && state.AdminBoxPlanReducer.adminBoxPlanDataById ? state.AdminBoxPlanReducer.adminBoxPlanDataById : null,
    addAdminBoxPlanData    : state.AdminBoxPlanReducer && state.AdminBoxPlanReducer.addAdminBoxPlanData ? state.AdminBoxPlanReducer.addAdminBoxPlanData : null,
    deleteAdminBoxPlanData : state.AdminBoxPlanReducer && state.AdminBoxPlanReducer.deleteAdminBoxPlanData ? state.AdminBoxPlanReducer.deleteAdminBoxPlanData : null,  
    updateAdminBoxPlanData :  state.AdminBoxPlanReducer && state.AdminBoxPlanReducer.updateAdminBoxPlanData ? state.AdminBoxPlanReducer.updateAdminBoxPlanData : null,       
    state,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminHome);
