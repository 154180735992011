import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import Layout from '../../../../layout/Admin/layout';
import { downloadCSV } from '../../../../redux/actions/admin/adminUserAction';
import { FaDownload } from 'react-icons/fa';
import ActiveUsers from './activeUsers';
import InActiveUsers from './inActiveUsers';
import UnsubscribeMailUsers from './unsubscribedMailUsers';
import AdminUsers from './adminUsers';

const AdminUserDashboard = (props:any) => {

  const [csvLoader,       setCSVLoader]       = useState(false);
  const [pageHeading,     setPageHeading]     = useState('');
  const [defaultUserList, setDefaultUserList] = useState<number>(1);

  const handleCSV = () =>{
    setCSVLoader(true);
    props.downloadCSV();
  }

  useEffect(() =>{
    if(csvLoader){
      setTimeout(() =>{
        setCSVLoader(false);
      },2000)
    }
  },[csvLoader])

  const handleFilters = (e:any) =>{
    let ddValue = '';
    if(e.target.value == 1){
      ddValue = 'Active Users'
    }else if(e.target.value == 2){
      ddValue = 'In-Active Users'
    }else if(e.target.value == 3){
      ddValue = 'Unsubscribed Mail Users'
    }else if(e.target.value == 4){
      ddValue = 'Admin Users'
    }else{
      ddValue = 'Active Users'
    }
    setDefaultUserList(Number(e.target.value));
    setPageHeading(ddValue);
  }
//----------------------------------------------------------------//    

  return (
    <div>
      <Layout className='pb-5'>
        <Row>
          <Col md={6}>
            <h3 className='text-warning'>{pageHeading ? pageHeading : 'Active Users'}</h3>
          </Col>
          <Col md={3} className='ms-auto'>
            <select className='form-control' value={defaultUserList} onChange={handleFilters}>
              <option value={1}>Active Users</option>
              <option value={2}>In-Active Users</option>
              <option value={3}>Unsubscribed Mail Users</option>
              <option value={4}>Admin Users</option>
            </select>
          </Col>
          <Col md="auto">
            {defaultUserList == 1 ? 
              <label className='bg-warning text-dark rounded p-1 pointer text-center' onClick={handleCSV}>{csvLoader ? 'Downloading...' : 'Download CSV '}{!csvLoader && <FaDownload style={{fontSize:"12px",marginBottom:"10px"}}/>}</label>
            :
              <label className='bg-secondary text-light rounded p-1 pointer text-center' style={{cursor:"not-allowed",visibility:"hidden"}}>Download CSV <FaDownload style={{fontSize:"12px",marginBottom:"10px",cursor:"not-allowed"}}/></label>
            }
          </Col>
        </Row>
        <hr style={{border:'1px solid #E79E1F'}}/>
        {
          defaultUserList === 1 ?
            <ActiveUsers/>
          : defaultUserList === 2 ?
            <InActiveUsers/>
          : defaultUserList === 3 ?
            <UnsubscribeMailUsers/> 
          : defaultUserList === 4 &&
            <AdminUsers/>   
        }
      </Layout>
    </div>
  );
};

  const mapDispatchToProps = (dispatch: any) => ({
    downloadCSV   : () => dispatch(downloadCSV()),
  });
  
  
  export default connect(null,mapDispatchToProps) (AdminUserDashboard);