import React from "react";
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row, Stack, Table, } from "react-bootstrap";
import { BsEyeFill, BsPencilFill, BsTrashFill } from "react-icons/bs";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import Layout from "../../../layout/Admin/layout";
import { addAdminFoundation, deleteAdminFoundation, getFoundationDetailById, getFoundationList, updateAdminFoundation, } from "../../../redux/actions/admin/adminFoundationAction";
import { BASE_URLS } from "../../../redux/constant";
import { addFoundationValidation } from "../../../validations/adminPage/addFoundation-form";

const AdminFoundation = (props: any) => {
  const [adminDetail, setAdminDetail] = useState([]);
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const handleClose = () => {
    setShow(false);
    setNewPlan({ fName: "", fDetails: "", fContactNumber: "", fWebsite: "", fProfilePic: "", });
    setCheckValidation({ fName: false, fDetails: false, fContactNumber: false, fWebsite: false, fProfilePic: false, });
    setProfileImage("");
  };
  const handleShow = () => setShow(true);
  let [isValid, setIsValid] = useState(true);
  const [fileName,            setFileName]            = useState("");
  const [viewModalShow,       setViewModalShow]       = useState(false);
  const [viewModalOpen,       setViewModalOpen]       = useState(false);
  const [disabledCheck,       setDisabledCheck]       = useState(true);
  const [addedFolderResult,   setAddedFolderResult]   = useState(false);
  const [folderDetailResult,  setFolderDetailResult]  = useState(false);
  const [updatedFolderResult, setUpdatedFolderResult] = useState(false);
  const [deleteFolderResult,  setDeleteFolderResult]  = useState(false);
  const [updateIconChange,    setUpdateIconChange]    = useState(false);
  const [profileImage,        setProfileImage]        = React.useState("");
  const [image,               setImage]               = useState({ preview: "", raw: "" });
  const [newPlan,             setNewPlan]             = useState({ fName:"",fDetails:"", fContactNumber:"", fWebsite: "", fProfilePic: "", });
  const [updatePlan,          setUpdatePlan]          = useState({ fName: "", fDetails: "", fContactNumber: "", fWebsite: "", fProfilePic: "", });
  const [viewModalDetails,    setViewModalDetails]    = useState<any>({ foundationName: "", foundationDetails: "", foundationPhone: "", foundationWebsite: "", foundationProfilePhoto: "", });
  let [errors,                setErrors]              = useState<any>({ fName: "", fDetails: "", fContactNumber: "", fWebsite: "", fProfilePic: "", });
  let [checkValidation,       setCheckValidation]     = useState<any>({ fName: false, fDetails: false, fContactNumber: false, fWebsite: false, fProfilePic: false, });
  let inputRef: any;

  useEffect(() => {
    props.getFoundationList();
  }, []);

  useEffect(() => {
    if (props.foundationListData) {
      setAdminDetail(props.foundationListData);
    }
  }, [props.foundationListData]);

  useEffect(() => {
    if (props.addAdminFoundationData) {
      handleAddPopup();
    }
  }, [props.addAdminFoundationData]);

  const handleAddPopup = () => {
    if (addedFolderResult) {
      setLoader(false);
      Swal.fire({
        title             : "Foundation added successfully",
        showCancelButton  : false,
        confirmButtonText : "OK",
        confirmButtonColor: "green",
        allowOutsideClick : false,
      }).then((result) => {
        if (result.isConfirmed) {
          setShow(false);
          props.getFoundationList();
          setProfileImage("");
          setAddedFolderResult(false);
          setNewPlan({ fName: "", fDetails: "", fContactNumber: "", fWebsite: "", fProfilePic: "", });
          setCheckValidation({ fName: false, fDetails: false, fContactNumber: false, fWebsite: false, fProfilePic: false, });
        }
      });
      setAdminDetail(props.foundationListData);
    }
  };

  const handleUpdateChange = (event: any) => {
    setCheckValidation({ ...checkValidation, [event.target.name]: true, });
    setUpdatePlan({ ...updatePlan, [event.target.name]: event.target.value, });
  };
  const handleImageChange = (e: any) => {
    if (e.target.files.length) {
      setUpdateIconChange(true);

      setProfileImage(URL.createObjectURL(e.target.files[0]));
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };

  const handleChange = (event: any) => {
    if (event.target.files && event.target.files.length) {
      setProfileImage(URL.createObjectURL(event.target.files[0]));
      setImage({
        preview: URL.createObjectURL(event.target.files[0]),
        raw: event.target.files[0],
      });
    }
    setCheckValidation({
      ...checkValidation,
      [event.target.name]: true,
    });
    setNewPlan({
      ...newPlan,
      [event.target.name]: event.target.value,
    });
    // setFileName(inputRef.files[0].name);
  };
  useEffect(() => {
    const validationErrors = addFoundationValidation(newPlan);

    isValid = Object.keys(validationErrors).length === 0;
    setIsValid(isValid);
    setErrors(validationErrors);
  }, [newPlan]);

  useEffect(() => {  
    const updateValidationErrors = addFoundationValidation(updatePlan);
    isValid = Object.keys(updateValidationErrors).length === 0;
    setIsValid(isValid);
    setErrors(updateValidationErrors);

  }, [updatePlan]);

  const handleViewModalClose = () => {
    // blank
    setCheckValidation({
      fName: false,
      fDetails: false,
      fContactNumber: false,
      fWebsite: false,
      fProfilePic: false,
    });
    setFolderDetailResult(false);
    setProfileImage("");
    setViewModalShow(false);
    setViewModalOpen(false);
  };
  const handleViewModalShow = (e: any) => {
    setDisabledCheck(true);
    openModal(e);
  };
  const handleUpdatePlanShow = (e: any) => {
    setDisabledCheck(false);
    openModal(e);
  };
  useEffect(() => {
    if (props.getFoundationDetailByIdData) {
      let result1 = props.getFoundationDetailByIdData
        ? props.getFoundationDetailByIdData
        : "";

      // view
      viewModalDetails.foundationName = result1.foundationName
        ? result1.foundationName
        : "";
      viewModalDetails.foundationDetails = result1.foundationDetails
        ? result1.foundationDetails
        : "";
      viewModalDetails.foundationPhone = result1.foundationPhone
        ? result1.foundationPhone
        : "";
      viewModalDetails.foundationWebsite = result1.foundationWebsite
        ? result1.foundationWebsite
        : "";
      viewModalDetails.foundationProfilePhoto = result1.foundationProfilePhoto
        ? result1.foundationProfilePhoto
        : "";

      // update
      updatePlan.fName = result1.foundationName
        ? result1.foundationName
        : "";
      updatePlan.fDetails = result1.foundationDetails
        ? result1.foundationDetails
        : "";
      updatePlan.fContactNumber = result1.foundationPhone
        ? result1.foundationPhone
        : "";
      updatePlan.fWebsite = result1.foundationWebsite
        ? result1.foundationWebsite
        : "";
      updatePlan.fProfilePic = result1.foundationProfilePhoto
        ? result1.foundationProfilePhoto
        : "";

      if (folderDetailResult) {
        setViewModalOpen(true);
        setViewModalShow(true);
      }
    }
  }, [props.getFoundationDetailByIdData]);

  const openModal = (e: any) => {
    let foundationId = e;

    // api call for particular foundation id
    props.getFoundationDetailById(foundationId);
    setFolderDetailResult(true);
  };
  const formDataUpdate = new FormData();
  const handleUpdateSubmit = (e: any) => {
    e.preventDefault();

    // formDataUpdate
    formDataUpdate.append("foundationName", updatePlan.fName);
    formDataUpdate.append("foundationDetails", updatePlan.fDetails);
    formDataUpdate.append("foundationWebsite", updatePlan.fWebsite);
    formDataUpdate.append("foundationPhone", updatePlan.fContactNumber);
    if (updateIconChange) {
      formDataUpdate.append("foundationProfilePhoto", image.raw);
    }
    let updateId = props.getFoundationDetailByIdData.id;
    let updatePayload = {
      id: updateId,
      data: formDataUpdate,
    };
    props.updateAdminFoundation(updatePayload);
    setUpdatedFolderResult(true);
    setLoader(true);
  };
  useEffect(() => {
    if (props.updateAdminFoundationData && updatedFolderResult) {
      setLoader(false);
      Swal.fire({
        title: "Updated successfully",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "green",
        allowOutsideClick: false
      }).then((result) => {
        if (result.isConfirmed) {
          props.getFoundationList();
          setUpdatedFolderResult(false);
          handleViewModalClose();
          //setvalidation blank
          setCheckValidation({
            fName: false,
            fDetails: false,
            fContactNumber: false,
            fWebsite: false,
            fProfilePic: false,
          });
        }
      });
    }
  }, [props.updateAdminFoundationData]);

  const handleRemoveFolder = (e: any, name: any) => {
    let deleteFoundationId = e;
    let removeFoundationName = name;
    Swal.fire({
      title: `Do you want to remove "${removeFoundationName}" foundation? `,
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "YES",
      denyButtonText: `NO`,
      confirmButtonColor: "green",
      showCloseButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        props.deleteAdminFoundation({ id: deleteFoundationId });
        setLoader(true);
        setDeleteFolderResult(true);
      }
    });
  };

  useEffect(() => {
    if (props.deleteAdminFoundationData && deleteFolderResult) {
      setLoader(false);
      let deteleResult = props.deleteAdminFoundationData.message;
      Swal.fire({
        title: 'Foundation deleted successfully',
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "green",
        allowOutsideClick: false
      }).then((result) => {
        if (result.isConfirmed) {
          props.getFoundationList();
          setDeleteFolderResult(false);
        }
      });
    }
  }, [props.deleteAdminFoundationData]);

  let result: any;
  useEffect(() => {
    if (viewModalOpen) {
      result = props.foundationListData ? props.foundationListData : "";
    }
  });

  const formDataAdd = new FormData();
  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (image && image.raw) {
      e.preventDefault();
      formDataAdd.append("foundationName", newPlan.fName);
      formDataAdd.append("foundationDetails", newPlan.fDetails);

      formDataAdd.append("foundationPhone", newPlan.fContactNumber);
      formDataAdd.append("foundationWebsite", newPlan.fWebsite);
      formDataAdd.append("foundationProfilePhoto", image.raw);

      if (true) {
        props.addAdminFoundation(formDataAdd);
        setLoader(true);

        setAddedFolderResult(true);
      }
    }

    setImage({
      preview: "",
      raw: "",
    });
  };

  const modalDetail = [
    {
      field1: "Foundation Name",
      field2: "fName",
    },
    {
      field1: " Contact Number",
      field2: "fContactNumber",
    },
    {
      field1: " Website",
      field2: "fWebsite",
    },

    {
      field1: "Profile Pic",
      field2: "fProfilePic",
    },
    {
      field1: "Detail",
      field2: "fDetails",
    },
  ];

  return (
    <>
      <Layout>
        {loader ? (
          <div className="adminLoader">
            <span className="text-warning text-center lds-dual-ring"></span>
          </div>
        ) : (
          ""
        )}

        <Row>
          <Col md={10}>
            <h3 style={{color:"#E79E1F"}}>Foundation Page</h3>
          </Col>
          <Col md="auto" className="ms-auto">
            <Stack direction="horizontal" gap={3}>
              <Button variant="info" className="ms-auto" onClick={handleShow}>Add</Button>
            </Stack>
          </Col>
        </Row>  
        <hr style={{border:'1px solid #E79E1F'}}/>
        <div className="usersManageFolders scrollBar">
          <Table responsive>
            <thead>
              <tr>
                <th className="text-warning text-center">S.No</th>
                <th className="text-warning text-center">Name</th>
                <th className="text-warning text-center">Logo</th>
                <th className="text-warning text-center">Detail</th>
                <th className="text-warning text-center">Contact Number</th>
                <th className="text-warning text-center">Website</th>
                <th className="text-warning text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {adminDetail && adminDetail.map((list: any, index: number) => {
                return (
                  <>
                    <tr>
                      <td className="text-center pt-5">{index + 1}</td>
                      <td className="text-center pt-5">{list.foundationName}</td>
                      <td className="text-center"><img className="img" width="260" style={{ height: "100px", width: "150px",maxWidth:"200px" }} src={ list && list.foundationProfilePhoto && `${BASE_URLS.BASE_URL}/user-box-files/resource?key=${list.foundationProfilePhoto}` } alt="" /></td>
                      <td className="text-center pt-5"><div className="foundationDetailColumn">{list.foundationDetails}</div></td>
                      <td className="text-center pt-5">{list.foundationPhone}</td>
                      <td className="text-center pt-5">{list.foundationWebsite}</td>
                      <td className="text-center pt-4">
                        <button type="submit" className="btn btn-outline-success" value={list.id} onClick={() => handleViewModalShow(list.id)} > <BsEyeFill /> </button>
                        <button type="submit" className="btn btn-outline-warning m-2" value={list.id} onClick={() => handleUpdatePlanShow(list.id)} > <BsPencilFill /> </button>
                        <button type="submit" className="btn btn-outline-danger" value={list.id} id={list.planType} onClick={() => handleRemoveFolder(list.id, list.foundationName) } > <BsTrashFill /> </button>
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </Table>
        </div>
        {/* -------------ADD FOLDER MODAL---------------  */}
        <Modal show={show} size="lg" backdrop="static" onHide={handleClose} className="adminModal">
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#E79E1F" }}>
              Add Foundation
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmit}>
              <Container>
                <Row>
                  {modalDetail &&
                    modalDetail.map((data: any, index) => {
                      return (
                        <>
                          <Col className="col-md-auto" md={6}>
                            {data.field1 == "Profile Pic" ? (
                              <div
                              // className="file-upload-input"
                              >
                                <Form.Group
                                  className="mb-3 mt-3 "
                                  //controlId={data.field2}
                                >
                                  <Form.Label style={{ color: "whitesmoke" }}>
                                    {data.field1}
                                  </Form.Label>
                                  <input
                                    className="fileInput form-control pr-3"
                                    type="file"
                                    id="file"
                                    name={"selectedFile"}
                                    accept={"image/jpeg"}
                                    ref={(refParam) => (inputRef = refParam)}
                                    onChange={handleChange}
                                  />
                                </Form.Group>
                                <strong
                                  className="text-warning"
                                  title={fileName}
                                  style={{
                                    display: "block",
                                    whiteSpace: "break-spaces",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {fileName}
                                </strong>
                                {profileImage ? (
                                  <img
                                    src={profileImage}
                                    alt=""
                                    width={"150"}
                                    height={"150"}
                                    style={{ borderRadius: "10px" }}
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                            ) : (
                              <Form.Group
                                className="mb-3 mt-3 "
                                //controlId={data.field2}
                              >
                                <Form.Label style={{ color: "whitesmoke" }}>
                                  {data.field1}
                                </Form.Label>
                                <Form.Control
                                  type={
                                    data.field1 == "Contact Number"
                                      ? "number"
                                      : "text"
                                  }
                                  name={data.field2}
                                  /*  
                            value={newPlan.} */
                                  onInput={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    e.target.value = e.target.value.trimStart();
                                  }}
                                  onChange={handleChange}
                                  autoFocus
                                  // as="textarea"
                                  // rows={3}
                                  style={
                                    data.field1 == "Detail"
                                      ? { height: "140px" }
                                      : { height: "50px" }
                                  }
                                  as={
                                    data.field1 == "Detail"
                                      ? "textarea"
                                      : "input"
                                  }
                                  // rows={data.field1 == "Foundation Detail" ? 3 : 1}
                                />

                                {checkValidation[data.field2] &&
                                  errors[data.field2] && (
                                    <p className="text-danger">
                                      {errors[data.field2]}
                                    </p>
                                  )}
                              </Form.Group>
                            )}
                          </Col>
                        </>
                      );
                    })}
                </Row>
                {loader ? (
                  <div className="adminLoader">
                    <span className="text-warning text-center lds-dual-ring"></span>
                  </div>
                ) : (
                  ""
                )}
              </Container>
              <Row className="justify-content-md-center mt-10">
                <Col className="" md="auto" style={!isValid && !profileImage ? {cursor:"not-allowed"} :{}}>
                  <Button className="backBtn" type="submit" disabled={isValid && profileImage ? false : true}> Submit </Button>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
          {/*  <Modal.Footer></Modal.Footer> */}
        </Modal>

        {/* ACTION - VIEW ,UPDATE AND DELETE */}

        <Modal
          show={viewModalShow}
          size="lg"
          backdrop="static"
          onHide={handleViewModalClose}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "whitesmoke" }}>
              {disabledCheck
                ? "Foundation View Details"
                : "Foundation Update Details"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleUpdateSubmit}>
              <Container>
                <Row>
                  <Col className="col-md-auto" md={6}>
                    <Form.Group className="mb-3 mt-3 ">
                      <Form.Label style={{ color: "whitesmoke" }}>
                        Foundation Name
                      </Form.Label>

                      <Form.Control
                        type="text"
                        name="fName"
                        onChange={handleUpdateChange}
                        value={
                          disabledCheck
                            ? viewModalDetails.foundationName
                            : updatePlan.fName
                        }
                        autoFocus
                        disabled={disabledCheck}
                      />
                    </Form.Group>
                    {checkValidation['fName'] &&
                                  errors['fName'] && (
                                    <p className="text-danger">
                                      {errors['fName']}
                                    </p>
                                  )}
                  </Col>

                  <Col className="col-md-auto" md={6}>
                    <Form.Group className="mb-3 mt-3 ">
                      <Form.Label style={{ color: "whitesmoke" }}>
                        Contact Number
                      </Form.Label>

                      <Form.Control
                        type="number"
                        name="fContactNumber"
                        onChange={handleUpdateChange}
                        value={
                          disabledCheck
                            ? viewModalDetails.foundationPhone
                            : updatePlan.fContactNumber
                        }
                        autoFocus
                        disabled={disabledCheck}
                      />
                    </Form.Group>
                    {checkValidation['fContactNumber'] &&
                                  errors['fContactNumber'] && (
                                    <p className="text-danger">
                                      {errors['fContactNumber']}
                                    </p>
                                  )}
                  </Col>
                  <Col className="col-md-auto" md={6}>
                    <Form.Group className="mb-3 mt-3 ">
                      <Form.Label style={{ color: "whitesmoke" }}>
                        Website
                      </Form.Label>

                      <Form.Control
                        type="text"
                        name="fWebsite"
                        onChange={handleUpdateChange}
                        value={
                          disabledCheck
                            ? viewModalDetails.foundationWebsite
                            : updatePlan.fWebsite
                        }
                        autoFocus
                        disabled={disabledCheck}
                      />
                    </Form.Group>
                    {checkValidation['fWebsite'] &&
                                  errors['fWebsite'] && (
                                    <p className="text-danger">
                                      {errors['fWebsite']}
                                    </p>
                                  )}
                  </Col>
                  <Col className="col-md-auto" md={6}>
                    <Form.Group className=" mt-3 ">
                      <Form.Label style={{ color: "whitesmoke" }}>
                        Profile Pic
                      </Form.Label>
                      <Col className="col-md-auto" md={2}>
                        {disabledCheck ? (
                          ""
                        ) : (
                          <div
                            className="file-upload-input"
                            style={{ marginTop: "-10px", marginLeft: "-10px" }}
                          >
                            <Form.Group
                              className="mb-3  "
                              //controlId={data.field2}
                            >
                              <input
                                className="fileInput form-control pr-3"
                                type="file"
                                id="file"
                                name={"selectedFile"}
                                accept={"image/jpeg"}
                                ref={(refParam) => (inputRef = refParam)}
                                onChange={handleImageChange}
                              />
                            </Form.Group>
                            <strong
                              className="text-warning"
                              title={fileName}
                              style={{
                                display: "block",
                                whiteSpace: "break-spaces",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {fileName}
                            </strong>
                            {profileImage ? (
                              <div
                                className="updateIcon"
                                style={{ width: "150px", height: "10px" }}
                              >
                                <img
                                  src={profileImage}
                                  alt=""
                                  width={"400"}
                                  height={"150"}
                                  style={{ borderRadius: "10px" }}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        )}
                        {!profileImage ? (
                          <div
                            className="profilePicViw"
                            style={{
                              width: "180px",
                              height: "20px",
                              maxHeight: "120",
                            }}
                          >
                            <img
                              onChange={handleUpdateChange}
                              src={
                                disabledCheck
                                  ? viewModalDetails &&
                                    viewModalDetails.foundationProfilePhoto &&
                                    `${BASE_URLS.BASE_URL}/user-box-files/resource?key=${viewModalDetails.foundationProfilePhoto}`
                                  : updatePlan &&
                                    updatePlan.fProfilePic &&
                                    `${BASE_URLS.BASE_URL}/user-box-files/resource?key=${updatePlan.fProfilePic}`
                              }
                              alt=""
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col className="col-md-auto" md={6}>
                    <Form.Group className="mb-3 mt-3 ">
                      <Form.Label style={{ color: "whitesmoke" }}>
                        Detail
                      </Form.Label>

                      <Form.Control
                        type="text"
                        name="fDetails"
                        onChange={handleUpdateChange}
                        value={
                          disabledCheck
                            ? viewModalDetails.foundationDetails
                            : updatePlan.fDetails
                        }
                        as="textarea"
                        rows={5}
                        autoFocus
                        disabled={disabledCheck}
                      />
                    </Form.Group>

                    {checkValidation['fDetails'] &&
                                  errors['fDetails'] && (
                                    <p className="text-danger">
                                      {errors['fDetails']}
                                    </p>
                                  )}
                  </Col>
                </Row>
                {loader ? (
                  <div className="adminLoader">
                    <span className="text-warning text-center lds-dual-ring"></span>
                  </div>
                ) : (
                  ""
                )}
              </Container>
              <Row className="justify-content-md-center mt-10">
                <Col className="" md="auto">
                  {!disabledCheck ? (
                    <Button
                      variant="primary"
                      type="submit"
                      // onClick={handleSubmit}
                      disabled={isValid || profileImage ? false : true}
                    >
                      Update
                    </Button>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
            </Form>
          </Modal.Body>
          {/*  <Modal.Footer></Modal.Footer> */}
        </Modal>
      </Layout>
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  getFoundationList: (data: any) => dispatch(getFoundationList(data)),
  addAdminFoundation: (data: any) => dispatch(addAdminFoundation(data)),
  deleteAdminFoundation: (data: any) => dispatch(deleteAdminFoundation(data)),
  getFoundationDetailById: (data: any) =>
    dispatch(getFoundationDetailById(data)),
  updateAdminFoundation: (data: any) => dispatch(updateAdminFoundation(data)),
});
const mapStateToProps = (state: any) => {
  return {
    foundationListData:
      state.AdminFoundationReducer &&
      state.AdminFoundationReducer.foundationListData
        ? state.AdminFoundationReducer.foundationListData
        : null,

    addAdminFoundationData:
      state.AdminFoundationReducer &&
      state.AdminFoundationReducer.addAdminFoundationData
        ? state.AdminFoundationReducer.addAdminFoundationData
        : null,
    deleteAdminFoundationData:
      state.AdminFoundationReducer &&
      state.AdminFoundationReducer.deleteAdminFoundationData
        ? state.AdminFoundationReducer.deleteAdminFoundationData
        : null,
    getFoundationDetailByIdData:
      state.AdminFoundationReducer &&
      state.AdminFoundationReducer.getFoundationDetailByIdData
        ? state.AdminFoundationReducer.getFoundationDetailByIdData
        : null,
    updateAdminFoundationData:
      state.AdminFoundationReducer &&
      state.AdminFoundationReducer.updateAdminFoundationData
        ? state.AdminFoundationReducer.updateAdminFoundationData
        : null,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminFoundation);
