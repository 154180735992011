import Api from "../apiCall";
import { API_URLS } from "../../constant/index";

export const getAdminUserService = async (params) => {
  const result = await Api.get(API_URLS.GET_ADMIN_USER_LIST_URL, params);
  return result;
};

export const getAdminUserAdminService = async (params) => {
  const result = await Api.get(API_URLS.GET_ADMIN_USER_ADMIN_LIST_URL, params);
  return result;
};

export const getAdminUserByIdService = async (params) => {
  
  const userId = params.id;
  let url = API_URLS.GET_ADMIN_USER_BY_ID_URL;
  let newurl = url.replace("{userId}", userId);
  return await Api.get(newurl, params);

};
export const getAdminUserPaymentDetailsService = async (params) => {
  
  const userId = params.id;
  let url = API_URLS.GET_ADMIN_USER_PAYMENT_DETAILS_URL;
  let newurl = url.replace("{userId}", userId);
  return await Api.get(newurl, params);

};
export const getAdminUserByIdentityService = async (params) => {
  
  const userId = params.id;
  let url = API_URLS.GET_ADMIN_USER_BY_IDENTITY_URL;
  let newurl = url.replace("{userIndentityNumber}", userId);
  return await Api.get(newurl, params);

};

export const disableAdminUserByIdService = async (params) => {
  
  const userId = params;
  let url = API_URLS.DISABLE_ADMIN_USER_BY_ID_URL;
  let newurl = url.replace("{userId}", userId);
  return await Api.delete(newurl, params);

};
export const activateDeactivateUserByIdService = async (params) => {
  
  const userId = params.id;
  let url = API_URLS.ACTIVATE_DEACTIVATE_USER_BY_ID_URL;
  let newurl = url.replace("{userId}", userId);
  return await Api.patch(newurl, params);

};

export const CSVdownloadService = async (params) => {
  let url = API_URLS.GET_URL_DOWNLOAD_CSV_FOR_ALL_USERS;
  return await Api.get(url, params);
};

export const getUnsubscribedMailUsersList_Service = async (params) => {
  let url = API_URLS.GET_UNSUBSCRIBED_MAIL_USER_LIST_URL;
  return await Api.get(url, params);
};