import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import Layout from "../../layout/Layout";
import { connect } from "react-redux";
import defaultCoverImg from "../../assets/images/no-image-available.png";
import { BASE_URLS } from "../../redux/constant";
import {MdRestore} from "react-icons/md";
import {BsTrash} from "react-icons/bs";
import Swal from 'sweetalert2';
import { getBoxIcon } from "../../redux/actions/boxAction";
import { getRecycleBinBoxes, getRecycleBinFiles,restoreItem,deleteItem } from "../../redux/actions/recycleBinAction";
import DisplayModal from "../box/uploaded_folder/display-modal";
import pdf from '../../assets/images/pdf.png';
import docx from '../../assets/images/docx.png';
import file from '../../assets/images/file.jpg';
import Axios from "axios";
import { FaDownload } from "react-icons/fa";

const RecycleBin = (props: any) => {
  const [recycleBinBoxes, setRecycleBinBoxes] = useState([]);
  const [recycleBinFiles, setRecycleBinFiles] = useState([]);
  const [itemType,        setItemType]        = useState('');
  const [itemId,          setItemId]          = useState(0);
  const [visbileBoxes,    setVisibleBoxes]    = useState(10);
  const [visibleFiles,    setVisibleFiles]    = useState(10);
  const [loader,          setLoader]          = useState<boolean>(false);
  const [docLoader,       setDocLoader]       = useState<boolean>(false);
  const [itemPayload,     setItemPayload]     = useState({type:'',id:0})
  const [notificationMsg, setNotificationMsg] = useState({type:'',message:''});
  const [isVisible,       setIsVisible]       = useState(false);
  const [iconList,        setIconList]        = useState<any>([]);
  const [modalShow,       setModalShow]       = useState<boolean>(false);
  const [modalHeading,    setModalHeading]    = useState('');
  const [modalContent,    setModalContent]    = useState<any>({title:'',message:'',image:'',video:'',videoLinks:'',audio:'',audioLinks:'',document:'',documentDetails:[]});
  const [fileExt,         setFileExt]         = useState('');
  let payload:any;
  
  useEffect(() => {
    setLoader(true);
    props.getRecycleBinBoxes();
    props.getRecycleBinFiles();
    props.getBoxIcon();
    if(props.restoreItemData && !localStorage.getItem('restoreData')){
        localStorage.setItem('restoreData','true');
        if(props.restoreItemData.error){
            setIsVisible(true);
            setNotificationMsg({type:"danger",message:props.restoreItemData.error.message})
        }else{
            setIsVisible(true);
            setNotificationMsg({type:"success",message:props.restoreItemData.message})
        }
    }

    if(props.deletedItemData && !localStorage.getItem('filesDeleted')){
        Swal.fire(
        'Deleted!',
        'Box has been removed successfully.',
        'success'
      ).then((result) =>{
          if(result.isConfirmed){
            localStorage.setItem('filesDeleted','true');
          }
        })
      }
  }, [props.restoreItemData,props.deletedItemData]);

  useEffect(() =>{
    if(isVisible){
        setTimeout(() => {
            setIsVisible(false);
            setNotificationMsg({type:"",message:""});
          }, 8000);
    }
  },[isVisible])

  useEffect(() => {
    if (props.recycleBoxData) {
        setLoader(false)
        setRecycleBinBoxes(props.recycleBoxData);
    }
  }, [props.recycleBoxData]);

  useEffect(() => {
    if (props.recycleFileData) {
        setLoader(false)
      setRecycleBinFiles(props.recycleFileData);
    }
  }, [props.recycleFileData]);

  useEffect(() =>{
    if(props.boxIconData){
        setIconList(props.boxIconData);
      }
    },[props.boxIconData])

  const handleRestore=(item_id:any,itemType:any,itemName:any)=>{
    setModalShow(false);
    if((item_id && item_id !== 0) && itemType){
        Swal.fire({
            title             : `Restore ${itemType} ?`,
            html              : `<div class="pt-1">Do you want to restore this ${itemType.toLowerCase()} <span class="text-danger">${itemName}</span> ?</div>`,
            showDenyButton    : true,
            showCancelButton  : false,
            confirmButtonText : `Yes! Restore it!`,
            confirmButtonColor: 'green',
            denyButtonText    : 'No',
            denyButtonColor   : 'blue',
            width             : '450px',
            customClass       : {
                                  actions       : 'my-actions',
                                  confirmButton : 'order-1',
                                  denyButton    : 'order-2',
                                }
        }).then((result) => {
            if (result.isConfirmed) {
                payload={ "restoreData": [{type:itemType,id:item_id}] } 
                localStorage.removeItem('restoreData');
                props.restoreItem(payload);
            } 
        })  
    }
}

const handleDelete = (item_id:any,itemType:any,itemName:any) =>{
    setModalShow(false);
    if((item_id && item_id !== 0) && itemType) {
        Swal.fire({
          title             : `Delete ${itemType} ?`,
          html              : `<div class="pt-1">Do you want to delete this ${itemType.toLowerCase()} <span class="text-danger">${itemName}</span> permanently ?</div>`,
          showDenyButton    : true,
          showCancelButton  : false,
          confirmButtonText : 'Yes,Delete it!',
          denyButtonText    : 'No',
          confirmButtonColor: 'red',
          denyButtonColor   : 'blue',
          width             : '450px',
          customClass       : {
                                actions       : 'my-actions',
                                confirmButton : 'order-1',
                                denyButton    : 'order-2',
                              }
        }).then((result) => {
            if (result.isConfirmed) {
                payload={ "deletedData": [{type:itemType,id:item_id}] } 
                localStorage.removeItem('filesDeleted');
                props.deleteItem(payload);
            }
        });
    }
}

const LoadMoreBoxes = () =>{
    setVisibleBoxes(visbileBoxes+5);
}

const LoadMoreFiles = () =>{
    setVisibleFiles(visibleFiles+5);
}

useEffect(() =>{
if(props.message && props.message.status === 'error' && props.message.page === 'recycle-bin')
{
    setIsVisible(true);
    setNotificationMsg({type:"danger",message:props.message.data})
}else{
    setIsVisible(false);
    setNotificationMsg({type:"",message:""})
}
},[props.message])

const handleModal = (e:any,data:any) =>{
    setModalShow(true);
    setModalHeading(data.boxFileType);
    
    if(data.boxFileType === 'Messages'){
        setModalContent({...modalContent,title:data.boxFileTitle,message:data.boxMessage})
    }

    if(data.boxFileType === 'Images'){
        setModalContent({...modalContent,image:data.boxFileName});
    }

    if(data.boxFileType === 'Videos'){
        if(!data.links){
            setModalContent({...modalContent,video:data.boxFileName,videoLinks:''});
        }else{
            const videoId = extractVideoId(data.links);
            setModalContent({...modalContent,videoLinks:videoId,video:''});
        }
    }

    if(data.boxFileType === 'Audio'){
        if(!data.links){
            setModalContent({...modalContent,audio:data.boxFileName,audioLinks:''});
        }else{
            setModalContent({...modalContent,audioLinks:data.links,audio:''});
        }
    }

    if(data.boxFileType === 'Documents'){
        var file_Ext = data.boxFileName.split('.').pop();
        let deletedDate = new Date(data.deletedAt);
        let deletedOn = deletedDate.setDate(deletedDate.getDate());
        setFileExt(file_Ext);
        setModalContent({...modalContent,
            document:data.boxFileName,
            documentDetails:{
                "Name":data.boxFileTitle,
                "FileExtension":file_Ext,
                "BoxName":(data.filesBTboxes && data.filesBTboxes.boxName ? data.filesBTboxes.boxName : ''),
                "FolderName":(data.filesBTfolder && data.filesBTfolder.folderName ? data.filesBTfolder.folderName : ''),
                "deletedOn":deletedOn,
            }
        });
    }
}

const closeModal = () =>{
    setModalShow(false);
}

 const extractVideoId = (url:any) => {
    let videoId = '';
    const videoUrlPattern     = /youtube\.com\/watch\?v=([a-zA-Z0-9_-]+)/;
    const shortenedUrlPattern = /youtu\.be\/([a-zA-Z0-9_-]+)/;
    if (videoUrlPattern.test(url)) {
      videoId = url.match(videoUrlPattern)[1];// Extract video ID from standard video URL
    } else if (shortenedUrlPattern.test(url)) {
      videoId = url.match(shortenedUrlPattern)[1];// Extract video ID from shortened URL
    }
    return videoId;
  };

  const handleDownload = (filePath:any) =>{
    setDocLoader(true);
    const userData     = JSON.parse(localStorage.getItem('userData')||'{}');
    const access_token = (userData && userData.Token)?userData.Token:'';
    if (access_token) {
      const config = { headers: {'Authorization': 'Bearer '+access_token} }
      let url      = `${BASE_URLS.BASE_URL}/user-box-files/resource/download?key=${filePath}`;
      Axios.get(url,config).then(function (response:any) {
        const link = document.createElement('a');
        link.href  = response.data;
        document.body.appendChild(link);
        link.click();
        setDocLoader(false);
      }).catch(function (error) {
        console.log(error);
      });
    }
  }
// ------------------------------------------------------------------------//

return (
    <div>
      <Layout>
        <Container className={loader ? `text-center` : ``}>
            {loader ? 
                <div className="lds-dual-ring text-center">
                    <span className="text-warning">Loading... please wait!</span>
                </div>
            :
            <>
            {(recycleBinBoxes && recycleBinBoxes.length === 0) && (recycleBinFiles && recycleBinFiles.length === 0) ?
                <p className="text-center text-warning pt-5"> 
                {/* Your Recycle Bin is empty. */}
                </p>
            :
                <>  
                    <div className="row">
                        <div className="col-md-3 mb-3"><h3>Recycle Bin</h3></div>
                        <div className="col-md-6">
                            {notificationMsg && <p className={`bg-${notificationMsg.type} text-light text-center rounded p-2`}>{notificationMsg.message}</p>}
                        </div>
                        <div className="col-md-3"></div>
                    </div>
                    {recycleBinBoxes && recycleBinBoxes.length !== 0 &&
                        <>
                            <h5 className="folderTitle text-warning">Deleted boxes</h5> 
                            <div className="" >
                                <div className="boxes deletedBox">
                                    <div className="boxList text-center">
                                        <div className="box-list-row">
                                            <ul className="">
                                                {recycleBinBoxes && recycleBinBoxes.length !== 0 && recycleBinBoxes.slice(0,visbileBoxes).map((list: any, index) => {
                                                    return (
                                                        <li key={list.id} className=" deletedBoxSize">
                                                            <div className="rounded" style={{ opacity: 0.5 }}>
                                                                <img height="100" width="180" style={{ borderRadius:'10px',cursor:'not-allowed' }} src={ list && list.coverPicture ? `${BASE_URLS.BASE_URL}/user-box-files/resource?key=${list.coverPicture}` : defaultCoverImg } alt=""  onError={({ currentTarget }) => {currentTarget.onerror = null; currentTarget.src=defaultCoverImg; }}/>
                                                            </div>
                                                            <h5 className="title-txt deletedBoxName"> {list.boxName ? list.boxName.toUpperCase() : "BOX-NAME"} </h5>
                                                            <b><MdRestore className="text-light pointer bg-success rounded" onClick={()=>{handleRestore(list.id,'Box',list.boxName)}} title="RESTORE" style={{fontSize:'25px',padding: '2px 0px 2px 0px'}}/></b>
                                                            &nbsp;&nbsp;
                                                            <b><BsTrash className="text-light pointer bg-danger rounded" onClick={()=>{handleDelete(list.id,'Box',list.boxName)}} title="DELETE" style={{fontSize:'25px',padding: '2px 0px 2px 0px'}}/></b>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="text-center pb-3" style={ visbileBoxes > Object.keys(recycleBinBoxes).length || visbileBoxes == Object.keys(recycleBinBoxes).length  ? { display: "none" } : { display: "block" } } >
                                <Button className="loadMoreBtn" onClick={() => LoadMoreBoxes()} >LOAD MORE</Button>
                            </div>
                        </>
                    }
            
                    {recycleBinBoxes && recycleBinBoxes.length !== 0 && recycleBinFiles && recycleBinFiles.length !== 0 && <hr style={{border:'1px solid #E79E1F'}}/>  }

                    {recycleBinFiles && recycleBinFiles.length !== 0 &&
                        <>
                            <h5 className="folderTitle text-warning">Deleted files</h5>
                            <Table responsive className="table-background rounded">
                            <thead>
                            <tr>
                                <th style={{textAlign:'center',color:"#E79E1F" }}>File Content</th>
                                <th style={{textAlign:'center',color:"#E79E1F" }}>File Icons</th>
                                <th style={{textAlign:'center',color:"#E79E1F" }}>Box Name</th>
                                <th style={{textAlign:'center',color:"#E79E1F" }}>Restore Before</th>
                                <th style={{textAlign:'center',color:"#E79E1F" }}>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {recycleBinFiles && recycleBinFiles.slice(0,visibleFiles).map((data: any,index) => {
                               let restore_date = new Date(data.deletedAt);
                               let restore_before = restore_date.setDate(restore_date.getDate()+30)
                               
                                return (
                                    <tr key={data.id} onClick={(e:any) =>{handleModal(e,data)}} className="pointer">
                                    <td style={{textAlign:'center' }}>{data.boxFileType && data.boxFileType}</td>
                                    <td style={{textAlign:'center' }}>
                                        {
                                            data.boxFileType === 'Images'    || data.boxFileType === 'Image' ? iconList && iconList[0] && iconList[0].folderIcon && <img height="20" width="20" src={`${BASE_URLS.BASE_URL}/user-box-files/resource?key=${iconList[0].folderIcon}`}/> 
                                        :
                                            data.boxFileType === 'Videos'    || data.boxFileType === 'Video' ? iconList && iconList[1] && iconList[1].folderIcon && <img height="20" width="20" src={`${BASE_URLS.BASE_URL}/user-box-files/resource?key=${iconList[1].folderIcon}`}/>
                                        :
                                            data.boxFileType === 'Messages'  || data.boxFileType === 'Message' ? iconList && iconList[2] && iconList[2].folderIcon && <img height="20" width="20" src={`${BASE_URLS.BASE_URL}/user-box-files/resource?key=${iconList[2].folderIcon}`}/>
                                        :
                                            data.boxFileType === 'Documents' || data.boxFileType === 'Document' ? iconList && iconList[3] && iconList[3].folderIcon && <img height="20" width="20" src={`${BASE_URLS.BASE_URL}/user-box-files/resource?key=${iconList[3].folderIcon}`}/>
                                        :   
                                            data.boxFileType === 'Audios'    || data.boxFileType === 'Audio' ? iconList && iconList[4] && iconList[4].folderIcon && <img height="20" width="20" src={`${BASE_URLS.BASE_URL}/user-box-files/resource?key=${iconList[4].folderIcon}`}/>
                                        :
                                            data.boxFileType === 'Recordings'|| data.boxFileType === 'Recording' ? iconList && iconList[4] && iconList[4].folderIcon && <img height="20" width="20" src={`${BASE_URLS.BASE_URL}/user-box-files/resource?key=${iconList[4].folderIcon}`}/>
                                        :
                                            ''
                                        }</td>
                                    <td style={{textAlign:'center' }}>{data.filesBTboxes && data.filesBTboxes.boxName ? data.filesBTboxes.boxName.charAt(0).toUpperCase() + data.filesBTboxes.boxName.slice(1).toLowerCase() : 'N/A' }</td>
                                    <td style={{textAlign:'center' }}>{new Date(restore_before).toLocaleDateString()}</td>
                                    <td style={{textAlign:'center' }}>
                                        <MdRestore className="pointer bg-success rounded" title="RESTORE" onClick={()=>{handleRestore(data.id,'File',data.boxFileType)}} style={{fontSize:'25px',padding: '2px 0px 2px 0px'}}/>
                                        &nbsp;&nbsp;
                                        <b><BsTrash className="text-light pointer bg-danger rounded" onClick={()=>{handleDelete(data.id,'File',data.boxFileType)}} title="DELETE" style={{fontSize:'25px',padding: '2px 0px 2px 0px'}}/></b>
                                    </td>
                                    </tr>
                                );
                                })}
                            </tbody>
                        </Table>
                          
                        <div className="text-center pt-2" style={ visibleFiles > Object.keys(recycleBinFiles).length || visibleFiles == Object.keys(recycleBinFiles).length  ? { display: "none" } : { display: "block" } } >
                            <Button className="loadMoreBtn" onClick={() => LoadMoreFiles()} >LOAD MORE</Button>
                        </div>
                        <DisplayModal backdrop="static" show={modalShow} onHide={closeModal} name={modalHeading} title={modalHeading === 'Messages' ? `Messages` : ``}>
                            <hr style={{border:'1px solid #E79E1F'}}/> 
                                {modalHeading === 'Messages' &&
                                    <>
                                        <div className="text-warning">Title : <span className="text-light">{modalContent.title}</span></div>
                                        <div className="text-warning">Message :
                                            <p style={{whiteSpace: "pre-line"}} className="text-light">{modalContent.message}</p>   
                                        </div>
                                    </>    
                                }

                                {modalHeading === 'Images' &&
                                    <img height="300" width="100%" src={`${BASE_URLS.BASE_URL}/user-box-files/resource?key=${modalContent.image}`}/>   
                                }    

                                {modalHeading === 'Videos' && 
                                    <>
                                    {modalContent && modalContent.video ? 
                                    <video width="100%" height="240" controls={true}>
                                        <source src={`${BASE_URLS.BASE_URL}/user-box-files/resource?key=${modalContent.video}`}/>
                                    </video>
                                    :
                                      modalContent.videoLinks ?
                                      <iframe width="100%" height="240" src={ `https://www.youtube.com/embed/${modalContent.videoLinks}`} allowFullScreen title='video'></iframe>  
                                      :
                                      <p className="bg-danger text-light p-2 rounded">No valid link is found</p>
                                    }
                                    </>
                                }

                                {modalHeading === 'Audio' && 
                                    <>
                                    {modalContent && modalContent.audio ? 
                                        <audio style={{width:"100%"}} controls={true}>
                                            <source src={`${BASE_URLS.BASE_URL}/user-box-files/resource?key=${modalContent.audio}`} />
                                        </audio> 
                                    :
                                      modalContent.audioLinks ?
                                        <audio style={{width:"100%"}} controls={true}>
                                            <source src={`${modalContent.audioLinks}`} />
                                        </audio>
                                      :
                                      <p className="bg-danger text-light p-2 rounded">No valid link is found</p>
                                    }
                                    </>
                                }

                                {modalHeading === 'Documents' &&
                                    <>
                                    {docLoader ?
                                        <span className='text-warning text-center lds-dual-ring mb-4' style={{fontSize:"14px",marginLeft: "11%",borderLeft: "1px solid",borderRight: "1px solid",borderRadius: "14px"}}>File downloading<br/>please wait...</span>
                                    :
                                        <Row className="align-items-center">
                                            <Col md="auto"><img className="ms-auto" width={320} height={250} src={fileExt === 'pdf' ? pdf : fileExt === 'docx' ? docx : file } alt=""/></Col>
                                            <Col className="ms-auto">
                                                {modalContent.documentDetails.length !== 0 ? 
                                                    <div>
                                                        <label className="text-warning"><u className="pt-1">DOCUMENT DETAILS</u></label><br/><br/>
                                                        <label className="text-warning">Name&nbsp;:&nbsp;<span className="text-light">{modalContent.documentDetails.Name}</span></label><br/>
                                                        <label className="text-warning">FileExtension&nbsp;:&nbsp;<span className="text-light">{modalContent.documentDetails.FileExtension}</span></label><br/>
                                                        <label className="text-warning">BoxName&nbsp;:&nbsp;<span className="text-light">{modalContent.documentDetails.BoxName}</span></label><br/>
                                                        <label className="text-warning">FolderName&nbsp;:&nbsp;<span className="text-light">{modalContent.documentDetails.FolderName}</span></label><br/>
                                                        <label className="text-warning">Deleted On&nbsp;:&nbsp;<span className="text-light">{new Date(modalContent.documentDetails.deletedOn).toLocaleDateString()}</span></label><br/><br/>
                                                        <label className="bg-warning text-dark p-1 rounded" onClick={()=>handleDownload(modalContent.document)} style={{cursor:'pointer'}}>Download file&nbsp;&nbsp;<FaDownload style={{fontSize:"12px",marginBottom:"10px"}}/></label>
                                                    </div>
                                                : 
                                                    ''
                                                }
                                            </Col>
                                        </Row>
                                    }
                                    </>
                                }

                            <hr style={{border:'1px solid #E79E1F'}}/> 
                            <Button style={{border:"1px solid #E79E1F"}} className='backBtn' onClick={closeModal}>Close</Button>
                        </DisplayModal>
                        </>
                    }
                </>
            }
            </>
            }
        </Container>
    </Layout>
</div>
);
};

const mapDispatchToProps = (dispatch: any) => ({
  getRecycleBinBoxes: (data: any) => dispatch(getRecycleBinBoxes(data)),
  getRecycleBinFiles: (data: any) => dispatch(getRecycleBinFiles(data)),
  restoreItem       : (data: any) => dispatch(restoreItem(data)),
  deleteItem        : (data: any) => dispatch(deleteItem(data)),
  getBoxIcon        : (data: any) => dispatch(getBoxIcon(data)),
});

const mapStateToProps = (state: any) => {
  return {
    recycleBoxData  : state.RecycleBinReducer && state.RecycleBinReducer.recycleBoxData  ? state.RecycleBinReducer.recycleBoxData  : null,
    recycleFileData : state.RecycleBinReducer && state.RecycleBinReducer.recycleFileData ? state.RecycleBinReducer.recycleFileData : null,
    restoreItemData : state.RecycleBinReducer && state.RecycleBinReducer.restoreItemData ? state.RecycleBinReducer.restoreItemData : null,
    deletedItemData : state.RecycleBinReducer && state.RecycleBinReducer.deletedItemData ? state.RecycleBinReducer.deletedItemData : null,
    boxIconData     : state.BoxReducer        && state.BoxReducer.boxIconData            ? state.BoxReducer.boxIconData            : null,
    message         : state.CommonMessageReducer&& state.CommonMessageReducer.message    ? state.CommonMessageReducer.message      : null,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RecycleBin);