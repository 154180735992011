import React, { useEffect, useState } from 'react';
import Layout from '../../layout/Layout';
import { Button, Container } from 'react-bootstrap';
import { mailUnSubscribed } from '../../redux/actions/userAction';
import { connect } from 'react-redux';
import { COMMON_MESSAGE, USER } from '../../redux/constant';
var CryptoJS = require("crypto-js");

const UnsubscribedMail = (props:any) => {
  const url = window.location.pathname.split('/');
  const [email,   setEmail]   = useState('');
  const [errors,  setErrors]  = useState('');
  const [success, setSuccess] = useState('');

  useEffect(()=>{
    var ciphertext = (url && url[2] ? url[2] : '');
    if(url[1] === 'unsubscribe-mail' && ciphertext){
        var bytes        = CryptoJS.AES.decrypt(decodeURIComponent(ciphertext), 'BB Dev9930');
        var originalText = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        setEmail(originalText.email);
    }
  },[])

  const [loader,setLoader] = useState<boolean>(false);
  const handleMailUnSubscribed = () =>{
    setLoader(true);
    props.mailUnSubscribed({"email":email});
  }

  useEffect(() =>{
    if(props.unsubscribedData && props.unsubscribedData.status == 'success'){
      setSuccess(props.unsubscribedData.message)
    }
  },[props.unsubscribedData])

  useEffect(() =>{
    if(props.message && props.message.status == 'error' && props.message.page == 'mail-unsubscribe'){
      setLoader(false);
      setErrors(props.message.data);
    }
  },[props.message])

  useEffect(() =>{
    if(errors){
      setTimeout(() =>{
        setErrors('');
        props.clearMessage(null);
      },3000)
    }
  },[errors])

  useEffect(() =>{
    if(success){
      props.clearUnsubscribe(null);
    }
  },[success])

//----------------------------------------------------------------------------//

  return (
    <Layout>
        <div className={localStorage.length ? "" : "charity_publicBox"}>
            <Container>
              <div className="aboutUsMainContent text-center">
                {loader ? 
                  <div className="lds-dual-ring text-center">
                    <span className="text-warning">Loading... please wait!</span>
                  </div>
                : 
                  !success ?
                    <>  
                      {!errors ? 
                        <div className="heading"><h1>Unsubscribe</h1></div>
                        :
                        <div><h1 className='text-danger'>{errors}</h1></div>
                      }
                      <h2 className="m-0 text-light">{email}</h2><br/>
                      {!errors ? 
                        <Button variant='success' onClick={handleMailUnSubscribed}>CONFIRM</Button>
                      :
                        <Button variant='secondary' style={{cursor:"not-allowed",opacity:'0.5'}}>CONFIRM</Button>
                      }
                    </>
                  :
                    <div><h3 className='text-warning'>{success}</h3></div>
                }
              </div>
            </Container>
        </div>
    </Layout>
  )
}

const mapDispatchToProps = (dispatch: any) => ({
  mailUnSubscribed : (data: any) => dispatch(mailUnSubscribed(data)),
  clearMessage     : (data: any) => dispatch({type:COMMON_MESSAGE,data}),
  clearUnsubscribe : (data: any) => dispatch({type:USER.GET_MAIL_UNSUBSCRIBED_CONFRIMATION,data})
});

const mapStateToProps = (state: any) => {
  return {
    unsubscribedData : (state.userReducer && state.userReducer.unsubscribedData) ? state.userReducer.unsubscribedData : null,
    message          : (state.CommonMessageReducer && state.CommonMessageReducer.message) ? state.CommonMessageReducer.message : null,
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(UnsubscribedMail)