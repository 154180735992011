import { Button, Container }   from "react-bootstrap"
import Layout                  from "../../layout/Layout"
import { connect }             from "react-redux";
import { useEffect, useState } from "react";
import { BASE_URLS }           from "../../redux/constant";
import user_Img                from "../../assets/images/user.png"
import { Link }                from "react-router-dom";
import quote                   from "../../assets/images/quote.png";
import "../../assets/css/beyondbox.css";
import { boxRecordsListingWith_n_WithoutSearchKeyword } from "../../redux/actions/boxAction";

function BoxRecords(props:any) {

  let userData                            = localStorage.getItem("profileData");
  const [visible,       setVisible]       = useState(4);
  const [boxRecordData, setBoxRecordData] = useState<any>([]);
  const [loader,        setLoader]        = useState(false);
  
  useEffect(() => {
    setLoader(true);  
    props.boxRecordsListingWith_n_WithoutSearchKeyword(props.keyword);
  },[props.isClicked,props.keyword]);
  
  useEffect(() =>{
    if(props.boxRecordListing){
      setLoader(false);
      setBoxRecordData(props.boxRecordListing);
    }
  },[props.boxRecordListing,])

  const LoadMoreBoxRecords = () =>{
    setVisible(visible+4);
  }

  const BoxRecordsComponent = () =>{

    return(
      <div className={`${ !userData ? `openDiscoverBox_publicPage` : `` }`} style={!userData ? visible > 10 ? {paddingTop:'170px',backgroundRepeat: "unset",backgroundSize: "contain"} : {paddingTop:'170px'} : {}}>
        <Container className={loader ? `text-center` : ``}>
        {
          loader ? 
          // <div className="lds-dual-ring text-warning">
          //   Loading... please wait!
          // </div>
            // <div className="text-light" style={{background:"rgba(97, 95, 95, 0.3)",borderRadius:"8px", padding: "25px 25px"}}>
            //   <p className="m-0">
            //     BeyondBox users can create a Public Box Profile with their final messages to the world. Here on the Box Records page, you can browse or search for these precious and profound sentiments in celebration of their lives and to honour the legacy they have chosen to leave behind.
            //   </p>
            // </div>
            <div className="text-light">
              <div className="aboutUsMainContent">
                <div className="heading"><h2>Box Records</h2></div>
                <p className="m-0">BeyondBox users can create a Public Box Profile with their final messages to the world. Here on the Box Records page, you can browse or search for these precious and profound sentiments in celebration of their lives and to honour the legacy they have chosen to leave behind.</p>
              </div>
            </div>
          :
          <>
            {/* <div className="col-auto text-warning">
              <h5>Box Records</h5>
            </div>
             */}
            <div className="text-light">
              <div className="aboutUsMainContent">
                <div className="heading"><h2>Box Records</h2></div>
                <p className="m-0">BeyondBox users can create a Public Box Profile with their final messages to the world. Here on the Box Records page, you can browse or search for these precious and profound sentiments in celebration of their lives and to honour the legacy they have chosen to leave behind.</p>
              </div>
            </div>

            <div className="box-records-row ">
              {boxRecordData && boxRecordData.length !==0 &&
              <>
                { boxRecordData.slice(0,visible).map((boxItem:any) =>{
                  return (
                    <div className="box-records-col pt-5" key={boxItem.boxId} onClick={() => props.searching && props.searching(false)}>
                      <Link to={`/box-record-user/${boxItem.boxId}`}>
                        <div className="box_records pointer">
                          <div className="image-frame">
                            <img className="profileImage" src={boxItem && boxItem.userImg ? `${BASE_URLS.BASE_URL}/user-box-files/resource?key=${boxItem.userImg}` : user_Img} onError={({ currentTarget } ) => {currentTarget.onerror = null; currentTarget.src=user_Img; }} alt="No Image"/>
                          </div>
                          <div className="btm-content-frame">
                            <h5 className="title-txt p-2 text-warning text-center">
                              {boxItem && boxItem.firstName && boxItem.lastName ? ((boxItem.firstName+' '+boxItem.lastName).length > 15 ) ? (boxItem.firstName+' '+boxItem.lastName).substring(0,15)+'...' : (boxItem.firstName+' '+boxItem.lastName) : 'N/A'}
                              <br/><small><i className="text-light pointer" title={boxItem &&  boxItem.boxName ? boxItem.boxName : ''}>{boxItem &&  boxItem.boxName ? (boxItem.boxName.length > 15 ? boxItem.boxName.substring(0,15)+'...' : boxItem.boxName) : 'N/A'}</i></small>
                            </h5>
                            <p className="text-light quotes">
                              <img src={quote} alt="" className="" /><br/> 
                                <span style={{opacity:'0.75'}}>{boxItem.messages[0].length > 200 ? boxItem.messages[0].substring(0,120)+'...............' : boxItem.messages[0]}</span>
                              <img src={quote} alt="" className="float-end" style={{transform:"scaleX(-1)"}}/>
                            </p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  )
                })}
              </>
              // :
              //   <div  className="text-warning float-center pb-5">
              //     <span> No public box found...</span>
              //   </div>
              
              }
            </div>
            <div className="text-center p-3" style={  visible > (boxRecordData && boxRecordData.length) || visible == boxRecordData.length ? { display: "none" } : { display: "block" } }>
              <Button className="loadMoreBtn" onClick={() => LoadMoreBoxRecords()}>LOAD MORE</Button>
            </div>
          </>
        }
        </Container>
      </div>
    )
  }

//--------------------------------------------------------------//  
  return (
    <>
      {
        props.isClicked || props.searching ? 
          <BoxRecordsComponent />
        :
          <Layout>
            <BoxRecordsComponent />
          </Layout>
      }
    </>
  )
}

const mapDispatchToProps = (dispatch: any) => ({
  boxRecordsListingWith_n_WithoutSearchKeyword : (data: any) => dispatch(boxRecordsListingWith_n_WithoutSearchKeyword(data)),
});

const mapStateToProps = (state: any) => {
  return {
    boxRecordListing: state.BoxReducer && state.BoxReducer.boxRecordsListingData ? state.BoxReducer.boxRecordsListingData : null,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BoxRecords);