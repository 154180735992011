import React from 'react'
import Layout from '../../../layout/Admin/layout'

export default function NotAuthorized() {
  return (
    <Layout>
      <h1 className='text-center'>You are not authorized to view this page.</h1>
    </Layout>
  )
}
