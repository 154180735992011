import { ADMIN_BOX_API } from "../../constant/index";

const initialState = {
  loading: false,
  adminBoxCoverData:null,
  addAdminBoxCoverData:null,
  adminReleaseBoxData:null,
  getAdminBoxCoverDataById:null,
  removeAdminBoxCoverData:null,

}
const AdminBoxApiReducer = (state = initialState, action) => {
    switch (action.type) {
      case ADMIN_BOX_API.SET_ADMIN_COVER_PHOTOS_TO_STATE:
        return {
          ...state,
          adminBoxCoverData: action.data,
          loading: false,
        };
        
        case ADMIN_BOX_API.SET_ADD_ADMIN_COVER_PHOTOS_TO_STATE:
        return {
          ...state,
          addAdminBoxCoverData: action.data,
          loading: false,
        };
        case ADMIN_BOX_API.SET_PUT_ADMIN_RELEASE_BOX_TO_STATE:
        return {
          ...state,
          adminReleaseBoxData: action.data,
          loading: false,
        };

        case ADMIN_BOX_API.SET_ADMIN_COVER_PHOTO_BY_ID_TO_STATE:
          return {
            ...state,
            getAdminBoxCoverDataById: action.data,
            loading: false,
          };

          case ADMIN_BOX_API.SET_REMOVE_ADMIN_COVER_PHOTOS_TO_STATE:
            return {
              ...state,
              removeAdminBoxCoverData: action.data,
              loading: false,
            };
    
        default:
            return state;
    }
}
export default AdminBoxApiReducer;