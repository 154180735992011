import Api from "../apiCall";
import { API_URLS } from "../../constant/index";

export const getAdminCountryService = async (params) => {
  const result = await Api.get(API_URLS.GET_ADMIN_COUNTRIES_URL, params);
  return result;
};

export const getAdminStatesService = async (params) => {
  const countryId = params.id;
  let url = API_URLS.GET_ADMIN_STATES_URL;
  let newurl = url.replace("{id}", countryId);
  return await Api.get(newurl, params);
};

export const getAdminCitiesService = async (params) => {
  const statesId = params.id;
  let url = API_URLS.GET_ADMIN_CITIES_URL;
  let newurl = url.replace("{id}", statesId);
  return await Api.get(newurl, params);
};

export const addAdminCountryService = async (params) => {
  const result = await Api.post(API_URLS.ADD_ADMIN_COUNTRIES_URL, params);
  return result;
};

export const getAdminCountryByIdService = async (params) => {
  const countryId = params.id;
  let url = API_URLS.GET_ADMIN_COUNTRIES_BY_ID_URL;
  let newurl = url.replace("{id}", countryId);
  return await Api.get(newurl, params);
};
export const updateAdminCountryService = async (params) => {
  const countryId = params.id;
  let url = API_URLS.UPDATE_ADMIN_COUNTRIES_URL;
  let newurl = url.replace("{id}", countryId);
  return await Api.put(newurl, params);
};

export const deleteAdminCountryService = async (params) => {
  const countryId = params.id;
  let url = API_URLS.REMOVE_ADMIN_COUNTRIES_URL;
  let newurl = url.replace("{id}", countryId);
  return await Api.delete(newurl, params);
};

export const addAdminStatesService = async (params) => {
  const result=await Api.post(API_URLS.ADD_ADMIN_STATES_URL, params);
  
  return result;
  
};


export const getAdminStatesByIdService = async (params) => {
  const stateId = params.id;
  let url = API_URLS.GET_ADMIN_STATE_BY_ID_URL;
  let newurl = url.replace("{id}", stateId);
  const result=await Api.get(newurl, params);
  
  return result;
  
};
export const updateAdminStatesService = async (params) => {
  const stateId = params.id;
  let url = API_URLS.UPDATE_ADMIN_STATES_URL;
  let newurl = url.replace("{id}", stateId);
  return await Api.put(newurl, params);
};

export const deleteAdminStatesService = async (params) => {
  const stateId = params.id;
  let url = API_URLS.REMOVE_ADMIN_STATES_URL;
  let newurl = url.replace("{id}", stateId);
  return await Api.delete(newurl, params);
};
export const addAdminCityService = async (params) => {
  const result=await Api.post(API_URLS.ADD_ADMIN_CITY_URL, params);
  
  return result;
  
};


export const getAdminCityByIdService = async (params) => {
  const cityId = params.id;
  let url = API_URLS.GET_ADMIN_CITY_BY_ID_URL;
  let newurl = url.replace("{id}", cityId);
  const result=await Api.get(newurl, params);
  
  return result;
  
};
export const updateAdminCityService = async (params) => {
  const cityId = params.id;
  let url = API_URLS.UPDATE_ADMIN_CITY_URL;
  let newurl = url.replace("{id}", cityId);
  return await Api.put(newurl, params);
};

export const deleteAdminCityService = async (params) => {
  const cityId = params.id;
  let url = API_URLS.REMOVE_ADMIN_CITY_URL;
  let newurl = url.replace("{id}", cityId);
  return await Api.delete(newurl, params);
};