import { useState, useEffect }            from "react";
import Layout                             from "../../layout/Layout";
import { connect }                        from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BASE_URLS }                      from "../../redux/constant";
import Swal                               from "sweetalert2";
import{ BsPencilFill, BsTrashFill,BsEyeFill} from "react-icons/bs";
import MyImage                            from "../../layout/ImageComponent/myimage";
import { Container, ProgressBar, Dropdown, DropdownButton, Button, Row, Col } from "react-bootstrap";
import defaultImg              from "../../assets/images/no-image-available.png";
import { deleteBox, getBoxById, getBoxDetails, getBoxList, getReceivedBoxList,getChildReceivedBoxList, listBoxContent } from "../../redux/actions/boxAction";

const Home = (props:any) => {
  const redirectTo                                                = useNavigate(); 
  const [userDetails,               setUserDetails]               = useState<any>({});
  const [createdBoxList,            setCreatedBoxList]            = useState([]);
  const [receivedBoxList,           setReceivedBoxList]           = useState([]);
  const [childReceivedBoxList,      setChildReceivedBoxList]      = useState([]);
  const [visibleCreateBoxes,        setVisibleCreateBoxes]        = useState(10);
  const [visibleReceivedBoxes,      setVisibleReceivedBoxes]      = useState(10);
  const [visibleChildReceivedBoxes, setVisibleChildReceivedBoxes] = useState(10);
  const [totalSpace,                setTotalSpace]                = useState(0);
  const [remainingSpace,            setRemainingSpace]            = useState(0);
  const [usedSpace,                 setUsedSpace]                 = useState(0);
  const [isVisible,                 setIsVisible]                 = useState(false);
  const [notification,              setNotification]              = useState('');
  const [notificationType,          setNotificationType]          = useState('');
  const [createdBoxLoader,          setCreatedBoxLoader]          = useState(false);
  const [recievedBoxLoader,         setRecievedBoxLoader]         = useState(false);
  const { state }                                                 = useLocation();
  let creatorName:any ='';

  useEffect(() => {
    if (!localStorage.getItem("message")) {
      notify();
    }
    setCreatedBoxLoader(true);
    setRecievedBoxLoader(true);
    localStorage.removeItem('isPublicBox');
  }, []);

  const notify = () => {
    if (state) {
      const { message, messageType } = state;
      if (message) {
        setIsVisible(true);
        setNotification(message);
        setNotificationType(messageType);
        localStorage.setItem("message", message);
      }
    }
  };

  useEffect(() =>{
    if(isVisible){
      setTimeout(() => {
        setIsVisible(false);
        setNotification('');
        setNotificationType('');
      }, 8000);
    }
  },[isVisible])

  useEffect(() => {
    if (props.profileData) {
      setUserDetails(props.profileData);
    }
  }, [props.profileData]);

  useEffect(() => {
    if (props.currentPlanDetailsData) {
      const isActive = props.currentPlanDetailsData && props.currentPlanDetailsData.planisActive ? props.currentPlanDetailsData.planisActive : 0;
      if (isActive !== 0) {
        props.getBoxList(); // get all the created box list from this and used these values on home page.
        props.getReceivedBoxList(); // get all the received box list from this and used these values on home page.
        props.getChildReceivedBoxList();// get all the child received box list from this and used these values on home page.
      }
    }

    if(props.deleteBoxData && !localStorage.getItem('filesDeleted')){
      Swal.fire(
        'Deleted!',
        'Box has been removed successfully.',
        'success'
      ).then((result) =>{
        if(result.isConfirmed){
          localStorage.setItem('filesDeleted','true');
        }
      })
    }
  }, [props.currentPlanDetailsData,props.deleteBoxData]);

  useEffect(() => {
    if (props.boxListData) {
      setCreatedBoxLoader(false)
      setCreatedBoxList(props.boxListData);
    }
  }, [props.boxListData]);

  useEffect(() => {
    if (props.receivedBoxListData) {
      setRecievedBoxLoader(false);
      setReceivedBoxList(props.receivedBoxListData);
    }
  }, [props.receivedBoxListData]);

  useEffect(() => {
    if (props.childReceivedBoxListData) {
      setChildReceivedBoxList(props.childReceivedBoxListData);
    }
  }, [props.childReceivedBoxListData]);

  const LoadMoreCreateBoxes = () => {
    setVisibleCreateBoxes(visibleCreateBoxes + 5);
  };

  const LoadMoreReceivedBoxes = () => {
    setVisibleReceivedBoxes(visibleReceivedBoxes + 5);
  };

//----------------------------DELETE BOX CODE
  const handleDeleteBox = (boxId: any,boxName: string) => {
    if (boxId) {
      Swal.fire({
        title             : '',
        html              : '<div class="pt-3" style="font-weight:800">Do you want to delete this box <span class="text-danger">'+boxName+'</span> ?</div>',
        showDenyButton    : true,
        showCancelButton  : false,
        confirmButtonText : 'Yes,Delete it!',
        denyButtonText    : 'No',
        confirmButtonColor: 'red',
        denyButtonColor   : 'blue',
        width             : '450px',
        customClass       : {
                              actions       : 'my-actions',
                              confirmButton : 'order-1',
                              denyButton    : 'order-2',
                            }
      }).then((result) => {
        if (result.isConfirmed) {
          localStorage.removeItem('filesDeleted');
          props.deleteBox({ id: boxId });          
        }
      });
    }
  };
  
  useEffect(() =>{
    if(props.userStorage && props.currentPlanDetailsData){
      if(props.currentPlanDetailsData.planCode === 'FREE'){
        let totalStorageInMB      = (props && props.userStorage && props.userStorage.total_storage   && (Number(props.userStorage.total_storage)   / 1000000000) * 1000);
        let remainingStorageInMB  = (props && props.userStorage && props.userStorage.remaining_space && (Number(props.userStorage.remaining_space) / 1000000000) * 1000);
        let usedStorageInMB       = (props && props.userStorage && props.userStorage.used_space      && (Number(props.userStorage.used_space)      / 1000000000) * 1000);
        let usedSpaceInPercentage = (Number(usedStorageInMB) / Number(totalStorageInMB))*100;
        
        if(totalStorageInMB){
          setTotalSpace(totalStorageInMB.toFixed(1));
        }

        if(remainingStorageInMB){
          setRemainingSpace(remainingStorageInMB.toFixed(1));
        }
        
        if(usedSpaceInPercentage){
          setUsedSpace(usedSpaceInPercentage);
        }
        
      }else{
        let totalStorageInGB      = (props && props.userStorage && props.userStorage.total_storage   && (Number(props.userStorage.total_storage)   / 1000000000));
        let remainingStorageInGB  = (props && props.userStorage && props.userStorage.remaining_space && (Number(props.userStorage.remaining_space) / 1000000000));
        let usedStorageInGB       = (props && props.userStorage && props.userStorage.used_space      && (Number(props.userStorage.used_space)      / 1000000000));
        let usedSpaceInPercentage = (Number(usedStorageInGB) / Number(totalStorageInGB))*100;
        
        if(totalStorageInGB){
          setTotalSpace(totalStorageInGB.toFixed(1));
        }
        
        if(remainingStorageInGB){
          setRemainingSpace(remainingStorageInGB.toFixed(1));
        }
        
        if(usedSpaceInPercentage){
          setUsedSpace(usedSpaceInPercentage);
        }
        
      }
  }
},[props.userStorage,props.currentPlanDetailsData])  

const handlePublicBox = (isPublic:boolean) =>{
  if(isPublic === true){
    localStorage.setItem('isPublicBox',`${isPublic}`);
  }else{
    localStorage.removeItem('isPublicBox');
  }
}  

//-----------------------------------------------------------------------------------------------//

  return (
    <Layout>
      <div className="boxes">
        <div className="welcome">
          <Container>
            <div className="row">
              <div className={notificationType === 'danger' ? `col-md-6` : `col-md-8`}>
                <h3>
                  Hi {userDetails && userDetails.firstName ? userDetails.firstName : ""},
                </h3>
                <p>Welcome back to <Link style={{ textDecoration: "none" }} to="/homepage"><span><b>Beyond<span className="text-light">Box</span></b></span></Link></p>
              </div>
              <div className={notificationType === 'danger' ? `col-md-6 pt-3` : 'col-md-4 pt-3'} style={{float:"right"}}>
                <span className={isVisible ? `bg-${notificationType} text-light rounded p-2` : `text-light rounded p-2`}>
                  {isVisible && notification ? notification : ''}
                </span>
              </div>
            </div>
          </Container>
        </div>
        <Container>
          <div className="row mb-5 justify-content-between align-items-center">
            <div className="col-auto">
              <h5>Created boxes</h5>
              <div className="spaceAcquired" style={{ height: "0.2erm" }}>
                <ProgressBar now={usedSpace} />
              </div>
              <span className="spaceLeft text-light">
                Storage : { props.currentPlanDetailsData && props.currentPlanDetailsData.planCode === 'FREE' ? `${remainingSpace}MB left of ${totalSpace}MB` : `${remainingSpace}GB left of ${totalSpace}GB`}
              </span>
            </div>

            {/* <div className="col-auto">
              <div className="sort">
                <DropdownButton id="dropdown-basic-button" title="Sort by">
                  <Dropdown.Item href="#/action-1">1</Dropdown.Item>
                  <Dropdown.Item href="#/action-2">2</Dropdown.Item>
                  <Dropdown.Item href="#/action-3">3</Dropdown.Item>
                </DropdownButton>
              </div>
            </div> */}
          </div>
          <div className={createdBoxLoader ? `boxList text-center` : `boxList`}>
            <div className="box-list-row">
              {createdBoxLoader ? 
                <div className="lds-dual-ring text-center">
                  <span className="text-warning">Loading... please wait!</span>
                </div>
              :
                <ul className="box-listing-frame" style={createdBoxList && createdBoxList.length === 0 ? {justifyContent:'center'}: {}}>
                  {createdBoxList && createdBoxList.length !== 0 ? 
                    ( createdBoxList.slice(0, visibleCreateBoxes).map((list: any) => {
                      let isPublicBox = (list.isPublic && list.isPublic === 1 ? true : false);
                      return (
                          <li key={list.id}>
                            <div className="menuIcon">
                              <DropdownButton  className="dropdown-icon-box" title={""} >
                                <Dropdown.Item as={Link} to={`/box-preview/${list.id}`} className="rounded p-1 text-center"> 
                                    Box Preview
                                    <span className="text-light"><BsEyeFill/></span>
                                </Dropdown.Item>
                                <Dropdown.Item as={Link} to={`/update-box/${list.id}`} className="rounded p-1 text-center"> 
                                  Edit Box 
                                  <span className="text-warning"><BsPencilFill/></span>
                                </Dropdown.Item>
                                <Dropdown.Item as={Link} to="" onClick={() => handleDeleteBox(list.id,list.boxName)} className="rounded p-1 text-center"> 
                                  Delete Box 
                                  <span className="text-danger"><BsTrashFill/></span>
                                </Dropdown.Item>
                              </DropdownButton>
                            </div>
                            <Link style={{ textDecoration: "none" }} to={`/created-box/${list.id}`} state={{ from: "home" }}  onClick={() =>{handlePublicBox(isPublicBox)}}>
                              <div className="image">
                                <MyImage src={ list && list.coverPicture ? `${BASE_URLS.BASE_URL}/user-box-files/resource?key=${list.coverPicture}` : defaultImg } onError={({ currentTarget }:{currentTarget:any}) => {currentTarget.onerror = null; currentTarget.src=defaultImg; }} alt="No Image" />
                                {/* <img src={ list && list.coverPicture ? `${BASE_URLS.BASE_URL}/user-box-files/resource?key=${list.coverPicture}` : "" } alt="" onError={({ currentTarget }) => {currentTarget.onerror = null; currentTarget.src=defaultImg; }}/> */}
                              </div>
                              <h5 className="title-txt"> {list.boxName ? list.boxName.toUpperCase() : "BOX-NAME"} </h5>
                              <h6 className="box-type"> {isPublicBox ? "(PUBLIC)" : ""} </h6>
                            </Link>
                          </li>
                      )})
                    ) 
                  : 
                    (
                      <div className="text-warning text-center">
                        {/* No box created yet... */}
                      </div>
                    )
                  }
                </ul>  
              }
            </div>
            <div className="text-center pt-3" style={ visibleCreateBoxes > Object.keys(createdBoxList).length || visibleCreateBoxes == Object.keys(createdBoxList).length  ? { display: "none" } : { display: "block" } } >
              <Button className="loadMoreBtn" onClick={() => LoadMoreCreateBoxes()} >LOAD MORE</Button>
            </div>
          </div>
          <br/>
          <hr style={{border:'1px solid #E79E1F'}}/> 

          {/* ---------------RECIVING SECTION------------ */}

          <div className="row mb-5 pt-3 justify-content-between align-items-center">
            <div className="col-auto">
              <h5>Received boxes</h5>
            </div>
            {/* <div className="col-auto">
              <div className="sort">
                <DropdownButton id="dropdown-basic-button" title="Sort by">
                  <Dropdown.Item href="#/action-1">1</Dropdown.Item>
                  <Dropdown.Item href="#/action-2">2</Dropdown.Item>
                  <Dropdown.Item href="#/action-3">3</Dropdown.Item>
                </DropdownButton>
              </div>
            </div> */}
          </div>
          <div className={recievedBoxLoader ? `boxList text-center` : `boxList`}>
            <div className="box-list-row">
            {recievedBoxLoader ? 
                <div className="lds-dual-ring text-center">
                  <span className="text-warning">Loading... please wait!</span>
                </div>
              :
              <>
                <ul className="box-listing-frame" style={receivedBoxList && receivedBoxList.length === 0 ? {justifyContent:'center'}: {}}>
                  {receivedBoxList && receivedBoxList.length !== 0 ? 
                    ( receivedBoxList.slice(0, visibleReceivedBoxes).map((list: any) => {
                      creatorName = list && list.BoxRecipientsBTuserBoxes && list.BoxRecipientsBTuserBoxes.UserboxesBTuser  && list.BoxRecipientsBTuserBoxes.UserboxesBTuser.firstName && list.BoxRecipientsBTuserBoxes.UserboxesBTuser.firstName+' '+list.BoxRecipientsBTuserBoxes.UserboxesBTuser.lastName;
                      return (
                        <li key={list.id}>
                          <Link to={`/recieved-box/${list.boxId}`}>
                            <div className="image">
                              <MyImage src={list && list.BoxRecipientsBTuserBoxes && list.BoxRecipientsBTuserBoxes.coverPicture ? `${BASE_URLS.BASE_URL}/user-box-files/resource?key=${list.BoxRecipientsBTuserBoxes.coverPicture}` : defaultImg} onError={({ currentTarget }:{currentTarget:any}) => {currentTarget.onerror = null; currentTarget.src=defaultImg; }} alt="No Image"/>
                              {/* <img src={list && list.BoxRecipientsBTuserBoxes && list.BoxRecipientsBTuserBoxes.coverPicture ? `${BASE_URLS.BASE_URL}/user-box-files/resource?key=${list.BoxRecipientsBTuserBoxes.coverPicture}` : defaultImg} onError={({ currentTarget }) => {currentTarget.onerror = null; currentTarget.src=defaultImg; }} alt="No Image" /> */}
                            </div>
                            <Row className="pt-1">
                              <Col>
                                <label className="text-light" style={{whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", width: "100%"}}>To : <small className="title-txt text-warning">{list && list.BoxRecipientsBTuserBoxes && list.BoxRecipientsBTuserBoxes.boxName ? list.BoxRecipientsBTuserBoxes.boxName.toUpperCase() : "BOX-NAME"} </small></label>
                              </Col>
                            </Row>
                            <Row>  
                              <Col>
                                <label className="text-light"style={{whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", width: "100%"}} title={creatorName}>From : <small className="title-txt text-warning" title={creatorName}> {creatorName ? (creatorName.length > 15 ? creatorName.substring(0,15)+'...' : creatorName)  : "N/A"}</small></label>
                              </Col>
                            </Row>
                          </Link>
                        </li>
                      )})
                    ) 
                  : 
                    ( <div className="text-warning text-center">
                      {/* No box received yet... */}
                      </div> )
                  }
                </ul>
                <ul className="box-listing-frame" style={childReceivedBoxList && childReceivedBoxList.length === 0 ? {justifyContent:'center'}: {}}>
                  {childReceivedBoxList && childReceivedBoxList.length !== 0 ? 
                    ( childReceivedBoxList.slice(0, visibleChildReceivedBoxes).map((list: any) => {
                      creatorName = list && list.BoxRecipientsBTuserBoxes && list.BoxRecipientsBTuserBoxes.UserboxesBTuser  && list.BoxRecipientsBTuserBoxes.UserboxesBTuser.firstName && list.BoxRecipientsBTuserBoxes.UserboxesBTuser.firstName+' '+list.BoxRecipientsBTuserBoxes.UserboxesBTuser.lastName;
                      return (
                        <li>
                          <Link to={`/recieved-box/${list.boxesId}`}>
                            <div className="image">
                              <MyImage src={list && list.recipientImage ? `${BASE_URLS.BASE_URL}/user-box-files/resource?key=${list.recipientImage}` : defaultImg} onError={({ currentTarget }:{currentTarget:any}) => {currentTarget.onerror = null; currentTarget.src=defaultImg; }} alt="No Image" size="20px"/>
                            </div>
                            <Row className="pt-1">
                              <Col>
                                <label className="text-light" style={{whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", width: "100%"}}>To : <small className="title-txt text-warning">{list && list.BoxRecipientsBTuserBoxes && list.BoxRecipientsBTuserBoxes.boxName ? list.BoxRecipientsBTuserBoxes.boxName.toUpperCase() : "BOX-NAME"} </small></label>
                              </Col>
                            </Row>
                            <Row>  
                              <Col>
                                <label className="text-light"style={{whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", width: "100%"}} title={creatorName}>From : <small className="title-txt text-warning" title={creatorName}> {creatorName ? (creatorName.length > 15 ? creatorName.substring(0,15)+'...' : creatorName)  : "N/A"}</small></label>
                              </Col>
                            </Row>
                          </Link>
                          <label className="text-center text-warning">( Child Box )</label>
                        </li>
                      )})
                    ) 
                  : 
                    ( <div className="text-warning text-center">
                      {/* No box received yet... */}
                      </div> )
                  }
                </ul>
              </>
            }
          </div>
          <div className="text-center pt-3" style={ (visibleReceivedBoxes+visibleChildReceivedBoxes) > (Object.keys(receivedBoxList).length+Object.keys(childReceivedBoxList).length) || (visibleReceivedBoxes+visibleChildReceivedBoxes) == (Object.keys(receivedBoxList).length+Object.keys(childReceivedBoxList).length) ? { display: "none" } : { display: "block" } } >
            <Button className="loadMoreBtn" onClick={() => LoadMoreReceivedBoxes()}>
              LOAD MORE
            </Button>
          </div>
        </div>
      </Container>
    </div>
  </Layout>
)};

const mapDispatchToProps = (dispatch: any) => ({
  getBoxList              : (data: any) => dispatch(getBoxList(data)),
  getReceivedBoxList      : (data: any) => dispatch(getReceivedBoxList(data)),
  getChildReceivedBoxList : (data: any) => dispatch(getChildReceivedBoxList(data)),
  getBoxDetails           : (data: any) => dispatch(getBoxDetails(data)),
  getBoxById              : (data: any) => dispatch(getBoxById(data)),
  deleteBox               : (data: any) => dispatch(deleteBox(data)),
  boxPreview              : (data: any) => dispatch(listBoxContent(data)),
});

const mapStateToProps = (state: any) => {
  return {
    profileData              : (state.ProfilePageReducer && state.ProfilePageReducer.profileData)      ? state.ProfilePageReducer.profileData      : null,
    currentPlanDetailsData   : (state.PlansReducer       && state.PlansReducer.currentPlanDetailsData) ? state.PlansReducer.currentPlanDetailsData : null,
    boxListData              : (state.BoxReducer         && state.BoxReducer.boxListData)              ? state.BoxReducer.boxListData              : null,
    receivedBoxListData      : (state.BoxReducer         && state.BoxReducer.receivedBoxListData)      ? state.BoxReducer.receivedBoxListData      : null,
    childReceivedBoxListData : (state.BoxReducer         && state.BoxReducer.childReceivedBoxListData) ? state.BoxReducer.childReceivedBoxListData : null,
    createdBoxDetails        : (state.BoxReducer         && state.BoxReducer.createdBoxDetails)        ? state.BoxReducer.createdBoxDetails        : null,
    boxByIdData              : (state.BoxReducer         && state.BoxReducer.boxByIdData)              ? state.BoxReducer.boxByIdData              : null,
    deleteBoxData            : (state.BoxReducer         && state.BoxReducer.deleteBoxData)            ? state.BoxReducer.deleteBoxData            : null,
    listBoxDetails           : (state.BoxReducer         && state.BoxReducer.listBoxDetails)           ? state.BoxReducer.listBoxDetails           : null,
    userStorage              : (state.ProfilePageReducer && state.ProfilePageReducer.userStorage) ? state.ProfilePageReducer.userStorage           : null,    
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Home);
