import { useEffect, useRef, useState } from 'react';
import Carousel                from 'react-bootstrap/Carousel';
import CloseButton             from 'react-bootstrap/CloseButton'
import Layout                  from '../../layout/Layout';
import addBtn                  from '../../assets/images/addBtn.png';
import { connect }             from 'react-redux';
import { BASE_URLS, BOX_API }  from '../../redux/constant';
import { useNavigate, useParams }         from 'react-router-dom';
// import Swal                    from 'sweetalert2';
import { getBoxList }          from "../../redux/actions/boxAction";
import defaultImg              from "../../assets/images/no-image-available.png";
import defaultUserImg          from "../../assets/images/user-no-image.png";
import { Button, Form, Container, Row, Col,Stack,Card, Spinner } from 'react-bootstrap';
import MyImage                 from "../../layout/ImageComponent/myimage";
import { getBoxCover, getUserDetailsByName,uploadCoverPhotoByUser,clearCommoneMessage,getBoxDetails,updateBox,getUserDetailsByEmail,getRelationalData,newRecipientProfilePic } from '../../redux/actions/boxAction';
import { createBoxFormValidation,createBoxAddRecipientValidation } from '../../validations/createBox-form';
import DisplayModal from './uploaded_folder/display-modal';
import { listUserPeople } from "../../redux/actions/userAction";

interface REC {
    email            : string;
    ruserId          : string;
    relationId       : number;
    oldRecipientFlag : any;
}

interface NEWREC {
    recipientFname   : string;
    recipientLname   : string;
    recipientEmail   : string;
    relationId       : number;
    dob              : string;
    parentEmailId    : string;
    newRecipientFlag : any;
}

const UpdateBox = (props: any) => {
    const navTo = useNavigate();
    const { id }                                              = useParams();
    const [image,                  setImage]                  = useState({ preview: "", raw: "" });
    const [coverPhoto,             setCoverPhoto]             = useState<any[]>([]);
    const [activeIndex,            setActiveIndex]            = useState(0);
    // const [interval,            setInterval]               = useState<any>(3000);
    const [planDetails,            setPlanDetails]            = useState([]);
    const [isActive,               setIsActive]               = useState(0);
    const [isValidCover,           setIsValidCover]           = useState(false);
    const [selectCover,            setSelectCover]            = useState('');
    const [toggleValue,            setToggleValue]            = useState(false);
    // const [searchValue,         setSearchValue]            = useState({userId: '',userName:'',userEmail:'',userImage:''});
    const [isPublic,               setIsPublic]               = useState<boolean>(false);
    const [loader,                 setLoader]                 = useState<boolean>(false); 
    // const [searchResult,        setSearchResult]           = useState<any[]>([]);    
    const [uploadImageFlag,        setUploadImageFlag]        = useState<boolean>(false);
    const [adminImageFlag,         setAdminImageFlag]         = useState<boolean>(false);
    const [updateBoxProgress,      setUpdateBoxProgress]      = useState<boolean>(false);
    // const [searchLoader,        setSearchLoader]           = useState<boolean>(false);
    const [onClickFlag,            setOnClickFlag]            = useState<boolean>(false);
    const [spinner,                setSpinner]                = useState<boolean>(false);
    const [relationalData,         setRelationalData]         = useState<any[]>([]);
    const [internalRelation,       setIntRelation]            = useState<any>([{index:'',data:''}]);
    const [externalRelation,       setExtRelation]            = useState<any>([{index:'',data:''}]);
    // const [intRelationErr,      setIntRelationErr]         = useState('');
    const [counter,                setCounter]                = useState<boolean>(false);
    const [relationCounter,        setRelationCounter]        = useState<boolean>(false);
    const [modalShow,              setModalShow]              = useState<boolean>(false);
    const [addNewRespIntVisible,   setAddNewRespIntVisible]   = useState<boolean>(false);
    const [externalUsers,          setExternalUsers]          = useState([]);
    const [internalUsers,          setInternalUsers]          = useState([]);
    const [notification,           setNotification]           = useState({type:'',message:''});
    const [intCheckboxErr,         setIntCheckboxErr]         = useState<any[]>([]);
    // const [intRelationErr,         setIntRelationErr]         = useState<any[]>([]);
    const [extCheckboxErr,         setExtCheckboxErr]         = useState<any[]>([]);
    // const [extRelationErr,         setExtRelationErr]         = useState<any[]>([]);
    const [isVisible,              setIsVisible]              = useState<boolean>(false);
    const [textLoader,             setTextLoader]             = useState<boolean>(false);
    const [newRecpPicLoader,       setnewRecpPicLoader]       = useState<boolean>(false);
    const [recipientProfilePic,    setRecipientProfilePic]    = useState('');
    const [recipientProfilePicErr, setrecipientProfilePicErr] = useState('');
    const [childRecipient,         setChildRecipient]         = useState(false);
    const [newRecipientData,       setNewRecipientData]       = useState([] as NEWREC[]);
    const [oldRecipientData,       setOldRecipientData]       = useState<any[]>([{ id: '',email:'', username: '', profilePic: '' }]);
    const [createBoxForm,          setCreateBoxForm]          = useState({ email: '', boxPicture: '', boxName: "", boxDescription: "", newRecipientEmail: "", newRecipientFname: "", newRecipientLname: "",newRecipientDob:"",newRecipientRelation:0,oldRecipient_ruserId:'',life_work:"",quote:"",what_i_lived_for:"" })
    let   [errors,                 setErrors]                 = useState({ email: '', newRecipientFname: '', newRecipientLname: '',newRecipientDob: '',newRecipientRelation: '', newRecipientEmail: '', boxName: '', boxDescription: '',boxPicture:'',RelationalErr:'',commonErr:'' });
    let   [isValid,                setIsValid]                = useState(true);
    let   [maxRecipient,           setMaxRecipient]           = useState<number>(0);
    let   [newRecipientPayload,    setNewRecipientPayload]    = useState([] as NEWREC[]);
    let   [oldRecipientPayload,    setOldRecipientPayload]    = useState([] as REC[]);
    let   [selectedRecipient,      setSelectedRecipient]      = useState<any>({'internalRecipient':[],'externalRecipient':[]});
    let   [myPeopleErr,            setMyPeopleErr]            = useState({type:'',message:''});
    let   [myPeople,               setMyPeople]               = useState<any>([]);
    let   userData:any= {};
    let   hiddenFileInput : any;
    let   hiddenFileInputForNewRecipient : any;
    let   newRecipientFlag_inOldArr:any = [];
    let   oldRecipientFlag_inNewArr:any = [];
    let   newData:any = [];
    let   chileUnder18_ValidationMsg = 'Since you have selected a recipient who is below the age of 18, please provide the email address of a parent or guardian.'
    const loginData    = localStorage.getItem('userData');
          
    useEffect(() => {
        if(id){
            setLoader(true);
            props.getBoxCover();
            props.getBoxList();
            props.listUserPeople();
            props.getRelationalData();
            props.getBoxDetails(Number(id));
            props.clearData_checkedByEmail(null);
        }    
    }, [id])

    useEffect(() => {
        if (props.boxCoverData) {
            let coverPhotosArray_UploadedByAdmin = (props.boxCoverData).filter((coverPicture:any) => coverPicture.uploadedBy === 'Admin');
            if(coverPhotosArray_UploadedByAdmin){
                setCoverPhoto(coverPhotosArray_UploadedByAdmin);
            }
        }

        if(props.createdBoxDetails){
            let oldArr:any[] = []
            setSelectCover(props.createdBoxDetails.coverPicture);
            if(props.createdBoxDetails.isPublic === 1){ setToggleValue(true); }else{  setToggleValue(false); }
            setCreateBoxForm(
              {
                  ...createBoxForm,
                  boxPicture:props.createdBoxDetails.coverPicture,
                  boxName:props.createdBoxDetails.boxName,
                  boxDescription:(!props.createdBoxDetails.boxdescription ? '' : props.createdBoxDetails.boxdescription),
                  life_work:(props.createdBoxDetails.life_work === 0 || props.createdBoxDetails.life_work === "0" ? '' : props.createdBoxDetails.life_work),
                  quote:(props.createdBoxDetails.quote === 0 || props.createdBoxDetails.quote === "0" ? '' : props.createdBoxDetails.quote),
                  what_i_lived_for:(props.createdBoxDetails.what_i_lived_for === 0 || props.createdBoxDetails.what_i_lived_for === "0" ? '' : props.createdBoxDetails.what_i_lived_for),
              })
            if(props.createdBoxDetails.internalRecipients && props.createdBoxDetails.internalRecipients.length !== 0){
                props.createdBoxDetails.internalRecipients.map((item:any) =>{
                    if(item.oldRecipientFlag === 1){
                        let newValue = {
                                recipientImage   : (item.usersBoxRecipients && item.usersBoxRecipients.userImg),
                                recipientEmail   : (Number(item.relationId) !== 3 && Number(item.relationId) !== 0 && item.usersBoxRecipients && item.usersBoxRecipients.email ? item.usersBoxRecipients.email : ''),
                                recipientFname   : (item.usersBoxRecipients && item.usersBoxRecipients.firstName),
                                recipientLname   : (item.usersBoxRecipients && item.usersBoxRecipients.lastName),
                                relationId       : item.relationId,
                                dob              : (item.usersBoxRecipients && item.usersBoxRecipients.dob),
                                parentEmailId    : (Number(item.relationId) === 3 && Number(item.relationId) !== 0 && item.usersBoxRecipients && item.usersBoxRecipients.email ? item.usersBoxRecipients.email : ''),
                                newRecipientFlag : 1

                            }
                        newRecipientFlag_inOldArr.push(newValue);
                    }
                })
                oldArr = displayRegisteredUsers(props.createdBoxDetails.internalRecipients);
            }else{
              setOldRecipientData([{ id: '',email:'', username: '', profilePic: '' }]);
              setOldRecipientPayload([] as REC[]);
            }
    
            if(props.createdBoxDetails.externalRecipients && props.createdBoxDetails.externalRecipients.length !== 0){
                props.createdBoxDetails.externalRecipients.map((item:any) =>{
                    if(item.newRecipientFlag === 1){
                        let oldValue = {
                            id        : Number(item.id),
                            email     : (item.parentEmailId ? item.parentEmailId : (item.recipientEmail ? item.recipientEmail : '')), 
                            username  : (item.recipientFname+' '+item.recipientLname),
                            profilePic: item.recipientImage,
                            oldRecipientFlag : 1
                        }
                        oldRecipientFlag_inNewArr.push(oldValue)
                    }else{
                        newData.push(item);
                    }
                })
                setNewRecipientPayload(props.createdBoxDetails.externalRecipients);
            }else{
              setNewRecipientPayload([] as NEWREC[]);
            }
            
            setNewRecipientData([...newRecipientFlag_inOldArr,...newData]);
            setOldRecipientData([...oldArr,...oldRecipientFlag_inNewArr]);
          }
    }, [props.boxCoverData,props.createdBoxDetails])

    useEffect(() =>{
        if(coverPhoto){
            setTimeout(() =>{
                setLoader(false);
            },1000)
        }
    },[coverPhoto])

    useEffect(() =>{
        if(props.boxListData){
            let findPublicBox = (props.boxListData).filter((item:any) =>item.isPublic === 1);
            if(findPublicBox.length !== 0){
                setIsPublic(true);
            }else{
                setIsPublic(false);
            }
        }
    },[props.boxListData])

    useEffect(() => {
        if (props.userPeopleData && props.userPeopleData.length != 0) {
            setExternalUsers( props.userPeopleData && props.userPeopleData.externalRecipients );
            setInternalUsers( props.userPeopleData && props.userPeopleData.internalRecipients );
        }
    }, [props.userPeopleData]);

    useEffect(()=>{
        let mergedArray:any = [];
        if(externalUsers && internalUsers){
            externalUsers.map((item:any)=>{
                if(item.relationId != 3){
                    if(item.parentEmailId){
                        mergedArray.push(item.parentEmailId);
                    }else if(item.recipientEmail){
                        mergedArray.push(item.recipientEmail);
                    }
                }
            })

            internalUsers.map((item:any)=>{
                if(item.usersBoxRecipients && item.usersBoxRecipients.email && item.relationId != 3){
                    mergedArray.push(item.usersBoxRecipients.email);
                }
            })
        }else if(externalUsers && (internalUsers.length === 0)){
            externalUsers.map((item:any)=>{
                if(item.relationId != 3){
                    if(item.parentEmailId){
                        mergedArray.push(item.parentEmailId);
                    }else if(item.recipientEmail){
                        mergedArray.push(item.recipientEmail);
                    }
                }
            })
        }else if((externalUsers.length === 0) && internalUsers){
            internalUsers.map((item:any)=>{
                if(item.usersBoxRecipients && item.usersBoxRecipients.email && item.relationId != 3){
                    mergedArray.push(item.usersBoxRecipients.email);
                }
            })
        }
        setMyPeople(mergedArray);
    },[externalUsers,internalUsers])   


    useEffect(() =>{
        if(props.getRelationShipList && props.getRelationShipList.length !== 0){
            setRelationalData(props.getRelationShipList);
        }
    },[props.getRelationShipList])

    const displayRegisteredUsers = (e: any) => {
        let response = e && e;
        let recipientArr: any[] = [];
        let recipientPayloadArr: any[] = [];
        if (response) {
          response && response.map((element:any,index:any)=>{ 
            if(!element.oldRecipientFlag){
                recipientArr =
                    [...recipientArr,{
                        id         : element.usersBoxRecipients.id, 
                        email      : element.usersBoxRecipients.email,
                        username   : element.usersBoxRecipients.firstName+" "+element.usersBoxRecipients.lastName,
                        profilePic : element.usersBoxRecipients.userImg
                    }]
            }
            const userEmail = element.usersBoxRecipients.email;
            const userId    = element.usersBoxRecipients.id;
            const relId     = element.relationId;
            
            if(userId!==null) {
              recipientPayloadArr = [...recipientPayloadArr,{ruserId: userId, email: userEmail, relationId: relId }]
            }
          })
        }
        setOldRecipientPayload(recipientPayloadArr);
        return recipientArr;
      }

    useEffect(() => {
        if (props.currentPlanDetailsData) {
            let selectedPlan = checkActive(props.currentPlanDetailsData.planisActive);
            if(selectedPlan){
                setPlanDetails(props.currentPlanDetailsData);
                setMaxRecipient(props.currentPlanDetailsData && props.currentPlanDetailsData.boxPlans && props.currentPlanDetailsData.boxPlans.privateRecipients);/* ?props.currentPlanDetailsData.boxPlans.privateRecipients:0 */
                setIsActive(props.currentPlanDetailsData.planisActive)
            }
        }
    }, [props.currentPlanDetailsData])

    const handleSelect = (selectedIndex:number,e:any) =>{
        if(e && e.target.className === 'carousel-control-prev-icon'){
            if(activeIndex > 0){
                setActiveIndex(activeIndex-3);
            }
        }else if(e && e.target.className === 'carousel-control-next-icon'){
            if(((coverPhoto.length)%2 === 0 ? (selectedIndex > (coverPhoto.length)) : (selectedIndex >= ((coverPhoto.length)-3))) || (selectedIndex === 0)){
                setActiveIndex(0);    
            }else{
                setActiveIndex(selectedIndex+2);
            }
        }
    }
    
    const handleChange = (event: any) => {
        event.preventDefault();
        if( 
            event.target.name === 'newRecipientEmail' ||
            event.target.name === 'newRecipientFname' || 
            event.target.name === 'newRecipientLname' || 
            event.target.name === 'newRecipientDob'   || 
            event.target.name === 'newRecipientRelation'
            ){
                setCreateBoxForm({...createBoxForm, [event.target.name]: event.target.value})
                if(event.target.name === 'newRecipientEmail'){ 
                    setCounter(true);
                }
                if(event.target.name === 'newRecipientRelation'){
                    setRelationCounter(true);
                }
        }else{
            setErrors({...errors,boxName:''});
            setCreateBoxForm({...createBoxForm, [event.target.name]: event.target.value})
        }
    };
    
    useEffect(() =>{
        if(counter){
            setCounter(false);
            const emregex      = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            let e_mail         = createBoxForm.newRecipientEmail;
            const foundItem    = myPeople.find((item:any) => item.toLowerCase() === e_mail.toLowerCase());
            let loggedInEmail  = props.profileData && props.profileData.email;
            
                if(errors.commonErr === 'Maximum recipients selected' || errors.commonErr === 'Only one recipient is allowed in Free plan'){
                    setMyPeopleErr({type:'',message:''});
                    setErrors({...errors,newRecipientEmail:''});
                }else if(e_mail == ''){
                    setTextLoader(false);
                    setIsVisible(false);
                    setAddNewRespIntVisible(false);
                    setErrors({...errors,RelationalErr:'',commonErr:''});        
                    setCreateBoxForm({...createBoxForm,newRecipientFname:'',newRecipientLname:'',newRecipientDob:'',newRecipientRelation:0});
                    setMyPeopleErr({type:'',message:''});
                    setErrors({...errors,newRecipientEmail:'Email is required'});
                }else if(!emregex.test(e_mail)){
                    setTextLoader(false);
                    setIsVisible(false);
                    setAddNewRespIntVisible(false);
                    setErrors({...errors,RelationalErr:'',commonErr:''});
                    setCreateBoxForm({...createBoxForm,newRecipientFname:'',newRecipientLname:'',newRecipientDob:'',newRecipientRelation:0});
                    setMyPeopleErr({type:'',message:''});
                    setErrors({...errors,newRecipientEmail:'Enter a valid email address'});
                }else{
                    setErrors({...errors,newRecipientEmail:'',RelationalErr:'',commonErr:''});
                    let totalRecipient = (newRecipientPayload.length + oldRecipientPayload.length);
                    if(totalRecipient < maxRecipient){
                        if(e_mail){
                            if(loggedInEmail.toLowerCase() === e_mail.toLowerCase() && !childRecipient){
                                setIsVisible(false);
                                setAddNewRespIntVisible(false);
                                setrecipientProfilePicErr('');
                                setErrors({...errors,newRecipientEmail:'You can\'t use your personal email id',commonErr:''});
                            }else if(foundItem && !childRecipient){
                                setIsVisible(false);
                                setAddNewRespIntVisible(false);
                                setrecipientProfilePicErr('');
                                setErrors({...errors,newRecipientEmail:'User already exists in my people list',commonErr:''});
                            }else{
                                setErrors({...errors,newRecipientEmail:'',commonErr:''});
                                setTextLoader(true);
                                props.findByEmailId({"email":e_mail});
                                setIsVisible(false);
                                setAddNewRespIntVisible(false);
                            }
                        }else{
                            setIsVisible(false);
                        }
                    }else{
                        setMyPeopleErr({type:'',message:''});
                        if(props.currentPlanDetailsData && props.currentPlanDetailsData.planCode == 'FREE'){
                            setErrors({...errors,commonErr:'Only one recipient is allowed in Free plan',newRecipientEmail:''});
                        }else{
                            setErrors({...errors,commonErr:'Maximum recipients selected',newRecipientEmail:''});
                        }
                    }
                }
        }
    },[createBoxForm])

    useEffect(() =>{
        setTextLoader(false);
        if(props.checkUserByEmailId){
            if(props.checkUserByEmailId.length !== 0){
                setErrors({...errors,newRecipientEmail:''});
                setRecipientProfilePic(props.checkUserByEmailId && props.checkUserByEmailId[0] && props.checkUserByEmailId[0].userImg);
                setCreateBoxForm({...createBoxForm,
                    oldRecipient_ruserId:((props.checkUserByEmailId && props.checkUserByEmailId[0] && props.checkUserByEmailId[0].id)),
                    newRecipientFname:(props.checkUserByEmailId && props.checkUserByEmailId[0] && props.checkUserByEmailId[0].firstName), 
                    newRecipientLname:(props.checkUserByEmailId && props.checkUserByEmailId[0] && props.checkUserByEmailId[0].lastName), 
                    newRecipientDob  :(props.checkUserByEmailId && props.checkUserByEmailId[0] && props.checkUserByEmailId[0].dob), 
                });
                if(!childRecipient){
                    setIsVisible(false);
                    setAddNewRespIntVisible(true);
                }else if(childRecipient){
                    setIsVisible(true);
                    setAddNewRespIntVisible(false);
                    setRecipientProfilePic('');
                    setCreateBoxForm({...createBoxForm, newRecipientFname:'', newRecipientLname:'', newRecipientDob:'' ,newRecipientRelation:(childRecipient ? 3 : 0)})
                }
            }else{
                setIsVisible(true);
                setAddNewRespIntVisible(false);
                setRecipientProfilePic('');
                setCreateBoxForm({...createBoxForm, newRecipientFname:'', newRecipientLname:'', newRecipientDob:'' ,newRecipientRelation:(childRecipient ? 3 : 0)})
            }
        }else if(!props.checkUserByEmailId){
            setIsVisible(false)
        }
    },[props.checkUserByEmailId]);

    useEffect(() =>{
        if(relationCounter){
            setRelationCounter(false);
            const validationErrors = createBoxAddRecipientValidation(createBoxForm,childRecipient);
            isValid = Object.keys(validationErrors).length === 0;
            if(!isValid){
                setErrors(validationErrors);
            }else{
                setErrors(validationErrors);
            }
        }
    },[relationCounter])

    // const handleRecipientChange = () => {
    //     const validationErrors = createBoxAddRecipientValidation(createBoxForm,childRecipient);
    //     isValid = Object.keys(validationErrors).length === 0;
    //     if(createBoxForm.newRecipientEmail && (isVisible || addNewRespIntVisible)){
    //         if(!isValid && !recipientProfilePic && !addNewRespIntVisible){
    //             setErrors(validationErrors);
    //             setrecipientProfilePicErr('Profile image is required');
    //         }else if(!isValid && recipientProfilePic){
    //             setErrors(validationErrors);
    //             setrecipientProfilePicErr('');
    //         }else if(isValid && !recipientProfilePic && !addNewRespIntVisible){
    //             setErrors(validationErrors);
    //             setrecipientProfilePicErr('Image is required');
    //         }else{
    //             setOnClickFlag(true);
    //             setSpinner(true);
    //         }
    //     }else if(!isValid){
    //         setErrors(validationErrors);
    //         setrecipientProfilePicErr('Profile image is required');
    //     }
    // }

    const handleRecipientChange = () => {
        const validationErrors = createBoxAddRecipientValidation(createBoxForm,childRecipient);
        isValid = Object.keys(validationErrors).length === 0;
        
    if(createBoxForm.newRecipientEmail && (isVisible || addNewRespIntVisible)){
        if(!isValid && !addNewRespIntVisible){
            setErrors(validationErrors);
        }else if(!isValid && recipientProfilePic){
            setErrors(validationErrors);
        }else{
            setOnClickFlag(true);
            setSpinner(true);
        }
    }else if(!isValid){
        setErrors(validationErrors);
    }
}

    useEffect(() =>{
        if(onClickFlag){
            setOnClickFlag(false);
            setSpinner(false);
            
            const isPresent      = newRecipientData.find((p:NEWREC) => ((p.recipientEmail && createBoxForm.newRecipientEmail) && (p.recipientEmail === createBoxForm.newRecipientEmail) && !childRecipient));
            const isPresentInOld = oldRecipientData.find((p:any) => ((p.email && createBoxForm.newRecipientEmail) && (p.email === createBoxForm.newRecipientEmail)));
            const isloggedInUserEmailPresent = props.profileData.email;
        
            if(createBoxForm.newRecipientEmail !== isloggedInUserEmailPresent){
                if(isPresentInOld){
                    // Swal.fire('Duplicate not allowed','','warning')
                    setErrors({...errors,commonErr:'Duplicate not allowed'});
                }else if(!isPresent){
                    let findDuplicateChildren = externalUsers.filter(
                    (element:any) => 
                        (
                            (createBoxForm.newRecipientEmail == element.parentEmailId) && 
                            ((createBoxForm.newRecipientFname && createBoxForm.newRecipientFname) == (element.recipientFname && element.recipientFname)) &&
                            ((createBoxForm.newRecipientLname && createBoxForm.newRecipientLname) == (element.recipientLname && element.recipientLname)) &&
                            childRecipient 
                        ) 
                    )
                    if(findDuplicateChildren.length != 0){
                        setErrors({...errors,commonErr:'Child already exists in my people list'})
                    }else{
                        const total   = oldRecipientPayload.length + newRecipientPayload.length;
                        const selectedRelData = relationalData && relationalData.filter((item:any) => item.id === Number(createBoxForm.newRecipientRelation));
                            
                        if (total < maxRecipient) {
                            setErrors({...errors,newRecipientEmail:''});
                            setNewRecipientData((current: any) => [...current, 
                            { 
                                recipientImage : (recipientProfilePic ? recipientProfilePic : defaultImg),
                                recipientEmail : (Number(createBoxForm.newRecipientRelation) !== 3 && Number(createBoxForm.newRecipientRelation) !== 0 ? createBoxForm.newRecipientEmail.toLowerCase() : ''),
                                recipientFname : createBoxForm.newRecipientFname, 
                                recipientLname : createBoxForm.newRecipientLname, 
                                relationId     : (selectedRelData && selectedRelData[0] && selectedRelData[0].id ? selectedRelData[0].id : 0 ),
                                dob            : createBoxForm.newRecipientDob,
                                parentEmailId  : (Number(createBoxForm.newRecipientRelation) === 3 ? createBoxForm.newRecipientEmail.toLowerCase() : '')
                            }])

                            if(props.checkUserByEmailId && props.checkUserByEmailId.length != 0 && !childRecipient){

                                setOldRecipientPayload((current: any) => [...current,
                                    {   
                                        email: createBoxForm.newRecipientEmail,
                                        ruserId:createBoxForm.oldRecipient_ruserId,
                                        relationId:(selectedRelData && selectedRelData[0] && selectedRelData[0].id ? Number(selectedRelData[0].id) : 0 ),
                                        dob:createBoxForm.newRecipientDob,
                                        userImg:recipientProfilePic,
                                        oldRecipientFlag:1
                                    }])

                            }else{
                                setNewRecipientPayload((current: any) => [...current, 
                                    {   
                                        id             : '',
                                        recipientImage : (recipientProfilePic ? recipientProfilePic :''),
                                        recipientEmail : (Number(createBoxForm.newRecipientRelation) !== 3 && Number(createBoxForm.newRecipientRelation) !== 0 ? createBoxForm.newRecipientEmail.toLowerCase() : ''),
                                        recipientFname : createBoxForm.newRecipientFname,
                                        recipientLname : createBoxForm.newRecipientLname, 
                                        relationId     : (selectedRelData && selectedRelData[0] && selectedRelData[0].id ? selectedRelData[0].id : 0 ),
                                        dob            : createBoxForm.newRecipientDob,
                                        parentEmailId  : (Number(createBoxForm.newRecipientRelation) === 3 ? createBoxForm.newRecipientEmail.toLowerCase() : '')
                                    }
                                ])
                            }
                            
                            setChildRecipient(false);
                            setIsVisible(false);
                            setAddNewRespIntVisible(false);
                            setErrors({...errors,RelationalErr:'',newRecipientEmail:''});
                            setRecipientProfilePic('');
                            setrecipientProfilePicErr('');
                            setCreateBoxForm({...createBoxForm, newRecipientEmail:'',newRecipientFname:'', newRecipientLname:'', newRecipientDob:'', newRecipientRelation:0 });
                        } else {
                            setMyPeopleErr({type:'',message:''});
                            if(props.currentPlanDetailsData && props.currentPlanDetailsData.planCode == 'FREE'){
                                setErrors({...errors,commonErr:'Only one recipient is allowed in Free plan',newRecipientEmail:''});
                            }else{
                                setErrors({...errors,commonErr:'Maximum recipients selected'});
                            }
                        }
                    }
                }else{
                    setMyPeopleErr({type:'',message:''});
                    setErrors({...errors,commonErr:'Same email can\'t be added again'});
                }
            }else{
                setMyPeopleErr({type:'',message:''});
                setErrors({...errors,commonErr:'You can\'t use your personal email id'});
            }
        }
    },[onClickFlag])

    useEffect(() =>{
        if(!isVisible && !addNewRespIntVisible){
            setCreateBoxForm({...createBoxForm,newRecipientFname:'', newRecipientLname:'', newRecipientDob:'', newRecipientRelation:0 });
            setErrors({...errors,RelationalErr:'',commonErr:'',newRecipientFname:'',newRecipientLname:'',newRecipientDob:'',newRecipientRelation:''});
            setRecipientProfilePic('');
            props.clearRecpProfilePic_res(null);
        }
    },[isVisible,addNewRespIntVisible])
    
    let oldRecp = oldRecipientPayload.map(({ruserId,relationId,oldRecipientFlag}) => ({ruserId,relationId,oldRecipientFlag}));
    const newRecp = newRecipientPayload.map((obj:any) => {
        const { id, boxIsReceived,  boxesId, created, isRemoved, modified, recipientsBTrelation, removedAt, userCreated, ...rest  } = obj;
        return rest;
    });
    
    userData = {
        id,
        payload:{
            "userBoxes": {
                "boxName"          : createBoxForm.boxName,
                "boxDescription"   : createBoxForm.boxDescription,
                "coverPicture"     : createBoxForm.boxPicture,
                "isPublic"         : toggleValue && toggleValue === true ? 1 : 0,
                "life_work"        : toggleValue && toggleValue === true && createBoxForm.life_work,
                "quote"            : toggleValue && toggleValue === true && createBoxForm.quote,
                "what_i_lived_for" : toggleValue && toggleValue === true && createBoxForm.what_i_lived_for,
            },
            "userRecipients"       : toggleValue === false && oldRecp && oldRecp.length < 6 ? oldRecp : [],
            "recipients"           : toggleValue === false && newRecp && newRecp.length < 6 ? newRecp : []
        }
    }

    const setBoxCover = (e: any) => {
        if (e !== '') {
            setImage({preview:'',raw:''});
            setSelectCover(e);
            createBoxForm.boxPicture = e;
            setIsValidCover(true)
            setAdminImageFlag(true);
            setUploadImageFlag(false);
        }
    }

    const changeToggleValue = () => { setToggleValue(!toggleValue); }

    const checkActive = (e: any) => {
        if (!e) {
            navTo('/select-plans')
        }else{
            return true;
        }
    }

    const handleSubmit = (e:any) => {
        e.preventDefault();
        const validationErrors = createBoxFormValidation(createBoxForm);
        isValid = Object.keys(validationErrors).length === 0;
        if(!isValid){
            setErrors(validationErrors); 
        }else {
            const validationErrors = createBoxFormValidation(createBoxForm);
            setErrors(validationErrors);
            if(uploadImageFlag){
                setUpdateBoxProgress(true);
                const formData = new FormData();
                formData.append("imageFile",image.raw);
                props.uploadCoverPhoto(formData);
            }else{
                setUpdateBoxProgress(true);
                props.updateBox(userData);
            }
        }
    }

    useEffect(()=>{
        if(props.getUploadedCoverPhoto && createBoxForm.boxName){
            userData.payload.userBoxes.coverPicture = (props.getUploadedCoverPhoto && props.getUploadedCoverPhoto.imageFile);
            props.updateBox(userData)
        }
    },[props.getUploadedCoverPhoto])

    const handleUploadChange = (e:any) =>{
        if(e.target.name === 'recipientImage'){
            const formData = new FormData();
            formData.append("recipientImage",hiddenFileInputForNewRecipient.files[0]);
            props.newRecipientProfilePic(formData);
            setnewRecpPicLoader(true);
        }else{
            setUploadImageFlag(true);
            setAdminImageFlag(false);
            setImage({preview: URL.createObjectURL(hiddenFileInput.files[0]),raw: hiddenFileInput.files[0]})
            setSelectCover(hiddenFileInput.files[0]);
            createBoxForm.boxPicture = hiddenFileInput.files[0].name;
        }
    }

    useEffect(()=>{
        if(props.tempNewRecpPic){
            setnewRecpPicLoader(false);
            setRecipientProfilePic(props.tempNewRecpPic.imagePath);
        }else{
            setRecipientProfilePic('');
        }
    },[props.tempNewRecpPic])
        
    const handleOldRecipient = (e:any) =>{
        let removedId = e.target.value;
        setOldRecipientData(oldRecipientData.filter((itm:any) => itm.id != removedId));
        setOldRecipientPayload(oldRecipientPayload.filter((item:any) => item.ruserId !== removedId));
        setNewRecipientPayload(newRecipientPayload.filter((item:any) => Number(item.id) !== Number(removedId)));
    }
    
    const handleNewRecipient = (e:any) =>{
        let removedEmail = e.target.value;
        setNewRecipientData(newRecipientData.filter((item:any) => 
            {
                if (item.recipientEmail) {
                return (item.recipientEmail !== removedEmail)
                } else {
                return (item.parentEmailId !== removedEmail)
                }
            }
        ));
        setNewRecipientPayload(newRecipientPayload.filter((item:any) => 
            {
                if (item.recipientEmail) {
                return (item.recipientEmail !== removedEmail)
                } else {
                return (item.parentEmailId !== removedEmail)
                }
            }
        ));
        setOldRecipientPayload(oldRecipientPayload.filter((item:any) => item.email !== removedEmail));
    }

    // select recipient from My People---starts
    const handleSelectRecipient = (e:any) =>{
        let totalRecipient = (newRecipientPayload.length + oldRecipientPayload.length);
        
        if(totalRecipient < (maxRecipient)){
            setMyPeopleErr({type:'',message:''})  
            setModalShow(true);
        }else{
            if(props.currentPlanDetailsData && props.currentPlanDetailsData.planCode == 'FREE'){
                setMyPeopleErr({type:'danger',message:'Only one recipient is allowed in Free plan'});
            }else{
                setMyPeopleErr({type:'danger',message:'Maximum recipients selected!'});
            }
           setErrors({...errors,commonErr:''}); 
        }
    }

    const handleRecipientCheckbox = (event:any,details:any,type:string) =>{
        const { checked } = event.target;
        const { internalRecipient, externalRecipient } = selectedRecipient;
        
        if(checked){
            setNotification({type:'',message:''})
            if(type === 'internal'){
                let intRecipient:any = {
                    "id"         : details.id,
                    "ruserId"    : details.ruserId,
                    "relationId" : (details.relationId ? details.relationId : null),
                    "firstName"  : (details.usersBoxRecipients && details.usersBoxRecipients.firstName ? details.usersBoxRecipients.firstName : '' ),
                    "lastName"   : (details.usersBoxRecipients && details.usersBoxRecipients.lastName  ? details.usersBoxRecipients.lastName  : '' ),
                    "email"      : (details.usersBoxRecipients && details.usersBoxRecipients.email ? details.usersBoxRecipients.email : ''),
                    "userImg"    : (details.usersBoxRecipients && details.usersBoxRecipients.userImg ? details.usersBoxRecipients.userImg : ''),
                }
                internalRecipient.push(intRecipient);
            }

            if(type === 'external'){
                let extRecipient:any = {
                    "id"               : details.id,
                    "dob"              : details.dob,
                    "relationId"       : (details.relationId ? details.relationId : null),
                    "parentEmailId"    : (details.parentEmailId ? details.parentEmailId : ''),
                    "recipientEmail"   : (details.recipientEmail ? details.recipientEmail : ''),
                    "recipientFname"   : details.recipientFname,
                    "recipientLname"   : details.recipientLname,
                    "recipientImage"   : details.recipientImage,
                    "newRecipientFlag" : 1,
                }
                externalRecipient.push(extRecipient);
            }
        }else{
            if(type === 'internal'){
                let intRecp = internalRecipient.filter((item:any) => item.id !== details.id)
                internalRecipient.splice(0,internalRecipient.length,...intRecp);
            }

            if(type === 'external'){
                let extRecp = externalRecipient.filter((item:any) => Number(item.id) !== Number(details.id))
                externalRecipient.splice(0,externalRecipient.length,...extRecp);
            }
        }
        
        setSelectedRecipient({internalRecipient,externalRecipient});
    }
    
    const saveRecipientPopup = () =>{
        const {internalRecipient, externalRecipient} = selectedRecipient;
        let recipientTotalCount = (newRecipientPayload.length + oldRecipientPayload.length + internalRecipient.length + externalRecipient.length);
        
        if(recipientTotalCount < (maxRecipient+1)){
            setNotification({type:'',message:''})
            let selectDisplayRecipient:any = [];

            let oldData_intResult :any[] = [];
            let oldData_extResult :any[] = [];

            let newData_intResult :any[] = [];
            let newData_extResult :any[] = [];

            if(internalRecipient.length === 0 && externalRecipient.length === 0){
                setIntCheckboxErr([]);
                setExtCheckboxErr([]);
                setNotification({type:'danger',message:'Please select atleast one recipient before proceeding!'})
            }else{
                if(oldRecipientData && (oldRecipientData.length == 0)){
                    if(internalRecipient.length !== 0){
                        newData_intResult  = newRecipientData.filter((element:any) => { return internalRecipient.find((intItem:any) => (intItem.email === element.parentEmailId) || (intItem.email === element.recipientEmail))});
                    }

                    if(externalRecipient.length !== 0){
                        newData_extResult  = newRecipientData.filter((element:any) => { return externalRecipient.find((extItem:any) => (((extItem.parentEmailId && element.parentEmailId) && (extItem.parentEmailId === element.parentEmailId)) || ((extItem.recipientEmail && element.recipientEmail) && (extItem.recipientEmail ===  element.recipientEmail))))});
                    }
                                        
                    if((newData_intResult.length !== 0 || newData_extResult.length !== 0)){
                        setIntCheckboxErr(newData_intResult);
                        setExtCheckboxErr(newData_extResult);
                        setNotification({type:'danger',message:'Same recipient(s) can\'t be added again'})
                    }else{
                        setNotification({type:'',message:''});
                        if(internalRecipient && internalRecipient.length != 0){
                            internalRecipient.map((item:any) =>{
                                selectDisplayRecipient = [...selectDisplayRecipient,{id:item.ruserId,email:item.email,username:(item.firstName+' '+item.lastName),profilePic:item.userImg}]
                                const oldUserDetails = { email: item.email,ruserId:item.ruserId,relationId:Number(item.relationId) };
                                setOldRecipientPayload((current: any) => [...current, oldUserDetails]);
                            })
                        }
        
                        if(externalRecipient && externalRecipient.length != 0){
                            externalRecipient.map((item:any) =>{
                                selectDisplayRecipient = [...selectDisplayRecipient,{id:item.id,email:(item.parentEmailId ? item.parentEmailId : item.recipientEmail),username:(item.recipientFname+" "+item.recipientLname),profilePic:item.recipientImage}]
                                setNewRecipientPayload((current: any) => [...current, 
                                    {   
                                        id               : item.id,
                                        recipientEmail   : (item.recipientEmail ? item.recipientEmail : ''),
                                        recipientFname   : item.recipientFname, 
                                        recipientLname   : item.recipientLname, 
                                        relationId       : item.relationId,
                                        dob              : item.dob,
                                        parentEmailId    : (item.parentEmailId ? item.parentEmailId : ''),
                                        newRecipientFlag : item.newRecipientFlag
                                    }
                                ])
                            })
                        }
                        setOldRecipientData(selectDisplayRecipient);
                        setModalShow(false);
                        setSelectedRecipient({'internalRecipient':[],'externalRecipient':[]});
                        setIntCheckboxErr([]);
                        setExtCheckboxErr([]);
                        // setIntRelationErr([]);
                        // setExtRelationErr([]);
                        setIntRelation([{index:'',data:''}]);
                        setExtRelation([{index:'',data:''}]);
                    }
                }else
                if((oldRecipientData && ((oldRecipientData.length !== 0) && (oldRecipientData[0] && oldRecipientData[0].id && oldRecipientData[0].id !== '')))){
                    if(internalRecipient.length !== 0){
                            newData_intResult  = newRecipientData.filter((element:any) => { return internalRecipient.find((intItem:any) => (intItem.email === element.parentEmailId) || (intItem.email === element.recipientEmail))});
                            oldData_intResult  = oldRecipientData.filter((element:any) => { return internalRecipient.find((intItem:any) => intItem.email === element.email)});
                        }

                        if(externalRecipient.length !== 0){
                            newData_extResult  = newRecipientData.filter((element:any) => { return externalRecipient.find((extItem:any) => (((extItem.parentEmailId && element.parentEmailId) && (extItem.parentEmailId === element.parentEmailId)) || ((extItem.recipientEmail && element.recipientEmail) && (extItem.recipientEmail === element.recipientEmail))))});
                            oldData_extResult  = oldRecipientData.filter((element:any) => { return externalRecipient.find((extItem:any) => (((extItem.parentEmailId && element.email) && (extItem.parentEmailId === element.email)) || ((extItem.recipientEmail && element.email) && (extItem.recipientEmail === element.email))))});
                        }
                        
                        if((oldData_intResult.length !== 0 || oldData_extResult.length !== 0) || (newData_intResult.length !== 0 || newData_extResult.length !== 0)){
                            let intResult = [...oldData_intResult,...newData_intResult]
                            let extResult = [...oldData_extResult,...newData_extResult];
                            setIntCheckboxErr(intResult);
                            setExtCheckboxErr(extResult);
                            setNotification({type:'danger',message:'Same recipient(s) can\'t be added again'})
                        }else{
                            setNotification({type:'',message:''});
                            selectDisplayRecipient = [...oldRecipientData];
                            
                            if(internalRecipient && internalRecipient.length != 0){
                                internalRecipient.map((item:any) =>{
                                    selectDisplayRecipient = [...selectDisplayRecipient,{id:item.ruserId,email:item.email,username:(item.firstName+' '+item.lastName),profilePic:item.userImg}]
                                    const oldUserDetails = { email: item.email,ruserId:item.ruserId,relationId:Number(item.relationId) };
                                    setOldRecipientPayload((current: any) => [...current, oldUserDetails]);
                                })
                            }
            
                            if(externalRecipient && externalRecipient.length != 0){
                                externalRecipient.map((item:any) =>{
                                    selectDisplayRecipient = [...selectDisplayRecipient,{id:item.id,email:(item.parentEmailId ? item.parentEmailId : item.recipientEmail),username:(item.recipientFname+" "+item.recipientLname),profilePic:item.recipientImage}]
                                    setNewRecipientPayload((current: any) => [...current, 
                                        {   
                                            id               : item.id,
                                            recipientEmail   : (item.recipientEmail ? item.recipientEmail : ''),
                                            recipientFname   : item.recipientFname, 
                                            recipientLname   : item.recipientLname, 
                                            relationId       : item.relationId,
                                            dob              : item.dob,
                                            parentEmailId    : (item.parentEmailId ? item.parentEmailId : ''),
                                            newRecipientFlag : item.newRecipientFlag
                                        }
                                    ])
                                })
                            }
                            setOldRecipientData(selectDisplayRecipient);
                            setModalShow(false);
                            setSelectedRecipient({'internalRecipient':[],'externalRecipient':[]});
                            setIntCheckboxErr([]);
                            setExtCheckboxErr([]);
                            // setIntRelationErr([]);
                            // setExtRelationErr([]);
                            setIntRelation([{index:'',data:''}]);
                            setExtRelation([{index:'',data:''}]);
                        }
                    
                }else{
                    setNotification({type:'',message:''});
                    if(internalRecipient && internalRecipient.length != 0){
                        internalRecipient.map((item:any) =>{
                            selectDisplayRecipient = [...selectDisplayRecipient,{id:item.ruserId,email:item.email,username:(item.firstName+' '+item.lastName),profilePic:item.userImg}]
                            const oldUserDetails = { email: item.email,ruserId:item.ruserId,relationId:Number(item.relationId) };
                            setOldRecipientPayload((current: any) => [...current, oldUserDetails]);
                        })
                    }
    
                    if(externalRecipient && externalRecipient.length != 0){
                        externalRecipient.map((item:any) =>{
                            selectDisplayRecipient = [...selectDisplayRecipient,{id:item.id,email:(item.parentEmailId ? item.parentEmailId : item.recipientEmail),username:(item.recipientFname+" "+item.recipientLname),profilePic:item.recipientImage}]
                            setNewRecipientPayload((current: any) => [...current, 
                                {   
                                    id               : item.id,
                                    recipientEmail   : (item.recipientEmail ? item.recipientEmail : ''),
                                    recipientFname   : item.recipientFname, 
                                    recipientLname   : item.recipientLname, 
                                    relationId       : item.relationId,
                                    dob              : item.dob,
                                    parentEmailId    : (item.parentEmailId ? item.parentEmailId : ''),
                                    newRecipientFlag : item.newRecipientFlag
                                }
                            ])
                        })
                    }
                    setOldRecipientData(selectDisplayRecipient);
                    setModalShow(false);
                    setSelectedRecipient({'internalRecipient':[],'externalRecipient':[]});
                    setIntCheckboxErr([]);
                    setExtCheckboxErr([]);
                    // setIntRelationErr([]);
                    // setExtRelationErr([]);
                    setIntRelation([{index:'',data:''}]);
                    setExtRelation([{index:'',data:''}]);
                }
            }
        }else{
            setNotification({type:'danger',message:'Maximum recipient selection is exceeding!'})
        }
    }
    
    const closeRecipientPopup = () =>{
        setModalShow(false);
        setSelectedRecipient({'internalRecipient':[],'externalRecipient':[]});
        setNotification({type:'',message:''});
        setIntCheckboxErr([]);
        setExtCheckboxErr([]);
        // setIntRelationErr([]);
        // setExtRelationErr([]);
        setIntRelation([{index:'',data:''}]);
        setExtRelation([{index:'',data:''}]);
    }
    // select recipient from My People---ends

    useEffect(()=>{
        if(props.messages && props.messages.status == 'error'){
            setErrors(props.messages.data);
            setUpdateBoxProgress(false);
            localStorage.removeItem("message");
            navTo('/homepage',{ state: { message: props.messages.data, messageType:'danger'}});     
        }
        if(props.messages && props.messages.status == 'success' && createBoxForm.boxName){
            let boxId = (props.createBoxData && props.createBoxData.boxResult && props.createBoxData.boxResult.id ? props.createBoxData.boxResult.id : '');
            setTimeout(() =>{
                setUpdateBoxProgress(false);
                localStorage.removeItem("message");
                navTo(`/homepage`,{ state: { message: 'Box updated successfully',messageType:'success'}});
            },1000)
        }
    },[props.messages,props.createBoxData])

    useEffect(() =>{
        window.scrollTo({ top: 0,behavior: "smooth" });
    },[updateBoxProgress])

    const handleChildRecipientCheckbox = (e:any) =>{
        const { checked } = e.target;
        let totalRecipient = (newRecipientPayload.length + oldRecipientPayload.length);
        if(totalRecipient < maxRecipient){
            if(checked){
                setChildRecipient(true);
                setRecipientProfilePic('');
                setCreateBoxForm({...createBoxForm,newRecipientFname:'',newRecipientLname:'',newRecipientDob:'',newRecipientRelation:3})
                // setErrors({...errors,newRecipientEmail:'',RelationalErr:chileUnder18_ValidationMsg})
                setIsVisible(true)
                setAddNewRespIntVisible(false)
                setMyPeopleErr({type:'',message:''});
                setErrors({...errors,newRecipientRelation:'',newRecipientEmail:'',commonErr:''})
            }else{
                setChildRecipient(false);
                setErrors({...errors,RelationalErr:''})
                setrecipientProfilePicErr('')
                setIsVisible(false)
                setAddNewRespIntVisible(false)
                let e_mail = createBoxForm.newRecipientEmail;
                if(e_mail){
                    setCounter(true);
                }
            }
        }else{
            setMyPeopleErr({type:'',message:''});
            if(props.currentPlanDetailsData && props.currentPlanDetailsData.planCode == 'FREE'){
                setErrors({...errors,commonErr:'Only one recipient is allowed in Free plan',newRecipientEmail:''});
            }else{
                setErrors({...errors,commonErr:'Maximum recipients selected',newRecipientEmail:''});
            }
        }
    }
    
// minimum Age 18 or 18+
  var minimumAge18 = new Date();
  minimumAge18.setFullYear(minimumAge18.getFullYear() - 18 );

  var minorAgeMaximum = new Date();
  minorAgeMaximum.setFullYear(minorAgeMaximum.getFullYear());
// minimum Age 18 or 18+  

useEffect(() =>{
    if(errors.boxPicture === 'Cover picture not selected'){
        window.scrollTo({ top: 350,behavior: "smooth" });
    }else 
    if(errors.boxName === 'Box name is required'){
        window.scrollTo({ top: 450,behavior: "smooth" });
    }
},[errors])

useEffect(() =>{
    if(recipientProfilePic){
        setrecipientProfilePicErr('');
    }
    },[recipientProfilePic])
    
    useEffect(() =>{
    if(errors.newRecipientEmail == 'User already exists in my people list' && !childRecipient){
        setIsVisible(false);
        setAddNewRespIntVisible(false);
    }
    },[textLoader])
    
//--------------------------------------------------------------------//

    return (
        <Layout>
            <Container className={updateBoxProgress ? 'text-center' : ''}>
                {updateBoxProgress ?
                    <div className="lds-dual-ring text-center">
                        <span className="text-warning">Updating box... please wait!</span>
                    </div>
                :    
                <div className="myProfile relationShip p-5" style={{background: "linear-gradient(268.67deg, #1A202E 1.01%, rgba(26, 32, 46, 0.97) 78.02%, rgba(26, 32, 46, 0.65) 98.78%)"}}>
                    <Container className={loader ? 'text-center' : ''}>
                        <div className="row justify-content-end mb-4">
                            <div className="col-auto">
                                <Button className="backBtn" onClick={() => navTo(-1)}>Back</Button>
                            </div>
                        </div>
                        <h3 className="imgDiv" style={loader ? {display:'flex',justifyContent:'start'}:{}}>Update Box&nbsp;
                        <span style={{fontSize:'12px',color:'#E79E1F'}}><b>( {props.createdBoxDetails && props.createdBoxDetails.isPublic && props.createdBoxDetails.isPublic === 1 ? 'Public Box' : 'Private Box'} )</b></span>
                            {!loader &&
                                <>
                                    <Row>
                                        <Col>
                                            <Row>
                                                <small>
                                                    <img height={120} width={120} className='carousel_img' src={image.preview && selectCover ? image.preview : (selectCover && !image.preview ? `${BASE_URLS.BASE_URL}/user-box-files/resource?key=${selectCover}` : defaultImg)} alt='No Image' style={{float:'right'}}/>
                                                </small>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Form.Label style={{float:"right",marginRight: "25px"}}>
                                                        <span className='pointer'>
                                                            <u className='text-light' style={{fontSize:"13px"}}>Upload</u>
                                                            <input type="file" style={{display:'none'}} onChange={handleUploadChange} ref={refParam => hiddenFileInput = refParam} accept="image/png, image/jpeg, image/jpg, image/gif, image/webp"/>
                                                        </span>
                                                    </Form.Label>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </>
                            }                       
                        </h3><br/>
                        {loader ? 
                            <div className="lds-dual-ring text-center">
                                <span className="text-warning">Loading... please wait!</span>
                            </div>
                        :
                        <Form>
                            <Form.Group className="formRow mb-5">
                                <Row>
                                    <Col md={8}>
                                        <Form.Label>Select box cover from ( <span className='text-light'>&#8681;</span> )</Form.Label>&nbsp;
                                    </Col>
                                    {loginData &&
                                        <Col md='auto'>
                                            <div className='text-light' style={{fontSize:'14px'}}>Image Credit&nbsp;:&nbsp; NASA, STScl</div>
                                        </Col>
                                    }
                                </Row>
                                    <Carousel data-interval="false" data-ride="carousel" data-pause="hover" activeIndex={activeIndex} /*interval={interval}*/ indicators={true} onSelect={handleSelect}>
                                        {coverPhoto && coverPhoto.map((cover: any,index:any) => {
                                            return(
                                                <Carousel.Item key={index}>
                                                    <Stack direction="horizontal" className="h-100 justify-content-center align-items-center" gap={3} >
                                                        {coverPhoto[index] ?
                                                            <Card className='carousel_img' style={{ border: (selectCover == coverPhoto[index].imageFile) ? '5px solid #fff' : 'none' ,cursor:"pointer"}}>
                                                                <img
                                                                    className="d-block createBoxImg m-auto"
                                                                    src={(cover && coverPhoto[index].imageFile) ? `${BASE_URLS.BASE_URL}/user-box-files/resource?key=${coverPhoto[index].imageFile}` : ''}
                                                                    onClick={() => setBoxCover(coverPhoto[index].imageFile)}
                                                                />
                                                            </Card>
                                                        :''}
                                                        {coverPhoto[index] && coverPhoto[index+1] ?
                                                            <Card className='carousel_img' style={{ border: (selectCover == coverPhoto[index+1].imageFile) ? '5px solid #fff' : 'none' ,cursor:"pointer"}}>
                                                                <img
                                                                    className="d-block createBoxImg m-auto"
                                                                    src={(Object.values(coverPhoto[index+1]) && Object.values(coverPhoto[index+1])[1]) ? `${BASE_URLS.BASE_URL}/user-box-files/resource?key=${coverPhoto[index+1].imageFile}` : ''}
                                                                    onClick={() => setBoxCover(coverPhoto[index+1].imageFile)}
                                                                />
                                                            </Card>
                                                        :''}
                                                        {coverPhoto[index] && coverPhoto[index+2] ?
                                                            <Card className='carousel_img' style={{ border: (selectCover == coverPhoto[index+2].imageFile) ? '5px solid #fff' : 'none' ,cursor:"pointer"}}>
                                                                <img
                                                                    className="d-block createBoxImg m-auto"
                                                                    src={ coverPhoto[index+2] && coverPhoto[index+2].imageFile ? `${BASE_URLS.BASE_URL}/user-box-files/resource?key=${coverPhoto[index+2].imageFile}` : ''}
                                                                    onClick={() => setBoxCover(coverPhoto[index+2].imageFile)}
                                                                />
                                                            </Card>
                                                        :''}
                                                    </Stack>
                                                </Carousel.Item>
                                            )})}
                                        </Carousel>
                                    </Form.Group>
                                    {!selectCover && errors.boxPicture && ( <div className="text-danger text-center">{errors.boxPicture}</div>)}
                                    
                                    <Form.Group className={'formRow pt-4'}>
                                        <Form.Label>Box Name*</Form.Label>
                                        <Form.Control type="text" name="boxName" id='boxName' placeholder="Please enter box name here..." value={createBoxForm.boxName} onChange={handleChange} />
                                        {(errors.boxName) && <span className="text-danger">{errors.boxName}</span>}
                                    </Form.Group>
                                    <Form.Group className="formRow">
                                        <Form.Label>Box Description</Form.Label>
                                        <Form.Control as="textarea" rows={3} name='boxDescription' id='boxDescription' placeholder="Please enter box description here..." value={createBoxForm.boxDescription} onChange={handleChange} style={{height:"auto"}}/>
                                        {(errors.boxDescription) && <span className="text-danger">{errors.boxDescription}</span>}
                                    </Form.Group>

                                    {(!toggleValue) ? 
                                        <div>
                                            <Form.Group className="formRow">
                                                <Form.Label>Add New Recipient</Form.Label>
                                                    <div className='pb-3'>
                                                        <input className='pointer' type="checkbox" style={{transform: "scale(1.5)"}} checked={childRecipient?true:false} onChange={handleChildRecipientCheckbox}/>&nbsp;&nbsp;
                                                        <Form.Label style={{marginBottom:"0"}}>Child Recipient</Form.Label>
                                                        <small className='d-block text-light'>
                                                        Check this box if you are adding a recipient under the age of 18. You must add the email address of the parent or guardian who will recieve this box on their behalf.
                                                        </small>
                                                    </div>
                                                    <Row className="userProfilePic align-items-start align-items-md-center justify-content-center ms-auto">
                                                        <Col>
                                                            {newRecipientData && newRecipientData.slice(0, 6).map((item: any) => {
                                                                if (item.recipientFname) {
                                                                    const relation = relationalData.filter((itm:any) => itm.id === item.relationId)
                                                                    
                                                                    return (
                                                                        <div className='addNewRecipent'>
                                                                            <Row className="addNewRecipentList userProfilePic align-items-start align-items-md-center" style={{ border:'3px solid #E79E1F',margin:"0px 15px 10px -11px",borderRadius:'10px', maxWidth:'94%',padding:'10px 0px 10px 0px' }}>
                                                                                <Row>
                                                                                <Col xs="auto" className='text-center'>
                                                                                    {item && item.recipientImage ?
                                                                                        <img className='rounded' height={50} src={(item && item.recipientImage) ? `${BASE_URLS.BASE_URL}/user-box-files/resource?key=${item.recipientImage}` : defaultUserImg} onError={({ currentTarget } : { currentTarget:any }) => {currentTarget.onerror = null; currentTarget.src=defaultUserImg; }} alt="No Image" />
                                                                                    :
                                                                                        <img src={defaultUserImg} alt="default-image" className='rounded' height={50}/>
                                                                                    }
                                                                                </Col>            
                                                                                <Col xs="auto" className="text-center pt-3">{(item.recipientFname ? item.recipientFname : '')+' '+(item.recipientLname ? item.recipientLname : '')}</Col>
                                                                                <Col xs="auto" className="text-center pt-3">{item.dob}</Col>
                                                                                <Col xs="auto" className="text-center pt-3">{relation[0] && relation[0].relationName ? relation[0].relationName : ''}</Col>
                                                                                <Col xs="auto" className="text-center pt-3 text-break">{Number(item.relationId) === 3 ? item.parentEmailId : item.recipientEmail}</Col>
                                                                                <Col xs="auto" className='ms-auto pt-3 text-center'>
                                                                                    <CloseButton type="button" className="btn-close" style={{top:"1px", right: "0"}} aria-label="Close" value={item.recipientEmail ? item.recipientEmail : item.parentEmailId} onClick={handleNewRecipient}></CloseButton>
                                                                                </Col>
                                                                                </Row>
                                                                            </Row>
                                                                        </div>
                                                                    )
                                                                }
                                                            })}
                                                        </Col>
                                                    </Row>
                                                    <Row className='justify-content-center'>
                                                        <Col xs={12} md={3} lg={1} className='px-1'>
                                                            <Form.Label>
                                                                {newRecpPicLoader ? 
                                                                    <>
                                                                        <Spinner style={{margin: "10px 0px 0px 25px"}}/>
                                                                        <small style={{fontSize:"15px",margin: "0px 0px 0px 15px"}}>Loading...</small>
                                                                    </>
                                                                :
                                                                    <span style={isVisible && !addNewRespIntVisible ? {cursor:`pointer`} : (!recipientProfilePic ? {cursor:`not-allowed`,opacity:"0.5"} :{cursor:`not-allowed`} )}>
                                                                        <img height={50} width={80} className='carousel_img' src={recipientProfilePic ? `${BASE_URLS.BASE_URL}/user-box-files/resource?key=${recipientProfilePic}` : defaultImg} onError={({ currentTarget } : { currentTarget:any }) => {currentTarget.onerror = null; currentTarget.src=defaultImg; }} alt='No Image'/>
                                                                        {isVisible && !addNewRespIntVisible &&
                                                                            <input type="file" name="recipientImage" style={{display:'none'}} onChange={handleUploadChange} ref={refParam => hiddenFileInputForNewRecipient = refParam} accept="image/png, image/jpeg, image/jpg, image/gif, image/webp"/>
                                                                        }
                                                                    </span>
                                                                }
                                                            </Form.Label>
                                                            {(isVisible && !addNewRespIntVisible && recipientProfilePicErr) && <span className="text-danger">{recipientProfilePicErr}</span>}
                                                        </Col> 
                                                        <Col xs={12} md={3} lg={2} className="text-center px-1" key={6}>
                                                            <Form.Control autoComplete='off' type="text" id='emailAddr' placeholder={createBoxForm.newRecipientRelation && Number(createBoxForm.newRecipientRelation) === 3 || childRecipient ? `Parent email`:`Email address`} name="newRecipientEmail" value={createBoxForm.newRecipientEmail} onChange={handleChange} style={{textTransform:"lowercase"}}/>
                                                            {textLoader ? <span className='text-warning text-center'>Validating email.<span className='blink'>..</span><Spinner style={{fontSize:"10px",height:'10px',width:'10px'}}/><br/></span> : ''}
                                                            {(errors.newRecipientEmail) && <span className="text-danger">{errors.newRecipientEmail}</span>}
                                                        </Col>
                                                        <Col className="order-first order-md-last col-auto px-1" key={1}>
                                                            <button type='button' className="bg-transparent border-0" onClick={handleRecipientChange} disabled={(addNewRespIntVisible || isVisible) ? false : true} style={(addNewRespIntVisible || isVisible) ? {} : { cursor: "not-allowed",opacity:"0.5"}}>
                                                                {!spinner ?
                                                                    <img src={addBtn} height={50} width={50}/>
                                                                :
                                                                    <div style={{fontSize:"10px",color:"#E79E1F"}}>
                                                                        <Spinner/><br/><small>Loading...</small>
                                                                    </div>
                                                                }
                                                            </button>
                                                        </Col>
                                                        <Col xs={12} md={3} lg={2} className="text-center px-1" key={2}>
                                                            <Form.Control autoComplete='off' type="text" id='Fname' placeholder="First name " name="newRecipientFname" value={createBoxForm.newRecipientFname} onChange={handleChange} disabled={isVisible ? false : true} style={isVisible ? {} : { color:'lightgrey', cursor: "not-allowed",backgroundColor:'grey'}}/>
                                                            {(errors.newRecipientFname) && <span className="text-danger">{errors.newRecipientFname}</span>}
                                                        </Col>
                                                        <Col xs={12} md={3} lg={2} className="text-center px-1" key={3}>
                                                            <Form.Control autoComplete='off' type="text" id='Lname' placeholder="Last name" name="newRecipientLname" value={createBoxForm.newRecipientLname} onChange={handleChange} disabled={isVisible ? false : true} style={isVisible ? {} : { color:'lightgrey', cursor: "not-allowed",backgroundColor:'grey'}}/>
                                                            {(errors.newRecipientLname) && <span className="text-danger">{errors.newRecipientLname}</span>}
                                                        </Col>
                                                        <Col xs={12} md={3} lg={2} className="text-center px-1" key={4}>
                                                            {childRecipient ? 
                                                                <Form.Control autoComplete='off' type="date" id='dob' onKeyDown={(e) => e.preventDefault()} placeholder="dd/mm/yyyy" min={minimumAge18.toISOString().substring(0,10)} max={minorAgeMaximum.toISOString().substring(0,10)} name="newRecipientDob" value={createBoxForm.newRecipientDob} className={createBoxForm.newRecipientDob && !isVisible ? `date-input--has-value-int` : (createBoxForm.newRecipientDob && isVisible ? `date-input--has-value` :  ``)} onChange={handleChange} disabled={isVisible ? false : true} style={isVisible ? {} : { cursor: "not-allowed",backgroundColor:'grey'}}/>
                                                            :
                                                                <Form.Control autoComplete='off' type="date" id='dob' onKeyDown={(e) => e.preventDefault()} placeholder="dd/mm/yyyy" max={minimumAge18.toISOString().substring(0,10)} name="newRecipientDob" value={createBoxForm.newRecipientDob} className={createBoxForm.newRecipientDob && !isVisible ? `date-input--has-value-int` : (createBoxForm.newRecipientDob && isVisible ? `date-input--has-value` :  ``)} onChange={handleChange} disabled={isVisible ? false : true} style={isVisible ? {} : { cursor: "not-allowed",backgroundColor:'grey'}}/>
                                                            }
                                                            {(errors.newRecipientDob) && <span className="text-danger">{errors.newRecipientDob}</span>}
                                                        </Col>
                                                        <Col xs={12} md={3} lg={2} className="text-center px-1" key={5}>
                                                            {childRecipient ? 
                                                                <Form.Select name="newRecipientRelation" value={createBoxForm.newRecipientRelation} onChange={handleChange} disabled={(childRecipient)? true : false} style={childRecipient ? { color:'#000', cursor: "not-allowed",backgroundColor:'grey'} : {}}>
                                                                    {relationalData && relationalData.map((relation: any) => (
                                                                        <>
                                                                            { relation.id == 3 &&
                                                                                <option value={relation.id} key={relation.id}>
                                                                                    {relation.relationName}
                                                                                </option>
                                                                            }
                                                                        </>))
                                                                    }
                                                                </Form.Select>    
                                                            :    
                                                                <Form.Select name="newRecipientRelation" value={createBoxForm.newRecipientRelation} onChange={handleChange} className={createBoxForm.newRecipientRelation && (createBoxForm.newRecipientRelation != 0) ? `extRel--has-value`: ``} disabled={(addNewRespIntVisible || isVisible)? false : true} style={(addNewRespIntVisible || isVisible) ? {} : { color:'lightgrey', cursor: "not-allowed",backgroundColor:'grey'}}>
                                                                    <option value="0">Select relation</option>
                                                                    {relationalData && relationalData.map((relation: any) => (
                                                                        <>
                                                                            { relation.id !== 3 &&
                                                                                <option value={relation.id} key={relation.id}>
                                                                                    {relation.relationName}
                                                                                </option>
                                                                            }
                                                                        </>))
                                                                    }
                                                                </Form.Select>    
                                                            }
                                                            {(errors.newRecipientRelation) && <span className="text-danger">{errors.newRecipientRelation}</span>}
                                                        </Col>
                                                    </Row>
                                                <Row className="userProfilePic align-items-start align-items-md-center">
                                                    {errors.RelationalErr && <Col md={12} ><span className="text-warning">{errors.RelationalErr}</span></Col>}
                                                    {errors.commonErr     && <Col md={12} className='text-center'><span className="text-danger"> {errors.commonErr}    </span></Col>}
                                                </Row>
                                            </Form.Group>
                                            <br/>
                                            <Form.Group className="formRow">
                                                <Row>
                                                    <Col md={2} className='mt-3'>
                                                        <Form.Label>
                                                            Select Recipient  
                                                        </Form.Label>
                                                    </Col>
                                                    <Col md={4} className='px-1 text-left'>
                                                        <Button className='backBtn pb-3' style={{margin:"10px 5px 0px",height:"10%",textTransform:"none"}} onClick={handleSelectRecipient}>{('My').charAt(0).toUpperCase() + ('My').slice(1)+' '+('People').charAt(0).toUpperCase() + ('People').slice(1)}</Button>
                                                    </Col>
                                                    {myPeopleErr && myPeopleErr.message &&
                                                    <Col md={6} className='ms-auto mt-2'><span className={`text-`+myPeopleErr.type}>{myPeopleErr.message}</span></Col>
                                                    }
                                                </Row>
                                            </Form.Group>
                                            <Row className="userProfilePic">
                                                {oldRecipientData && oldRecipientData.slice(0, 6).map((item: any, index:any) => {
                                                    if (item.id !== "") {
                                                        return (
                                                            <Col xs="auto" className="text-center me-3" style={{margin: "0px 0px 0px 20px"}}>
                                                                <Card style={{right:"15px",backgroundColor: "transparent",border: "none"}}>
                                                                    <CloseButton type="button" className="btn-close float-right" style={{right: "-145px",top:"20px",backgroundColor:'#E79E1F',color:"#fff",padding:"6px"}} aria-label="Close" value={item.id} onClick={handleOldRecipient}></CloseButton>
                                                                    <div className="pic">
                                                                        <div key={item.id}>
                                                                            {item && item.profilePic ?
                                                                                <MyImage src={(item && item.profilePic) ? `${BASE_URLS.BASE_URL}/user-box-files/resource?key=${item.profilePic}` : defaultImg} onError={({ currentTarget } : { currentTarget:any }) => {currentTarget.onerror = null; currentTarget.src=defaultImg; }} alt="No Image" />
                                                                            :
                                                                                <img src={defaultImg} alt="default-image"/>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <Card.Title title={item.username} className='pic' style={{whiteSpace: "nowrap",overflow: "hidden",textOverflow: "ellipsis",height:"25px",cursor:"pointer"}}>{item.username}</Card.Title>
                                                                </Card>        
                                                            </Col>
                                                        )
                                                    }
                                                })}
                                            </Row><br/> 
                                        </div> 
                                    :
                                        <>
                                            <hr className="mt-3" style={{border:'0.5px solid #E79E1F'}}/>    
                                            <Form.Group className="formRow">
                                                <Form.Label style={{fontWeight: '700', textTransform: 'uppercase'}}>Public Box Info</Form.Label>
                                                <div><small className="formRow text-center" style={{fontSize:'20px', fontWeight: '700',  color: '#E79E1F'}}>The information below is optional and will be displayed in your Public Box, viewable on the BeyondBox Box Records page and our social media accounts.</small></div>
                                            </Form.Group>
                                            <Form.Group className="formRow">
                                                <Form.Label>Life’s work</Form.Label>
                                                <Form.Control as="textarea" name="life_work" id="editProfile_lifesWork" rows={3} placeholder="Optional info for Box Records Profile" onChange={handleChange} value={createBoxForm && createBoxForm.life_work ? createBoxForm.life_work : ''} style={{ height: "auto" }} />
                                            </Form.Group>
                                            <Form.Group className="formRow">
                                                <Form.Label>Quote</Form.Label>
                                                <Form.Control as="textarea" name="quote" id="editProfile_quote" rows={3} placeholder="Optional info for Box Records Profile" onChange={handleChange} value={createBoxForm && createBoxForm.quote ? createBoxForm.quote : ''} style={{ height: "auto" }} />
                                            </Form.Group>
                                            <Form.Group className="formRow">
                                                <Form.Label>What I lived for</Form.Label>
                                                <Form.Control as="textarea" name="what_i_lived_for" id="editProfile_whatILivedFor" rows={3} placeholder="Optional info for Box Records Profile" onChange={handleChange} value={createBoxForm && createBoxForm.what_i_lived_for ? createBoxForm.what_i_lived_for : ''} style={{ height: "auto" }} />
                                            </Form.Group>
                                        </>    
                                    }
                                    {props.message && <div className={(props.message && props.message.status === 'success') ? 'text-success' : 'text-danger'}>{props.message.data}</div>}
                                    <Button className="yellowBtn" type="submit" onClick={handleSubmit}>Update</Button>
                                </Form>
                                } 

                                {/* RECIPIENT POPUP MODAL STARTS FROM HERE*/}
                                    <DisplayModal backdrop="static" show={modalShow} onHide={closeRecipientPopup} name='Select Recipient(s) from My People'>
                                        <hr style={{border:'0.5px solid #E79E1F'}}/>
                                        <div className='recipientScrollbar' style={(internalUsers && externalUsers) && (internalUsers.length !== 0 && externalUsers.length !== 0) ? ((internalUsers.length + externalUsers.length) > 3 ? {overflowY:"scroll"} :{}): ((internalUsers && !externalUsers) && (internalUsers.length > 3) ? {overflowY:"auto"} : ((externalUsers && !internalUsers) && (externalUsers.length > 3) ? {overflowY:"auto"} : {}))}>
                                        {
                                            (internalUsers && internalUsers.length !== 0 || externalUsers&&externalUsers.length !== 0) ?
                                            <>
                                            {externalUsers && externalUsers.length !== 0 &&
                                                <Container>
                                                    {externalUsers.map((data: any) => {
                                                        let details = data;
                                                        
                                                        return (
                                                            <>
                                                                <Row className='recipientList pt-2 pb-2 align-items-center' style={(extCheckboxErr && extCheckboxErr.length != 0 && extCheckboxErr.find((item:any) =>(((item.parentEmailId && item.parentEmailId === details.parentEmailId && details.parentEmailId) || (item.recipientEmail && item.recipientEmail === details.recipientEmail && details.recipientEmail)) || ((item.email && item.email === details.parentEmailId && details.parentEmailId) || (item.email && item.email === details.recipientEmail && details.recipientEmail))))) ? {border:"3px solid red",color:"#fff",background:"#0B1320"} : {color:"#fff",background:"#0B1320"}}> 
                                                                    <Col md={1}>
                                                                        {details && details.recipientImage ?
                                                                            <img height={50} width={100} src={(details && details.recipientImage) ? `${BASE_URLS.BASE_URL}/user-box-files/resource?key=${details.recipientImage}` : defaultUserImg} style={{borderRadius:"15px"}} onError={({ currentTarget } : { currentTarget:any }) => {currentTarget.onerror = null; currentTarget.src=defaultUserImg; }} />
                                                                        :
                                                                            <img src={defaultUserImg} alt="default-image" style={{borderRadius:"5px"}} height={50}/>
                                                                        }
                                                                    </Col>
                                                                    <Col md={3}><label className='pt-2' style={{textAlign:'center',fontSize:"20px"}}>{(details.recipientFname && details.recipientLname ? details.recipientFname : '')+" "+(details && details.recipientLname ? details.recipientLname : '')}</label></Col>
                                                                    { details.parentEmailId ?
                                                                        <Col md={4}><label title={details.parentEmailId && details.parentEmailId.length > 21 ? details.parentEmailId : ''} className='pt-2' style={{textAlign:'center',fontSize:"20px" }}>{details && details.parentEmailId ? (details.parentEmailId.length > 21 ? details.parentEmailId.substring(0,21)+'...' : details.parentEmailId) : 'N/A'}</label></Col>
                                                                    : details.recipientEmail &&
                                                                        <Col md={4}><label title={details.recipientEmail && details.recipientEmail.length > 21 ? details.recipientEmail : ''} className='pt-2' style={{textAlign:'center',fontSize:"20px" }}>{details && details.recipientEmail ? (details.recipientEmail.length > 21 ? details.recipientEmail.substring(0,21)+'...' : details.recipientEmail) : 'N/A'}</label></Col>
                                                                    }
                                                                    <Col md={3}><label className='pt-2' style={{textAlign:'center',fontSize:"20px"}}>{details && details.recipientsBTrelation ? details.recipientsBTrelation.relationName : 'N/A'}</label></Col>
                                                                    <Col md={1} className='ms-auto'><input className='pointer' type="checkbox" style={{transform: "scale(1.5)",marginTop:"15px"}} onChange={(e) =>{handleRecipientCheckbox(e,data,'external')}}/></Col>
                                                                </Row><br/>
                                                            </>
                                                        );
                                                    })}
                                                </Container>  
                                            } 

                                            {internalUsers && internalUsers.length !== 0 &&
                                                <Container>
                                                    {internalUsers.map((data: any) => {
                                                        let details = data.usersBoxRecipients;
                                                        
                                                        return (
                                                            <>
                                                                <Row className='recipientList pt-2 pb-2 align-items-center' style={(intCheckboxErr && intCheckboxErr.length != 0 && intCheckboxErr.find((item:any) => ((item.email && details.email) && (item.email === details.email)) || (((item.parentEmailId && details.email) && (item.parentEmailId === details.email)) || ((item.recipientEmail && details.email) && (item.recipientEmail === details.email))))) ? {border:"3px solid red",color:"#fff",background:"#0B1320"} : {color:"#fff",background:"#0B1320"}}> 
                                                                    <Col md={1}>
                                                                        {details && details.userImg ?
                                                                            <img height={50} width={100} src={(details && details.userImg) ? `${BASE_URLS.BASE_URL}/user-box-files/resource?key=${details.userImg}` : defaultUserImg} style={{borderRadius:"15px"}} onError={({ currentTarget } : { currentTarget:any }) => {currentTarget.onerror = null; currentTarget.src=defaultUserImg; }} />
                                                                        :
                                                                            <img src={defaultUserImg} alt="default-image" style={{borderRadius:"5px"}} height={50}/>
                                                                        }
                                                                    </Col>
                                                                    <Col md={3}><label className='pt-2' style={{textAlign:'center',fontSize:"20px"}}>{(details && details.firstName ? details.firstName : '')+" "+(details && details.lastName ? details.lastName : '')}</label></Col>
                                                                    <Col md={4}><label title={details && details.email && details.email.length > 21 ? details.email : ''} className='pt-2' style={{textAlign:'center',fontSize:"20px" }}>{details && details.email ? (details.email.length > 21 ? details.email.substring(0,21)+'...' : details.email) : 'N/A'}</label></Col>
                                                                    <Col md={3}><label className='pt-2' style={{textAlign:'center',fontSize:"20px"}}>{data && data.boxRecipientsBTrelation ? data.boxRecipientsBTrelation.relationName : 'N/A' }</label></Col>
                                                                    <Col md={1} className='ms-auto'><input className='pointer' type="checkbox" style={{transform: "scale(1.5)",marginTop:"15px"}} onChange={(e) =>{handleRecipientCheckbox(e,data,'internal')}}/></Col>
                                                                </Row><br/>
                                                            </>
                                                        );
                                                    })}
                                                </Container>
                                            }
                                            </>
                                        :
                                            (internalUsers && internalUsers.length === 0 && externalUsers&&externalUsers.length === 0) &&
                                            <p className="text-center text-warning pt-5">No recipients list found...</p>
                                        }
                                    </div>
                                    <hr style={{border:'0.5px solid #E79E1F'}}/> 
                                    <Form.Group className="formRow">
                                        <Row>
                                            <Col md="auto"><Button style={{border:"1px solid #E79E1F"}} className='backBtn' onClick={saveRecipientPopup}>Save</Button></Col>
                                            <Col md="auto"><Button  style={{border:"1px solid #E79E1F"}} className='backBtn' onClick={closeRecipientPopup}>Cancel</Button></Col>
                                            {notification.message &&
                                                <Col md="auto" className='ms-auto'><p className={`p-2 rounded text-light bg-`+notification.type}>{notification.message}</p></Col>
                                            }
                                        </Row>
                                    </Form.Group>
                                </DisplayModal>
                                {/* RECIPIENT POPUP MODAL ENDS HERE */}
                            </Container>
                        </div>
                    }
                </Container>
            </Layout>
        );
    };

// ------------------------------------------------------------------------------ 

const mapDispatchToProps = (dispatch  : any) => ({
    getBoxCover                : (data: any) => dispatch(getBoxCover(data)),
    getBoxList                 : (data: any) => dispatch(getBoxList(data)),
    getSearchResultByName      : (data: any) => dispatch(getUserDetailsByName(data)),
    uploadCoverPhoto           : (data: any) => dispatch(uploadCoverPhotoByUser(data)),
    clearCommoneMessage        : (data: any) => dispatch(clearCommoneMessage(data)),
    findByEmailId              : (data: any) => dispatch(getUserDetailsByEmail(data)),
    getRelationalData          : (data: any) => dispatch(getRelationalData(data)),
    listUserPeople             : (data: any) => dispatch(listUserPeople(data)),
    clearData_checkedByEmail   : (data: any) => dispatch({type:BOX_API.SET_USER_BY_EMAIL_TO_STATE,data}),
    newRecipientProfilePic     : (data: any) => dispatch(newRecipientProfilePic(data)),
    clearRecpProfilePic_res    : (data: any) => dispatch({type:BOX_API.SET_UPLOAD_RECIPIENT_PROFILE_PIC,data}),
    getBoxDetails              : (data: any) => dispatch(getBoxDetails(data)),
    updateBox                  : (data: any) => dispatch(updateBox(data)),
});

const mapStateToProps = (state : any) => {
    return {
        profileData            : (state.ProfilePageReducer   && state.ProfilePageReducer.profileData )        ? state.ProfilePageReducer.profileData         : null,
        boxCoverData           : (state.BoxReducer           && state.BoxReducer.boxCoverData)                ? state.BoxReducer.boxCoverData                : null,
        currentPlanDetailsData : (state.PlansReducer         && state.PlansReducer.currentPlanDetailsData)    ? state.PlansReducer.currentPlanDetailsData    : null,
        boxListData            : (state.BoxReducer           && state.BoxReducer.boxListData)                 ? state.BoxReducer.boxListData                 : null,
        getSearchedResultByName: (state.BoxReducer           && state.BoxReducer.getSearchedResultByName)     ? state.BoxReducer.getSearchedResultByName     : null,
        getUploadedCoverPhoto  : (state.BoxReducer           && state.BoxReducer.getCoverPhotoUploadedByUser) ? state.BoxReducer.getCoverPhotoUploadedByUser : null,
        messages               : (state.CommonMessageReducer && state.CommonMessageReducer.message)           ? state.CommonMessageReducer.message           : null,
        checkUserByEmailId     : (state.BoxReducer           && state.BoxReducer.usernameByEmailData)         ? state.BoxReducer.usernameByEmailData         : null,
        getRelationShipList    : (state.BoxReducer           && state.BoxReducer.getRelationShipList)         ? state.BoxReducer.getRelationShipList         : null,
        userPeopleData         : state.UserReducer           && state.UserReducer.userPeopleData              ? state.UserReducer.userPeopleData             : null,
        tempNewRecpPic         : (state.BoxReducer           && state.BoxReducer.tempNewRecpPic)              ? state.BoxReducer.tempNewRecpPic              : null,
        createdBoxDetails      : (state.BoxReducer           && state.BoxReducer.createdBoxDetails)           ? state.BoxReducer.createdBoxDetails           : null,
        updateBoxData          : state.BoxReducer            && state.BoxReducer.updateBoxData                ? state.BoxReducer.updateBoxData               : null,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateBox);