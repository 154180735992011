export function formValidation(value: any) {
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    let errors: any = {};
    
    if(value.firstName === ''){
        errors['firstName'] = 'First name is required';
    }
    
    if(value.lastName === ''){
        errors['lastName'] = 'Last name is required';
    }

    if(value.email === ''){
        errors['email'] = 'email is required';
    }else
    if (!emailRegex.test(value.email)){
        errors['email'] = 'Enter a valid email address';
    }    

    if(value.message === ''){
        errors['message'] = 'message is required';
    }

    return errors;
}