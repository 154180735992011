import { ADMIN_FOLDER_MANAGEMENT } from "../../constant/index";
const initialState = {
    loading: false,
    addAdminFolderData:null,
  
  }


  const AdminFolderManagementReducer = (state= initialState, action)=>{
    switch (action.type){
        
        case ADMIN_FOLDER_MANAGEMENT.SET_ADD_ADMIN_FOLDER_TO_STATE:
          
          return {
            ...state,
            addAdminFolderData: action.data,
            loading: false,
            
          };
          case ADMIN_FOLDER_MANAGEMENT.SET_DELETE_ADMIN_FOLDER_TO_STATE:
          
          return {
            ...state,
            deleteAdminFolderData: action.data,
            loading: false,
            
          };
          case ADMIN_FOLDER_MANAGEMENT.SET_UPDATE_ADMIN_FOLDER_TO_STATE:
          
            return {
              ...state,
              updateAdminFolderData: action.data,
              loading: false,
              
            };
            case ADMIN_FOLDER_MANAGEMENT.SET_GET_FOLDER_DETAIL_BY_ID_TO_STATE:
          
            return {
              ...state,
              getFolderDetailByIdData: action.data,
              loading: false,
              
            };

          default:
            return state;
    }

  }

  export default AdminFolderManagementReducer;