import {Route,Routes,BrowserRouter}  from "react-router-dom"
import PrivateRoutes                 from "./private_routes";
import Login                         from "../pages/login/login";
import Home                          from "../pages/home/home";
import Landing                       from "../pages/landing/landing";
import Signup                        from "../pages/signup/signup";
import ForgotPassword                from "../pages/forgot-password/forgot-password";
import Otp                           from "../pages/forgot-password/otp";
import ResetPassword                 from "../pages/reset-password/reset-password";
import ChangePassword                from "../pages/change-password/change-password";
import EditProfile                   from "../pages/home/edit-profile";
import CreateBox                     from "../pages/box/create-box";
import Plans                         from "../pages/home/plans/planTemplates";
import PaymentPage                   from "../pages/payfast/payment-page";
import CreatedBox                    from "../pages/box/created-box";
import ReceivedBox                   from "../pages/box/received-box";
import FolderTemplate                from "../pages/box/uploaded_folder/template";
import UploadModal                   from "../pages/box/upload-modal";
import Foundation                    from "../pages/home/foundation-page";
import BoxRecords                    from "../pages/home/box-records";
import PageNotFound                  from "../pages/error_pages/page_not_found";
import UpdateBox                     from "../pages/box/edit-box";
import EmailAndMobileOTPVerification from "../pages/signup/emailAndMobileOTPVerification";
import UserAuthorisation             from "../pages/login/user_authorisation";

// People & Recycle bin
import People                        from "../pages/people/people";
import RecycleBin                    from "../pages/recycle-bin/recycle-bin";

import ContactUs                     from "../pages/contact-us/contact_us";
import FAQ                           from "../pages/home/faq";
import UnsubscribedMail              from '../pages/mail/unsubscribe-mail';
import UserBoxRelease                from "../pages/login/user_box_release";
// ADMIN IMPORT SECTION STARTS HERE :-------------------------------------------------
import AdminRoutes                   from "./admin_routes";
import AdminHome                     from "../pages/Admin/pages/home";
import AdminCountry                  from "../pages/Admin/pages/country";
import AdminCountryStates            from "../pages/Admin/pages/country-states"
import AdminCountryCity              from "../pages/Admin/pages/country-city"
import AdminBoxCover                 from "../pages/Admin/pages/box-cover-photo";
import AdminUserProfile              from "../pages/Admin/pages/user-profile";
import AdminUserDashboard            from "../pages/Admin/pages/users/user-dashboard";
// import AdminUserDashboardDisabled    from "../pages/Admin/pages/users/user-dashboardDisabled";
import AdminReleaseBox               from "../pages/Admin/pages/release-box";
import ManageFolders                 from "../pages/Admin/pages/manage-folders";
import AdminFoundation               from "../pages/Admin/pages/adminFoundation";
// ADMIN IMPORT SECTION ENDS HERE   :-------------------------------------------------

import TermAndConditions             from "../pages/TnC/terms-and-condition";
import AboutUs                       from "../pages/home/about-us";
import PrivacyPolicy                 from "../pages/home/privacy-policy";
import ViewDetailsList               from "../pages/box/view-box-details";
import AdminUserPaymentDetails       from "../pages/Admin/pages/user-payment-details";
import ViewPlans                     from "../pages/landing/view-plans";
import BoxRecordUser                 from "../pages/home/box-record-user-detail"
import PublicBoxList                 from "../pages/home/public-box-list";
import AdminMarketer                 from "../pages/Admin/pages/adminMarketer";
import AdminMarketeruserList         from "../pages/Admin/pages/adminMaketerUserList";
import AdminMaketerAllUserList       from "../pages/Admin/pages/adminMarketerAllUserList";

//-------------------------------------------------------------------------------------------//

const SiteRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing/>} />
        <Route path="/login" element={<Login/>} />
        <Route path="/login/:loginData" element={ <Login/>} />
        <Route path="/signup" element={<Signup/>} />
        <Route path="/signup/:signupData" element={<Signup/>} />
        <Route path="/signup-with-promocode/:promocode" element={<Signup/>} />
        <Route path="/forgot-password" element={<ForgotPassword/>} />
        <Route path="/otp" element={<Otp/>} />
        <Route path="/reset-password/:key" element={<ResetPassword/>} />
        <Route path="/foundation" element={<Foundation/>} />
        <Route path="/box_records" element={<BoxRecords/>} />
        <Route path="/about_us" element={<AboutUs/>} />
        <Route path="/edit-profile" element={<PrivateRoutes> <EditProfile/></PrivateRoutes>}/>
        <Route path="/homepage" element={ <PrivateRoutes><Home/></PrivateRoutes>} />
        <Route path="/create-box" element={ <PrivateRoutes><CreateBox /></PrivateRoutes>} />
        <Route path="/select-plans" element={ <PrivateRoutes><Plans /></PrivateRoutes>} />
        <Route path="/public-box-list" element={ <PrivateRoutes><PublicBoxList /></PrivateRoutes>} />
        <Route path="/terms-and-condition" element={ <TermAndConditions />} />
        <Route path="/suicide-exclusion" element={ <TermAndConditions />} />
        <Route path="/complete-payment" element={ <PrivateRoutes><PaymentPage /></PrivateRoutes>} />
        <Route path="/privacy_Policy" element={<PrivacyPolicy/>} />
        <Route path="/view-plans" element={<ViewPlans/>} />
        <Route path="/box-record-user/:id" element={ <BoxRecordUser/>} />
        <Route path="/contact_us" element={ <ContactUs/>} />
        <Route path="/frequently-asked-questions" element={ <FAQ/>} />
        <Route path="/unsubscribe-mail/:unsubscribedData" element={ <UnsubscribedMail/>} />
        
        {/* CREATED BOX */}
        <Route path="/created-box/:boxid/:foldername/:boxfolderid" element={ <PrivateRoutes> <FolderTemplate/></PrivateRoutes>} />
        <Route path="/upload-box-modal" element={ <PrivateRoutes> <UploadModal/></PrivateRoutes>}/>
        <Route path="/created-box/:id" element={ <PrivateRoutes> < CreatedBox/></PrivateRoutes>} />

        {/* RECIEVED BOX */}
        <Route path="/recieved-box/:id" element={ <PrivateRoutes> <ReceivedBox/></PrivateRoutes>} />

        {/* CHANGE PASSWORD */}
        <Route path="/change-password" element={ <PrivateRoutes> <ChangePassword/></PrivateRoutes>} />
        
        {/* UPDATE BOX */}
        <Route path="/update-box/:id" element={ <PrivateRoutes> <UpdateBox/></PrivateRoutes>} />
        <Route path="/box-preview/:id" element={ <PrivateRoutes> <ViewDetailsList/></PrivateRoutes>} />

        {/* EMAIL AND MOBILE VERIFICATION VIA OTP */}
        <Route path="/user-verification" element={ <EmailAndMobileOTPVerification/> } />
        <Route path="/user-authorisation" element={ <UserAuthorisation/> } />
        <Route path="/user-box-release" element={ <UserBoxRelease/> } />

        {/* PEOPLE & RECYCLE BIN */}
        <Route path="/people" element={<PrivateRoutes> <People/></PrivateRoutes>} />
        <Route path="/recycle-bin" element={<PrivateRoutes> <RecycleBin/></PrivateRoutes>} />
        
        {/* ADMIN SECTION STARTS HERE */}
        <Route path="/admin" element={<AdminRoutes><AdminHome/></AdminRoutes>} />
        <Route path="/admin-countries" element={<AdminRoutes><AdminCountry/></AdminRoutes>} />
        <Route path="/admin-states" element={<AdminRoutes><AdminCountryStates/></AdminRoutes>} />
        <Route path="/admin-cities" element={<AdminRoutes>< AdminCountryCity/></AdminRoutes>} />
        <Route path="/admin-cover-photos" element={<AdminRoutes>< AdminBoxCover/></AdminRoutes>} />
        <Route path="/admin-user-profile" element={<AdminRoutes>< AdminUserProfile/></AdminRoutes>} />
        <Route path="/admin-user-dashboard" element={<AdminRoutes>< AdminUserDashboard/></AdminRoutes>} />
        <Route path="/admin-user-payment-details" element={<AdminRoutes>< AdminUserPaymentDetails/></AdminRoutes>} />
        {/* <Route path="/admin-user-dashboard-disabled-users" element={<AdminRoutes>< AdminUserDashboardDisabled/></AdminRoutes>} /> */}
        <Route path="/admin-release-box" element={<AdminRoutes>< AdminReleaseBox/></AdminRoutes>} />
        <Route path="/admin-manage-folders" element={<AdminRoutes>< ManageFolders/></AdminRoutes>} />
        <Route path="/admin-foundation" element={<AdminRoutes>< AdminFoundation/></AdminRoutes>} />  
        <Route path="/admin-marketer" element={<AdminRoutes>< AdminMarketer/></AdminRoutes>} />  
        <Route path="/admin-marketer-user-list" element={<AdminRoutes>< AdminMarketeruserList/></AdminRoutes>} />  
        <Route path="/admin-marketer-all-user-list" element={<AdminRoutes>< AdminMaketerAllUserList/></AdminRoutes>} />  
        
        <Route path="/complete-admin-payment" element={ <AdminRoutes><PaymentPage /></AdminRoutes>} />
        {/*  ADMIN SECTION ENDS HERE  */}

        <Route path="*" element={<PageNotFound /> }/>
      </Routes>
    </BrowserRouter>
  );
};
export default SiteRoutes;
