import { useEffect, useState } from "react";
import { Button, Container }   from 'react-bootstrap';
import boxImg                  from '../../assets/images/box-img.png';
import defaultImg              from '../../assets/images/no-image-available.png';
import Layout                  from "../../layout/Layout";
import UploadModal             from "./upload-modal";
import { connect }             from "react-redux";
import { BASE_URLS }           from "../../redux/constant";
import {recipientValidation}   from "../../../src/validations/recipient_details_validation";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Swal                             from 'sweetalert2';
import { getBoxDetails, getBoxIcon,listBoxContent,getUserDetailsByEmail,updateRecipient,clearCommoneMessage } from "../../redux/actions/boxAction";
import NotAuthorized from "../frontend/error_pages/not_authorised";

const CreatedBox = (props: any) => {
    const redirectTo                          = useNavigate();
    const handleClose                         = () => setModalShow(false);
    const handleShow                          = () => setModalShow(true);
    const { id }                              = useParams();
    const [isDisabled,     setIsDisabled]     = useState<any>(localStorage.getItem('isPublicBox'));
    const [modalShow,      setModalShow]      = useState<boolean>(false);
    const [iconList,       setIconList]       = useState([])
    const [newRecipients,  setNewRecipients]  = useState([]);
    const [oldRecipients,  setOldRecipients]  = useState([]);
    const [boxDescription, setBoxDescription] = useState('');
    const [recId,          setRecId]          = useState<number>(0);
    const [loader,         setLoader]         = useState(false);
    const [succss,         setSuccess]        = useState('');
    const [errs,           setErrors]         = useState('');
    const [preLoader,      setPreLoader]      = useState(false);
    const [readMore,       setReadMore]       = useState('');
    const [boxData,        setBoxData]        = useState({id:0,boxid:0,profilePic:'',Fname:'',Lname:'',relationId:0,parentEmail:'',email:''});
    const [oldValue,       setOldValue]       = useState({Fname:'',Lname:'',parentEmailId:'',email:''});
    const [isClicked,      setIsClicked]      = useState(false);
    const [warning,        setWarning]        = useState(false);
    const [isVisible,      setIsVisible]      = useState(false);
    const [notification,   setNotification]   = useState('');
    const [notificationType,setNotificationType]= useState('');
    const { state }                           = useLocation();
    const [authFlag,       setAuthFlag]       = useState(false);
    
    useEffect(()=>{
        props.clearCommoneMessage(null);
        setErrors('');
        setSuccess('');
        if (!localStorage.getItem("message")) {
            notify();
        }
    },[id])

    const notify = () => {
        if (state) {
          const { message, messageType } = state;
          if (message) {
            setIsVisible(true);
            setNotification(message);
            setNotificationType(messageType);
            localStorage.setItem("message", message);
          }
        }
      };
    
    useEffect(() =>{
        if(isVisible){
            setTimeout(() => {
            setIsVisible(false);
            setNotification('');
            setNotificationType('');
            }, 8000);
        }
    },[isVisible])  

    useEffect(()=>{
        setLoader(true);
        props.getBoxDetails(Number(id));
        props.getBoxIcon();
    },[])
    
    useEffect(()=>{
      if(props.boxIconData){
        setIconList(props.boxIconData);
        setTimeout(() =>{
            setLoader(false);
        },1000)
      }

      if(props.createdBoxDetails){
        setTimeout(() =>{
            if(props.createdBoxDetails.isPublic === 1){
                setIsDisabled(true);
            }else{
                setIsDisabled(false);
            }
            setLoader(false);
        },1000)
        
        if(props.createdBoxDetails.externalRecipients && props.createdBoxDetails.externalRecipients.length !== 0){
            setNewRecipients(props.createdBoxDetails.externalRecipients);
        }else{ setNewRecipients([]); } 
        
        if(props.createdBoxDetails.internalRecipients && props.createdBoxDetails.internalRecipients.length !== 0){
            setOldRecipients(props.createdBoxDetails.internalRecipients)
        }else{ setOldRecipients([]); }

        if(props.createdBoxDetails.boxdescription){
            setBoxDescription(props.createdBoxDetails.boxdescription);
        }else{ setBoxDescription('') }
     }
    },[props.boxIconData,props.createdBoxDetails])

    const handleRecipientsEdit = (recordId:number,boxId:number,profPic:any,fname:any,lname:any,relId:number,pEmail:any,email:any) =>{
        if(recordId){
            setRecId(recordId);
            setSuccess('');
            setErrors('');
            setBoxData({...boxData,
                            id:recordId,
                            boxid:boxId,
                            profilePic:profPic,
                            Fname:fname,
                            Lname:lname,
                            relationId:relId,
                            parentEmail:pEmail,
                            email:email
                        })
            setOldValue({Fname:fname,Lname:lname,parentEmailId:pEmail,email:email});
        }
    }

    const handleRecipientsSave = () =>{
        setPreLoader(true);
        let errors = recipientValidation(boxData);
        if(Object.entries(errors).length !== 0){
            setPreLoader(false);
            setErrors(errors);
        }else if(Object.entries(errors).length === 0){
            setIsClicked(true);
            let e_mail:any = '';
            let o_email:any = '';
            if(boxData.relationId && boxData.relationId === 3){
                e_mail = boxData.parentEmail;
                o_email = oldValue.parentEmailId;
            }else{
                e_mail = boxData.email;
                o_email = oldValue.email;
            }

            if( o_email !== e_mail){
                props.checkDuplicacyByEmailid({'email':e_mail});
            }else{
                const {id,boxid,profilePic,Fname,Lname,relationId,email,parentEmail} = boxData;
                let payload = {
                        args:{
                            'Id':Number(id),
                            // 'boxId':boxid,
                        },
                        data:{
                            'recipientFname':Fname,
                            'recipientLname':Lname,
                            'recipientEmail':(relationId !== 3 ? email : ''),
                            'parentEmailId' :(relationId === 3 ? parentEmail : ''),
                            'recipientImage':(profilePic ? profilePic : ''),
                            'relationId'    :(relationId && Number(relationId)),
                        }
                    }
                if(id && boxid){
                    props.updateRecipient(payload);
                }
            }
        }
    }

    useEffect(() =>{
        if(props.checkDuplicate && props.checkDuplicate.length !== 0 && isClicked === true){
            setPreLoader(false);
            setErrors("Please add this recipient via the 'Edit Box' tool.");
        }else if(props.checkDuplicate && props.checkDuplicate.length === 0){
            const {id,boxid,profilePic,Fname,Lname,relationId,email,parentEmail} = boxData;
            let payload = {
                    args:{
                        'Id':Number(id),
                        // 'boxId':boxid,
                    },
                    data:{
                        'recipientFname':Fname,
                        'recipientLname':Lname,
                        'recipientEmail':(relationId !== 3 ? email : ''),
                        'parentEmailId' :(relationId === 3 ? parentEmail : ''),
                        'recipientImage':(profilePic ? profilePic : ''),
                        'relationId'    :(relationId && Number(relationId)),
                    }
                }
          
            if(id && boxid){
                props.updateRecipient(payload);
            }
        }
    },[props.checkDuplicate])

    useEffect(()=>{
        if(props.updateRcipient && props.updateRcipient.message){
            setPreLoader(false);
            props.getBoxDetails(Number(id));
        }
    },[props.updateRcipient])

    useEffect(() =>{
        if(preLoader){
            setTimeout(() => {
                setPreLoader(false);
                setSuccess('');
            }, 8000);
        }
    },[preLoader])

    useEffect(()=>{
        if(props.message && props.message.status == 'error'){
            if(props.message.data == 'Unauthorized'){
                setAuthFlag(true);
            }else{
                setAuthFlag(false);
                setPreLoader(false);
                if(props.message && props.message.data === 'Email id already exists.'){
                    setErrors(props.message.data.replace('Email id already exists.',"Please add this recipient via the 'Edit Box' tool."));
                }else{
                    setErrors(props.message.data);
                }
                setSuccess('');
            }
        }
        if(props.message && props.message.status == 'success'){
            setRecId(0);
            setPreLoader(false);
            setErrors('');
            setSuccess(props.message.data);
        }
    },[props.message])

    const handleRecipientChange = (event:any) =>{
        if(event.target.name === 'fname'){
            setBoxData({...boxData,Fname:event.target.value})
        }else if(event.target.name === 'lname'){
            setBoxData({...boxData,Lname:event.target.value})
        }else if(event.target.name === 'email'){
            if(boxData.relationId && boxData.relationId === 3){
                setBoxData({...boxData,parentEmail:event.target.value})
            }else{
                setBoxData({...boxData,email:event.target.value})
            }
            
        }
    }

// READ MORE ACTION -----------------------  
    const readMoreFunction = (value:any) =>{
        setReadMore(value);
    }

// READ LESS ACTION -----------------------  
    const readLessFunction = () => {
        setReadMore('');
    }
  
    useEffect(() =>{
        if(props.currentPlanData && props.currentPlanData.planisActive === 1 && props.currentPlanData.planCode === 'FREE'){
            setWarning(true);
        }
    },[props.currentPlanData])

    const handleWarning = () =>{
        Swal.fire({
            title             : 'Your current plan is <span class="text-danger">FREE!</span>',
            html              : '<div class="pt-2" style="font-weight:800">Please upgrade to the <span class="text-success">Family Plus plan</span> <br>to add all media types to your box</span></div>',
            showDenyButton    : false,
            showCancelButton  : true,
            confirmButtonText : 'View Plans!',
            confirmButtonColor: 'red',
            cancelButtonColor : 'blue',
            width             : '450px',
            customClass       : {
                                actions       : 'my-actions',
                                confirmButton : 'order-1',
                                cancelButton  : 'order-2',
                                }
        }).then((result) => {
            if (result.isConfirmed) {
            redirectTo('/select-plans');       
            }
        });
    }
   
//------------------------------------------------------------------------------//  

    return (
        <Layout>
            {authFlag ?
                <NotAuthorized/>
            :
                <>
                    <UploadModal show={modalShow} onHide={handleClose} boxId={id}/> 
                    <div className='col-md-12 text-center'>
                        <span className={isVisible ? `bg-${notificationType} text-light rounded p-2` : `text-light rounded p-2`}>
                        {isVisible && notification ? notification : ''}
                        </span>
                    </div>
                    <div className="boxes createBox">
                        <Container>
                            <div className={loader ? `boxListFolder text-center` : `boxListFolder`}>
                                <div className="userDetails">
                                    <Link to={`/box-preview/${id}`} >
                                    <div className="img pointer" title="Click here for box preview" >
                                        {props.createdBoxDetails && props.createdBoxDetails.coverPicture ?
                                            <img src={`${BASE_URLS.BASE_URL}/user-box-files/resource?key=${props.createdBoxDetails.coverPicture}`} alt="" style={{borderRadius:"20px"}}  onError={({ currentTarget }) => {currentTarget.onerror = null; currentTarget.src=defaultImg; }}/>
                                        :
                                            <img src={defaultImg} alt="" style={{borderRadius:"20px"}} />
                                        }
                                    </div>
                                    </Link>
                                    <div className="userName">{props.createdBoxDetails && props.createdBoxDetails.boxName ? props.createdBoxDetails.boxName.toUpperCase() :''}</div>
                                </div>
                                
                                <div className="row justify-content-end floatingBtnBlock">
                                    <div className="col-auto">
                                        <Button className="backBtn" style={{padding: '10px 20px', fontSize: '18px', height: 'auto',}} onClick={() => redirectTo(-1)}>Back</Button>
                                    </div>
                                    <div className="col-auto">
                                        <Button className="addToBox yellowBtn btn btn-primary" style={{padding: '10px 20px'}} onClick={handleShow}>Add to Box</Button>
                                    </div>
                                </div> 
                                
                                {loader ? 
                                    <div className="lds-dual-ring">
                                        <span className="text-warning">Loading... please wait!</span>
                                    </div>
                                : 
                                    <>
                                        <div style={{paddingLeft:'20px',paddingRight:'20px'}}>
                                            {(newRecipients.length !== 0 || oldRecipients.length !== 0) &&   
                                                <section className={`recipient-section ${!boxDescription ? ` mb-5` : ``}`}>
                                                    <h2 className="title text-warning">Recipient Details:&nbsp;&nbsp;&nbsp; 
                                                    {preLoader ? 
                                                        <small className="blink text-center">
                                                            <small className="text-light">Loading... please wait</small>
                                                        </small>
                                                    : 
                                                        <>
                                                        {succss && <small className={`bg-success p-2 rounded text-light`}>{succss}</small>}
                                                        {errs &&<small className="p-2 rounded bg-danger text-light">{errs}</small>}
                                                        </>
                                                    }    
                                                    </h2>
                                                    <div className="recipient-dtl-list">
                                                        <div className="dtl-list-tbl">
                                                            <table className="table m-0">
                                                                <thead>
                                                                    <tr>
                                                                        {/* <th style={{width: "100px" }}>S. No.</th> */}
                                                                        <th>First Name<small className="text-danger">*</small></th>
                                                                        <th>Last Name<small className="text-danger">*</small></th>
                                                                        <th>Relation<small className="text-danger">*</small></th>
                                                                        <th>Email/Parent Email<small className="text-danger">*</small></th>
                                                                        <th style={{width: "120px" }}>Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {newRecipients.length !== 0 && newRecipients.map((item:any,index:number) =>{
                                                                        
                                                                    return(
                                                                            <tr key={item.id}>
                                                                                {/* <td>{index+1}.</td> */}
                                                                                {Number(recId) === Number(item.id) ? 
                                                                                    <>
                                                                                        <td><input className="form-control" name="fname" value={boxData.Fname} onChange={(e) =>handleRecipientChange(e)}/></td>
                                                                                        <td><input className="form-control" name="lname" value={boxData.Lname} onChange={(e) =>handleRecipientChange(e)}/></td>
                                                                                        <td><input style={{ color:'lightgrey', cursor: "not-allowed",backgroundColor:'grey'}} className={`form-control`} name="relation" value={item && item.recipientsBTrelation && item.recipientsBTrelation.relationName ? item.recipientsBTrelation.relationName : 'N/A'} readOnly disabled={true}/></td>
                                                                                        <td><input className="form-control" name="email" value={item && item.recipientsBTrelation && item.recipientsBTrelation.id === 3 ? boxData.parentEmail : boxData.email} onChange={(e) =>handleRecipientChange(e)}/></td>
                                                                                        <td><Button className="recipient-saveBtn" style={{paddingTop:'5px'}} onClick={handleRecipientsSave}>Save</Button></td>
                                                                                    </>
                                                                                :
                                                                                    <>
                                                                                        <td>{item.recipientFname && item.recipientFname}</td>
                                                                                        <td>{item.recipientLname && item.recipientLname}</td>
                                                                                        <td>{item && item.recipientsBTrelation && item.recipientsBTrelation.relationName ? item.recipientsBTrelation.relationName : 'N/A'}</td>
                                                                                        <td>{item && item.recipientsBTrelation && item.recipientsBTrelation.id === 3 ? item.parentEmailId : item.recipientEmail}</td>
                                                                                        <td>{item.relationId && item.relationId != 3 &&
                                                                                            <Button className="recipient-editBtn" style={{paddingTop:'5px'}} onClick={()=>{handleRecipientsEdit(item.id,item.boxesId,item.recipientImage,item.recipientFname,item.recipientLname,(item && item.recipientsBTrelation && item.recipientsBTrelation.id ? item.recipientsBTrelation.id : 0),(item.parentEmailId ? item.parentEmailId : '') ,item.recipientEmail)}}>Edit</Button>
                                                                                            }
                                                                                        </td>
                                                                                    </>
                                                                                }
                                                                            </tr>
                                                                        )
                                                                    })}
                                                                    {oldRecipients.length !== 0 && oldRecipients.map((item:any,index:number) =>{
                                                                        
                                                                        return(
                                                                            <>
                                                                                {item.usersBoxRecipients &&
                                                                                    <tr>
                                                                                        {/* <td>{newRecipients.length !== 0 ? newRecipients.length+(index+1) : index+1}.</td> */}
                                                                                        <td>{item.usersBoxRecipients.firstName && item.usersBoxRecipients.firstName}</td>
                                                                                        <td>{item.usersBoxRecipients.lastName && item.usersBoxRecipients.lastName}</td>
                                                                                        <td>{item.boxRecipientsBTrelation && item.boxRecipientsBTrelation.relationName ? item.boxRecipientsBTrelation.relationName : 'N/A'}</td>
                                                                                        <td>{item.usersBoxRecipients.email && item.usersBoxRecipients.email}</td>
                                                                                        <td>
                                                                                            {/* <label className="text-danger">non-editable</label> */}
                                                                                        </td>
                                                                                    </tr>
                                                                                }
                                                                            </>
                                                                        )
                                                                    })}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </section>
                                            }
                                            {boxDescription &&
                                                <section className="description-section ">
                                                    <h2 className="title text-warning">Description</h2>
                                                    <div className="desc-data">
                                                    {readMore ?  
                                                        <span className="text-light"><p>{readMore} {boxDescription.length > 50 && <small className="text-warning pointer read_more_or_less" onClick={() => readLessFunction()}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <u>Read Less</u> </small> } </p> </span>
                                                    :
                                                        <span className="text-light"> {boxDescription.length > 50 ? boxDescription.substring(0,50)+'...' : boxDescription} {boxDescription.length > 50 && <small className="text-warning pointer read_more_or_less" onClick={() => readMoreFunction(boxDescription)}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<u>Read More</u></small> }</span>
                                                    }
                                                    </div>
                                                </section>
                                            }
                                        </div> 
                                        
                                        <ul>
                                            {iconList && iconList.length !== 0 && iconList.map((icon:any)=>{
                                                return(
                                                    <>
                                                        {isDisabled &&  icon.folderName === 'Messages' ?
                                                            <li key={icon.id}>
                                                                <Link to={`/created-box/${id}/${icon.folderName}/${icon.id}`} style={{textDecoration: 'none'}}>
                                                                    <div className="folderView">
                                                                        <div className="imgBox ">
                                                                            <img src={(icon && icon.folderIcon)&& `${BASE_URLS.BASE_URL}/user-box-files/resource?key=${icon.folderIcon}` } alt=""  />
                                                                        </div>
                                                                    </div>
                                                                    <h5 className="folderTitle">{icon.folderName}</h5>
                                                                </Link>
                                                            </li>
                                                        :
                                                        !isDisabled ?
                                                            <li key={icon.id}>
                                                                {warning && icon.folderName !== 'Messages'? 
                                                                    <div onClick={handleWarning} className='pointer'>
                                                                        <div className="folderView">
                                                                            <div className="imgBox ">
                                                                                <img src={(icon && icon.folderIcon)&& `${BASE_URLS.BASE_URL}/user-box-files/resource?key=${icon.folderIcon}` } alt=""  />
                                                                            </div>
                                                                        </div>
                                                                        <h5 className="folderTitle">{icon.folderName}</h5>
                                                                    </div>
                                                                
                                                                : 
                                                                    <Link to={`/created-box/${id}/${icon.folderName}/${icon.id}`} style={{textDecoration: 'none'}}>
                                                                        <div className="folderView">
                                                                            <div className="imgBox ">
                                                                                <img src={(icon && icon.folderIcon)&& `${BASE_URLS.BASE_URL}/user-box-files/resource?key=${icon.folderIcon}` } alt=""  />
                                                                            </div>
                                                                        </div>
                                                                        <h5 className="folderTitle">{icon.folderName}</h5>
                                                                    </Link>
                                                                }
                                                            </li>
                                                        :
                                                            <li key={icon.id} style={{cursor:'not-allowed',opacity:'0.2'}} >
                                                                <div className="folderView">
                                                                    <div className="imgBox ">
                                                                        <img src={(icon && icon.folderIcon)&& `${BASE_URLS.BASE_URL}/user-box-files/resource?key=${icon.folderIcon}` } alt=""  />
                                                                    </div>
                                                                </div>
                                                                <h5 className="folderTitle">{icon.folderName}</h5>
                                                            </li>
                                                        }
                                                    </>
                                                )
                                            })}
                                        </ul>
                                    </>    
                                }  
                            </div>
                        </Container>
                    </div>
                </>
            }
        </Layout>
    );
};

const mapDispatchToProps = (dispatch: any) => ({
    getBoxDetails           : (data: any) => dispatch(getBoxDetails(data)),
    getBoxIcon              : (data: any) => dispatch(getBoxIcon(data)),
    boxPreview              : (data: any) => dispatch(listBoxContent(data)),
    checkDuplicacyByEmailid : (data: any) => dispatch(getUserDetailsByEmail(data)),
    updateRecipient         : (data: any) => dispatch(updateRecipient(data)),
    clearCommoneMessage     : (data: any) => dispatch(clearCommoneMessage(data)),
});

const mapStateToProps = (state: any) => {
    return {
        createdBoxDetails: (state.BoxReducer           && state.BoxReducer.createdBoxDetails)   ? state.BoxReducer.createdBoxDetails   : null,
        boxIconData      : (state.BoxReducer           && state.BoxReducer.boxIconData)         ? state.BoxReducer.boxIconData         : null,
        listBoxDetails   : (state.BoxReducer           && state.BoxReducer.listBoxDetails)      ? state.BoxReducer.listBoxDetails      : null,
        checkDuplicate   : (state.BoxReducer           && state.BoxReducer.usernameByEmailData) ? state.BoxReducer.usernameByEmailData : null,
        updateRcipient   : (state.BoxReducer           && state.BoxReducer.updateRecipient)     ? state.BoxReducer.updateRecipient     : null,
        message          : (state.CommonMessageReducer && state.CommonMessageReducer.message)   ? state.CommonMessageReducer.message   : null,
        currentPlanData  : (state.PlansReducer         && state.PlansReducer.currentPlanDetailsData)  ? state.PlansReducer.currentPlanDetailsData : null,
    };
};

export default connect(mapStateToProps, mapDispatchToProps) (CreatedBox);