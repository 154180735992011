import {put, call} from 'redux-saga/effects';
import {getPlanService,selectPlanService,selectPaidPlanService,currentPlanService,
  cancelPlanService,getAllPlanService} from '../services/plansService'
import {COMMON_MESSAGE, SUBSCRIPTION_PLAN} from '../constant/index';
import {generateReadableError} from '../../hooks/messages';

const clearErrorMessage = {
  type: COMMON_MESSAGE,
  data: {
    status: '',
    page: '',
    data: [],
  }}

export function* fetchPlans({payload}) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(getPlanService, payload);
   
    yield put({type: SUBSCRIPTION_PLAN.SET_SUBSCRIPTION_PLAN_TO_STATE, data});
    

  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'error',
        page: 'plans',
        data: generateReadableError(error),
      },
    });
  }
}


export function* fetchSelectedPlans({payload}) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(selectPlanService, payload);
    
    yield put({type: SUBSCRIPTION_PLAN.SET_SELECTED_SUBSCRIPTION_PLAN_TO_STATE, data});
    

  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'error',
        page: 'plans',
        data: generateReadableError(error),
      },
    });
  }
}
export function* fetchSelectedPaidPlans({payload}) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(selectPaidPlanService, payload);
    
    yield put({type: SUBSCRIPTION_PLAN.SET_SELECTED_PAID_SUBSCRIPTION_PLAN_TO_STATE, data});
    

  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'error',
        page: 'plans',
        data: generateReadableError(error),
      },
    });
  }
}
export function* fetchCurrentPlan({payload}) {
  try {
    yield put({type: SUBSCRIPTION_PLAN.SET_CURRENT_PLAN_TO_STATE, data:null});
    yield put(clearErrorMessage);
    const data = yield call(currentPlanService, payload);
    
    yield put({type: SUBSCRIPTION_PLAN.SET_CURRENT_PLAN_TO_STATE, data});
    

  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'error',
        page: 'plans',
        data: generateReadableError(error),
      },
    });
  }
}
export function* fetchCancelPlans({payload}) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(cancelPlanService, payload);    
    yield put({type: SUBSCRIPTION_PLAN.SET_CANCEL_PLAN_TO_STATE, data});
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'error',
        page: 'plans',
        data: generateReadableError(error),
      },
    });
  }
}

export function* fetchAllPlans({payload}) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(getAllPlanService, payload);    
    yield put({type: SUBSCRIPTION_PLAN.SET_ALL_PLAN_TO_STATE, data});
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'error',
        page: 'plans',
        data: generateReadableError(error),
      },
    });
  }
}