import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Container,
  Modal,
  Row,
  Col,
  Stack,
  Table,
} from "react-bootstrap";
import { connect } from "react-redux";
import { updateAdminPlan } from "../../../redux/actions/admin/adminBoxPlanAction";
const UpdatePlanModal = (props: any) => {
  let boxPlanId = parseInt(
    props && props.boxPlanIdProp ? props.boxPlanIdProp : ""
  );
  const [discountedValue, setDiscountedValue] = useState("");
  const [pPrice1, setPPrice1] = useState(0);
  const [pDiscount1, setPDiscount1] = useState(0);
 
  const [updatePlan, setUpdatePlan] = useState({
    form_field_Type: "",
    form_field_Code: "",
    form_field_Price: "",
    form_field_privateBoxes: "",
    form_field_publicBoxes: "",
    form_field_recievedBoxes: "",
    form_field_totalBoxes: "",
    form_field_Duration: "",
    form_field_Storage: "",
    form_field_recievedBoxAccessTime: "",
    // form_field_paypal_id: "",
    form_field_privateRecipients: 0,
    form_field_marketerPlanDiscount:"",
    form_field_marketerCommission:"",
    form_field_priceAfterDiscount: "",
    
  });
  let newResult: any;

  useEffect(() => {
    if (props.adminBoxPlanDataById) {
      const result = props.adminBoxPlanDataById
        ? props.adminBoxPlanDataById
        : "";
      updatePlan.form_field_Type =
        (result && result.planType) ? result.planType : "";
      updatePlan.form_field_Code =
       ( result && result.planCode) ? result.planCode : "";
      updatePlan.form_field_Price =
       ( result && result.planPrice) ? result.planPrice : "";
      updatePlan.form_field_privateBoxes =
       ( result && result.privateBoxes) ? result.privateBoxes : "";
      updatePlan.form_field_publicBoxes =
        (result && result.publicBoxes )? result.publicBoxes : "";
      updatePlan.form_field_recievedBoxes =
       ( result && result.recievedBoxes )? result.recievedBoxes : "";
      updatePlan.form_field_recievedBoxAccessTime =
        (result && result.recievedBoxAccessTime)
          ? result.recievedBoxAccessTime
          : "";
      updatePlan.form_field_Duration =
        (result && result.planDuration) ? result.planDuration : "";
      updatePlan.form_field_Storage =
        (result && result.planStorage) ? result.planStorage : "";
      // updatePlan.form_field_paypal_id =
      //  ( result && result.plan_id )? result.plan_id : "";
      updatePlan.form_field_privateRecipients =
        (result && result.privateRecipients) ? /* parseInt(result.privateRecipients )*/(result.privateRecipients): 0;

      //   updatePlan.form_field_priceAfterDiscount =
      //  ( result && result.discountedPlanPrice )? result.discountedPlanPrice : ""; // change plan_id
        updatePlan.form_field_marketerPlanDiscount =
        ( result && result.marketerPlanDiscount )? result.marketerPlanDiscount : "";
        updatePlan.form_field_marketerCommission =
       ( result && result.marketerCommission )? result.marketerCommission : "";

       setDiscountedValue(result.discountedPlanPrice);
       
    }
  }, [props.adminBoxPlanDataById]);

  // var pPrice2=0;
  var pDiscount2=0;
  const handleChange = (event: any) => {
    
    if(event.target.name == 'form_field_Price' ){
      
        if(event.target.name == 'form_field_marketerPlanDiscount'){
          event.target.value ='';
        }
     
        
      setPPrice1(event.target.value)

    }
    if(event.target.name == 'form_field_marketerPlanDiscount'){ // new
      setPDiscount1(event.target.value)
      pDiscount2= event.target.value;
    }
    if(pPrice1 && pDiscount2 ){
      
      let disPrice = pPrice1 - ((pPrice1 * pDiscount2)/100);
      let finalValue = '' + disPrice
      
      setDiscountedValue(finalValue);
    }
   
    setUpdatePlan({
      ...updatePlan,
      [event.target.name]: event.target.value,
    });
    //console.warn('-----------resultArray-----------------',resultArray)
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();

    let payload = {
      id: 0,
      planType: "",
      planCode: "",
      planPrice: "",
      privateBoxes: "",
      publicBoxes: "",
      recievedBoxes: "",
      totalBoxes: "",
      planDuration: "",
      planStorage: "",
      recievedBoxAccessTime: "",
      plan_id: "",
      privateRecipients: 0,
      marketerPlanDiscount:"",
      marketerCommission:"",
      discountedPlanPrice:""
    };
    payload.id = boxPlanId;
    payload.planType = updatePlan.form_field_Type;
    payload.planCode = updatePlan.form_field_Code;
    payload.planPrice = updatePlan.form_field_Price;
    payload.privateBoxes = updatePlan.form_field_privateBoxes;
    payload.publicBoxes = updatePlan.form_field_publicBoxes;
    payload.recievedBoxes = updatePlan.form_field_recievedBoxes;
    /* payload.totalBoxes= updatePlan.pPrivateBox+updatePlan.pPublicBox; */
    payload.planDuration = updatePlan.form_field_Duration;
    payload.planStorage = updatePlan.form_field_Storage;
    payload.recievedBoxAccessTime = updatePlan.form_field_recievedBoxAccessTime;
    // payload.plan_id = updatePlan.form_field_paypal_id;
    payload.privateRecipients= ~~updatePlan.form_field_privateRecipients;

    payload.marketerPlanDiscount = updatePlan.form_field_marketerPlanDiscount;
    payload.marketerCommission = updatePlan.form_field_marketerCommission;
    payload.discountedPlanPrice = discountedValue;
   

    props.updateAdminPlan(payload);
  };
  
 /*  useEffect(() => {
    if (props.updateAdminBoxPlanData) {
      alert("plan updated");
    }
  }, [props.updateAdminBoxPlanData]); */
  return (
    <div>
      <Modal {...props} show={props && props.show} size="lg" backdrop="static" scrollable="true" className="adminModal">
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#E79E1F" }}>Update Plan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Container>
              <Row>
                <Col md={6}>
                  <Form.Group
                    className="mb-3 mt-3 "
                    //controlId={data.field2}
                  >
                    <Form.Label style={{ color: "whitesmoke" }}>
                      Plan Name
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="form_field_Type"
                      value={updatePlan.form_field_Type}
                      onChange={handleChange}
                      autoFocus
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group
                    className="mb-3 mt-3 "
                    //controlId={data.field2}
                  >
                    <Form.Label style={{ color: "whitesmoke" }}>
                      Plan Code
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="form_field_Code"
                      value={updatePlan.form_field_Code}
                      onChange={handleChange}
                      autoFocus
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group
                    className="mb-3 mt-3 "
                    //controlId={data.field2}
                  >
                    <Form.Label style={{ color: "whitesmoke" }}>
                      Plan Price (R)
                    </Form.Label>
                    <Form.Control
                      type="number"
                      name="form_field_Price"
                      value={updatePlan.form_field_Price}
                      onChange={handleChange}
                      autoFocus
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group
                    className="mb-3 mt-3 "
                    //controlId={data.field2}
                  >
                    <Form.Label style={{ color: "whitesmoke" }}>
                      Plan Duration
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="form_field_Duration"
                      value={updatePlan.form_field_Duration}
                      onChange={handleChange}
                      autoFocus
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group
                    className="mb-3 mt-3 "
                    //controlId={data.field2}
                  >
                    <Form.Label style={{ color: "whitesmoke" }}>
                      Plan Storage
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="form_field_Storage"
                      value={updatePlan.form_field_Storage}
                      onChange={handleChange}
                      autoFocus
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group
                    className="mb-3 mt-3 "
                    //controlId={data.field2}
                  >
                    <Form.Label style={{ color: "whitesmoke" }}>
                      Private Boxes
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="form_field_privateBoxes"
                      value={updatePlan.form_field_privateBoxes}
                      onChange={handleChange}
                      autoFocus
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group
                    className="mb-3 mt-3 "
                    //controlId={data.field2}
                  >
                    <Form.Label style={{ color: "whitesmoke" }}>
                      Public Boxes
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="form_field_publicBoxes"
                      value={updatePlan.form_field_publicBoxes}
                      onChange={handleChange}
                      autoFocus
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group
                    className="mb-3 mt-3 "
                    //controlId={data.field2}
                  >
                    <Form.Label style={{ color: "whitesmoke" }}>
                      Recieved Boxes
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="form_field_recievedBoxes"
                      value={updatePlan.form_field_recievedBoxes}
                      onChange={handleChange}
                      autoFocus
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group
                    className="mb-3 mt-3 "
                    //controlId={data.field2}
                  >
                    <Form.Label style={{ color: "whitesmoke" }}>
                      Private Box Recipients
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="form_field_privateRecipients"
                      value={updatePlan.form_field_privateRecipients}
                      onChange={handleChange}
                      autoFocus
                    />
                  </Form.Group>
                </Col>

                <Col md={6}>
                  <Form.Group
                    className="mb-3 mt-3 "
                    //controlId={data.field2}
                  >
                    <Form.Label style={{ color: "whitesmoke" }}>
                      Recieved Boxes Duration
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="form_field_recievedBoxAccessTime"
                      value={updatePlan.form_field_recievedBoxAccessTime}
                      onChange={handleChange}
                      autoFocus
                    />
                  </Form.Group>
                </Col>
                
                 {/* marketer  */}
                 <Col md={6}>
                  <Form.Group
                    className="mb-3 mt-3 "
                    //controlId={data.field2}
                  >
                    <Form.Label style={{ color: "whitesmoke" }}>
                    Marketer Plan Discount(%)
                    </Form.Label>
                    <Form.Control
                      type="number"
                      name="form_field_marketerPlanDiscount"
                      value={updatePlan.form_field_marketerPlanDiscount}
                      onChange={handleChange}
                      autoFocus
                    />
                  </Form.Group>
                </Col>
                 <Col md={6}>
                  <Form.Group
                    className="mb-3 mt-3 "
                    //controlId={data.field2}
                  >
                    <Form.Label style={{ color: "whitesmoke" }}>
                      Price After Discount (R)
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="discountedValue"
                      value={ discountedValue} // this or that
                      // onChange={handleChange}
                      disabled
                      autoFocus
                    />
                  </Form.Group>
                </Col>
                 <Col md={6}>
                  <Form.Group
                    className="mb-3 mt-3 "
                    //controlId={data.field2}
                  >
                    <Form.Label style={{ color: "whitesmoke" }}>
                    Marketer Commission
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="form_field_marketerCommission"
                      value={updatePlan.form_field_marketerCommission}
                      onChange={handleChange}
                      autoFocus
                    />
                  </Form.Group>
                </Col>
                 
                
                {/* marketer  */}
                {/* <Col md={6}>
                  <Form.Group
                    className="mb-3 mt-3 "
                    //controlId={data.field2}
                  >
                    <Form.Label style={{ color: "whitesmoke" }}>
                      Paypal Plan Subscription Id
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="form_field_paypal_id"
                      value={updatePlan.form_field_paypal_id}
                      onChange={handleChange}
                      autoFocus
                    />
                  </Form.Group>
                </Col> */}
               

                {/* </>
                    );
                  })} */}
              </Row>
            </Container>
            <Row className="justify-content-md-center mt-10">
              <Col className="" md="auto">
                <Button className="backBtn"
                  variant="primary"
                  type="submit"
                  // onClick={handleSubmit}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        {/*  <Modal.Footer></Modal.Footer> */}
      </Modal>
    </div>
  );
};
const mapDispatchToProps = (dispatch: any) => ({
  //getAdminPlanById: (data: any) => dispatch(getAdminPlanById(data)),
  //addAdminPlans:(data: any) => dispatch(addAdminPlans(data))
  updateAdminPlan: (data: any) => dispatch(updateAdminPlan(data)),
});

const mapStateToProps = (state: any) => {
  return {
    adminBoxPlanDataById:(state.AdminBoxPlanReducer && state.AdminBoxPlanReducer.adminBoxPlanDataById)
        ? state.AdminBoxPlanReducer.adminBoxPlanDataById : null,
    updateAdminBoxPlanData: (state.AdminBoxPlanReducer && state.AdminBoxPlanReducer.updateAdminBoxPlanData)
        ? state.AdminBoxPlanReducer.updateAdminBoxPlanData : null,
    state,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePlanModal);
