export function otpFormValidation(value: any) {

    const passwordRegex = /^(?!.*\s)(?=.{6,})(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+=]).*$/;
    const regex         = /^[0-9\b]+$/;
    
    let errors:any={};

    if (value.OTP==='') {
        errors['OTP'] = 'OTP is required';
    }else if(!regex.test(value.OTP)){
        errors['OTP'] = 'Invalid input, OTP must contain only numbers';
    }else if((Object.keys(value.OTP).length < 4)){
        errors['OTP'] = 'OTP must be 4 digits'
    }
    
    if (value.newPassword === '') {
        errors['newPassword'] = 'New password is required';
    }else if ((Object.keys(value.newPassword).length < 8)) {
        errors['validNewPassword'] = 'Password must be 8 characters long';
    }else if (!passwordRegex.test(value.newPassword)) {
        errors['validNewPassword'] = 'Enter a valid password';
    }

    if (value.confirmPassword === '') {
        errors['confirmPassword'] = 'Confirm password is required';
    }else if ((Object.keys(value.confirmPassword).length < 8)) {
        errors['validConfirmPassword'] = 'Password must be 8 characters long';
    }else if (!passwordRegex.test(value.confirmPassword)) {
        errors['validConfirmPassword'] = 'Enter a valid password';
    }else if (value.newPassword !== value.confirmPassword) {
        errors['confirmPassword'] = 'Passwords do not match';
    }
    
    return errors;


}

