import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Spinner, Stack, Table } from 'react-bootstrap';
import { BsFillEyeFill,BsTrashFill } from 'react-icons/bs';
import { FaRupeeSign } from 'react-icons/fa';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { getAdminUserList } from '../../../../redux/actions/admin/adminUserAction';

const ActiveUsers = (props:any) => {

    const [userDataList, setUserDataList] = useState([]);
    const [loader,       setLoader]       = useState<boolean>(false);
    const navToUser                       = useNavigate();
    const navTo                           = useNavigate();
   
    useEffect(()=>{
        setLoader(true);
        props.getUserList();
    },[])

    useEffect(()=>{
        if(props.adminUserData){
            setUserDataList(props.adminUserData);
        }
    },[props.adminUserData])

    useEffect(() =>{
        if(userDataList.length != 0){
            setLoader(false);
        }
    },[userDataList])
    
    const handleUserView=(e:any)=>{
        let userId=e;
        if(userId){
          navToUser('/admin-user-profile',{ state:{id:userId }})
        }
    }
    const handleUserDisable=(e:any)=>{
      let userId=e;
      Swal.fire({
        title              : `Are you sure you want to delete this user ? `,
        showDenyButton     : true,
        showCancelButton   : false,
        confirmButtonText  : "YES",
        denyButtonText     : `NO`,
        confirmButtonColor : "green",
        showCloseButton    : true,
      }).then((result) => {
        if (result.isConfirmed) {
           props.InActiveUsers(userId);
        }
        props.getUserList();
      });
    }

    useEffect(()=>{
      if(props.disableAdminUserData)
      { props.getUserList(); }
    },[props.disableAdminUserData])

    const handlePaymentDetails=(e:any)=>{
      let userId=e;
      navTo('/admin-user-payment-details', {state:{id:userId}})
    }

//----------------------------------------------------------------//    
    let countActive = 0;
    return (
        <div className={loader ? `text-center scrollBar` : `scrollBar`}>
          {loader ?
                <div className="lds-dual-ring mt-5">
                    <span className="text-warning">Loading... please wait!</span>
                </div>
            :  
          <Table responsive>
            <thead>
              <tr>
                <th className="text-warning text-center">S.No.</th>
                <th className="text-warning text-center">User Name</th>
                <th className="text-warning text-center">Plan Type</th>
                <th className="text-warning text-center">Action</th>
                <th className="text-warning text-center">Payment Details</th>
              </tr>
            </thead>
            <tbody>
                {userDataList && userDataList.map((list: any, index: number) => {
                  
                    {
                    if(list.isActive==1) 
                        {
                          countActive = countActive+1;
                            return (
                            <>
                                <tr>
                                <td className="text-center">{countActive}</td>
                                <td className="text-center"> {list.firstName}  {list.lastName}</td>
                                <td className="text-center"> 
                                    {list.userHMpayments && list.userHMpayments[0].boxPlans && list.userHMpayments[0].boxPlans.planType ? list.userHMpayments[0].boxPlans.planType :"N/A" }
                                </td>
                                <td className="text-center">
                                    <button type="submit" className="btn btn-outline-warning m-2" onClick={()=>handleUserView(list.id)} ><BsFillEyeFill /></button>
                                    <button type="submit" className="btn btn-outline-danger" onClick={()=>handleUserDisable(list.id)} ><BsTrashFill/></button>
                                </td>
                                <td className="text-center">
                                    <button  className="btn btn-outline-warning m-2" onClick={()=>handlePaymentDetails(list.id)}>R</button> 
                                </td>
                                </tr>
                            </>
                            );
                        }
                        }})}
                </tbody>
            </Table>
            }
        </div> 
    );
};

  const mapDispatchToProps = (dispatch: any) => ({
    getUserList           : (data: any) => dispatch(getAdminUserList(data)),
  });
  
  const mapStateToProps = (state: any) => {
    return {
      adminUserData        : (state.AdminUserReducer && state.AdminUserReducer.adminUserData)        ? state.AdminUserReducer.adminUserData        : null,
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps) (ActiveUsers);