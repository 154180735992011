import React from "react";
import { Container } from "react-bootstrap";
import Layout from "../../layout/Layout";

const AboutUs = (props: any) => {
  return (
    <Layout>
      <div className={localStorage.length ? "" : "charity_publicBox"}>
        <Container>
          <div className="text-light">
            <div className="aboutUsMainContent">
              <div className="heading"><h2> About Us </h2></div>
              <p>At its core, BeyondBox is driven by the importance of family and the special connections we make during our limited time on Earth. The idea for creating BeyondBox arose from the tragic events our family experienced, where we lost six beloved family members within a span of just three years. After getting married in April 2018, my wife and I, along with our newly blended family, eagerly anticipated the memories we would create together. However, in December of that same year, my mother-in-law’s battle with stage 4 cancer took a turn, marking the beginning of a challenging period for our family. Sadly, she lost her fearlessly fought battle in March 2019, leaving my wife devastated and forcing me to confront the harsh reality of irreversible loss for the first time in my adult life.</p>
              <p>In 2019, my wife also lost her grandmother and just when we thought things couldn't get worse, the COVID-19 pandemic struck in 2020. In January 2021, I lost both of my parents within a month of each other, my wife’s uncle in August and  in January 2022 my father-in-law passed away as well.</p>
              <p>With each loss, we faced shock, sadness, and numerous administrative tasks. During this time, we found ourselves longing for guidance from our departed loved ones, wishing we had had conversations with them that were now impossible. We realized that while death is inevitable, we often shy away from having difficult conversations with our loved ones to prepare them for the inevitable moment. As a result, we don't express our feelings, share important information, or provide guidance that could ease the burden for our loved ones after we're gone. This realization inspired us to create a digital service that addresses this gap in communication that occurs when life ends abruptly.</p>
              <p>We are proud to offer a platform that we believe will bring comfort, closure, and peace to families in the aftermath of a loss for years to come. Thank you for visiting our site and taking the time to hear our story. We look forward to welcoming you to the BeyondBox family.</p>
              <p>
                Best regards,<br/>
                Kapil Ramdeyal<br/>
                Founder: BeyondBox
              </p>
            </div>
          </div>
        </Container>
      </div>
    </Layout>
  );
};

export default AboutUs;
