export function resetPasswordFormValidation(value: any) {
    const passwordRegex = /^(?!.*\s)(?=.{8,})(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+=]).*$/;

    let errors: any = {};
    if (value.password === '') {
        errors['password'] = 'Password is required';
    } else if (!passwordRegex.test(value.password)) {
        errors['password'] = 'Enter a valid password';
    }
    if (value.confirmPassword === '') {
        errors['confirmPassword'] = 'Password is required';
    } else if (!passwordRegex.test(value.confirmPassword)) {
        errors['confirmPassword'] = 'Enter a valid password';
    } else if (value.password !== value.confirmPassword) {
        errors['confirmPassword'] = 'Passwords do not match';
    }
    return errors;
}