import React, { Component, useState } from 'react';
import { Nav, Navbar, Button, Form, Container, Dropdown } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/Admin/images/beyondBoxLogo.svg';
import menu from '../../assets/Admin/images/hamburger.png';
import profileImage from '../../assets/Admin/images/user-no-image.png';
import { logoutUser } from '../../redux/actions/authAction';
import AdminModal from '../../pages/Admin/pages/adminModal/modal'
import ChangePassword from '../../pages/Admin/pages/change-password';

 const  Header = ( props:any) => {

  const [modalShow, setModalShow] = useState<boolean>(false);
  const closeModal = () =>{
    setModalShow(false);
  }

  const profileData    = JSON.parse(localStorage.getItem('profileData')||'{}');

  const navOut = useNavigate();

  const  handleLogout =()=> {
    localStorage.clear();
    props.logoutUser && props.logoutUser();
    navOut('/',{replace:true});
    return;
  }
  const handleDrawer = () => {
    // When screen size goes small, this toggle starts working
    props.handleDrawerOpen && props.handleDrawerOpen(false);
  };

  const changePassword = () =>{
    setModalShow(true);
  }
// -----------------------------------------------------------------------//
  
  return (
    <header className='admin_header'>
      <AdminModal backdrop="static" show={modalShow} onHide={closeModal} name="Change Password">
        <ChangePassword/>
      </AdminModal>
      <Container>
        <Navbar expand="md" variant="dark">
          <Navbar.Brand href="#home" className="d-md-none"><img src={logo} alt="" /></Navbar.Brand>
          <Button className="dotMenuBtn" onClick={handleDrawer}><img src={menu} alt="" /></Button>
          <div className="navLeft">
            <ul className='mb-0'>
              <li className="profile">
                <Dropdown align="end">
                  <Dropdown.Toggle id="dropdown-basic" >
                    <span className="profileImg"><img height={30} src={profileImage} alt="" /></span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {/* <Dropdown.Item href="#/action-1">profile</Dropdown.Item> */}
                    <Form.Label style={{fontSize:"20px",lineHeight:"15px",color:'#FFF'}}>
                      <b>{profileData.username}</b>
                      <br/><small style={{fontSize:"13px",color:"#fff"}}>{profileData.email}</small>
                    </Form.Label>
                    <hr style={{border:'1px solid #E79E1F',fontSize:"0px",margin:'0'}}/>
                    <Dropdown.Item className='p-1 rounded' style={{fontSize:"15px",marginBottom:'0'}} onClick={changePassword}>Change password</Dropdown.Item>
                    <hr style={{border:'1px solid #E79E1F',fontSize:"0px",margin:'0'}}/>
                    <Dropdown.Item className='p-1 rounded' onClick={handleLogout} style={{fontSize:"15px"}}>logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            </ul>
          </div>
        </Navbar>
      </Container>
    </header>
  )
}

const mapDispatchToProps = (dispatch: any) => ({
  logoutUser: ()=>dispatch(logoutUser()),
});

const mapStateToProps = (state: any) => {
  return {
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);