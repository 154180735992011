export const BASE_URLS = {
  // BASE_URL                                           : 'https://beyondbox.org:3000/',
  // BASE_URL                                           : "http://3.237.9.175:3000",
  // BASE_URL                                           : "http://3.235.147.127:3000",
  BASE_URL                                              : process.env.REACT_APP_API_BASE_URL,
  APP_BASE_URL                                          : process.env.REACT_APP_BASE_URL,
  API_VERSION                                           : "",
};

export const API_URLS = {
  CHECK_LOGIN_URL                                       : BASE_URLS.BASE_URL + "/users/login",
  SAVE_ACCOUNT_URL                                      : BASE_URLS.BASE_URL + "/users",
  FORGOT_PASSWORD_URL                                   : BASE_URLS.BASE_URL + "/users/reset-password/init",
  // CHECK_USER_OTP_URL                                 : BASE_URLS.BASE_URL + "/users/verifyOtp",
  CHECK_USER_OTP_URL                                    : BASE_URLS.BASE_URL + "/users/reset-password",
  // CHANGE_PASSWORD_URL                                : BASE_URLS.BASE_URL + "/users/reset-password/finish",
  GET_COUNTRIES_URL                                     : BASE_URLS.BASE_URL + "/countries",
  GET_STATES_URL                                        : BASE_URLS.BASE_URL + `/countries/{id}/states`,
  GET_CITIES_URL                                        : BASE_URLS.BASE_URL + `/states/{id}/cities`,
  GET_PROFILE_URL                                       : BASE_URLS.BASE_URL + "/users/me",
  GET_COUNTRY_ID_URL                                    : BASE_URLS.BASE_URL + `/users/{id}/country`,
  GET_STATE_ID_URL                                      : BASE_URLS.BASE_URL + `/users/{id}/state`,
  GET_DETAILS_USERNAME_URL                              : BASE_URLS.BASE_URL + `/users/findByUsername/{userName}`,
  GET_ALL_PLAN_URL                                      : BASE_URLS.BASE_URL + `/payments/user/{userId}/active-plans`,
  EDIT_PROFILE_URL                                      : BASE_URLS.BASE_URL + `/users/me/edit/{id}`,
  UPLOAD_PROFILE_IMAGE_URL                              : BASE_URLS.BASE_URL + `/users/users-upload/{id}`,
  UPDATE_USER_PROFILE_URL                               : BASE_URLS.BASE_URL + `/users/{id}`,
  GET_KIN_DETAILS_URL                                   : BASE_URLS.BASE_URL + `/kin-details?filter[where][userId]={id}`,
  GET_SUBSCRIPTION_PLAN_URL                             : BASE_URLS.BASE_URL + "/box-plans",
  SELECT_SUBSCRIPTION_PLAN_URL                          : BASE_URLS.BASE_URL + "/payment/freeplan",
  SELECT_PAID_SUBSCRIPTION_PLAN_URL                     : BASE_URLS.BASE_URL + `/payments`,
  // GET_BOX_COVER_PHOTO_URL                               : BASE_URLS.BASE_URL + "/box-cover-photos",
  GET_BOX_COVER_PHOTO_URL                               : BASE_URLS.BASE_URL + "/box-cover-photos?filter[where][uploadedBy]=Admin",
  GET_CURRENT_PLAN_URL                                  : BASE_URLS.BASE_URL + "/payments/user/currentplans",
  GET_USER_BY_NAME_URL                                  : BASE_URLS.BASE_URL + `/users/search?keyword={keyword}`,
  GET_USER_BY_EMAIL_URL                                 : BASE_URLS.BASE_URL + `/users/findByEmail/{email}`,
  GET_USER_BY_ID_URL                                    : BASE_URLS.BASE_URL + `/users/{userId}`,
  GET_BOX_ICON_URL                                      : BASE_URLS.BASE_URL + "/user-box-folders",
  CREATE_BOX_URL                                        : BASE_URLS.BASE_URL + "/user-boxes",
  // CANCEL_PLAN_URL                                    : BASE_URLS.BASE_URL + "/payments/cancelplan",
  CANCEL_PLAN_URL                                       : BASE_URLS.BASE_URL + "/payments/payfast/cancel",
  GET_BOX_LIST_URL                                      : BASE_URLS.BASE_URL + "/user/user-boxes",
  GET_CHANGE_PASSWORD_URL                               : BASE_URLS.BASE_URL + "/users/change-password-init",
  GET_OTP_VERIFIED                                      : BASE_URLS.BASE_URL + "/users/reset-password",
  GET_USER_STORAGE_URL                                  : BASE_URLS.BASE_URL + "/user/storage",
  GET_LOCATION_URL                                      : BASE_URLS.BASE_URL + "/users/{id}/city",
  GET_STATE_LOCATION_URL                                : BASE_URLS.BASE_URL + "/users/{id}/state",
  GET_COUNTRY_LOCATION_URL                              : BASE_URLS.BASE_URL + "/users/{id}/country",
  GET_BOXCOVER_IMAGE_BY_USER_UPLOAD_URL                 : BASE_URLS.BASE_URL + "/box-cover-photos/upload-file",
  
  // RECEIVED BOX
  GET_RECEIVED_BOX_LIST_URL                             : BASE_URLS.BASE_URL + "/box-recipients/recieved-boxes",
  GET_CHILD_RECEIVED_BOX_LIST_URL                       : BASE_URLS.BASE_URL + "/recipients/child-boxes",

  // CREATED BOX DETAILS
  // UPDATE RECIPIENT DETAILS--
  RECIPIENT_DETAILS_UPDATE_URL                          : BASE_URLS.BASE_URL + "/recipients/{id}/{boxId}",
  // GET_CREATED_BOX_DETAILS_URL                        : BASE_URLS.BASE_URL + "/user-boxes/{id}",
  GET_CREATED_BOX_DETAILS_URL                           : BASE_URLS.BASE_URL + "/user-boxes-details/{id}",

  UPLOAD_BOX_FILES                                      : BASE_URLS.BASE_URL + "/user-box-files/upload-file",
  UPLOAD_BOX_MESSAGE                                    : BASE_URLS.BASE_URL + "/user-box-message",

  // DELETE UPLOADED FILES BY ITS ID
  DELETE_UPLOADED_FILES_URL                             : BASE_URLS.BASE_URL + '/user-box-files/{id}',

  // FOLDER INSIDE CREATED BOX
  GET_UPLOADED_FILES_DETAILS_URL                        : BASE_URLS.BASE_URL + '/user-box-files/view-files/{box_id}/{box_folder_id}',
  
  //SCHEDULE BOX API 
  SCHEDULE_BOX_URL                                      : BASE_URLS.BASE_URL + `/user-boxes/schedule-box/{id}`,
  GET_BOX_BY_ID_URL                                     : BASE_URLS.BASE_URL + `/user-boxes/{id}`,
  UPDATE_BOX_URL                                        : BASE_URLS.BASE_URL + `/user-boxes/{id}`,
  DELETE_BOX_URL                                        : BASE_URLS.BASE_URL + `/user-boxes/{id}`,

  //LIST BOX CONTENT
  LIST_BOX_CONTENT_URL                                  : BASE_URLS.BASE_URL + `/user-box-files/view-recieved-files/{boxId}`,
  LIST_RECIEVED_BOX_CONTENT_URL                         : BASE_URLS.BASE_URL + `/user-box-files/view-recieved-items/{boxId}`,
  
  //LIST BOX RECORDS
  GET_PUBLIC_BOX_LIST_URL                               : BASE_URLS.BASE_URL + "/user/public-boxes?keyword={keyword}",
  GET_PUBLIC_BOX_DETAIL_URL                             : BASE_URLS.BASE_URL + `/user-box-files/view-public-data/{boxId}`,

  //Foundation Page
  GET_FOUNDATION_PAGE_URL                               : BASE_URLS.BASE_URL + '/foundation-data',

  // MESSAGE VIEW PAGE
  GET_UPDATE_MESSAGE_URL                                : BASE_URLS.BASE_URL + `/user-box-message/{id}`,

  //GET COVER PHOTO UPLOAD BY USER URL   
  GET_COVER_PHOTO_UPLOAD_BY_USER_URL                    : BASE_URLS.BASE_URL + `/box-cover-photos/upload-file`,
  
  // GET OTP URL FOR USER VERIFICATION
  GET_USER_VERIFICATION_OTP_URL                         : BASE_URLS.BASE_URL + `/users/OTPverify`,
  GET_USERVERIFICATION_RESEND_OTP_URL                   : BASE_URLS.BASE_URL + `/users/OTPresend`,

  // LOGIN OTP VERIFICATION URL
  GET_LOGIN_OTP_URL                                     : BASE_URLS.BASE_URL + `/users/TFA-OTPverify`,

  // PEOPLE AND RECYCLE BIN
  GET_USER_PEOPLE_URL                                   : BASE_URLS.BASE_URL + `/user-all-recipients`,
  // RECIPIENT_DETAILS_UPDATE_URL_FOR_MY_PEOPLE_PAGE       : BASE_URLS.BASE_URL + `/recipients/{id}`,
  RECIPIENT_DETAILS_UPDATE_URL_FOR_MY_PEOPLE_PAGE       : BASE_URLS.BASE_URL + `/recipients-data/{id}`,
  GET_RECYCLE_BIN_BOXES_URL                             : BASE_URLS.BASE_URL + `/user/deleted-boxes`,
  GET_RECYCLE_BIN_FILES_URL                             : BASE_URLS.BASE_URL + `/user-box-files/deleted-files`,
  RESTORE_ITEM_URL                                      : BASE_URLS.BASE_URL + `/user-box-files/restore-data`,
  DELETE_ITEM_PERMANENTLY_URL                           : BASE_URLS.BASE_URL + `/user-box-files/delete-data`,

  //CONTACT US
  GET_CONTACTUS_URL                                     : BASE_URLS.BASE_URL + `/contact-us`,

  GET_COUNTRY_DAIL_CODE_URL                             : BASE_URLS.BASE_URL + `/country-codes`,

  GET_OTP_TO_EDIT_PHONE_NO_URL                          : BASE_URLS.BASE_URL + `/users/edit-mobile`,
  GET_OTP_VERIFICATION_URL_TO_EDIT_PHONE_NO             : BASE_URLS.BASE_URL + `/users/verify-mobile`,
  
  //SAVE AUDIO/VIDEO LINKS
  GET_URL_TOSAVE_AUDIO_OR_VIDEO_LINKS                   : BASE_URLS.BASE_URL + `/user-box-files/links`,

  // RELATIONSHIP with RECIPIENTS (Old/New)
  GET_RELATIONSHIP_MASTER_DATA_URL                      : BASE_URLS.BASE_URL + `/relations`,

  // NEW RECIPIENT PROFILE PIC UPLOAD URL
  GET_NEW_RECIPIENT_PROFILE_PIC_UPLOAD_URL              : BASE_URLS.BASE_URL + `/user-boxes/recipient-image`,

  // GET REMOVE SCHEDULER URL
  REMOVE_SCHEDULER_URL                                  : BASE_URLS.BASE_URL + `/user-boxes-files/schedule-file-remove/{id}`,

  // MAIL UNSUBSCRIBED
  GET_MAIL_UNSUBSCRIBE_URL                              : BASE_URLS.BASE_URL + `/users/mail-unsubscribe`,

  // BOX RELEASE FOR USER FROM DIFF EMAIL
  GET_VERIFY_USER_URL                                   : BASE_URLS.BASE_URL + `/users/login-verify`,
  GET_URL_FOR_RELEASE_BOXES_FROM_DIFF_EMAIL             : BASE_URLS.BASE_URL + `/users/recipient-get-boxes`,

//------------------------------------------------------------------------------------------------------------------//

  // ADMIN  
  GET_ADMIN_BOX_PLAN_URL                                : BASE_URLS.BASE_URL + '/box-plans',
  GET_ADMIN_BOX_PLAN_BY_ID_URL                          : BASE_URLS.BASE_URL + `/box-plans/{id}`,
  ADD_ADMIN_BOX_PLAN_URL                                : BASE_URLS.BASE_URL + '/box-plans',
  UPDATE_ADMIN_BOX_PLAN_URL                             : BASE_URLS.BASE_URL + `/box-plans/{id}`,
  DELETE_ADMIN_BOX_PLAN_URL                             : BASE_URLS.BASE_URL + `/box-plans/{id}`,

  // ADMIN FOLDER
  ADD_ADMIN_FOLDER_URL                                  : BASE_URLS.BASE_URL + '/user-box-folders/upload-file',
  DELETE_ADMIN_FOLDER_URL                               : BASE_URLS.BASE_URL + `/user-box-folders/{id}`,
  UPDATE_ADMIN_FOLDER_URL                               : BASE_URLS.BASE_URL + `/user-box-folders/update-file/{id}`,
  GET_FOLDER_DETAIL_BY_ID_URL                           : BASE_URLS.BASE_URL + `/user-box-folders/{id}`,
  
  //ADMIN-COUNTRY
  GET_ADMIN_COUNTRIES_URL                               : BASE_URLS.BASE_URL + "/countries",
  GET_ADMIN_STATES_URL                                  : BASE_URLS.BASE_URL + `/countries/{id}/states`,
  GET_ADMIN_CITIES_URL                                  : BASE_URLS.BASE_URL + `/states/{id}/cities`,
  ADD_ADMIN_COUNTRIES_URL                               : BASE_URLS.BASE_URL + "/countries",
  GET_ADMIN_COUNTRIES_BY_ID_URL                         : BASE_URLS.BASE_URL + `/countries/{id}`,
  UPDATE_ADMIN_COUNTRIES_URL                            : BASE_URLS.BASE_URL + `/countries/{id}`,
  REMOVE_ADMIN_COUNTRIES_URL                            : BASE_URLS.BASE_URL + `/countries/{id}`,
  
  //ADMIN-COUNTRY-STATES
  ADD_ADMIN_STATES_URL                                  : BASE_URLS.BASE_URL + "/states",
  UPDATE_ADMIN_STATES_URL                               : BASE_URLS.BASE_URL + `/states/{id}`,
  REMOVE_ADMIN_STATES_URL                               : BASE_URLS.BASE_URL + `/states/{id}`,
  GET_ADMIN_STATE_BY_ID_URL                             : BASE_URLS.BASE_URL + `/states/{id}`,
  
  //ADMIN-COUNTRY-CITY
  ADD_ADMIN_CITY_URL                                    : BASE_URLS.BASE_URL + "/cities",
  UPDATE_ADMIN_CITY_URL                                 : BASE_URLS.BASE_URL + `/cities/{id}`,
  REMOVE_ADMIN_CITY_URL                                 : BASE_URLS.BASE_URL + `/cities/{id}`,
  GET_ADMIN_CITY_BY_ID_URL                              : BASE_URLS.BASE_URL + `/cities/{id}`,
  
  //ADMIN BOX API
  GET_ADMIN_COVER_PHOTOS_URL                            : BASE_URLS.BASE_URL + "/box-cover-photos",
  ADD_ADMIN_COVER_PHOTOS_URL                            : BASE_URLS.BASE_URL + "/box-cover-photos/upload-file",
  PUT_ADMIN_RELEASE_BOX_URL                             : BASE_URLS.BASE_URL + "/users/upload-dod/{userId}",
  GET_ADMIN_COVER_PHOTO_BY_ID_URL                       : BASE_URLS.BASE_URL + `/box-cover-photos/{id}`,
  REMOVE_ADMIN_COVER_PHOTO_URL                          : BASE_URLS.BASE_URL + `/box-cover-photos/{id}`,
  ACTIVATE_DEACTIVATE_USER_BY_ID_URL                    : BASE_URLS.BASE_URL + `/users-status/{userId}`,
  
  //ADMIN-USER API
  GET_ADMIN_USER_LIST_URL                               : BASE_URLS.BASE_URL + '/users-details',
  GET_ADMIN_USER_ADMIN_LIST_URL                         : BASE_URLS.BASE_URL + '/users?filter=%7B%0A%20%20%20%20%22where%22%3A%20%7B%0A%20%20%20%20%20%20%20%20%22roles%22%3A%20%5B%0A%20%20%20%20%20%20%20%20%20%20%20%20%22Admin%22%0A%20%20%20%20%20%20%20%20%5D%0A%7D%0A%7D',
  GET_ADMIN_USER_BY_ID_URL                              : BASE_URLS.BASE_URL + `/users/{userId}`,
  DISABLE_ADMIN_USER_BY_ID_URL                          : BASE_URLS.BASE_URL + `/users/{userId}`,
  GET_ADMIN_USER_BY_IDENTITY_URL                        : BASE_URLS.BASE_URL + `/users/findByIdentityNumber/{userIndentityNumber}`,
  GET_ADMIN_USER_PAYMENT_DETAILS_URL                    : BASE_URLS.BASE_URL + `/payments/user/{userId}/all-plans`,
  GET_URL_DOWNLOAD_CSV_FOR_ALL_USERS                    : BASE_URLS.BASE_URL + `/users-details/csv`,
  GET_UNSUBSCRIBED_MAIL_USER_LIST_URL                   : BASE_URLS.BASE_URL + `/users?filter[where][mailIsUnsubscribed]=1`,

  //ADMIN FOUNDATION
  ADD_ADMIN_FOUNDATION_URL                              : BASE_URLS.BASE_URL + '/foundation-data',
  GET_FOUNDATION_LIST_URL                               : BASE_URLS.BASE_URL + '/foundation-data',
  DELETE_ADMIN_FOUNDATION_URL                           : BASE_URLS.BASE_URL + `/foundation-data/{id}`,
  GET_FOUNDATION_DETAIL_BY_ID_URL                       : BASE_URLS.BASE_URL + `/foundation-data/{id}`,
  UPDATE_ADMIN_FOUNDATION_URL                           : BASE_URLS.BASE_URL + `/foundation-data/{id}`,

  //ADMIN MARKETER
  ADD_ADMIN_MARKETER_URL                                : BASE_URLS.BASE_URL + `/marketers`,
  GET_MARKETER_LIST_URL                                 : BASE_URLS.BASE_URL + '/marketers',
  DELETE_ADMIN_MARKETER_URL                             : BASE_URLS.BASE_URL + `/marketers/{id}`,
  GET_MARKETER_DETAIL_BY_ID_URL                         : BASE_URLS.BASE_URL + `/marketers/{id}`,
  UPDATE_ADMIN_MARKETER_URL                             : BASE_URLS.BASE_URL + `/marketers/{id}`,
  GET_MARKETER_PROMOCODE_URL                            : BASE_URLS.BASE_URL + '/marketer-promo-codes',
  GET_MARKETER_PROMOCODE_BY_ID_URL                      : BASE_URLS.BASE_URL + `/marketer-promo-codes/?filter[where][marketerId]={id}`,
  GET_USER_THROUGH_MARKETERS_URL                        : BASE_URLS.BASE_URL + '/users/marketer',
  GET_MARKETER_USERS_BY_ID_URL                          : BASE_URLS.BASE_URL + `/marketers/{id}/subscribed-users`,
  GET_UNSUBSCRIBED_USERS_URL                            : BASE_URLS.BASE_URL + `/marketers/{id}/non-subscribed-users`,
  GET_MT_HISTORY_URL                                    : BASE_URLS.BASE_URL + `/marketer-payfasts?filter[where][custom_str1]={id}`,
  GET_SUBSCRIBED_CSV_DOWNLOAD_URL                       : BASE_URLS.BASE_URL + `/marketers/{id}/subscribed-users-csv`,

//CATEGORYWISE SCHEDULER FOR UPLOADED FILES
  PATCH_CATEGORYWISE_SCHEDULER_URL                      : BASE_URLS.BASE_URL + `/user-box-files/schedule-items`,

};

export const COMMON_MESSAGE                             = "COMMON_MESSAGE";

export const AUTH = {
  LOGIN_REQUEST                                         : "LOGIN_REQUEST",
  LOGIN_SUCCESS                                         : "LOGIN_SUCCESS",
  REGISTER_REQUEST                                      : "REGISTER_REQUEST",
  REGISTER_SUCCESS                                      : "REGISTER_SUCCESS",
  FORGOT_PASSWORD                                       : "FORGOT_PASSWORD",
  CHECK_USER_OTP                                        : "CHECK_USER_OTP",
  // CHANGE_PASSWORD                                    : "CHANGE_PASSWORD",
  USER_LOGOUT                                           : "USER_LOGOUT",
  GET_DETAILS_USERNAME                                  : "GET_DETAILS_USERNAME",
  SET_DETAILS_USERNAME                                  : "SET_DETAILS_USERNAME",

  // VERIFY USER BY OTP
  GET_OTP_TO_VERIFY_USER                                : 'GET_OTP_TO_VERIFY_USER',
  SET_RESPONSE_ON_VALID_OTP                             : 'SET_RESPONSE_ON_VALID_OTP',
  GET_USERVERIFICATION_RESEND_OTP                       : 'GET_USERVERIFICATION_RESEND_OTP',

  // LOGIN OTP
  GET_LOGIN_OTP                                         : 'GET_LOGIN_OTP',
  SET_LOGIN_OTP_RESPONSE                                : 'SET_LOGIN_OTP_RESPONSE',

  // VERIFY USER FOR BOX RELEASE FROM DIFF EMAIL
  VERIFY_USER_FOR_BOX_RELEASE_FROM_DIFF_EMAIL           : 'VERIFY_USER_FOR_BOX_RELEASE_FROM_DIFF_EMAIL',
  GET_RESPONSE_USER_FOR_BOX_RELEASE_FROM_DIFF_EMAIL     : 'GET_RESPONSE_USER_FOR_BOX_RELEASE_FROM_DIFF_EMAIL',
};

export const COUNTRY_DATA = {
  GET_COUNTRIES                                         : "GET_COUNTRIES",
  SET_COUNTRIES_TO_STATE                                : "SET_COUNTRIES_TO_STATE",
  GET_STATES                                            : "GET_STATES",
  SET_STATES_TO_STATE                                   : "SET_STATES_TO_STATE",
  GET_CITIES                                            : "GET_CITIES",
  SET_CITIES_TO_STATE                                   : "SET_CITIES_TO_STATE",
  GET_COUNTRY_ID                                        : "GET_COUNTRY_ID",
  SET_COUNTRY_ID                                        : "SET_COUNTRY_ID",
  GET_LOCATION                                          : "GET_LOCATION",
  SET_LOCATION                                          : "SET_LOCATION",
  GET_STATE_LOCATION                                    : "GET_STATE_LOCATION",
  SET_STATE_LOCATION                                    : "SET_STATE_LOCATION",
  GET_COUNTRY_LOCATION                                  : "GET_COUNTRY_LOCATION",
  SET_COUNTRY_LOCATION                                  : "SET_COUNTRY_LOCATION",
};

export const USER_PROFILE = {
  GET_PROFILE_PAGE                                      : "GET_PROFILE_PAGE",
  SET_PROFILE_PAGE_TO_STATE                             : "SET_PROFILE_PAGE_TO_STATE",
  GET_EDIT_PROFILE                                      : "GET_EDIT_PROFILE",
  SET_EDIT_PROFILE_TO_STATE                             : "SET_EDIT_PROFILE_TO_STATE",
  UPLOAD_PROFILE_IMAGE                                  : "UPLOAD_PROFILE_IMAGE",
  SET_UPLOAD_PROFILE_IMAGE_TO_STATE                     : "SET_UPLOAD_PROFILE_IMAGE_TO_STATE",
  UPDATE_USER_PROFILE                                   : "UPDATE_USER_PROFILE",
  SET_UPDATE_USER_PROFILE_TO_STATE                      : "SET_UPDATE_USER_PROFILE_TO_STATE",
  GET_KIN_DETAILS                                       : "GET_KIN_DETAILS",
  SET_KIN_DETAILS_TO_STATE                              : "SET_KIN_DETAILS_TO_STATE",
  REQUEST_CHANGE_PASSWORD                               : "REQUEST_CHANGE_PASSWORD",
  RESPONSE_CHANGE_PASSWORD                              : "RESPONSE_CHANGE_PASSWORD",
  GET_STORAGE                                           : 'GET_STORAGE',
  SET_STORAGE                                           : 'SET_STORAGE',
  GET_OTP_TO_EDIT_PHONE_NO                              : 'GET_OTP_TO_EDIT_PHONE_NO',
  SET_OTP_TO_EDIT_PHONE_NO                              : 'SET_OTP_TO_EDIT_PHONE_NO',
  GET_OTP_VERIFICATION_TO_EDIT_PHONE_NO                 : 'GET_OTP_VERIFICATION_TO_EDIT_PHONE_NO',
  SET_OTP_VERIFICATION_TO_EDIT_PHONE_NO                 : 'SET_OTP_VERIFICATION_TO_EDIT_PHONE_NO'
};

export const SUBSCRIPTION_PLAN = {
  GET_SUBSCRIPTION_PLAN                                 : "GET_SUBSCRIPTION_PLAN",
  SET_SUBSCRIPTION_PLAN_TO_STATE                        : "SET_SUBSCRIPTION_PLAN_TO_STATE",
  SELECT_SUBSCRIPTION_PLAN                              : "SELECT_SUBSCRIPTION_PLAN",
  SET_SELECTED_SUBSCRIPTION_PLAN_TO_STATE               : "SET_SELECTED_SUBSCRIPTION_PLAN_TO_STATE",
  SELECT_PAID_SUBSCRIPTION_PLAN                         : "SELECT_PAID_SUBSCRIPTION_PLAN",
  SET_SELECTED_PAID_SUBSCRIPTION_PLAN_TO_STATE          : "SET_SELECTED_PAID_SUBSCRIPTION_PLAN_TO_STATE",
  GET_CURRENT_PLAN                                      : "GET_CURRENT_PLAN",
  SET_CURRENT_PLAN_TO_STATE                             : "SET_CURRENT_PLAN_TO_STATE",
  CANCEL_PLAN                                           : "CANCEL_PLAN",
  SET_CANCEL_PLAN_TO_STATE                              : "SET_CANCEL_PLAN_TO_STATE",
  GET_ALL_PLAN                                          : 'GET_ALL_PLAN',
  SET_ALL_PLAN_TO_STATE                                 : 'SET_ALL_PLAN_TO_STATE'
};

export const BOX_API = {
  GET_BOX_COVER_PHOTO                                   : "GET_BOX_COVER_PHOTO",
  SET_BOX_COVER_PHOTO_TO_STATE                          : "SET_BOX_COVER_PHOTO_TO_STATE",
  SELECT_BOX_COVER_PHOTO                                : "SELECT_BOX_COVER_PHOTO",
  SET_SELECTED_BOX_COVER_PHOTO_TO_STATE                 : "SET_SELECTED_BOX_COVER_PHOTO_TO_STATE",
  GET_USER_BY_NAME                                      : "GET_USER_BY_NAME",
  SET_RESULT_SEARCHED_BY_NAME                           : "SET_RESULT_SEARCHED_BY_NAME",
  GET_USER_BY_EMAIL                                     : "GET_USER_BY_EMAIL",
  SET_USER_BY_EMAIL_TO_STATE                            : "SET_USER_BY_EMAIL_TO_STATE",
  GET_USER_BY_ID                                        : "GET_USER_BY_ID",
  SET_USER_BY_ID_TO_STATE                               : "SET_USER_BY_ID_TO_STATE",
  GET_BOX_ICON                                          : "GET_BOX_ICON",
  SET_BOX_ICON_TO_STATE                                 : "SET_BOX_ICON_TO_STATE",
  CREATE_BOX                                            : "CREATE_BOX",
  SET_CREATE_BOX_TO_STATE                               : "SET_CREATE_BOX_TO_STATE",
  GET_BOX_LIST                                          : "GET_BOX_LIST",
  SET_BOX_LIST_TO_STATE                                 : "SET_BOX_LIST_TO_STATE",
  GET_PUBLIC_BOX_LIST                                   : "GET_PUBLIC_BOX_LIST",
  SET_PUBLIC_BOX_LIST_TO_STATE                          : "SET_PUBLIC_BOX_LIST_TO_STATE",
  UPLOAD_BOXCOVER_IMAGE_BY_USER                         : "UPLOAD_BOXCOVER_IMAGE_BY_USER", 
  GET_UPLOADED_COVER_PHOTO_BY_USER                      : "GET_UPLOADED_COVER_PHOTO_BY_USER", 
  
  //RECEIVED BOX
  GET_RECEIVED_BOX_LIST                                 : "GET_RECEIVED_BOX_LIST",
  SET_RECEIVED_BOX_LIST                                 : "SET_RECEIVED_BOX_LIST",
  GET_RECEIVED_BOX_DETAILS                              : "GET_RECEIVED_BOX_DETAILS",
  SET_RECEIVED_BOX_DETAILS                              : "SET_RECEIVED_BOX_DETAILS",

  //CHILD RECEIVED BOX
  GET_CHILD_RECEIVED_BOX_LIST                           : "GET_CHILD_RECEIVED_BOX_LIST",
  SET_CHILD_RECEIVED_BOX_LIST                           : "SET_CHILD_RECEIVED_BOX_LIST",
  

  // CREATED BOX
  SEND_RECIPIENT_DETAILS_FOR_UPDATE                     : 'SEND_RECIPIENT_DETAILS_FOR_UPDATE',
  GET_RESPONSE_AFTER_RECIPIENT_UPDATE                   : 'GET_RESPONSE_AFTER_RECIPIENT_UPDATE',
  GET_CREATED_BOX_DETAILS                               : 'GET_CREATED_BOX_DETAILS',
  SET_CREATED_BOX_DETAILS                               : 'SET_CREATED_BOX_DETAILS',
  UPLOAD_BOX_FILES                                      : 'UPLOAD_BOX_FILES',
  UPLOAD_BOX_FILES_SUCCESS                              : 'UPLOAD_BOX_FILES_SUCCESS',

  //DELETE UPLOADED FILES
  DELETE_UPLOADED_FILES                                 : 'DELETE_UPLOADED_FILES',
  DELETE_UPLOADED_FILES_SUCCESS                         : 'DELETE_UPLOADED_FILES_SUCCESS',

  // CREATED BOX INNER FOLDER(Example : Images,Videos etc)
  GET_UPLOADED_FILES_DETAILS                            : 'GET_UPLOADED_FILES_DETAILS',
  SET_UPLOADED_FILES_DETAILS                            : 'SET_UPLOADED_FILES_DETAILS',

  // SCHEDULE BOX
  SCHEDULE_BOX                                          : 'SCHEDULE_BOX',
  SET_SCHEDULE_BOX_TO_STATE                             : 'SET_SCHEDULE_BOX_TO_STATE',

  //get box by id
  GET_BOX_BY_ID                                         : 'GET_BOX_BY_ID',
  SET_BOX_BY_ID_TO_STATE                                : 'SET_BOX_BY_ID_TO_STATE',
 
  // UPDATE BOX
  UPDATE_BOX                                            : 'UPDATE_BOX',
  SET_UPDATE_BOX_TO_STATE                               : 'SET_UPDATE_BOX_TO_STATE',

  //delete box
  DELETE_BOX                                            : 'DELETE_BOX',
  SET_DELETE_BOX_TO_STATE                               : 'SET_DELETE_BOX_TO_STATE',
  LIST_BOX_CONTENT                                      : 'LIST_BOX_CONTENT',
  SET_LIST_BOX_CONTENT_TO_STATE                         : 'SET_LIST_BOX_CONTENT_TO_STATE',
  
  //BOX RECORDS
  GET_BOX_RECORDS                                       : 'GET_BOX_RECORDS',
  SET_BOX_RECORDS_TO_STATE                              : 'SET_BOX_RECORDS_TO_STATE',
  GET_PUBLIC_BOX_DETAIL_CONTENT                         : 'GET_PUBLIC_BOX_DETAIL_CONTENT',
  SET_PUBLIC_BOX_DETAIL_CONTENT                         : 'SET_PUBLIC_BOX_DETAIL_CONTENT',

  // CATEGORYWISE SCHEDULER ON UPLOADED FILES
  SET_CATEGORYWISE_SCHEDULER_ON_UPLOADED_FILES          : 'SET_CATEGORYWISE_SCHEDULER_ON_UPLOADED_FILES',
  GET_RESPONSE_CATEGORYWISE_SCHEDULER_ON_UPLOADED_FILES : 'GET_RESPONSE_CATEGORYWISE_SCHEDULER_ON_UPLOADED_FILES',

  // MESSAGE VIEW PAGE
  UPDATE_MESSAGE                                        : 'UPDATE_MESSAGE',
  GET_UPDATED_MESSAGE                                   : 'GET_UPDATED_MESSAGE',

  // UPLOAD COVER PHOTO BY USER
  UPLOAD_COVERPHOTO_BY_USER                             : 'UPLOAD_COVERPHOTO_BY_USER',
  GET_RESPONSE_AFTER_COVERPHOTO_UPLOAD                  : 'GET_RESPONSE_AFTER_COVERPHOTO_UPLOAD',

  //SAVE AUDIO/VIDEO LINKS
  SAVE_AUDIO_OR_VIDEO_LINKS                             : 'SAVE_AUDIO_OR_VIDEO_LINKS',
  SAVED_AUDIO_OR_VIDEO_LINK_RESPONSE                    : 'SAVED_AUDIO_OR_VIDEO_LINK_RESPONSE',

  // RELATIONSHIP with RECIPIENTS (Old/New)
  GET_RELATIONSHIP_MASTER_DATA                          : 'GET_RELATIONSHIP_MASTER_DATA',
  SET_RELATIONSHIP_MASTER_DATA                          : 'SET_RELATIONSHIP_MASTER_DATA',

  // RECIPIENT FLOW CHANGES
  UPLOAD_RECIPIENT_PROFILE_PIC                          : 'UPLOAD_RECIPIENT_PROFILE_PIC',
  SET_UPLOAD_RECIPIENT_PROFILE_PIC                      : 'SET_UPLOAD_RECIPIENT_PROFILE_PIC',

  //REMOVE BOX FILE SCHEDULER
  REMOVE_FILE_SCHEDULER                                 : 'REMOVE_FILE_SCHEDULER',
  GET_RESP_AFTER_SCHEDULER_REMOVE                       : 'GET_RESP_AFTER_SCHEDULER_REMOVE',

  // RELEASE BOX FOR DIFF EMAIL
  SUBMIT_RELEASE_BOX_CONFIRMATION                       : 'SUBMIT_RELEASE_BOX_CONFIRMATION',
  GET_RELEASE_BOX_CONFIRMATION_RESPONSE                 : 'GET_RELEASE_BOX_CONFIRMATION_RESPONSE',
}

export const LIST_USER={
  GET_USER_PEOPLE                                       : 'GET_USER_PEOPLE',
  SET_USER_PEOPLE_TO_STATE                              : 'SET_USER_PEOPLE_TO_STATE'
}

export const USER = {
  CONFIRM_MAIL_UNSUBSCRIPTION                           : 'CONFIRM_MAIL_UNSUBSCRIPTION',
  GET_MAIL_UNSUBSCRIBED_CONFRIMATION                    : 'GET_MAIL_UNSUBSCRIBED_CONFRIMATION',
}

export const RECYCLE_BIN={
  GET_RECYCLE_BIN_BOXES                                 : 'GET_RECYCLE_BIN_BOXES',
  SET_RECYCLE_BIN_BOXES_TO_STATE                        : 'SET_RECYCLE_BIN_BOXES_TO_STATE',
  GET_RECYCLE_BIN_FILES                                 : 'GET_RECYCLE_BIN_FILES',
  SET_RECYCLE_BIN_FILES_TO_STATE                        : 'SET_RECYCLE_BIN_FILES_TO_STATE',
  RESTORE_ITEM                                          : 'RESTORE_ITEM',
  SET_RESTORE_ITEM_TO_STATE                             : 'SET_RESTORE_ITEM_TO_STATE',
  DELETE_ITEM_PERMANENTLY                               : 'DELETE_ITEM_PERMANENTLY',
  GET_RESPONSE_AFTER_DELETED_ITEM                       : 'GET_RESPONSE_AFTER_DELETED_ITEM',
}

export const WEB_API={
  GET_CONTACTUS                                         : 'GET_CONTACTUS',
  SET_CONTACTUS                                         : 'SET_CONTACTUS',
  GET_COUNTRY_DAIL_CODE                                 : 'GET_COUNTRY_DAIL_CODE',
  SET_COUNTRY_DAIL_CODE                                 : 'SET_COUNTRY_DAIL_CODE',
}

export const  FOUNDATION_DATA={
  GET_FOUNDATION_PAGE                                   : 'GET_FOUNDATION_PAGE',
  SET_GET_FOUNDATION_PAGE_TO_STATE                      : 'SET_GET_FOUNDATION_PAGE_TO_STATE ',
}

export const ADMIN_BOX_API={
  GET_ADMIN_COVER_PHOTOS                                : 'GET_ADMIN_COVER_PHOTOS',
  ADD_ADMIN_COVER_PHOTOS                                : 'ADD_ADMIN_COVER_PHOTOS',
  REMOVE_ADMIN_COVER_PHOTOS                             : 'REMOVE_ADMIN_COVER_PHOTOS',
  GET_ADMIN_COVER_PHOTO_BY_ID                           : 'GET_ADMIN_COVER_PHOTO_BY_ID',
  PUT_ADMIN_RELEASE_BOX                                 : 'PUT_ADMIN_RELEASE_BOX',
  SET_ADMIN_COVER_PHOTOS_TO_STATE                       : 'SET_ADMIN_COVER_PHOTOS_TO_STATE',
  SET_ADD_ADMIN_COVER_PHOTOS_TO_STATE                   : 'SET_ADD_ADMIN_COVER_PHOTOS_TO_STATE',
  SET_ADMIN_COVER_PHOTO_BY_ID_TO_STATE                  : 'SET_ADMIN_COVER_PHOTO_BY_ID_TO_STATE',
  SET_REMOVE_ADMIN_COVER_PHOTOS_TO_STATE                : 'SET_REMOVE_ADMIN_COVER_PHOTOS_TO_STATE',
  SET_PUT_ADMIN_RELEASE_BOX_TO_STATE                    : 'SET_PUT_ADMIN_RELEASE_BOX_TO_STATE',
};

export const ADMIN_BOX_PLAN={
  GET_ADMIN_BOX_PLAN                                    : 'GET_ADMIN_BOX_PLAN',
  GET_ADMIN_BOX_PLAN_BY_ID                              : 'GET_ADMIN_BOX_PLAN_BY_ID',
  ADD_ADMIN_BOX_PLAN                                    : 'ADD_ADMIN_BOX_PLAN',
  UPDATE_ADMIN_BOX_PLAN                                 : 'UPDATE_ADMIN_BOX_PLAN',
  DELETE_ADMIN_BOX_PLAN                                 : 'DELETE_ADMIN_BOX_PLAN',
  SET_ADMIN_BOX_PLAN_TO_STATE                           : 'SET_ADMIN_BOX_PLAN_TO_STATE',      
  SET_ADMIN_BOX_PLAN_BY_ID_TO_STATE                     : 'SET_ADMIN_BOX_PLAN_BY_ID_TO_STATE', 
  SET_ADD_ADMIN_BOX_PLAN_TO_STATE                       : 'SET_ADD_ADMIN_BOX_PLAN_TO_STATE',   
  SET_UPDATE_ADMIN_BOX_PLAN_TO_STATE                    : 'SET_UPDATE_ADMIN_BOX_PLAN_TO_STATE',  
  SET_DELETE_ADMIN_BOX_PLAN_TO_STATE                    : 'SET_DELETE_ADMIN_BOX_PLAN_TO_STATE',
}

export const ADMIN_COUNTRY_DATA={
  GET_ADMIN_COUNTRIES                                   : 'GET_ADMIN_COUNTRIES',    
  GET_ADMIN_STATES                                      : 'GET_ADMIN_STATES',   
  GET_ADMIN_CITIES                                      : 'GET_ADMIN_CITIES',   
  GET_ADMIN_COUNTRIES_BY_ID                             : 'GET_ADMIN_COUNTRIES_BY_ID',
  ADD_ADMIN_COUNTRY                                     : 'ADD_ADMIN_COUNTRY',    
  UPDATE_ADMIN_COUNTRIES                                : 'UPDATE_ADMIN_COUNTRIES', 
  REMOVE_ADMIN_COUNTRIES                                : 'REMOVE_ADMIN_COUNTRIES', 
  ADD_ADMIN_STATES                                      : 'ADD_ADMIN_STATES',    
  UPDATE_ADMIN_STATES                                   : 'UPDATE_ADMIN_STATES',    
  REMOVE_ADMIN_STATES                                   : 'REMOVE_ADMIN_STATES',    
  GET_ADMIN_STATE_BY_ID                                 : 'GET_ADMIN_STATE_BY_ID',  
  ADD_ADMIN_CITY                                        : 'ADD_ADMIN_CITY',   
  UPDATE_ADMIN_CITY                                     : 'UPDATE_ADMIN_CITY',    
  REMOVE_ADMIN_CITY                                     : 'REMOVE_ADMIN_CITY',   
  GET_ADMIN_CITY_BY_ID                                  : 'GET_ADMIN_CITY_BY_ID',
  SET_ADMIN_COUNTRIES_TO_STATE                          : 'SET_ADMIN_COUNTRIES_TO_STATE',      
  SET_ADMIN_STATES_TO_STATE                             : 'SET_ADMIN_STATES_TO_STATE',      
  SET_ADMIN_CITIES_TO_STATE                             : 'SET_ADMIN_CITIES_TO_STATE',      
  SET_ADMIN_COUNTRIES_BY_ID_TO_STATE                    : 'SET_ADMIN_COUNTRIES_BY_ID_TO_STATE',
  SET_ADD_ADMIN_COUNTRY_TO_STATE                        : 'SET_ADD_ADMIN_COUNTRY_TO_STATE',    
  SET_UPDATE_ADMIN_COUNTRIES_TO_STATE                   : 'SET_UPDATE_ADMIN_COUNTRIES_TO_STATE',
  SET_REMOVE_ADMIN_COUNTRIES_TO_STATE                   : 'SET_REMOVE_ADMIN_COUNTRIES_TO_STATE',
  SET_ADD_ADMIN_STATES_TO_STATE                         : 'SET_ADD_ADMIN_STATES_TO_STATE',     
  SET_UPDATE_ADMIN_STATES_TO_STATE                      : 'SET_UPDATE_ADMIN_STATES_TO_STATE',  
  SET_REMOVE_ADMIN_STATES_TO_STATE                      : 'SET_REMOVE_ADMIN_STATES_TO_STATE',  
  SET_ADMIN_STATE_BY_ID_TO_STATE                        : 'SET_ADMIN_STATE_BY_ID_TO_STATE',    
  SET_ADD_ADMIN_CITY_TO_STATE                           : 'SET_ADD_ADMIN_CITY_TO_STATE',       
  SET_UPDATE_ADMIN_CITY_TO_STATE                        : 'SET_UPDATE_ADMIN_CITY_TO_STATE',    
  SET_REMOVE_ADMIN_CITY_TO_STATE                        : 'SET_REMOVE_ADMIN_CITY_TO_STATE',    
  SET_ADMIN_CITY_BY_ID_TO_STATE                         : 'SET_ADMIN_CITY_BY_ID_TO_STATE',     
};
   
export const ADMIN_USER={
  GET_ADMIN_USER_LIST                                   : 'GET_ADMIN_USER_LIST', 
  SET_ADMIN_USER_LIST_TO_STATE                          : 'SET_ADMIN_USER_LIST_TO_STATE',
  GET_ADMIN_USER_ADMIN_LIST                             : 'GET_ADMIN_USER_ADMIN_LIST', 
  SET_ADMIN_USER_ADMIN_LIST_TO_STATE                    : 'SET_ADMIN_USER_ADMIN_LIST_TO_STATE',
  GET_ADMIN_USER_BY_ID                                  : 'GET_ADMIN_USER_BY_ID',
  SET_ADMIN_USER_BY_ID_TO_STATE                         : 'SET_ADMIN_USER_BY_ID_TO_STATE',
  DISABLE_ADMIN_USER_BY_ID                              : 'DISABLE_ADMIN_USER_BY_ID',
  SET_DISABLE_ADMIN_USER_BY_ID_TO_STATE                 : 'SET_DISABLE_ADMIN_USER_BY_ID_TO_STATE',
  GET_ADMIN_USER_BY_IDENTITY                            : 'GET_ADMIN_USER_BY_IDENTITY',
  SET_ADMIN_USER_BY_IDENTITY_TO_STATE                   : 'SET_ADMIN_USER_BY_IDENTITY_TO_STATE',
  GET_ADMIN_USER_PAYMENT_DETAILS                        : 'GET_ADMIN_USER_PAYMENT_DETAILS ',
  SET_ADMIN_USER_PAYMENT_DETAILS_TO_STATE               : 'SET_ADMIN_USER_PAYMENT_DETAILS_TO_STATE',
  ACTIVATE_DEACTIVATE_USER_BY_ID                        : 'ACTIVATE_DEACTIVATE_USER_BY_ID',
  SET_ADMIN_ACTIVATE_DEACTIVATE_USER_BY_ID_TO_STATE     : 'SET_ADMIN_ACTIVATE_DEACTIVATE_USER_BY_ID_TO_STATE',
  REQUEST_DOWNLOAD_CSV                                  : 'REQUEST_DOWNLOAD_CSV',
  RESPONSE_DOWNLOAD_CSV                                 : 'RESPONSE_DOWNLOAD_CSV',
  GET_UNSUBSCRIBED_MAIL_USER_LIST                       : 'GET_UNSUBSCRIBED_MAIL_USER_LIST',
  SET_UNSUBSCRIBED_MAIL_USER_LIST                       : 'SET_UNSUBSCRIBED_MAIL_USER_LIST'
};

export const ADMIN_FOLDER_MANAGEMENT={
  GET_ADMIN_FOLDER_LIST                                 : 'GET_ADMIN_FOLDER_LIST',
  SET_ADMIN_FOLDER_LIST_TO_STATE                        : 'SET_ADMIN_FOLDER_LIST_TO_STATE',
  ADD_ADMIN_FOLDER                                      : 'ADD_ADMIN_FOLDER',
  SET_ADD_ADMIN_FOLDER_TO_STATE                         : 'SET_ADD_ADMIN_FOLDER_TO_STATE',
  DELETE_ADMIN_FOLDER                                   : 'DELETE_ADMIN_FOLDER',
  SET_DELETE_ADMIN_FOLDER_TO_STATE                      : 'SET_DELETE_ADMIN_FOLDER_TO_STATE',
  UPDATE_ADMIN_FOLDER                                   : 'UPDATE_ADMIN_FOLDER',
  SET_UPDATE_ADMIN_FOLDER_TO_STATE                      : 'SET_UPDATE_ADMIN_FOLDER_TO_STATE',
  GET_FOLDER_DETAIL_BY_ID                               : 'GET_FOLDER_DETAIL_BY_ID',
  SET_GET_FOLDER_DETAIL_BY_ID_TO_STATE                  : 'SET_GET_FOLDER_DETAIL_BY_ID_TO_STATE',
  
}
export const ADMIN_FOUNDATION={
  GET_FOUNDATION_LIST                                   : 'GET_FOUNDATION_LIST',
  SET_GET_FOUNDATION_LIST_TO_STATE                      : 'SET_GET_FOUNDATION_LIST_TO_STATE',

  ADD_ADMIN_FOUNDATION                                  : 'ADD_ADMIN_FOUNDATION',
  SET_ADD_ADMIN_FOUNDATION_TO_STATE                     : 'SET_ADD_ADMIN_FOUNDATION_TO_STATE',

  DELETE_ADMIN_FOUNDATION                               : 'DELETE_ADMIN_FOUNDATION',
  SET_DELETE_ADMIN_FOUNDATION_TO_STATE                  : 'SET_DELETE_ADMIN_FOUNDATION_TO_STATE',
  
  GET_FOUNDATION_DETAIL_BY_ID                           : 'GET_FOUNDATION_DETAIL_BY_ID',
  SET_GET_FOUNDATION_DETAIL_BY_ID_TO_STATE              : 'SET_GET_FOUNDATION_DETAIL_BY_ID_TO_STATE',
  
  UPDATE_ADMIN_FOUNDATION                               : 'UPDATE_ADMIN_FOUNDATION',
  SET_UPDATE_ADMIN_FOUNDATION_TO_STATE                  : 'SET_UPDATE_ADMIN_FOUNDATION_TO_STATE',
  
}

export const ADMIN_MARKETER={
  GET_MARKETER_LIST                                   : 'GET_MARKETER_LIST',
  SET_GET_MARKETER_LIST_TO_STATE                      : 'SET_GET_MARKETER_LIST_TO_STATE',

  ADD_ADMIN_MARKETER                                  : 'ADD_ADMIN_MARKETER',
  SET_ADD_ADMIN_MARKETER_TO_STATE                     : 'SET_ADD_ADMIN_MARKETER_TO_STATE',

  DELETE_ADMIN_MARKETER                               : 'DELETE_ADMIN_MARKETER',
  SET_DELETE_ADMIN_MARKETER_TO_STATE                  : 'SET_DELETE_ADMIN_MARKETER_TO_STATE',
  
  GET_MARKETER_DETAIL_BY_ID                           : 'GET_MARKETER_DETAIL_BY_ID',
  SET_GET_MARKETER_DETAIL_BY_ID_TO_STATE              : 'SET_GET_MARKETER_DETAIL_BY_ID_TO_STATE',
  
  UPDATE_ADMIN_MARKETER                               : 'UPDATE_ADMIN_MARKETER',
  SET_UPDATE_ADMIN_MARKETER_TO_STATE                  : 'SET_UPDATE_ADMIN_MARKETER_TO_STATE',

  GET_MARKETER_PROMOCODE                              : 'GET_MARKETER_PROMOCODE',
  SET_GET_MARKETER_PROMOCODE_TO_STATE                 : 'SET_GET_MARKETER_PROMOCODE_TO_STATE',

  GET_MARKETER_PROMOCODE_BY_ID                        : 'GET_MARKETER_PROMOCODE_BY_ID',
  SET_GET_MARKETER_PROMOCODE_BY_ID_TO_STATE           : 'SET_GET_MARKETER_PROMOCODE_BY_ID_TO_STATE',
  
  GET_USER_THROUGH_MARKETERS                          : 'GET_USER_THROUGH_MARKETERS',
  SET_GET_USER_THROUGH_MARKETERS_TO_STATE             : 'SET_GET_USER_THROUGH_MARKETERS_TO_STATE',
  
  GET_MARKETER_USERS_BY_ID                            : 'GET_MARKETER_USERS_BY_ID',
  SET_GET_MARKETER_USERS_BY_ID_TO_STATE               : 'SET_GET_MARKETER_USERS_BY_ID_STATE',

  GET_UNSUBSCRIBED_USERS                              : 'GET_UNSUBSCRIBED_USERS',
  SET_GET_UNSUBSCRIBED_USERS_TO_STATE                 : 'SET_GET_UNSUBSCRIBED_USERS_STATE',

  GET_MT_HISTORY                                      : 'GET_MT_HISTORY',
  SET_GET_MT_HISTORY_TO_STATE                         : 'SET_GET_MT_HISTORY_STATE',

  DOWNLOAD_SUBSCRIBED_CSV                             : 'DOWNLOAD_SUBSCRIBED_CSV',
  RESPONSE_DOWNLOAD_CSV                               : 'RESPONSE_DOWNLOAD_CSV',
}
