import {RECYCLE_BIN} from "../constant/index";

const initialState = {
  loading        : false,
  recycleBoxData : null,
  recycleFileData: null,
  restoreItemData: null,
  deletedItemData: null
}

const RecycleBinReducer = (state = initialState, action) => {

  switch (action.type) {    

  case RECYCLE_BIN.SET_RECYCLE_BIN_BOXES_TO_STATE:
    return { ...state, recycleBoxData: action.data, loading: false,
    };
  
  case RECYCLE_BIN.SET_RECYCLE_BIN_FILES_TO_STATE:
    return { ...state, recycleFileData: action.data, loading: false,
    };
  
  case RECYCLE_BIN.SET_RESTORE_ITEM_TO_STATE:
    return { ...state, restoreItemData: action.data, loading: false,
      };
  
  case RECYCLE_BIN.GET_RESPONSE_AFTER_DELETED_ITEM:
    return { ...state, deletedItemData: action.data, loading: false,
    };    
  
  default:
    return state;
  }
};

export default RecycleBinReducer