
import { Button, Form, Modal, ProgressBar } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { uploadBoxFiles,saveLinks } from '../../redux/actions/boxAction';
import { getUserStorage } from '../../redux/actions/profileAction';
import { connect } from 'react-redux';
import useRecorder from "./useRecorder";
import { checkExtension } from "../../validations/audio_fileExtestion_Validation";

const UploadFilesModal = (props: any) => {
    let   prfileData = JSON.parse(localStorage.getItem('profileData')||'{}');
    let   [audioURL, isRecording, startRecording, stopRecording, blob, resetRecording]: any = useRecorder();
    const [showRecordingUpload, setShowRecordingUpload] = useState(false);
    const [title,               setTitle]               = useState('');
    const [message,             setMessage]             = useState('');
    const [result,              setResult]              = useState({ status: '', message: '' });
    const [fileName,            setFileName]            = useState<any>('');
    const [fileSize,            setFileSize]            = useState(0);
    const [selectedFilesCounter,setSelectedFilesCounter]= useState(0);
    const [percentage,          setPercentage]          = useState<any>(null);
    const [videoUI,             setVideoUI]             = useState('upload');
    const [audioUI,             setAudioUI]             = useState('upload');
    const [itemScheduler,       setItemScheduler]       = useState(false);
    const [months,              setMonths]              = useState<any>('');
    const [years,               setYears]               = useState<any>('');
    const [seconds,             setSeconds]             = useState(0);
    const [minutes,             setMinutes]             = useState(0);
    const [selectedFiles,       setSelectedFiles]       = useState(false);
    const [audiolinks,          setAudioLinks]          = useState<any>('');
    const [videolinks,          setVideoLinks]          = useState<any>(''); 
         
    const schedulerMessage = 'Schedule the release of this content. If left unchecked this content will be released immediately upon our verification of your passing.'
    // const schedulerMessage = '( Please select when, after your death this content should be released. You can leave this blank/unchecked, if the content should be released immediately upon our verification of your passing. )';
    // const urlRegex         = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&=]*)/;
    var   timer    : any;
    let   inputRef : any;
    let   ext = (props.boxIconData && props.boxIconData.filter((item:any) => item.folderName === props.selectedMedia));
    
    let extArray = (ext && ext[0] && ext[0].filesExtension && ext[0].filesExtension.split(','));
    
    const youtube_urlRegex = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    
    const handleUpload = async (e: any) => {
        e.preventDefault();
        // Message block with scheduler-----
        if (props.selectedMedia === 'Messages') {
            if(itemScheduler){
                if(((years != null && months != null) && (years == 0 && months == 0)) || (!years && months) && (months == 0) || (years && !months) && (years == 0)){
                    setResult({ status: 'bg-danger', message: `Invalid scheduler` });    
                }else if((!years && months) && (months != 0)){
                    setResult({ status: 'bg-danger', message: `Years is required` });    
                }else if((years && !months) && (years != 0)){
                    setResult({ status: 'bg-danger', message: `Months is required` });    
                }else if(!years && !months){
                    setResult({ status: 'bg-danger', message: `Years & Months, required to add a scheduler on an item` });    
                }else if(((years != null && months != null))){
                    if (title.trim() && message) {
                        props.uploadBoxFiles({
                            "boxId"          : props.boxId,
                            "boxFolderId"    : props.selectedMediaFolder,
                            "boxFileTitle"   : title,
                            "boxMessage"     : message,
                            "boxFileType"    : props.selectedMedia,
                            "scheduled_year" : years,
                            "scheduled_month": months,
                        });
                    } else if (!(title.trim()) && !(message)) {
                        setResult({ status: 'bg-danger', message: 'Title and Message are required' });
                    } else if (!(title.trim())) {
                        setResult({ status: 'bg-danger', message: 'Title is required' });
                    } else if (!(message)) {
                        setResult({ status: 'bg-danger', message: 'Message is required' });
                    }
                }
           }else{
            // Message block without scheduler-----
                if (title.trim() && message) {
                    props.uploadBoxFiles({
                        "boxId"       : props.boxId,
                        "boxFolderId" : props.selectedMediaFolder,
                        "boxFileTitle": title,
                        "boxMessage"  : message,
                        "boxFileType" : props.selectedMedia,
                    });
                } else if (!(title.trim()) && !(message)) {
                    setResult({ status: 'bg-danger', message: 'Title and Message are required' });
                } else if (!(title.trim())) {
                    setResult({ status: 'bg-danger', message: 'Title is required' });
                } else if (!(message)) {
                    setResult({ status: 'bg-danger', message: 'Message is required' });
                }
            }
            
        } else {
            
            var selectedFilesExtArr : any = [];
            var RecFileExt : any = '';
            var checkDuplicate = false;
            const formData = new FormData();
            var linkPayload : any = {};
                        
            if(props.selectedMedia === 'Audio' && audioUI === 'record'){
                var filename = (Math.random() + 1).toString(36).substring(7) + '.webm';
                var file     = blobToFile(blob, filename);
                RecFileExt   = file.name.split('.').pop();
                formData.append("boxFileName", file );
                formData.append("boxFileTitle", filename);

            }else if(props.selectedMedia === 'Audio' && audioUI === 'links'){
                linkPayload = {
                    "boxId"      : props.boxId,
                    "userId"     : prfileData.id,
                    "boxFolderId": props.selectedMediaFolder,
                    "boxFileType": props.selectedMedia,
                    "links"      : audiolinks,
                }

                if(itemScheduler){
                    linkPayload = {
                        ...({...linkPayload,"scheduled_year":years, "scheduled_month":months }),
                    };
                }
            }else if(props.selectedMedia === 'Videos' && videoUI === 'links'){
                linkPayload = {
                    "boxId"      : props.boxId,
                    "userId"     : prfileData.id,
                    "boxFolderId": props.selectedMediaFolder,
                    "boxFileType": props.selectedMedia,
                    "links"      : videolinks,
                }
                
                if(itemScheduler){
                    linkPayload = {
                        ...({...linkPayload,"scheduled_year":years, "scheduled_month":months }),
                    };
                }
            }else{
                for(let i = 0; i < inputRef.files.length; i++){
                    formData.append(`boxFileName`, inputRef.files[i] );
                    formData.append(`boxFileTitle[${i}]`, inputRef.files[i].name);
                    setFileSize(fileSize+inputRef.files[i].size);
                    checkDuplicate = selectedFilesExtArr.includes(inputRef.files[i].name.split('.').pop());
                    if(!checkDuplicate){
                        selectedFilesExtArr.push(inputRef.files[i].name.split('.').pop().toLowerCase());
                    }
                }
            }
            
            formData.append("boxId", props.boxId);
            formData.append("boxFolderId", props.selectedMediaFolder);
            formData.append("boxFileType", props.selectedMedia);
            if(itemScheduler){ // Checks if scheduler checkbox is selected
                formData.append("scheduled_year", years);
                formData.append("scheduled_month", months);
            }
            
            let payLoad = 
                    {
                        _parts: {
                            data: formData,
                            onUploadProgress: (ProgressEvent: any) => {
                                const { loaded, total } = ProgressEvent;
                                const percent = Math.floor(loaded * 100 / total);                      
                                setPercentage(percent);
                            }
                        }
                    }
            
            if(props.selectedMedia === 'Audio' && audioUI === 'links' && audiolinks){
                if(itemScheduler){
                    if(((years != null && months != null) && (years == 0 && months == 0)) || (!years && months) && (months == 0) || (years && !months) && (years == 0)){
                        setResult({ status: 'bg-danger', message: `Invalid scheduler` });    
                    }else if((!years && months) && (months != 0)){
                        setResult({ status: 'bg-danger', message: `Years is required` });    
                    }else if((years && !months) && (years != 0)){
                        setResult({ status: 'bg-danger', message: `Months is required` });    
                    }else if(!years && !months){
                        setResult({ status: 'bg-danger', message: `Years & Months, required to add a scheduler on an item` });    
                    }else if((years != null && months != null)){
                        if(!checkExtension(audiolinks,extArray)){
                            setResult({ status: 'bg-danger', message: `Invalid URL, please enter valid URL` });    
                        }else{
                            if(youtube_urlRegex.test(audiolinks)){
                                setResult({ status: 'bg-danger', message: `Invalid URL, please enter valid audio URL` });    
                            }else if(checkExtension(audiolinks,extArray)){
                                setResult({ status: '', message: '' });
                                setPercentage(0);
                                props.saveLinks(linkPayload);
                            }
                        }
                    }
                }else{
                    if(!checkExtension(audiolinks,extArray)){
                        setResult({ status: 'bg-danger', message: `Invalid URL, please enter valid URL` });    
                    }else{
                        if(youtube_urlRegex.test(audiolinks)){
                            setResult({ status: 'bg-danger', message: `Invalid URL, please enter valid audio URL` });    
                        }else if(checkExtension(audiolinks,extArray)){
                            setResult({ status: '', message: '' });
                            setPercentage(0);
                            props.saveLinks(linkPayload);
                        }
                    }
                }
            }else if(props.selectedMedia === 'Videos' && videoUI === 'links' && videolinks){
                if(itemScheduler){
                    if(((years != null && months != null) && (years == 0 && months == 0)) || (!years && months) && (months == 0) || (years && !months) && (years == 0)){
                        setResult({ status: 'bg-danger', message: `Invalid scheduler` });    
                    }else if((!years && months) && (months != 0)){
                        setResult({ status: 'bg-danger', message: `Years is required` });    
                    }else if((years && !months) && (years != 0)){
                        setResult({ status: 'bg-danger', message: `Months is required` });    
                    }else if(!years && !months){
                        setResult({ status: 'bg-danger', message: `Years & Months, required to add a scheduler on an item` });    
                    }else if((years != null && months != null)){
                        if(!youtube_urlRegex.test(videolinks)){
                            if(!checkExtension(videolinks,extArray)){
                                setResult({ status: 'bg-danger', message: `Invalid URL, please enter valid URL` });    
                            }
                        }else{
                            if(youtube_urlRegex.test(videolinks)){
                                setResult({ status: '', message: '' });
                                setPercentage(0);
                                props.saveLinks(linkPayload);    
                            }else{
                                setResult({ status: 'bg-danger', message: `Invalid URL, please enter valid youtube link` });    
                            }
                        }
                    }
                }else{
                    if(!youtube_urlRegex.test(videolinks)){
                        if(!checkExtension(videolinks,extArray)){
                            setResult({ status: 'bg-danger', message: `Invalid URL, please enter valid URL` });    
                        }
                    }else{
                        if(youtube_urlRegex.test(videolinks)){
                            setResult({ status: '', message: '' });
                            setPercentage(0);
                            props.saveLinks(linkPayload);    
                        }else{
                            setResult({ status: 'bg-danger', message: `Invalid URL, please enter valid youtube link` });    
                        }
                    }
                }
            }else       
            if ((selectedFiles && inputRef && inputRef.files.length !== 0) || (props.selectedMedia === 'Audio' && audioUI === 'record')) {

                var invalidExt: any = [];
                
                if(props.selectedMedia === 'Audio' && audioUI === 'record'){
                    var allowedRecExtArr = [(ext && ext[0] && ext[0].filesExtension && ext[0].filesExtension.split(",").filter((item:any) => item === 'webm'))];
                    if(allowedRecExtArr != RecFileExt){
                        invalidExt.push(RecFileExt);
                    }
                }else{
                    var allowedExtArrList = (ext && ext[0] && ext[0].filesExtension && ext[0].filesExtension.split(","));
                    for(let j = 0; j < selectedFilesExtArr.length; j++){
                        if(!allowedExtArrList.includes(selectedFilesExtArr[j])){
                            invalidExt.push(selectedFilesExtArr[j]);
                        } 
                    }
                }
                
                if(invalidExt.length === 0 ){
                    if(itemScheduler){
                        if(((years != null && months != null) && (years == 0 && months == 0)) || (!years && months) && (months == 0) || (years && !months) && (years == 0)){
                            setResult({ status: 'bg-danger', message: `Invalid scheduler` });    
                        }else if((!years && months) && (months != 0)){
                            setResult({ status: 'bg-danger', message: `Years is required` });    
                        }else if((years && !months) && (years != 0)){
                            setResult({ status: 'bg-danger', message: `Months is required` });    
                        }else if(!years && !months){
                            setResult({ status: 'bg-danger', message: `Years & Months, required to add a scheduler on an item` });    
                        }else
                        if((years != null && months != null)){
                            setResult({ status: '', message: '' });
                            if((Number(fileSize) <= Number(props.userStorage.remaining_space))){
                                props.uploadBoxFiles(payLoad) // upload the selected file(s)
                                props.getUserStorage();
                            }else if((Number(fileSize) > Number(props.userStorage.remaining_space) && Number(props.userStorage.remaining_space) !== 0)){
                                setResult({ status: 'bg-danger', message: `Selected file size is greater than space left.` }); 
                            }else if(Number(props.userStorage.remaining_space) === 0){
                                if(result.message === ''){
                                    setResult({ status: 'bg-danger', message: `Can't upload,your upload limit reached to maximum` }); 
                                }      
                            }
                        }
                    }else{
                        if(Number(fileSize) <= Number(props.userStorage.remaining_space)){
                            props.uploadBoxFiles(payLoad) // upload the selected file(s)
                        }else if((Number(fileSize) > Number(props.userStorage.remaining_space) && Number(props.userStorage.remaining_space) !== 0)){
                            setResult({ status: 'bg-danger', message: `Selected file(s) size is greater than space left.` }); 
                        }else if(Number(props.userStorage.remaining_space) === 0){
                            if(result.message === ''){
                                setResult({ status: 'bg-danger', message: `Can't upload,your upload limit reached to maximum` }); 
                            }     
                        }
                    }    
                } else {
                    // setResult({ status: 'bg-danger', message: `File extension(s) ${invalidExt} does not supported for ${props.selectedMedia.toLowerCase()}` });
                    setResult({ status: 'bg-danger', message: `File extension is not supported` });
                }
            } else {
                
                if(audioUI === 'links' && audiolinks === '' || videoUI === 'links' && videolinks === ''){
                    setResult({ status: 'bg-danger', message: `${audioUI === 'links' && audiolinks === '' ? 'Audio' : (videoUI === 'links' && videolinks === '' && 'Video')} link is required` });
                }else if(audioUI !== 'record'){
                    setResult({ status: 'bg-danger', message: `Please select ${props.selectedMedia === 'Images' || props.selectedMedia === 'Audio' ? 'an' : 'a'} ${props.selectedMedia === 'Audio' ? props.selectedMedia.toLowerCase() + " file" : props.selectedMedia.substring(0, props.selectedMedia.length - 1).toLowerCase()} before uploading` });
                }
                
            }
        }
    }

    useEffect(() => {
        setFileName('');
        setItemScheduler(false);
        setResult({ status: '', message: '' });
        setAudioUI('upload');
        setVideoUI('upload');
        setAudioLinks('');
        setVideoLinks('');
        setSelectedFilesCounter(0);
    }, [props.show])

    useEffect(() => {
        if(props.message && props.message && props.message.data.length !== 0){
            setResult({ status: 'bg-danger', message: (props.message && props.message.data) ? props.message.data : '' });
        }
    }, [props.message])
    
    useEffect(() => {
        if (props.fileUploaded) {
            if(props.fileUploaded.code === 404 && props.fileUploaded.status === 'error' && props.selectedMedia === 'Messages'){
                setResult({ status: 'bg-danger', message: (props.selectedMedia === 'Messages') ? props.fileUploaded.message : '' });
            }else{
                setTitle('');
                setYears('');
                setMonths('');
                setMessage('');
                setFileName('');
                setPercentage(null);
                props.getUserStorage();
                setItemScheduler(false);
                setSelectedFilesCounter(0);
                setShowRecordingUpload(false);
                resetRecording();
                setSelectedFiles(false);
                setAudioLinks('');
                setVideoLinks('');
                let uploadedFilesMessage = ((inputRef && inputRef.files && inputRef.files.length !== 0 && inputRef.files.length === 1) ? `${inputRef.files.length} file uploaded successfully!` : ((inputRef && inputRef.files && inputRef.files.length !== 0 && inputRef.files.length > 1) ? `${inputRef.files.length} files uploaded successfully!` : '') )
                let linkSaveMessage      = (props.fileUploaded.links && props.fileUploaded.links) && (audioUI === 'links' ? 'Audio link saved successfully' : videoUI === 'links' ? 'Video link saved successfully' : '');
                setResult({ status: 'bg-success', message: (props.selectedMedia === 'Messages') ? 'Message saved successfully!' : (uploadedFilesMessage ? uploadedFilesMessage : linkSaveMessage ? linkSaveMessage : 'Recording uploaded successfully') });
            }
        }
    }, [props.fileUploaded])

    const handleChange = (e:any) => {
        setResult({ status: '', message: '' })
        if(e.target.value){
            setSelectedFiles(true);
            setSelectedFilesCounter(inputRef.files.length)
        }
    }

    const blobToFile = (theBlob: Blob, fileName: string): File => {
        return new File(
            [theBlob as any],
            fileName,
            {
                lastModified: new Date().getTime(),
                type: theBlob.type
            }
        )
    }
    
    useEffect(() => {
        if (blob)
            setShowRecordingUpload(true);
        else
            setShowRecordingUpload(false);
            clearInterval(timer);
            setMinutes(0);
            setSeconds(0);
            setItemScheduler(false);
    }, [blob])

    useEffect(() => { 
        if (result && result.status === 'text-success')
            setShowRecordingUpload(false);
    }, [result])

    useEffect(() => {
        setResult({ status: '', message: '' });
    }, [isRecording])

    const handleItemScheduler = () =>{
        setResult({ status: '', message: '' });
        setYears(null);
        setMonths(null);
        setFileName('');
        if(itemScheduler){
            setItemScheduler(!itemScheduler);
        }else{
            setItemScheduler(!itemScheduler);
        }
    }

    const handleYear = (e:any) =>{
        setYears(e.target.value);
    }

    const handleMonth = (e:any) =>{
        setMonths(e.target.value);
    }

    useEffect(() =>{
        if(isRecording){
            timer = setInterval(() =>{
                setSeconds(seconds+1);
                if(seconds === 59){
                    setMinutes(minutes+1);
                    setSeconds(0);
                }
            },1000);
            return () => clearInterval(timer);
        }
    })

//-------------------------------------------------------------------------------------//

    return (
        <Modal backdrop="static" keyboard={false} className="uploadModal" {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={(props && props.show)} >
            <Modal.Header closeButton={percentage ? false : true} className="border-0 pb-0 pt-1" onClick={resetRecording}></Modal.Header>
            <Modal.Body style={props.selectedMedia === 'Messages' ? { height: 525} : ((props.selectedMedia === 'Audio' && audioUI === 'record' && blob) ? {height:550} : { height: 450})}>
                <div style={{display: 'flex', flexDirection: 'column', height: '100%', alignItems: 'center'}}>
                    {   
                        // MESSAGE BLOCK
                        (props.selectedMedia === 'Messages') ? 
                            <div className='text-warning'>
                                <h4 className='mb-3'>Add Message</h4>
                                    <h6 className="text-light" style={{cursor:"pointer"}}>
                                        <input type="checkbox" style={{cursor:"pointer"}} checked={itemScheduler} onClick={handleItemScheduler}/>&nbsp;<span onClick={handleItemScheduler}>Schedule</span>
                                        <br/><small className='text-warning small'>{schedulerMessage}</small>
                                    </h6>
                                {
                                    itemScheduler && 
                                    <> 
                                        <div className='row justify-content-center mb-2 pt-2' style={fileName ? {} : {}}>
                                            <div className='col-auto' style={{whiteSpace: 'nowrap'}}>
                                                <label className='text-light'>Years</label>&nbsp;
                                                <select className='form-control text-warning' style={{background:'none',border:'none',borderBottom:'3px solid yellow',textAlign:'center',display:'inline-table',width:"75px"}} onChange={handleYear} value={years}>
                                                    <option value=''>Select</option>
                                                    {[...Array.from(Array(11).keys())].map((item:any)=><option className='text-dark' value={item}>{item}</option>)}
                                                </select>
                                            </div>
                                            <div className='col-auto' style={{whiteSpace: 'nowrap'}}>
                                                <label className='text-light'>Months</label>&nbsp;
                                                <select className='form-control text-warning' style={{background:'none',border:'none',borderBottom:'3px solid yellow',color:'black',textAlign:'center',display:'inline-table',width:"75px"}} onChange={handleMonth} value={months}>
                                                    <option value=''>Select</option>
                                                    {[...Array.from(Array(13).keys())].map((item:any)=><option className='text-dark' value={item}>{item}</option>)}
                                                </select>
                                            </div>
                                        </div>
                                    </>
                                }
                                <div className='pb-2 pt-2'>
                                    <label>Title<small className='text-danger'>*</small></label>
                                    <input type="text" className='form-control' value={title} onChange={(e) => setTitle(e.target.value)} />
                                </div>
                                <div>
                                    <label htmlFor="">Message<small className='text-danger'>*</small></label>
                                    <textarea className="message-text form-control" value={message} onChange={(e) => setMessage(e.target.value)}> </textarea>
                                </div>
                                <div className="file-upload-button text-center" style={{width: '100%'}}>
                                    <Button style={{ color: "#fff" }} className="uploadBtn" size="sm" onClick={handleUpload} type="button">Save</Button>
                                </div>
                            </div>
                    : 
                    //---------------------------------------------------------------------------------//
                        // AUDIO BLOCK
                        (props.selectedMedia === 'Audio') ? 
                            <div>
                                {audioUI === 'links' ?
                                    <h4>
                                        Add Audio Link<br/><br/>
                                    </h4>
                                :
                                    <h4>{audioUI === 'record' ? 'Record' : 'Upload'} Audio<br />
                                        <small className='text-danger'>{props.selectedMedia === 'Audio' && (ext && ext[0] && ext[0].filesExtension && audioUI === 'record' ? `( ${ext[0].filesExtension.split(',').filter((item:any) => item === 'webm')} )` : audioUI === 'upload' && `( ${ext[0].filesExtension.split(',').filter((item:any) => item !== 'webm')} )`)}</small> 
                                    </h4>
                            
                                }
                                <>
                                    {!showRecordingUpload && props.selectedMedia === 'Audio' && (ext && ext[0] && ext[0].filesExtension && audioUI === 'record') || (percentage === 0 || percentage) ?
                                        <span className="text-secondary" style={{cursor:"not-allowed"}}>
                                            <input type="checkbox" disabled={true} checked={itemScheduler} style={{cursor:"not-allowed"}}/>&nbsp;Schedule
                                            <br/><small className='text-secondary'>{schedulerMessage}</small><br/>
                                        </span>
                                    :
                                        <span className="text-light" style={{cursor:"pointer"}} onClick={handleItemScheduler}>
                                            <input type="checkbox" style={{cursor:"pointer"}} checked={itemScheduler}/>&nbsp;Schedule
                                            <br/><small className='text-warning'>{schedulerMessage}</small><br/>
                                        </span>            
                                    }
                                    <div className='text-warning mt-2'>
                                        {/* upload */}
                                        <input disabled={audioUI === 'record' && ((percentage === 0 || percentage) || isRecording) ? true : false} style={audioUI === 'record' && ((percentage === 0 || percentage) || isRecording) ? {cursor:"not-allowed"} : {cursor:"pointer"}} type="radio" onClick={() => {setAudioUI('upload');setResult({ status: '', message: ''})}} name="audio" checked={audioUI === 'upload' ? true : false}/>&nbsp;{audioUI === 'record' && ((percentage === 0 || percentage) || isRecording) ? <span className='text-secondary' style={{cursor:'not-allowed'}}>Upload</span> : <span onClick={() => {setAudioUI('upload');setResult({ status: '', message: ''})}}>Upload</span>}&nbsp;&nbsp;
                                        
                                        {/* record */}
                                        <input disabled={(audioUI === 'upload' || audioUI === 'links') && ( percentage === 0 || percentage) ? true : false} style={(audioUI === 'upload' || audioUI === 'links') && (percentage === 0 || percentage) ? {cursor:"not-allowed"} : {cursor:"pointer"}} type="radio" onClick={() => {setAudioUI('record');setResult({ status: '', message: ''})}} name="audio" checked={audioUI === 'record' ? true : false}/>&nbsp;{audioUI === 'upload' && (percentage === 0 || percentage) ? <span className='text-secondary' style={{cursor:'not-allowed'}}>Record</span> : <span onClick={() => {setAudioUI('record');setResult({ status: '', message: ''})}}>Record</span>}&nbsp;&nbsp;
                                        
                                        {/* links */}
                                        {/* <input disabled={audioUI === 'record' && ((percentage === 0 || percentage) || isRecording) ? true : false} style={audioUI === 'record' && ((percentage === 0 || percentage) || isRecording) ? {cursor:"not-allowed"} : {cursor:"pointer"}} type="radio" onClick={() => {setAudioUI('links'); setResult({ status: '', message: ''});setVideoUI('upload')}} name="audio" checked={audioUI === 'links' ? true : false}/>&nbsp;{audioUI === 'record' && ((percentage === 0 || percentage) || isRecording) ? <span className='text-secondary' style={{cursor:'not-allowed'}}>links</span>  : <span onClick={() => {setAudioUI('links'); setResult({ status: '', message: ''});setVideoUI('upload')}}>Links</span>} */}
                                    </div>
                                </>
                                {
                                    audioUI === 'record' ? // RECORDING BLOCK FOR AUDIO
                                    <div> 
                                         {
                                            itemScheduler && showRecordingUpload &&
                                            <>
                                                <div className='row justify-content-center pt-2'>
                                                    <div className='col-auto' style={{whiteSpace: 'nowrap'}}>
                                                        <label className='text-light'>Years</label>&nbsp;
                                                        <select className='form-control text-warning' style={{background:'none',border:'none',borderBottom:'3px solid yellow',textAlign:'center',display:'inline-table',width:"75px"}} onChange={handleYear} value={years}>
                                                            <option value=''>Select</option>
                                                            {[...Array.from(Array(11).keys())].map((item:any)=><option className='text-dark' value={item}>{item}</option>)}
                                                        </select>
                                                        
                                                    </div>
                                                    <div className='col-auto' style={{whiteSpace: 'nowrap'}}>
                                                        <label className='text-light'>Months</label>&nbsp;
                                                        <select className='form-control text-warning' style={{background:'none',border:'none',borderBottom:'3px solid yellow',color:'black',textAlign:'center',display:'inline-table',width:"75px"}} onChange={handleMonth} value={months}>
                                                            <option value=''>Select</option>
                                                            {[...Array.from(Array(13).keys())].map((item:any)=><option className='text-dark' value={item}>{item}</option>)}
                                                        </select>
                                                    </div>
                                                </div>
                                            </>
                                        }   
                                        <div className='recordInput pt-3'>
                                            <div className='text-center'>
                                                {
                                                    !showRecordingUpload ? 
                                                        <div className='bg-light text-danger p-2 rounded text-center d-inline-block' style={!isRecording || isRecording ? {cursor:'not-allowed'} : {}}>
                                                            [ <span className={`${isRecording ? `blink` : ``}`}>&#11044; </span>REC ]&nbsp;&nbsp;&nbsp;
                                                            <span className=''>{(minutes < 10) ? '0'+minutes : minutes } : {(seconds < 10) ? '0'+seconds : seconds }</span>
                                                        </div> 
                                                    : 
                                                        <><audio src={audioURL} controls/></>
                                                }    

                                                <div style={!isRecording || isRecording ? {cursor:'not-allowed', paddingTop: '10px'} : {paddingTop: '10px'}}>
                                                    <Button style={{ color: "#fff" }} className="uploadBtn" size="sm" onClick={startRecording} disabled={!isRecording && !blob ? false : true}>Start</Button>
                                                    <Button style={{ color: "#fff", marginLeft: 10 }} className="uploadBtn" size="sm" onClick={stopRecording} disabled={!isRecording}>Stop</Button>
                                                    <Button style={{ color: "#fff", marginLeft: 10 }} className="uploadBtn" size="sm" onClick={resetRecording} disabled={!audioURL} >Reset</Button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="file-upload-button text-center" style={itemScheduler ? { width: '100%',marginTop:'10px'} : { width: '100%',marginTop:'10px' }}>
                                            {(showRecordingUpload) && <Button style={{ color: "#fff" }} className="uploadBtn" size="sm" onClick={handleUpload} type="button">Upload</Button> }
                                        </div>
                                    </div>
                                :
                                    audioUI === 'upload' ? // UPLOADING BLOCK FOR AUDIO
                                    <div className='row justify-content-center pt-2' style={itemScheduler ? {} : {}}>
                                        <div className='col-md-4'  style={itemScheduler ? {padding: "10px 0px 0"} : {padding: "10px 0px 0"}}>
                                            <label htmlFor="file" className='customeFileInput'>Select</label>
                                            <input className="form-control ps-2 customInput" type="file" style={{width: '112px', margin: '0 auto'}} id="file" name={'selectedFile'} 
                                                accept={ (props.selectedMedia === 'Audio' && audioUI === 'upload') ? 'audio/mp3' : '' } 
                                                ref={refParam => inputRef = refParam} onChange={handleChange} multiple/>
                                            {/* <strong className='text-warning' title={fileName} style={{ display: "block", whiteSpace: "break-spaces", overflow: "hidden", textOverflow: "ellipsis" }}>{fileName && fileName.length > 15 ? fileName.substring(0,15)+'...' : fileName }</strong> */}
                                            <div className='text-center'>
                                                <strong className='text-warning'>{selectedFilesCounter !== 0 && (selectedFilesCounter === 1 ? `${selectedFilesCounter} file selected`  : '')}</strong>
                                                <strong className='text-warning'>{selectedFilesCounter !== 0 && (selectedFilesCounter  >  1 ? `${selectedFilesCounter} files selected` : '')}</strong>
                                            </div>
                                        </div>
                                        {
                                            itemScheduler &&
                                            <>
                                                <div className='col-md-8' style={fileName ? {padding: "5px 0 0px"} : {padding: "5px 0 0px"}}>
                                                    <div className='row justify-content-center'>
                                                    <div className='col-auto' style={{whiteSpace: 'nowrap'}}>
                                                        <label className='text-light'>Years</label>&nbsp;
                                                        <select className='form-control text-warning' style={{background:'none',border:'none',borderBottom:'3px solid yellow',textAlign:'center',display:'inline-table',width:"75px"}} onChange={handleYear} value={years}>
                                                            <option value=''>Select</option>
                                                            {[...Array.from(Array(11).keys())].map((item:any)=><option className='text-dark' value={item}>{item}</option>)}
                                                        </select>
                                                    </div>
                                                    <div className='col-auto' style={{whiteSpace: 'nowrap'}}>
                                                        <label className='text-light'>Months</label>&nbsp;
                                                        <select className='form-control text-warning' style={{background:'none',border:'none',borderBottom:'3px solid yellow',color:'black',textAlign:'center',display:'inline-table',width:"75px"}} onChange={handleMonth} value={months}>
                                                            <option value=''>Select</option>
                                                            {[...Array.from(Array(13).keys())].map((item:any)=><option className='text-dark' value={item}>{item}</option>)}
                                                        </select>
                                                    </div>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        <div className="file-upload-button text-center" style={{ width: '100%'}}>
                                            <Button style={{ color: "#fff" }} className="uploadBtn" size="sm" onClick={handleUpload} type="button">Upload</Button>
                                        </div>
                                    </div>
                                :
                                    audioUI === 'links' && // LINK BLOCK FOR AUDIO
                                    <div className='row justify-content-center pt-2' style={itemScheduler ? {} : {}}>
                                        <div className='col-md-4'  style={itemScheduler ? {padding: "10px 0px 0"} : {padding: "10px 0px 0"}}>
                                            <Form.Control placeholder='Enter audio link' name='audiolinks' value={audiolinks} onChange={(e) => {setAudioLinks(e.target.value);setVideoLinks('');}}/>
                                        </div>
                                        {
                                            itemScheduler &&
                                            <>  
                                                <br/>
                                                <div className='col-md-8'>
                                                    <div className='row justify-content-center'>
                                                    <div className='col-auto' style={{whiteSpace: 'nowrap'}}>
                                                        <label className='text-light'>Years</label>&nbsp;
                                                        <select className='form-control text-warning' style={{background:'none',border:'none',borderBottom:'3px solid yellow',textAlign:'center',display:'inline-table',width:"75px"}} onChange={handleYear} value={years}>
                                                            <option value=''>Select</option>
                                                            {[...Array.from(Array(11).keys())].map((item:any)=><option className='text-dark' value={item}>{item}</option>)}
                                                        </select>
                                                    </div>
                                                    <div className='col-auto' style={{whiteSpace: 'nowrap'}}>
                                                        <label className='text-light'>Months</label>&nbsp;
                                                        <select className='form-control text-warning' style={{background:'none',border:'none',borderBottom:'3px solid yellow',color:'black',textAlign:'center',display:'inline-table',width:"75px"}} onChange={handleMonth} value={months}>
                                                            <option value=''>Select</option>
                                                            {[...Array.from(Array(13).keys())].map((item:any)=><option className='text-dark' value={item}>{item}</option>)}
                                                        </select>
                                                    </div>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        <div className="file-upload-button text-center" style={{ width: '100%'}}>
                                            <Button style={{ color: "#fff" }} className="uploadBtn" size="sm" onClick={handleUpload} type="button">Save</Button>
                                        </div>
                                    </div>
                                }
                            </div>
                        :
                        //---------------------------------------------------------------------------------//
                            // VIDEO BLOCK
                            (props.selectedMedia === 'Videos') ? 
                                <div>
                                    {videoUI === 'links' ?
                                        <h4>
                                            Add Video Links<br/><br/>
                                        </h4>
                                    :
                                        <h4>{videoUI === 'upload' && 'Upload'} Videos<br />
                                            <small className='text-danger'>{props.selectedMedia === 'Videos' ? (ext && ext[0] && ext[0].filesExtension ? `( ${ext[0].filesExtension} )`: '( mp4 )' ): ''}</small> 
                                        </h4>
                                    }
                                    <span className="text-light" style={{cursor:"pointer"}} onClick={handleItemScheduler}>
                                        <input type="checkbox" style={{cursor:"pointer"}} checked={itemScheduler}/>&nbsp;Schedule
                                        <br/><small className='text-warning'>{schedulerMessage}</small><br/>
                                    </span> 
                                    <div className='text-warning mt-2'>
                                        {/* <input disabled={( percentage === 0 || percentage )? true : false} style={((percentage === 0 || percentage)) ? {cursor:"not-allowed"} : {cursor:"pointer"}} type="radio" onClick={() => {setVideoUI('upload');setResult({ status: '', message: ''})}} name="video" checked={videoUI === 'upload' ? true : false}/>&nbsp;{((percentage === 0 || percentage)) ? <span className='text-secondary' style={{cursor:'not-allowed'}}>Upload</span> : <span onClick={() => {setVideoUI('upload');setResult({ status: '', message: ''})}}>Upload</span>}&nbsp;&nbsp; */}
                                        {/* <input disabled={( percentage === 0 || percentage )? true : false} style={((percentage === 0 || percentage)) ? {cursor:"not-allowed"} : {cursor:"pointer"}} type="radio" onClick={() => {setVideoUI('links'); setResult({ status: '', message: ''});setAudioUI('upload')}} name="video" checked={videoUI === 'links'  ? true : false}/>&nbsp;{((percentage === 0 || percentage)) ? <span className='text-secondary' style={{cursor:'not-allowed'}}>links</span>  : <span onClick={() => {setVideoUI('links'); setResult({ status: '', message: ''});setAudioUI('upload')}}>Links</span>} */}
                                    </div>
                                
                                    {
                                        videoUI === 'upload' ? // UPLOADING BLOCK FOR VIDEO
                                            <div className='row justify-content-center pt-2' style={itemScheduler ? {} : {}}>
                                                <div className='col-md-4'  style={itemScheduler ? {padding: "10px 0px 0"} : {padding: "10px 0px 0"}}>
                                                    <label htmlFor="file" className='customeFileInput'>Select</label>
                                                    <input className="form-control ps-2 customInput" type="file" style={{width: '112px', margin: '0 auto'}} id="file" name={'selectedFile'} 
                                                        accept={ (props.selectedMedia === 'Videos' && videoUI === 'upload') ? 'video/mp4' : '' } 
                                                        ref={refParam => inputRef = refParam} onChange={handleChange} multiple/>
                                                    {/* <strong className='text-warning' title={fileName} style={{ display: "block", whiteSpace: "break-spaces", overflow: "hidden", textOverflow: "ellipsis" }}>{fileName && fileName.length > 15 ? fileName.substring(0,15)+'...' : fileName }</strong> */}
                                                    <div className='text-center'>
                                                        <strong className='text-warning'>{selectedFilesCounter !== 0 && (selectedFilesCounter === 1 ? `${selectedFilesCounter} file selected`  : '')}</strong>
                                                        <strong className='text-warning'>{selectedFilesCounter !== 0 && (selectedFilesCounter  >  1 ? `${selectedFilesCounter} files selected` : '')}</strong>
                                                    </div>
                                                </div>
                                                {
                                                    itemScheduler &&
                                                    <>
                                                        <div className='col-md-8' style={fileName ? {padding: "5px 0 0px"} : {padding: "5px 0 0px"}}>
                                                            <div className='row justify-content-center'>
                                                            <div className='col-auto' style={{whiteSpace: 'nowrap'}}>
                                                                <label className='text-light'>Years</label>&nbsp;
                                                                <select className='form-control text-warning' style={{background:'none',border:'none',borderBottom:'3px solid yellow',textAlign:'center',display:'inline-table',width:"75px"}} onChange={handleYear} value={years}>
                                                                    <option value=''>Select</option>
                                                                    {[...Array.from(Array(11).keys())].map((item:any)=><option className='text-dark' value={item}>{item}</option>)}
                                                                </select>
                                                            </div>
                                                            <div className='col-auto' style={{whiteSpace: 'nowrap'}}>
                                                                <label className='text-light'>Months</label>&nbsp;
                                                                <select className='form-control text-warning' style={{background:'none',border:'none',borderBottom:'3px solid yellow',color:'black',textAlign:'center',display:'inline-table',width:"75px"}} onChange={handleMonth} value={months}>
                                                                    <option value=''>Select</option>
                                                                    {[...Array.from(Array(13).keys())].map((item:any)=><option className='text-dark' value={item}>{item}</option>)}
                                                                </select>
                                                            </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                                <div className="file-upload-button text-center" style={{ width: '100%'}}>
                                                    <Button style={{ color: "#fff" }} className="uploadBtn" size="sm" onClick={handleUpload} type="button">Upload</Button>
                                                </div>
                                            </div>
                                    :
                                        videoUI === 'links' && // LINK BLOCK FOR AUDIO
                                        <div className='row justify-content-center pt-2' style={itemScheduler ? {} : {}}>
                                            <div className='col-md-4'  style={itemScheduler ? {padding: "10px 0px 0"} : {padding: "10px 0px 0"}}>
                                                <Form.Control placeholder='Enter video link' name='videolinks' value={videolinks} onChange={(e) => {setVideoLinks(e.target.value);setAudioLinks('');}}/>
                                            </div>
                                            {
                                                itemScheduler &&
                                                <>  
                                                    <br/>
                                                    <div className='col-md-8'>
                                                        <div className='row justify-content-center'>
                                                        <div className='col-auto' style={{whiteSpace: 'nowrap'}}>
                                                            <label className='text-light'>Years</label>&nbsp;
                                                            <select className='form-control text-warning' style={{background:'none',border:'none',borderBottom:'3px solid yellow',textAlign:'center',display:'inline-table',width:"75px"}} onChange={handleYear} value={years}>
                                                                <option value=''>Select</option>
                                                                {[...Array.from(Array(11).keys())].map((item:any)=><option className='text-dark' value={item}>{item}</option>)}
                                                            </select>
                                                        </div>
                                                        <div className='col-auto' style={{whiteSpace: 'nowrap'}}>
                                                            <label className='text-light'>Months</label>&nbsp;
                                                            <select className='form-control text-warning' style={{background:'none',border:'none',borderBottom:'3px solid yellow',color:'black',textAlign:'center',display:'inline-table',width:"75px"}} onChange={handleMonth} value={months}>
                                                                <option value=''>Select</option>
                                                                {[...Array.from(Array(13).keys())].map((item:any)=><option className='text-dark' value={item}>{item}</option>)}
                                                            </select>
                                                        </div>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                            <div className="file-upload-button text-center" style={{ width: '100%'}}>
                                                <Button style={{ color: "#fff" }} className="uploadBtn" size="sm" onClick={handleUpload} type="button">Save</Button>
                                            </div>
                                        </div>
                                    }
                                </div>
                        : 
                        //---------------------------------------------------------------------------------------------//
                            // (IMAGES,DOCUMENTS) BLOCKS
                            <div>
                                <h4>Upload {props.selectedMedia}<br /> 
                                    <small className='text-danger'>
                                        {
                                            props.selectedMedia === 'Images' ? (ext && ext[0] && ext[0].filesExtension ? `( ${ext[0].filesExtension} )`:'( png,jpeg,jpg,webp )')
                                        :
                                            props.selectedMedia === 'Documents' && (ext && ext[0] && ext[0].filesExtension ? `( ${ext[0].filesExtension} )`: '( pdf,docx )')
                                        }
                                    </small>
                                </h4>
                                {(percentage === 0 || percentage) ?
                                    <span className="text-secondary" style={{cursor:"not-allowed"}}>
                                        <input type="checkbox" disabled={true} checked={itemScheduler} style={{cursor:"not-allowed"}}/>&nbsp;Schedule
                                        <br/><small className='text-secondary'>{schedulerMessage}</small><br/>
                                     </span>
                                :
                                    <span className="text-light" style={{cursor:"pointer"}} onClick={handleItemScheduler}>
                                        <input type="checkbox" style={{cursor:"pointer"}} checked={itemScheduler}/>&nbsp;Schedule
                                        <br/><small className='text-warning'>{schedulerMessage}</small>
                                    </span>
                                }
                                <div className='row justify-content-center pt-4' style={itemScheduler ? {} : {}}>
                                    <div className='col-md-4' style={itemScheduler ? {padding:"10px 0 0"} : {padding:"10px 0 0"}}>
                                        <label htmlFor="file" className='customeFileInput'>Select</label>
                                        <input className="form-control ps-2 customInput" style={{width: '112px', margin: '0 auto'}} type="file" id="file" name={'selectedFile'}
                                            accept={
                                                        (props.selectedMedia === 'Images') ? "image/png, image/jpeg, image/jpg, image/gif, image/webp"
                                                    :
                                                        (props.selectedMedia === 'Documents') ? '.docx, application/pdf'
                                                    : ''
                                            }
                                            ref={refParam => inputRef = refParam} onChange={handleChange} multiple/>
                                        {/* <strong className='text-warning' title={fileName} style={{ display: "block", whiteSpace: "break-spaces", overflow: "hidden", textOverflow: "ellipsis" }}>{fileName && fileName.length > 15 ? fileName.substring(0,15)+'...' : fileName }</strong> */}
                                        <div className='text-center'>
                                            <strong className='text-warning'>{selectedFilesCounter !== 0 && (selectedFilesCounter === 1 ? `${selectedFilesCounter} file selected`  : '')}</strong>
                                            <strong className='text-warning'>{selectedFilesCounter !== 0 && (selectedFilesCounter  >  1 ? `${selectedFilesCounter} files selected` : '')}</strong>
                                        </div>
                                    </div>
                                    {itemScheduler &&
                                        <div className="col-md-8" style={fileName ? {margin: '0',padding: '5px 0px 0px'} : {margin: '0',padding: '5px 0px 0'}}>
                                            <div className='row justify-content-center'>
                                            <div className='col-auto' style={{whiteSpace:'nowrap'}}>
                                                <label className='text-light'>Years</label>&nbsp;
                                                <select className='form-control text-warning' style={{background:'none',border:'none',borderBottom:'3px solid yellow',textAlign:'center',display:'inline-table',width:"75px", paddingLeft: '0', paddingRight: '0'}} onChange={handleYear} value={years}>
                                                    <option value=''>Select</option>
                                                    {[...Array.from(Array(11).keys())].map((item:any)=><option className='text-dark' value={item}>{item}</option>)}
                                                </select>
                                            </div>
                                            <div className='col-auto' style={{whiteSpace:'nowrap'}}>
                                                <label className='text-light'>Months</label>&nbsp;
                                                <select className='form-control text-warning' style={{background:'none',border:'none',borderBottom:'3px solid yellow',color:'black',textAlign:'center',display:'inline-table',width:"75px", paddingLeft: '0', paddingRight: '0'}} onChange={handleMonth} value={months}>
                                                    <option value=''>Select</option>
                                                    {[...Array.from(Array(13).keys())].map((item:any)=><option className='text-dark' value={item}>{item}</option>)}
                                                </select>
                                            </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="file-upload-button text-center" style={{width: '100%'}}>
                                        <Button style={{ color: "#fff" }} className="uploadBtn" size="sm" onClick={handleUpload} type="button">Upload</Button>
                                    </div>
                                </div>
                            </div>
                        }
                        { percentage === 0 ? <div className={`bg-success text-light file-upload-message rounded`}>{audiolinks || videolinks ? 'Saving URL... please wait' : 'Uploading started...please wait!'}</div> : percentage && <ProgressBar now={percentage} variant="success" label={`${percentage}%`} striped animated style={{width:"100%",height:"25px"}} className="file-upload-message"/>}
                        {/* {(percentage === 0 || percentage) && <div className={`text-light file-upload-message rounded blink`} style={{background:"rgba(231, 158, 31, 0.8)"}} >File uploading, Please wait...</div>} */}
                        {(!percentage && result && result.message) && <div className={`${result.status} text-light file-upload-message rounded`} style={{ width: "100%" }}>{result.message}</div>}
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
    
    const mapDispatchToProps = (dispatch: any) => ({
        uploadBoxFiles : (data: any) => dispatch(uploadBoxFiles(data)),
        getUserStorage : (data: any) => dispatch(getUserStorage()),
        saveLinks      : (data: any) => dispatch(saveLinks(data)),
    });

    const mapStateToProps = (state: any) => {
        return {
            fileUploaded : (state.BoxReducer && state.BoxReducer.fileUploaded) ? state.BoxReducer.fileUploaded : null,
            boxIconData  : (state.BoxReducer && state.BoxReducer.boxIconData) ? state.BoxReducer.boxIconData : null,
            message      : (state.CommonMessageReducer && state.CommonMessageReducer.message) ? state.CommonMessageReducer.message : null,
            userStorage  : (state.ProfilePageReducer && state.ProfilePageReducer.userStorage) ? state.ProfilePageReducer.userStorage : null, 
        };
    };

    export default connect(mapStateToProps, mapDispatchToProps)(UploadFilesModal);