export function addFoundationValidation(value: any) {

  const webregex = /^((https?|ftp|smtp):\/\/)+(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/;
  const emregex = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/ig;
  const phoneRegex = /^((?:\+27|27)|0)(\d{2})-?(\d{3})-?(\d{4})$/;
  let errors: any = {};
  if (!value.fName) {
    errors["fName"] = "Foundation name is required";
  }
  if (!value.fDetails) {
    errors["fDetails"] = "Foundation Details is required";
  }
  if (!value.fContactNumber) {
    errors["fContactNumber"] = "Foundation Phone is required";
  } else if (!phoneRegex.test(value.fContactNumber)) {
    errors["fContactNumber"] = "Enter a valid South Africa mobile number";
  }
  if (!value.fWebsite) {
    errors["fWebsite"] = "Foundation Website is required";
  }  else if (!webregex.test(value.fWebsite)) {
    errors['fWebsite'] = 'Enter a valid Website with - http(s)';
}

  return errors;
}
