import Api from './apiCall';
import {API_URLS} from '../constant/index';

export const getBoxCoverService = async params => {
      const result = await Api.get(API_URLS.GET_BOX_COVER_PHOTO_URL, params);
      return result;
  };

  export const  getUserByNameService = async params => {
    let url      = API_URLS.GET_USER_BY_NAME_URL;
    let newurl   = url.replace("{keyword}", params);
    let result   = await Api.get(newurl, []);
    return result
  };
  
export const  getUserByEmailService = async params => {
  const userId = params.email;
  let url      = API_URLS.GET_USER_BY_EMAIL_URL;
  let newurl   = url.replace("{email}", userId);
  return await Api.get(newurl, params)
};


export const  getUserByIdService = async params => {
  const userId =  params.userId ;
  let url      = API_URLS.GET_USER_BY_ID_URL;
  let newurl   = url.replace("{userId}", userId);
  return await Api.get(newurl, params)
};

export const getBoxIconService = async params => {
  const result = await Api.get(API_URLS.GET_BOX_ICON_URL, params);
  return result;
};


export const createBoxService = async params => {
  const result = await Api.post(API_URLS.CREATE_BOX_URL, params);
  return result;
};

export const getBoxListService = async params => {
  const result = await Api.get(API_URLS.GET_BOX_LIST_URL, params);
  return result;
};

export const getPublicBoxListService = async params => {
  const payload = params.keyword.state.keyword;
  let url    = API_URLS.GET_PUBLIC_BOX_LIST_URL;
  let newurl = url.replace("{keyword}", payload);
  return await Api.get(newurl, params)
};

// RECEIVED BOX LIST ----
export const getReceiedBoxListService = async params => {
  const result = await Api.get(API_URLS.GET_RECEIVED_BOX_LIST_URL, params);
  return result;
};

export const getChildReceiedBoxListService = async params => {
  const result = await Api.get(API_URLS.GET_CHILD_RECEIVED_BOX_LIST_URL, params);
  return result;
};

// RECEIVED BOX DETAILS BASED ON BOX ID ----
export const getReceivedBoxDetailService = async params => {
  let url    = API_URLS.LIST_RECIEVED_BOX_CONTENT_URL;
  let newurl = url.replace("{boxId}", params);
  let result = await Api.get(newurl, params);
  return result;
};

// CREATED BOX DETAILS ---
export const getCreatedBoxDetails = async params => {
    let url    = API_URLS.GET_CREATED_BOX_DETAILS_URL;
    let newurl = url.replace("{id}", params);
    return await Api.get(newurl, params)
};

export const updateFilesService = async params => {
  let url      = (params && params._parts && params._parts.data instanceof FormData)?API_URLS.UPLOAD_BOX_FILES:API_URLS.UPLOAD_BOX_MESSAGE;
  const result = await Api.post(url, params);
  return result;
}

// FOLDER DETAILS INSIDE CREATED BOX ---
export const getUploadedFilesByBoxIdandFolderId = async params => {
  let boxId       = params.boxId;
  let boxFolderId = params.boxFolderId;
  let url         = API_URLS.GET_UPLOADED_FILES_DETAILS_URL;
  let newurl1     = url.replace("{box_id}", boxId);
  let newurl2     = newurl1.replace("{box_folder_id}",boxFolderId);
  return await Api.get(newurl2, params)
};

// DELETE UPLOADED FILES BY ITS ID ---
export const deleteUploadedFilesByFileId = async params => {
  let fileId = params;
  let url    = API_URLS.DELETE_UPLOADED_FILES_URL;
  let newurl = url.replace("{id}", fileId);
  return await Api.delete(newurl, params)
};

//scheduled box service by id------
export const setScheduledBoxService = async params => {
  let box_id = params.id;
  let url    = API_URLS.SCHEDULE_BOX_URL;
  let newurl = url.replace("{id}", box_id);
  return await Api.put(newurl, params)
};

export const getBoxByIdService = async params => {
  let box_id = params.id;
  let url    = API_URLS.GET_BOX_BY_ID_URL;
  let newurl = url.replace("{id}", box_id);
  return await Api.get(newurl, params)
};

export const updateBoxService = async params => {
  let box_id = Number(params.id);
  let url    = API_URLS.UPDATE_BOX_URL;
  let newurl = url.replace("{id}", box_id);
  let result = await Api.patch(newurl, params.payload);
  return result
};

export const deleteBoxService = async params => {
  let box_id = params.id;
  let url    = API_URLS.DELETE_BOX_URL;
  let newurl = url.replace("{id}", box_id);
  return await Api.delete(newurl, params)
};

export const getBoxDetailService = async params => {
  let box_id = params.id;
  let url    = API_URLS.LIST_BOX_CONTENT_URL;
  let newurl = url.replace("{boxId}", box_id);
  return await Api.get(newurl, params)
}

export const getBoxRecordsListingWithSearchKeywordService = async params => {
  let search   = (params ? params : '')
  let url      = API_URLS.GET_PUBLIC_BOX_LIST_URL;
  let newUrl   = url.replace("{keyword}",search)
  const result = await Api.get(newUrl, []);
  return result;
};

export const getPublicBoxDetailContent = async params => {
  let box_id   = params.id
  let url      = API_URLS.GET_PUBLIC_BOX_DETAIL_URL;
  let newUrl   = url.replace("{boxId}",box_id)
  const result = await Api.get(newUrl, params);
  return result;
};

export const uploadSearvice_forBoxCoverImageByUser = async params => {
  let url      = API_URLS.GET_BOXCOVER_IMAGE_BY_USER_UPLOAD_URL;
  const result = await Api.post(url, params);
  return result;
};

// RECIPIENT DETAILS UPDATE SERVICE
export const updateRecipientDetailService = async params => {
  let id     = params.args.Id;
  let boxid  = params.args.boxId;
  let result = [];
  if(id && boxid){
    let url      = API_URLS.RECIPIENT_DETAILS_UPDATE_URL;
    let newurl1  = url.replace("{id}",id);
    let newurl2  = newurl1.replace("{boxId}",boxid);
    result = await Api.patch(newurl2, params.payloadData);
  }else if(id && !boxid){
    let url      = API_URLS.RECIPIENT_DETAILS_UPDATE_URL_FOR_MY_PEOPLE_PAGE;
    let newurl1  = url.replace("{id}",id);
    result       = await Api.patch(newurl1, params.data);
  }
  return result;
};

// RECIPIENT DETAILS UPDATE SERVICE
export const setCategorywise_scheduler = async params => {
  let url      = API_URLS.PATCH_CATEGORYWISE_SCHEDULER_URL;
  const result = await Api.patch(url, params);
  return result;
};

// UPDATE MESSAGE FROM VIEW PAGE
export const updateMessagesService = async params => {
  let id       = params.id;
  let url      = API_URLS.GET_UPDATE_MESSAGE_URL;
  let newurl   = url.replace("{id}",id);
  const result = await Api.patch(newurl, params);
  return result;
};

// UPLOAD COVER PHOTO BY LOGGED IN USER
export const uploadCoverPhotoByloggedInUserService = async params => {
  let url      = API_URLS.GET_COVER_PHOTO_UPLOAD_BY_USER_URL;
  const result = await Api.post(url, params);
  return result;
};

// SAVE AUDIO/VIDEO LINKS
export const saveAudioOrVideoLinksService = async params => {
  let url      = API_URLS.GET_URL_TOSAVE_AUDIO_OR_VIDEO_LINKS;
  const result = await Api.post(url, params);
  return result;
};

// RELATIONSHIP with RECIPIENTS (Old/New)
export const getRelationshipMasterDataService = async params => {
  let url      = API_URLS.GET_RELATIONSHIP_MASTER_DATA_URL;
  const result = await Api.get(url, params);
  return result;
};

// RECIPIENT FLOW
export const saveRecipientProfilePicTemprorayService = async params => {
  let url = API_URLS.GET_NEW_RECIPIENT_PROFILE_PIC_UPLOAD_URL;
  const result = await Api.post(url, params);
  return result;
}

// REMOVE FILE SCHEDULER
export const removeScheduler_service = async params => {
  let id = params;
  let url = API_URLS.REMOVE_SCHEDULER_URL;
  let newurl   = url.replace("{id}",id);
  const result = await Api.delete(newurl, params);
  return result;
}

// BOX RELEASE FOR DIFF EMAIL
export const releaseBoxes_service = async params => {
  let url = API_URLS.GET_URL_FOR_RELEASE_BOXES_FROM_DIFF_EMAIL;
  const result = await Api.put(url, params);
  return result;
}