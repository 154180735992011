import Api from "../apiCall";
import { API_URLS } from "../../constant/index";

export const getAddAdminFolderService= async (params) => {
    const result = await Api.post(API_URLS.ADD_ADMIN_FOLDER_URL, params);
    return result;
  };
 
  export const getUpdateAdminFolderService= async (params) => {
    const updateFolderId = params.id;
    let url = API_URLS.UPDATE_ADMIN_FOLDER_URL;
    let newurl = url.replace("{id}", updateFolderId);
    const result = await Api.put(newurl, params.data);
    return result;
  };

  export const getDeleteAdminFolderService= async (params) => {
   const deleteFolderId = params.id;
   let url = API_URLS.DELETE_ADMIN_FOLDER_URL;
   let newurl = url.replace("{id}", deleteFolderId);
   const result = await Api.delete(newurl, params);
    return result;
  };
  export const getFolderDetailByIdService= async (params) => {
    const getFolderById = params;
    let url = API_URLS.GET_FOLDER_DETAIL_BY_ID_URL;
    let newurl = url.replace("{id}", getFolderById);
    const result = await Api.get(newurl, params);
     return result;
   };

