import Api from './apiCall';
import {API_URLS} from '../constant/index';

export const getContactUsService = async params => {
  const result = await Api.post(API_URLS.GET_CONTACTUS_URL, params);
  return result;
};

export const getCountryDailCodeService = async params => {
  const result = await Api.get(API_URLS.GET_COUNTRY_DAIL_CODE_URL, params);
  return result;
};