import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Button, Col, Container, Row } from "react-bootstrap";
import Layout from "../../../layout/Layout";
import { connect } from "react-redux";
import { cancelPlan, getAllPlan, getPlans, selectFreePlan } from "../../../redux/actions/plansAction";
import selected from '../../../assets/images/selected.png';
import upcoming from '../../../assets/images/upcoming.png';
import { SUBSCRIPTION_PLAN } from "../../../redux/constant";
var converter = require('number-to-words');


  const PlansWithpromoCode = (props: any) => {
  const navToPayment                   = useNavigate();
  const redirectTo                     = useNavigate();
  const datePickr                      = React.createRef<HTMLInputElement>();
  const [planDetails,  setPlanDetails] = useState([]);
  const [userDetails,  setUserDetails] = useState<any>({});
  const [allPlans,     setAllPlans]    = useState<any>([]);
  const [currentPlan,  setCurrentPlan] = useState(null as any);
  const [upcomingPlan, setUpcomingPlan]= useState(null as any);
  const [paidPlanMsg,  setPdPlanMsg]   = useState("");
  const [paidPlnMsgUpdt,setPdPlnMsg]   = useState("");
  const [loader,       setLoader]      = useState(true);
  const freePlanMessage                = "<div style='color:red;font-size:20px'>You are about to select the Family membership plan.<br/> This plan is free but has limited features.<br/> Are you sure you would like to continue ?</div>";
  const cancelMessage                  = "<div style='font-size:20px'>Selected plan cancelled successfully!</div>";  
  const MySwal                         = withReactContent(Swal);
  let   userData                       = { payment: { boxPlansId: 0, planCode: "", subscriptionId: "", amount: 0, status: "", planisActive: 0, } };
  let   planAmount: any, planId: any, user_FName:any,user_LName:any, user_Email:any,user_Id:any,planduration:any,plan_frequency:any,currPlan_token:any ;
  
  useEffect(() => {
    props.getPlans();
    if(props.cancelPlanDetailsData && !localStorage.getItem('cancelPlan')){
      Swal.fire(
        'Cancelled!',
        `${cancelMessage}`,
        'success'
      ).then((result) =>{
        if(result.isConfirmed){
          setLoader(false);
          setPdPlnMsg("");
          localStorage.setItem('cancelPlan','success');
        }
      })
    }
  }, [props.cancelPlanDetailsData]);

  
  useEffect(() => {
    if(props.masterPlanData){
      setPlanDetails(props.masterPlanData);
    }
  }, [props.masterPlanData]);

  useEffect(() => {
    if(props.profileData && props.profileData.verified){
      props.getAllPlan({userId:props.profileData.id});
      setUserDetails(props.profileData);
    }
  }, [props.profileData,props.cancelPlanDetailsData]);

  useEffect(() =>{
    if(props.allPlansDetail){
      setLoader(false);
      setAllPlans(props.allPlansDetail)
    }
  },[props.allPlansDetail]);

  useEffect(() => {
    if (props.currentPlanDetailsData) {
      setCurrentPlan(props.currentPlanDetailsData);
    }
  }, [props.currentPlanDetailsData]);

  useEffect(()=>{
    if((allPlans && allPlans.length !== 0) && (currentPlan && currentPlan.planCode !== 'FREE')){
      setUpcomingPlan(allPlans.find((item:any) => item.planCode !== 'FREE' && item.planCode !== currentPlan.planCode));
    }

    if(allPlans && allPlans.length === 0){
      setPdPlanMsg("Do you really want to choose this plan?");
    }else if((allPlans && allPlans.length !== 0) && (currentPlan && currentPlan.planCode !== 'FREE')){
      setPdPlanMsg("Do you really want to choose this plan?");
      setPdPlnMsg("This will activate, once your current paid plan expires.");
    }
  },[allPlans,currentPlan])

  const handleSubmit = (e: any) => {
    let planName   = e.target.name;
    planAmount     = e.target.value;
    planId         = e.target.id;
    user_FName     = props.profileData.firstName;
    user_LName     = props.profileData.lastName;
    user_Email     = props.profileData.email;
    user_Id        = props.profileData.id;
    currPlan_token = currentPlan && currentPlan.payfastToken;
    planduration   = e.target.dataset.plan_duration;
    plan_frequency = (
        planduration === 'Daily'      ? '1' : 
        planduration === 'Weekly'     ? '2' : 
        planduration === 'Monthly'    ? '3' :
        planduration === 'Quarterly'  ? '4' :
        planduration === 'Biannually' ? '5' : 
        planduration === 'Yearly'    && '6' 
      ); 
     
    if (planName !== 'Family Free' &&  planAmount > 0) {
      // Swal.fire({
      //   title              : `${planName} - R${planAmount}`,
      //   html               : `<div style="color:red;font-size:20px">${paidPlanMsg}<br/>${paidPlnMsgUpdt}</div>`,
      //   showDenyButton     : true,
      //   showCancelButton   : false,
      //   confirmButtonText  : "YES",
      //   confirmButtonColor : "green",
      //   denyButtonText     : `NO`,
      //   showCloseButton    : true,
      //   width              : '600px'
      // }).then((result) => {
      //   if (result.isConfirmed) {
          navToPayment("/complete-payment",  
            { state: 
              { 
                amount     : planAmount, 
                itemName   : planName, 
                id         : planId, 
                name_first : user_FName,
                name_last  : user_LName, 
                email_addr : user_Email, 
                usrId      : user_Id,
                planToken  : currPlan_token,
                frequency  : plan_frequency
              } 
            }
          );
      //   }
      // });
    }else {
      if(allPlans.length === 0){
      Swal.fire({
        title              : `${planName} - R${planAmount}`,
        html               : freePlanMessage,
        showDenyButton     : true,
        showCancelButton   : false,
        confirmButtonText  : "YES",
        confirmButtonColor : "green",
        denyButtonText     : `NO`,
        showCloseButton    : true,
        width              : '650px'
      }).then((result) => {
        if (result.isConfirmed) {
         setFreePlan();
        }
      });
    }else{
      setFreePlan();
    }
  }
};
 
  const setFreePlan = () => {
    if (planAmount < 1) {
      userData.payment.boxPlansId     = planId;
      userData.payment.planCode       = "FREE";
      userData.payment.amount         = planAmount;
      userData.payment.subscriptionId = "free-plan";
      userData.payment.status         = "COMPLETED";
      userData.payment.planisActive   = 1;
      props.selectFreePlan(userData);
      props.getPlans();
    }
  };
   
// DATE COMPONENT----------------------STARTS HERE
  const DateInput = () =>{
    return(
      <div>
        <div>Enter your <span style={{color:"red"}}>Date of birth</span> for confirmation:</div>
        <input required={true} ref={datePickr}  type="date" max={new Date().toISOString().slice(0, 10)} className="form-control" style={{marginLeft:"18%",width:"64%"}}/>
      </div>
    )
  }

  async function preCnfrm () {
    const profileData   = props.profileData ? props.profileData : " ";
    var dATE            = profileData && profileData.dob && (profileData.dob).split('/');
    const userStoredDOB = ((dATE.length === 3) ? (dATE[2]+'-'+dATE[1]+'-'+dATE[0]) : profileData.dob);

    return await new Promise(function (resolve, reject) {
        setTimeout(function () {
            if (datePickr.current?.value === '') {
                reject('Date of birth is required!');
            } else if(datePickr.current?.value !== userStoredDOB){
              reject('Enter valid DOB for your confirmation!');
            }else {
                resolve(true)
            }
        }, 1000)
    }).catch(err => {
        MySwal.showValidationMessage('<div style="color:red">'+err+'</div>')
        return false
    })
  }
// DATE COMPONENT----------------------ENDS HERE

  const cancelPlan = (eventName:string,eventData:any) => {
    localStorage.removeItem('cancelPlan')
    let payLoad = {
        userId    : (eventData && eventData.userId ? eventData.userId : null),
        paymentId : (eventData && eventData.id     ? eventData.id     : null) 
    }
    MySwal.fire({
      title             : "<span style='color:black;font-weight:800;'>Do you want to cancel this  <small style='"+(eventName === 'current' ? 'color:red' : eventName === 'upcoming' ? 'color:green' : '')+"'>("+eventName+")</small> <br/><span style='"+(eventName === 'current' ? 'color:green' : eventName === 'upcoming' ? 'color:red' : '')+"'>"+(eventData && eventData.boxPlans && eventData.boxPlans.planType)+"</span> subscription?</span>",
      showDenyButton    : true,
      showCancelButton  : false,
      confirmButtonText : "YES",
      denyButtonText    : `NO`,
      confirmButtonColor: "green",
      showCloseButton   : true,
      width             : '600px',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        MySwal.fire({
          title               : "Do you really want to cancel this plan?",
          html                : <DateInput/>,
          showDenyButton      : true,
          showCancelButton    : false,
          confirmButtonText   : 'CONFIRM',
          denyButtonText      : `CANCEL`,
          showLoaderOnConfirm : true,
          confirmButtonColor  : "green",
          cancelButtonColor   : "red",
          width               : '600px',
          allowOutsideClick   : false,
          allowEscapeKey      : false,
          preConfirm          : preCnfrm,
        }).then((result) => {
          if (result.isConfirmed) {
            setLoader(true);
            props.cancelPlan(payLoad);
          }
        })
      }
    });
  };

  useEffect(() =>{
    if(props.selectPlanDetailsData){
      if(props.selectPlanDetailsData.planCode && props.selectPlanDetailsData.planCode === 'FREE'){
        props.clearFreePlanData(null);
        redirectTo('/homepage');
      }
    }
  },[props.selectPlanDetailsData])
 
//---------------------------------------------------------------------------------//

return (
  <Layout> 
    <div className="welcome welcome-content-frame">
      <Container>
        <div className="row">
          <div className="col-md-12">
            <Row className="justify-content-between">
              <div className="col-md-auto col-sm-12 order-md-2 text-right d-flex justify-content-end">
                <Link to={'#'} style={{textDecoration:"none"}} className="backBtn d-inline-flex" onClick={() => redirectTo(-1)}>Back
                </Link>
              </div>
              <div className="col-md-auto col-sm-12">
                <h3>Hi {userDetails && userDetails.firstName ? userDetails.firstName.charAt(0).toUpperCase() + userDetails.firstName.slice(1) :""},</h3>
              </div>
            </Row>
            <p style={{maxWidth:"none"}}>
              Please select from one of the plans detailed below. A third of all profits generated from our paid plans have been pledged toward helping existing charities and those in need. You can view our Foundation page to find out more about who we help.
              Thank you for your support. 
            </p>
          </div>
        </div>
      </Container>
    </div>{/* Welcome content-frame// */}
    <div className="plans">
      <Container className={loader ? `text-center` : ``}>
        {loader ? 
          <div className="lds-dual-ring">
            <span className="text-warning">Loading... please wait!</span>
          </div>
        :
          <>
          {props.message && ( <div className={ props.message && props.message.status === "success" ? "text-success" : "text-danger" } > {props.message.data} </div> )}
          <div className="planList">
            {planDetails && planDetails.length !== 0 && planDetails.map((plan: any) => {
              const currentPlanClass   = (currentPlan && plan.planCode && plan.planCode === currentPlan.planCode ? "active-plan" : "");
              const upcomingPlanClass  = (upcomingPlan && upcomingPlan.planCode !== 'FREE' && upcomingPlan.planCode !== currentPlan.planCode && upcomingPlan.planCode === plan.planCode)  ? 'upcoming-plan' : ''; 
              const planActPrice       = (plan.planCode !== 'FREE' ? plan.planPrice : 0);
              
              return (
                  // <div className={`text-warning ${currentPlanClass !== 'active-plan' && upcomingPlanClass !== 'upcoming-plan'? `mt-4` : `mb-4`}`} key={plan.id}> 
                  <div className={`text-warning planWraper ${currentPlanClass !== 'active-plan' && upcomingPlanClass !== 'upcoming-plan'? `mt-4` : `mb-4`}`} key={plan.id}> 
                  <div className="text-center">{currentPlanClass === 'active-plan' ? <div className="text-light bg-success rounded-top w-75" style={{marginLeft:"13%"}}>CURRENT PLAN</div> : upcomingPlanClass === 'upcoming-plan' ? <div className="text-light bg-danger rounded-top w-75" style={{marginLeft:"13%"}}>UPCOMING PLAN</div> : ''}</div>
                  <div className={`listItem ${currentPlanClass}`} style={currentPlanClass === 'active-plan' ? {} : {border: "2px solid grey",borderRadius:"20px"}} key={plan.id}>
                    <div className="listHead" style={currentPlanClass === 'active-plan' ? {background:"rgba(239, 161, 26, 0.13)",height: '265.53px'} : {height: "265.53px"}}>
                      <h3>{plan.planType}</h3>
                      <p><span>Membership</span><br/>
                          <small className="text-warning">{currentPlanClass && currentPlan.planCode !== 'FREE' ? `( Valid Upto: ${ new Date(currentPlan.planExpiryDate).toLocaleDateString()} )` : upcomingPlanClass ? `( Start from: ${new Date(upcomingPlan.planStartDate).toLocaleDateString()} )` : <span>&nbsp;</span>}</small>
                      </p>
                      <div className={`rate`} style={{paddingTop:'80px'}}>
                          <b className="text-warning">R</b>{Number(planActPrice).toFixed(2)}
                        <span>{plan.planDuration !== 'Unlimited' && plan.planDuration}</span>
                     </div>
                    </div>
                    <ul>
                      <li>{converter.toWords(plan.publicBoxes).charAt(0).toUpperCase() + converter.toWords(plan.publicBoxes).slice(1)} public box</li>
                      <li style={{lineHeight:"15px"}}>{(plan.privateBoxes !== 'Unlimited') ? converter.toWords(plan.privateBoxes).charAt(0).toUpperCase() + converter.toWords(plan.privateBoxes).slice(1) : plan.privateBoxes } private boxes
                        <br/><small style={{fontSize:"13px",color:"#E79E1F"}}>{plan.planCode === 'FREE' ? '( One recipient per box )' :'( Up to five recipients per box )'}</small>                      
                      </li>
                      <li style={{lineHeight:"15px"}}>{(plan.recievedBoxes !== 'Unlimited') ? converter.toWords(plan.recievedBoxes).charAt(0).toUpperCase() + converter.toWords(plan.recievedBoxes).slice(1) : plan.recievedBoxes } received boxes
                        <br/><small style={{fontSize:"13px",color:"#E79E1F"}}>{plan.planCode === 'FREE' ? '( Access received box content for 12 months )' : '( Unlimited access to received box content )' }</small>
                      </li>
                      {/* ${(Number(plan.planStorage) / 1000000000) * 1000} MB storage plan */}
                      {plan.planCode !== 'FREE' && <li>{`${(Number(plan.planStorage) / 1000000000)} GB storage plan`}</li>}
                      {plan.planCode === 'FREE' ? <li>Box Content – Text Messages</li> : <li>Box Content – Text Messages, Video, Audio, Documents, Images</li>}   
                      {plan.planCode !== "FREE" && <li>Cancel any time</li>}
                    </ul>
                    {currentPlanClass === 'active-plan' ? <div className="text-center text-success font-weight-bold">
                      <img src={selected} alt="" style={{marginTop:"-140px",opacity:"0.4",maxWidth: "50%"}}/></div> 
                      : 
                      upcomingPlanClass === 'upcoming-plan' ? <div className="text-center text-success font-weight-bold">
                        <img src={upcoming} alt="" style={{marginTop:"-150px",marginBottom:"0px",opacity:"0.4",maxWidth: "50%"}}/></div> 
                      : 
                      <></>
                    }
                    <div className="text-center mt-auto">
                      {
                        (allPlans && allPlans.length === 0 && currentPlan && currentPlan.planIsActive === 0) || (allPlans && allPlans.length === 0 && plan.planCode !== 'FREE') || (allPlans && allPlans.length !== 0 && currentPlanClass !== 'active-plan' && upcomingPlanClass !== 'upcoming-plan' && plan.planCode !== currentPlan.planCode && plan.planCode !== 'FREE') ?   
                          <Button className="chooseBtn" onClick={handleSubmit} name={plan.planType} value={Number(planActPrice).toFixed(2)} id={plan.id} data-plan_id={plan.plan_id} data-plan_duration={plan.planDuration}> SELECT </Button> 
                        :
                          currentPlanClass === 'active-plan' && currentPlan && currentPlan.planCode !== 'FREE' ? <Button className={`chooseBtn text-danger`} onClick={()=>cancelPlan('current',currentPlan)}>CANCEL</Button> 
                        : 
                          upcomingPlanClass === 'upcoming-plan' && currentPlan && currentPlan.planCode !== 'FREE' ? <Button className={`chooseBtn text-danger`} onClick={()=>cancelPlan('upcoming',upcomingPlan)}>CANCEL</Button>
                        :  
                          <></>
                          // <Button className="chooseBtn" onClick={handleSubmit} name={plan.planType} value={plan.planPrice} id={plan.id} data-plan_id={plan.plan_id}>UPGRADE</Button>
                      }
                    </div>
                  </div>
                  </div>
                );
              })}
          </div>
          </>
        }
        </Container>
      </div>
    </Layout>
  );
};
const mapDispatchToProps = (dispatch: any) => ({
  getPlans          : (data: any) => dispatch(getPlans(data)),
  getAllPlan        : (data: any) => dispatch(getAllPlan(data)),
  selectFreePlan    : (data: any) => dispatch(selectFreePlan(data)),
  cancelPlan        : (data: any) => dispatch(cancelPlan(data)),
  clearFreePlanData : (data: any) => dispatch({type:SUBSCRIPTION_PLAN.SET_SELECTED_SUBSCRIPTION_PLAN_TO_STATE,data})
});

const mapStateToProps = (state: any) => {
  return {
    profileData            : (state.ProfilePageReducer && state.ProfilePageReducer.profileData)      ? state.ProfilePageReducer.profileData      : null,
    masterPlanData         : (state.PlansReducer       && state.PlansReducer.planDetailsData)        ? state.PlansReducer.planDetailsData        : null,
    currentPlanDetailsData : (state.PlansReducer       && state.PlansReducer.currentPlanDetailsData) ? state.PlansReducer.currentPlanDetailsData : null,
    allPlansDetail         : (state.PlansReducer       && state.PlansReducer.allPlanDetail)          ? state.PlansReducer.allPlanDetail          : null,
    cancelPlanDetailsData  : (state.PlansReducer       && state.PlansReducer.cancelPlanDetailsData)  ? state.PlansReducer.cancelPlanDetailsData  : null,
    selectPlanDetailsData  : (state.PlansReducer       && state.PlansReducer.selectPlanDetailsData)  ? state.PlansReducer.selectPlanDetailsData  : null,    
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlansWithpromoCode)
