import { lazy, Suspense } from "react";
import { useParams } from "react-router-dom";
import Layout from "../../../layout/Layout";

const Images    = lazy(() => import("./Folders/Images"));
const Documents = lazy(() => import("./Folders/Documents"));
const Messages  = lazy(() => import("./Folders/Messages"));
const Audio     = lazy(() => import("./Folders/Audio"));
const Videos    = lazy(() => import("./Folders/Videos"));

function FolderTemplate() {
    const { boxid,foldername,boxfolderid } = useParams();
    const loading = <div className="text-warning text-center">Loading...</div>;
    return (
        <>
            {
                foldername === 'Images' ?
                    <Layout>
                        <Suspense fallback={loading}>
                                <Images boxid={boxid} foldername={foldername} boxfolderid={boxfolderid} />
                        </Suspense>
                    </Layout>
            :   foldername === 'Videos' ?
                    <Layout>
                        <Suspense fallback={loading}>    
                                <Videos boxid={boxid} foldername={foldername} boxfolderid={boxfolderid} />    
                        </Suspense>
                    </Layout>
            :   foldername === 'Messages' ?
                    <Layout>
                        <Suspense fallback={loading}>
                                <Messages boxid={boxid} foldername={foldername} boxfolderid={boxfolderid} />
                        </Suspense>
                    </Layout>
            :   foldername === 'Documents' ?
                    <Layout>
                        <Suspense fallback={loading}>
                                <Documents boxid={boxid} foldername={foldername} boxfolderid={boxfolderid} />
                        </Suspense>
                    </Layout>
            :   foldername === 'Audio' ?
                    <Layout>
                        <Suspense fallback={loading}>
                                <Audio boxid={boxid} foldername={foldername} boxfolderid={boxfolderid} />
                        </Suspense>
                    </Layout>
            :
                <></>                            
            }
        </>
    )
}

export default FolderTemplate