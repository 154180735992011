import { ADMIN_BOX_API } from "../../constant";
export const getCoverPhotos = data => {
    return {
      type: ADMIN_BOX_API.GET_ADMIN_COVER_PHOTOS,
      payload: data,
    };
  };
  
  export const addCoverPhotos = data => {
    return {
      type: ADMIN_BOX_API.ADD_ADMIN_COVER_PHOTOS,
      payload: data,
    };
  };

  export const getCoverPhotoById = data => {
    return {
      type: ADMIN_BOX_API.GET_ADMIN_COVER_PHOTO_BY_ID,
      payload: data,
    };
  };

  export const removeCoverPhotos = data => {
    return {
      type: ADMIN_BOX_API.REMOVE_ADMIN_COVER_PHOTOS,
      payload: data,
    };
  };
  export const uploadDC = data => {
    return {
      type: ADMIN_BOX_API.PUT_ADMIN_RELEASE_BOX,
      payload: data,
    };
  };
