import { useEffect } from "react";
import { Container } from "react-bootstrap";
import Layout from "../../layout/Layout";

const PrivacyPolicy = (props: any) => {
  return (
    <>
      <Layout>
        <div className={localStorage.length ? "" : "charity_publicBox"}>
          <Container>
            <div className="tnc">
              <div className="row">
                <div className="text-light">
                  <div className="header">
                    {" "}
                    <h2> BeyondBox Privacy Policy </h2>
                  </div>
                  <div className="header">
                    <h4>
                      READ THIS POLICY CAREFULLY BEFORE BROWSING THIS PLATFORM
                      OR USING ANY OF THE SERVICES. YOUR CONTINUED USE OF THIS
                      PLATFORM INDICATES THAT YOU HAVE BOTH READ AND AGREE TO
                      THE TERMS OF THIS PRIVACY POLICY. YOU CANNOT USE THIS
                      PLATFORM IF YOU DO NOT ACCEPT THIS POLICY. ALL SECTIONS OF
                      THIS POLICY ARE APPLICABLE TO BOTH BROWSERS AND USERS
                      UNLESS THE SECTION EXPRESSLY STATES OTHERWISE.
                    </h4>
                  </div>

                  <div className="list">
                    <ul>
                      <li className="mainPoint">
                        <h4> 1.Privacy Policy </h4>
                        <ul>
                          <li>
                            1.1.{" "}
                            <span>
                              {" "}
                              For the purposes of this section, Personal
                              Information will be understood in accordance with
                              the definition provided in the Protection of
                              Personal Information Act 4 of 2013 ("the Act") and
                              in accordance with the definition of Personal Data
                              in Article 4(1) of the General Data Protection
                              Regulation GDPR (EU) 2016/679. BeyondBox also
                              subscribes to the principles for electronically
                              collecting personal information outlined in the
                              Act, and the further legislation referred to
                              therein. We endeavour to ensure the quality,
                              accuracy and confidentiality of Personal
                              Information in our possession.{" "}
                            </span>
                          </li>
                          <li>
                            1.2.{" "}
                            <span>
                              {" "}
                              The user confirms that he/she will not only be
                              providing us with their Personal Information but
                              also that of Next of Kin and Recipients, and in
                              these circumstances has obtained the necessary
                              consent from the Next of Kin and Recipients (or
                              parents/guardians of Recipients as the case may
                              be) to do so.{" "}
                            </span>
                          </li>
                          <li>
                            1.3.{" "}
                            <span>
                              {" "}
                              We, in processing the Personal Information of the
                              Next of Kin and Recipients, do so based on the
                              confirmation as recorded in clause 1.2 above.
                            </span>
                          </li>
                          <li>
                            1.4.{" "}
                            <span>
                              {" "}
                              In adopting this Privacy Policy, we wish to
                              balance our legitimate business interests and your
                              reasonable expectation of privacy. Accordingly, we
                              will take all reasonable steps to prevent
                              unauthorised access to, or disclosure of your
                              Personal Information. However, it is impossible to
                              guarantee that your Personal Information shall be
                              100% secure.
                            </span>
                          </li>
                          <li>
                            1.5.{" "}
                            <span>
                              {" "}
                              When making use of the Website, users may be asked
                              to provide the following information (Personal
                              Information) relating to themselves:
                              <ul>
                                <li>
                                  1.5.1.
                                  <span>first name;</span>
                                </li>
                                <li>
                                  1.5.2.
                                  <span>surname;</span>
                                </li>
                                <li>
                                  1.5.3.
                                  <span>email;</span>
                                </li>
                                <li>
                                  1.5.4.
                                  <span>
                                    full physical address including
                                    state/province/city and country;
                                  </span>
                                </li>
                                <li>
                                  1.5.5.
                                  <span>age;</span>
                                </li>
                                <li>
                                  1.5.6.
                                  <span>language</span>
                                </li>
                                <li>
                                  1.5.7.
                                  <span>phone number; and</span>
                                </li>
                                <li>
                                  1.5.8.
                                  <span>
                                    identification number/social security
                                    number.
                                  </span>
                                </li>
                              </ul>
                            </span>
                          </li>
                          <li>
                            1.6.
                            <span>
                              We will attempt to limit the types of Personal
                              Information we process to only that to which you
                              consent to (for example, in the context of online
                              registration, newsletters, message boards,
                              surveys, polls, professional announcements, SMS,
                              MMS and other mobile services), but, to the extent
                              necessary, your agreement to these this Privacy
                              Policy constitutes your consent as contemplated in
                              section 69 of the Act and/or Article 7 of the GDPR
                              (whichever is applicable). Where necessary, we
                              will seek to obtain your specific consent in
                              future instances should we deem same required by
                              law and where your consent herein might not be
                              lawfully sufficient.
                            </span>
                          </li>
                          <li>
                            1.7.
                            <span>
                              When registering for the Services, Users may be
                              asked to provide the following information
                              (Personal Information) relating to Next of Kin and
                              Recipients:
                              <ul>
                                <li>
                                  1.7.1.
                                  <span>first name;</span>
                                </li>
                                <li>
                                  1.7.2.
                                  <span>surname;</span>
                                </li>
                                <li>
                                  1.7.3.
                                  <span>email;</span>
                                </li>
                                <li>
                                  1.7.4.
                                  <span>language; and</span>
                                </li>
                                <li>
                                  1.7.5.
                                  <span>date of birth.</span>
                                </li>
                              </ul>
                            </span>
                          </li>
                          <li>
                            1.8.
                            <span>
                              We will not collect, use or disclose sensitive
                              information (such as information about racial or
                              ethnic origins or political or religious beliefs,
                              where relevant) except with your specific consent
                              or in the circumstances permitted by law.
                            </span>
                          </li>
                          <li>
                            1.9.
                            <span>
                              By agreeing to the terms contained in this Privacy
                              Policy, you consent to the use of your Personal
                              Information in relation to:
                              <ul>
                                <li>
                                  1.9.1.
                                  <span>
                                    the provision and performance of the
                                    Services;
                                  </span>
                                </li>
                                <li>
                                  1.9.2.
                                  <span>
                                    informing you of changes made to our
                                    Platform;
                                  </span>
                                </li>
                                <li>
                                  1.9.3.
                                  <span>
                                    the provision of marketing related services
                                    to you by BeyondBox;
                                  </span>
                                </li>
                                <li>
                                  1.9.4.
                                  <span>
                                    responding to any queries or requests you
                                    may have;
                                  </span>
                                </li>
                                <li>
                                  1.9.5.
                                  <span>
                                    developing a more direct and substantial
                                    relationship with users for the purposes
                                    described in this clause;
                                  </span>
                                </li>
                                <li>
                                  1.9.6.
                                  <span>
                                    understanding general user trends and
                                    patterns so that we can develop and support
                                    existing and ongoing marketing strategies;
                                  </span>
                                </li>
                                <li>
                                  1.9.7.
                                  <span>
                                    for security, administrative and legal
                                    purposes; and
                                  </span>
                                </li>
                                <li>
                                  1.9.8.
                                  <span>
                                    the creation and development of market data
                                    profiles which may provide insight into
                                    market norms, practices and trends to help
                                    us improve our offering to you. Such
                                    information will be compiled and retained in
                                    aggregated form, but shall not be used in
                                    any way which may comprise the identity of a
                                    User.
                                  </span>
                                </li>
                              </ul>
                            </span>
                          </li>
                          <li>
                            1.10.
                            <span>
                              By providing Personal Information relating to Next
                              of Kin and Recipients, you consent to the use of
                              this Personal Information in relation to:
                              <ul>
                                <li>
                                  1.10.1.
                                  <span>
                                    the provision of the Services as relate to
                                    Next of Kin and Recipients in the Terms of
                                    Service; and
                                  </span>
                                </li>
                                <li>
                                  1.10.2.
                                  <span>
                                    the Personal Information may be processed
                                    and retained for no longer than necessary.
                                  </span>
                                </li>
                              </ul>
                            </span>
                          </li>
                          <li>
                            1.11.
                            <span>
                              Although absolute security cannot be guaranteed,
                              we have in place up-to-date, reasonable technical
                              and organisational security measures to protect
                              your Personal Information against accidental or
                              intentional manipulation, loss, misuse,
                              destruction or against unauthorised disclosure or
                              access to the information we process online.
                            </span>
                          </li>
                          <li>
                            1.12.
                            <span>
                              While we cannot ensure or warrant the security of
                              any Personal Information you provide us, we will
                              continue to maintain and improve these security
                              measures over time in line with legal and
                              technological developments.
                            </span>
                          </li>
                          <li>
                            1.13.
                            <span>
                              {" "}
                              We store your Personal Information directly, or
                              alternatively, store your Personal Information on,
                              and transfer your Personal Information to, a
                              central database. If the location of the central
                              database is located in a country that does not
                              have substantially similar laws which provide for
                              the protection of Personal Information, we will
                              take the necessary steps to ensure that your
                              Personal Information is adequately protected in
                              that jurisdiction by means of ensuring that
                              standard data protection clauses are enforced as
                              envisaged by the GDPR.
                            </span>
                          </li>
                          <li>
                            1.14.
                            <span>
                              Your information will not be stored for longer
                              than is necessary for the purposes described in
                              these Terms or as required by applicable
                              legislation.
                            </span>
                          </li>
                          <li>
                            1.15.
                            <span>
                              The Personal Information we collect from Users
                              shall only be accessed by BeyondBox employees,
                              representatives and consultants.
                            </span>
                          </li>
                          <li>
                            1.16.
                            <span>
                              We shall have the right, but shall not be obliged,
                              to monitor or examine any information and
                              materials including any website link that you
                              publish or submit to us.  You shall be solely
                              responsible for the contents of all Content
                              provided by yourself.
                            </span>
                          </li>
                          <li>
                            1.17.
                            <span>
                              We constantly review our systems and data to
                              ensure the best possible service to our users. We
                              do not accept any users, or representatives of
                              users, younger than 16 years of age. Users over 16
                              years of age but under the age of majority without
                              the consent of a parent or guardian, alternatively
                              users who do not otherwise have the relevant
                              capacity to be bound by this Privacy Policy.
                            </span>
                          </li>
                          <li>
                            1.18.
                            <span>
                              We will not sell, share, or rent your Personal
                              Information to any third party or use your e-mail
                              address for unsolicited mail. Any emails sent by
                              us will only be in connection with the provision
                              of our Services and/or the marketing thereof.
                            </span>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <h4> 2. Your Rights</h4>
                        <ul>
                          <li>
                            2.1.{" "}
                            <span>
                              {" "}
                              A user has the right:
                              <ul>
                                <li>
                                  2.1.1.
                                  <span>
                                    of access to their Personal Information
                                    stored and processed by us. This may be done
                                    by emailing us at the address referred to in
                                    clause 6.8 below;
                                  </span>
                                </li>
                                <li>
                                  2.1.2
                                  <span>
                                    to rectification of Personal Information
                                    that we hold on a User's behalf, in
                                    instances where such Personal Information is
                                    incorrect or incomplete;
                                  </span>
                                </li>
                                <li>
                                  2.1.3.
                                  <span>
                                    of erasure of Personal Information ("right
                                    to be forgotten") if such information is no
                                    longer needed for the original processing
                                    purpose, alternatively if a User withdraws
                                    their consent and there is no other reason
                                    or justification to retain such Personal
                                    Information, further alternatively, if a
                                    User has objected to such Personal
                                    Information being processed and there is no
                                    justified reason for the processing of such
                                    Personal Information;
                                  </span>
                                </li>
                                <li>
                                  2.1.4.
                                  <span>
                                    to restrict/suspend processing of Personal
                                    Information to only that which is strictly
                                    necessary for us to perform our Services to
                                    you;
                                  </span>
                                </li>
                                <li>
                                  2.1.5.
                                  <span>
                                    if we breach any of the terms of this
                                    Privacy Policy, to complain to the requisite
                                    data protection authority in their
                                    territory;
                                  </span>
                                </li>
                                <li>
                                  2.1.6.
                                  <span>
                                    to withdraw their consent at any time, if
                                    processing of Personal Information is based
                                    on consent;
                                  </span>
                                </li>
                                <li>
                                  2.1.7.
                                  <span>
                                    to object to processing of Personal
                                    Information, if such processing is based on
                                    legitimate interests; and
                                  </span>
                                </li>
                                <li>
                                  2.1.8.
                                  <span>
                                    to object to the processing of Personal
                                    Information for direct marketing purposes.
                                  </span>
                                </li>
                              </ul>
                            </span>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <h4> 3. Log Files</h4>

                        <ul>
                          <li>
                            3.1.{" "}
                            <span>
                              {" "}
                              When you visit us, even if you do not create an
                              account, we may collect information, such as your
                              IP address, the name of your ISP (Internet Service
                              Provider), your browser, the website from which
                              you visit us, the pages on our Platform that you
                              visit and in what sequence, the date and length of
                              your visit, and other information concerning your
                              computer's operating system, language settings,
                              and broad demographic information. This
                              information is aggregated and anonymous data and
                              does not identify you specifically. However, you
                              acknowledge that this data may be able to be used
                              to identify you if it is aggregated with other
                              Personal Information that you supply to us. This
                              information is not shared with third parties and
                              is used only within BeyondBox on a need-to-know
                              basis. Any individually identifiable information
                              related to this data will never be used in any way
                              different to that stated above, without your
                              explicit permission.
                            </span>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <h4>4. Cookies </h4>

                        <ul>
                          <li>
                            4.1.{" "}
                            <span>
                              {" "}
                              We use cookies. A cookie is a small piece of
                              information stored on your computer or smart phone
                              by the web browser. The two types of cookies used
                              on the Platform are described below:
                              <ul>
                                <li>
                                  4.1.1.
                                  <span>
                                    "Session cookies": These are used to
                                    maintain a so-called 'session state' and
                                    only lasts for the duration of your use of
                                    the Platform. A session cookie expires when
                                    you close your browser, or if you have not
                                    visited the server for a certain period of
                                    time. Session cookies are required for the
                                    Platform to function optimally, but are not
                                    used in any way to identify you personally.
                                  </span>
                                </li>
                                <li>
                                  4.1.2.
                                  <span>
                                    "Permanent cookies": These cookies
                                    permanently store a unique code on your
                                    computer or smart device hard drive in order
                                    to identify you as an individual user. No
                                    Personal Information is stored in permanent
                                    cookies. You can view permanent cookies by
                                    looking in the cookies directory of your
                                    browser installation. These permanent
                                    cookies are not required for the Platform to
                                    work, but may enhance your browsing
                                    experience.
                                  </span>
                                </li>
                              </ul>
                            </span>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <h4>5. Links from BeyondBox</h4>
                        <ul>
                          <li>
                            5.1.{" "}
                            <span>
                              {" "}
                              BeyondBox, and the services available through the
                              Platform, may contain links to other third party
                              websites, including (without limitation) social
                              media platforms, payment gateways, appointment
                              scheduling and/or live chat platforms ("Third
                              Party Websites").  If you select a link to any
                              Third Party Website, you may be subject to such
                              Third Party Website's terms and conditions and/or
                              other policies, which are not under the control,
                              nor responsibility, of BeyondBox.
                            </span>
                          </li>
                          <li>
                            5.2.
                            <span>
                              Hyperlinks to Third Party Websites are provided
                              "as is", and we do not necessarily agree with,
                              edit or sponsor the content on Third Party
                              Websites.
                            </span>
                          </li>
                          <li>
                            5.3.
                            <span>
                              We do not monitor or review the content of any
                              Third Party Website. Opinions expressed or
                              material appearing on such websites are not
                              necessarily shared or endorsed by us and we should
                              not be regarded as the publisher of such opinions
                              or material. Please be aware that we are not
                              responsible for the privacy practices, or content,
                              of other websites, either.
                            </span>
                          </li>
                          <li>
                            5.4.
                            <span>
                              Users should evaluate the security and
                              trustworthiness of any Third Party Website before
                              disclosing any personal information to them. We do
                              not accept any responsibility for any loss or
                              damage in whatever manner, howsoever caused,
                              resulting from your disclosure to third parties of
                              personal information.
                            </span>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <h4>
                          6. Application Of The Electronic Communications And
                          Transactions Act 25 Of 2002 ("ECT Act")
                        </h4>
                        <ul>
                          <li>
                            6.1.{" "}
                            <span>
                              {" "}
                              Data Messages (as defined in the ECT Act) will be
                              deemed to have been received by us if and when we
                              respond to the Data Messages.
                            </span>
                          </li>
                          <li>
                            6.2.{" "}
                            <span>
                              {" "}
                              Data Messages sent by us to a User will be deemed
                              to have been received by such user in terms of the
                              provisions specified in section 23(b) of the ECT
                              Act.
                            </span>
                          </li>
                          <li>
                            6.3.{" "}
                            <span>
                              {" "}
                              Users acknowledge that electronic signatures,
                              encryption and/or authentication are not required
                              for valid electronic communications between Users
                              and BeyondBox.
                            </span>
                          </li>
                          <li>
                            6.4.{" "}
                            <span>
                              {" "}
                              Information to be provided in terms of section
                              43(1) of the ECT Act:
                            </span>
                            <ul>
                              <li>
                                6.4.1.
                                <span>
                                  Users warrant that Data Messages sent to
                                  BeyondBox from any electronic device, used by
                                  such user, from time to time or owned by such
                                  user, were sent and or authorised by such
                                  User, personally.
                                </span>
                              </li>
                              <li>
                                6.4.2.
                                <span>
                                  This Website is owned and operated by
                                  BeyondBox (Pty) Ltd, (Registration Number:
                                  2022/488557/07) a private company duly
                                  incorporated in accordance with the laws of
                                  the Republic of South Africa.
                                </span>
                              </li>
                            </ul>
                          </li>
                          <li>
                            6.5.{" "}
                            <span>
                              {" "}
                              Address for service of legal documents: Brechin
                              Place Montrose Pietermaritzburg KwaZulu-Natal
                              South Africa 3201
                            </span>
                          </li>
                          <li>
                            6.6. <span> Contact Number: 0722339137 </span>
                          </li>
                          <li>
                            6.7.{" "}
                            <span> Website - http://www.beyondbox.org ; </span>
                          </li>
                          <li>
                            6.8.{" "}
                            <span> Email address: info@beyondbox.org </span>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </Layout>
    </>
  );
};

export default  PrivacyPolicy;
