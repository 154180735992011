import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Layout from '../../layout/Layout';
// import { connect } from "react-redux";
import { Button, Container } from 'react-bootstrap';
import img1 from '../../assets/images/img1.png';
import img2 from '../../assets/images/img2.png';
import img3 from '../../assets/images/img3.png';
import appstore from '../../assets/images/appstore.png';
import googleplay from '../../assets/images/googleplay.png';
import youtubePlaceholder from '../../assets/images/youtubeVideoPlaceholder.png';
import comming_soon from "../../assets/images/coming_soon.png";

const Landing = () => {
  localStorage.clear();
  const vId:any = "SiRiNSBSOdw";
  
  return (
     <Layout>
      <div className="intro">
        <div className="content text-center">
          <h1>Parting gifts from Beyond</h1>
          <p>Create digital boxes filled with treasured messages, memories, media and more, to be released after you have moved on from this world.</p>
          {/* <Link to="">Read more</Link> */}
          <div className="d-flex justify-content-center">
            <Link to="/login" className="yellowBtn btn btn-primary me-4">Sign in </Link>
            <Link to="/signup" className="yellowBtn btn btn-primary">Create Profile</Link>
          </div>
        </div>
      </div>
      <div className="gift">
        <Container>
          <div className="row align-items-center">
            <div className="col-lg-6 pe-lg-5 text-center">
              <img className="img-fluid" src={img1} alt="" />
            </div>
            <div className="col-lg-6">
              <h2>A gift like no other</h2>
              <p>At some point, we'll all leave this world behind. We know that death is an inevitable part of life, but it's not always easy to face. It can come suddenly, without warning, leaving us with unfinished business and unsaid words. We all have people in our lives who mean the world to us, and we want to make sure they know how much we care. When it's time for us to go, we wish we could provide our loved ones the comfort, closure, and guidance they will undoubtedly seek.</p>
              <p>The BeyondBox platform allows you to create a timeless digital treasure trove filled with messages, pictures, videos, recordings, documents, and more, to be released to the people who matter most in your life, after you have moved on from this world. You can leave a box to individuals, groups or even the world and share your final words of wisdom, heartfelt sentiments, treasured memories, and information that you only need to convey after you pass away. Each created box is end-to-end encrypted, ensuring that only your intended recipient can view its contents. </p>
              <p>It's a unique and unparalleled gift that will be treasured for generations to come.</p>
              <p>Sign up for free today and start creating your BeyondBox legacy.</p>
              <Link to="/view-plans" className="planBtn"><span>View Plans</span></Link>
            </div>
          </div>
        </Container>
      </div>

      <div className="howItWorks">
        <Container>
          <h2 className="text-center">Introducing BeyondBox</h2>
          <div className="videoContainer" style={{justifyContent:"center"}}>
            <div className="">
              {/* <img src={youtubePlaceholder} className="App-logo" alt="logo" /> */}
              <iframe height='600px' src={`https://youtube.com/embed/${vId}`} allowFullScreen></iframe>
            </div>
            {/* <div className="videoBlock">
              <img src={youtubePlaceholder} className="App-logo" alt="logo" /> 
            </div> */}
          </div>
        </Container>
      </div>

      <div className="download">
        <Container>
          <h2>Get the BeyondBox App</h2>
          <div className="image">
            <img className="img-fluid" src={img2} alt="" />
          </div>
          <div className="btnBlock text-center">
              <Link to="https://apps.apple.com/us/app/beyondbox-messenger/id6448447137" className="appstore pointer col-auto" >
                <img src={appstore} alt="" />
              </Link>
            <Link to="https://play.google.com/store/apps/details?id=com.beyondboxapp" className="googleplay">
              <img src={googleplay} alt="" />
            </Link>
          </div>
        </Container>
      </div>

      <div className="gift">
        <Container>
          <div className="row align-items-center flex-md-row-reverse">
            <div className="col-lg-6 pe-lg-5">
              <img className="img-fluid" src={img3} alt="" />
            </div>
            <div className="col-lg-6">
              <h2>Going Beyond for those in need</h2>
              <p>We are excited to introduce you to the BeyondBox Foundation, our way of giving back and supporting existing charities in their vital humanitarian and philanthropic work. We have pledged a third of our platform's net earnings to our Foundation, which will provide financial assistance to our network of partner charities. Our commitment to supporting these charities is directly tied to the growth of our platform, which motivates us to continually improve and provide the best possible service to our users. When you use BeyondBox, you can feel good knowing that you're not only creating a legacy for yourself but also supporting important causes. You can view our Foundation page to learn more about the incredible organizations we support and the impact they're making in their communities. Join us in our mission to make the world a better place, one BeyondBox at a time.</p>
              <Link to="/foundation" className="planBtn"><span> Learn More</span></Link>
            </div>
          </div>
        </Container>
      </div>
    </Layout>
        
    );
};

export default Landing;