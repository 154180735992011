import {LIST_USER,USER} from '../constant/index';

export const listUserPeople = data => {
  return {
    type: LIST_USER.GET_USER_PEOPLE,
    payload: data,
  };
};

export const mailUnSubscribed = data => {
  return {
    type: USER.CONFIRM_MAIL_UNSUBSCRIPTION,
    payload: data,
  };
};
