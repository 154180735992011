import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Stack,Table } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Layout from "../../../layout/Admin/layout";
import { getAdminUserPaymentDetails } from "../../../redux/actions/admin/adminUserAction";
var converter = require('number-to-words');

const AdminUserPaymentDetails = (props: any) => {
  const navTo=useNavigate();
  const UserPayementDetailsLocation = useLocation();
  let userId = UserPayementDetailsLocation.state
    ? UserPayementDetailsLocation.state.id
    : "NA";

  const [userDetailData, setUserDetailData] = useState<any>([]);
  const [activePlan, setActivePlan] = useState<any>("NA");

  useEffect(() => {
    if (userId) {
      props.getAdminUserPaymentDetails({ id: userId });
    }
  }, []);

  useEffect(() => {
    if (props.adminUserPaymentDetailsData) {
      setUserDetailData(props.adminUserPaymentDetailsData);

    }
  }, [props.adminUserPaymentDetailsData]);
  useEffect(() => {
    if (userDetailData) {
      userDetailData && userDetailData.map((list:any)=>{
        if(list.planisActive == 1 ){
             setActivePlan(list.boxPlans.planType)
        }

      })
      
      
    }
  }, [userDetailData]);

  return (
    <>
      <Layout>
      <div className="col-auto" >
            <Button className='backBtn float-end '  onClick={() => navTo(-1)}>Back</Button>
          </div>
        <Container>
          <div className="myProfile p-3 mb-3 bg-transparent">
            <h2 className="mb-5 d-flex justify-content-center"> Payment Details</h2>

            <div className="paymentDetailData">
              <Form>
                <Row>
                  <Col>
                    <Form.Group className="formRow">
                      <Form.Label>Active Plan :</Form.Label>
                      <Form.Text>
                        {" "}
                        {activePlan}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="formRow">
                      <Form.Label>Total Subscribed plan:</Form.Label>
                      <Form.Text>
                        {" "}
                        {userDetailData && userDetailData[0]
                          ? userDetailData.length+' ( '+converter.toWords(userDetailData.length).charAt(0).toUpperCase() + converter.toWords(userDetailData.length).slice(1)+' )'
                          : "N/A"}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </div>
            <div className="paymentDetailTable">
            {/* <Layout> */}
            {/* <Stack direction="horizontal" gap={3}>
                   {'gap stack'}
        </Stack> */}
                <Table responsive>
          <thead>
            <tr>
              <th>S.No.</th>
              <th>Plan Type</th>
              <th>Plan Created</th>
              <th>Plan Expiry</th>
              <th>Plan Amount (R)</th>
            </tr>
          </thead>
          <tbody>
            {userDetailData &&
              userDetailData.map((list: any, index: number) => {
                {
                  if(1==1) 
                  
                {return (
                  <>
                    <tr>
                      <td>{index + 1}</td>
                      
                      <td>
                        {list.boxPlans.planCode}
                      </td>
                      <td> { new Date (list.created).toLocaleDateString()} </td>
                         <td> {  new Date (list.planExpiryDate).toLocaleDateString()}
                         </td>
                         <td>{list.boxPlans.planPrice}
                         </td>
                    </tr>
                  </>
                );}
                  }
              })}
          </tbody>
        </Table>
            {/* </Layout> */}
            </div>
          </div>
        </Container>
      </Layout>
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  getAdminUserPaymentDetails: (data: any) =>
    dispatch(getAdminUserPaymentDetails(data)),
});

const mapStateToProps = (state: any) => {
  return {
    adminUserPaymentDetailsData:
      state.AdminUserReducer &&
      state.AdminUserReducer.adminUserPaymentDetailsData
        ? state.AdminUserReducer.adminUserPaymentDetailsData
        : null,

    state,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminUserPaymentDetails);
