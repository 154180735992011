import Api from './apiCall';
import {API_URLS} from '../constant/index';

export const getUserPeopleService = async params => {
   const result = await Api.get(API_URLS.GET_USER_PEOPLE_URL, params);
   return result;
};

export const confirmMailUnSubscription_Service = async params => {
   const result = await Api.put(API_URLS.GET_MAIL_UNSUBSCRIBE_URL, params);
   return result;
};  

