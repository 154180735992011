
import { select } from "redux-saga/effects";
import {SUBSCRIPTION_PLAN} from "../constant/index";

const initialState = {
    loading : false,
    planDetailsData:null,
    selectPlanDetailsData:null,
    selectPaidPlanDetailsData:null,
    currentPlanDetailsData:null,
    cancelPlanDetailsData:null,
    allPlanDetail:null,
  }
const PlansReducer = (state = initialState, action) => {

      switch (action.type) {    

      case SUBSCRIPTION_PLAN.SET_SUBSCRIPTION_PLAN_TO_STATE:
        return {
          ...state,
          planDetailsData: action.data,
          loading: false,
        };
        case SUBSCRIPTION_PLAN.SET_SELECTED_SUBSCRIPTION_PLAN_TO_STATE:
        return {
          ...state,
          selectPlanDetailsData: action.data,
          loading: false,
        };
        case SUBSCRIPTION_PLAN.SET_SELECTED_PAID_SUBSCRIPTION_PLAN_TO_STATE:
        return {
          ...state,
          selectPaidPlanDetailsData: action.data,
          loading: false,
        };
        case SUBSCRIPTION_PLAN.SET_CURRENT_PLAN_TO_STATE:
        return {
            ...state,
            currentPlanDetailsData: action.data,
            loading: false,
          };  
          case SUBSCRIPTION_PLAN. SET_CANCEL_PLAN_TO_STATE:
            return {
                ...state,
                cancelPlanDetailsData: action.data,
                loading: false,
              }; 
              case SUBSCRIPTION_PLAN. SET_ALL_PLAN_TO_STATE:
            return {
                ...state,
                allPlanDetail: action.data,
                loading: false,
              }; 
        default:
            return state;
        }
       };

    export default PlansReducer