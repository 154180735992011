export function addMarketerValidation(value: any) {
    const emregex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const phoneRegex = /^((?:\+27|27)|0)(\d{2})-?(\d{3})-?(\d{4})$/;
    let errors: any = {};
    if (!value.mName) {
      errors["mName"] = "Marketer name is required";
    }
    if (!value.mPayfastPassphrase) {
      errors["mPayfastPassphrase"] = "Passphrase  is required";
    }
   
    if (!value.mContactNumber) {
      errors["mContactNumber"] = "Marketer Phone is required";
    } else if (!phoneRegex.test(value.mContactNumber)) {
      errors["mContactNumber"] = "Enter a valid  mobile number";
    }
    //
    if (!value.mMerchantId) {
        errors["mMerchantId"] = "Marketer Merchant Id is required";
      } 
      if (!value.mMerchantKey) {
        errors["mMerchantKey"] = "Marketer Merchant Key is required";
      } 

    if (!value.mEmail) {
        errors["mEmail"] = "Marketer email is required";
      } else if (!emregex.test(value.mEmail)) {
        errors["mEmail"] = "Enter a valid email";
      }
    // if (!value.mBankName) {
    //   errors["mBankName"] = "Marketer bank name is required";
    // }
    // if (!value.mIntcode) {
    //     errors["mIntcode"] = "Marketer Int code is required";
    //   }
      // if (!value.mAccountType) {
      //   errors["mAccountType"] = "Account type is required";
      // }
      // if (!value.mBranchCode) {
      //   errors["mBranchCode"] = "Branch code is required";
      // }
   
  
    return errors;
  }
  