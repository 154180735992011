import { ADMIN_USER,ADMIN } from "../../constant";
export const getAdminUserList = data => {
    return {
      type: ADMIN_USER.GET_ADMIN_USER_LIST,
      payload: data,
    };
  };
  
  export const getAdminUserAdminList = data => {
    return {
      type: ADMIN_USER.GET_ADMIN_USER_ADMIN_LIST,
      payload: data,
    };
  };
  export const getAdminUserById = data => {
    return {
      type: ADMIN_USER.GET_ADMIN_USER_BY_ID,
      payload: data,
    };
  };
  export const getAdminUserPaymentDetails = data => {
    return {
      type: ADMIN_USER.GET_ADMIN_USER_PAYMENT_DETAILS,
      payload: data,
    };
  };
  export const disableAdminUserById = data => {
    return {
      type: ADMIN_USER.DISABLE_ADMIN_USER_BY_ID,
      payload: data,
    };
  };
  export const getAdminUserByIdentity = data => {
    return {
      type: ADMIN_USER.GET_ADMIN_USER_BY_IDENTITY,
      payload: data,
    };
  };

  export const activateDeactivateUserById = data => {
    return {
      type: ADMIN_USER.ACTIVATE_DEACTIVATE_USER_BY_ID,
      payload: data,
    };
  };

  export const downloadCSV = data => {
    return {
      type: ADMIN_USER.REQUEST_DOWNLOAD_CSV,
      payload: data,
    };
  };

  export const getUnsubscribedMailUsersList = data => {
    return {
      type: ADMIN_USER.GET_UNSUBSCRIBED_MAIL_USER_LIST,
      payload: data,
    };
  };
 