import { put, call } from 'redux-saga/effects';

import {
  updateFilesService,
  getBoxCoverService,
  getBoxListService,
  getPublicBoxListService,
  getUserByNameService,
  getUserByEmailService,
  getBoxIconService,
  createBoxService,
  getReceiedBoxListService,
  getReceivedBoxDetailService,
  getChildReceiedBoxListService,
  getCreatedBoxDetails,
  getUploadedFilesByBoxIdandFolderId,
  deleteUploadedFilesByFileId,
  setScheduledBoxService,
  getBoxByIdService,
  updateBoxService,
  deleteBoxService,
  getUserByIdService,
  getBoxDetailService,
  getBoxRecordsListingWithSearchKeywordService,
  getPublicBoxDetailContent,
  uploadSearvice_forBoxCoverImageByUser,
  updateRecipientDetailService,
  setCategorywise_scheduler,
  updateMessagesService,
  uploadCoverPhotoByloggedInUserService,
  saveAudioOrVideoLinksService,
  getRelationshipMasterDataService,
  saveRecipientProfilePicTemprorayService,
  removeScheduler_service,
  releaseBoxes_service,
} from '../services/boxService';

import { BOX_API, COMMON_MESSAGE } from '../constant/index';
import { generateReadableError } from '../../hooks/messages';

const clearErrorMessage = {
  type: COMMON_MESSAGE,
  data: {
    status: '',
    page: '',
    data: [],
  }
}

export function* fetchBoxCover({ payload }) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(getBoxCoverService, payload);

    yield put({ type: BOX_API.SET_BOX_COVER_PHOTO_TO_STATE, data });


  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'error',
        page: 'box',
        data: generateReadableError(error),
      },
    });
  }
}

export function* fetchBoxIcons({ payload }) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(getBoxIconService, payload);

    yield put({ type: BOX_API.SET_BOX_ICON_TO_STATE, data });


  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'error',
        page: 'box',
        data: generateReadableError(error),
      },
    });
  }
}

export function* fetchUserByName({ payload }) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(getUserByNameService, payload);
    yield put({ type: BOX_API.SET_RESULT_SEARCHED_BY_NAME, data });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'error',
        page: 'Create_or_Edit_Box',
        data: generateReadableError(error),
      },
    });
  }
}

export function* fetchUserByEmail({ payload }) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(getUserByEmailService, payload);
    yield put({ type: BOX_API.SET_USER_BY_EMAIL_TO_STATE, data });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'error',
        page: 'box',
        data: generateReadableError(error),
      },
    });
  }
}

export function* fetchUserById({ payload }) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(getUserByIdService, payload);
    yield put({ type: BOX_API.SET_USER_BY_ID_TO_STATE, data });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'error',
        page: 'box',
        data: generateReadableError(error),
      },
    });
  }
}

export function* fetchCreateBox({ payload }) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(createBoxService, payload);
    yield put({ type: BOX_API.SET_CREATE_BOX_TO_STATE, data });

    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'success',
        page: 'create_box',
        data: '',
      },
    });

  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'error',
        page: 'create_box',
        data: generateReadableError(error),
      },
    });
  }
}

export function* fetchBoxList({ payload }) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(getBoxListService, payload);
    yield put({ type: BOX_API.SET_BOX_LIST_TO_STATE, data });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'error',
        page: 'box',
        data: generateReadableError(error),
      },
    });
  }
}
export function* fetchPublicBoxList({ payload }) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(getPublicBoxListService, payload);
    yield put({ type: BOX_API.SET_PUBLIC_BOX_LIST_TO_STATE, data });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'error',
        page: 'box',
        data: generateReadableError(error),
      },
    });
  }
}

// RECEIVED BOX LIST ----
export function* fetchReceiedBoxList({ payload }) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(getReceiedBoxListService, payload);
    yield put({ type: BOX_API.SET_RECEIVED_BOX_LIST, data });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'error',
        page: 'box',
        data: generateReadableError(error),
      },
    });
  }
}

export function* fetchChildReceiedBoxList({ payload }) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(getChildReceiedBoxListService, payload);
    yield put({ type: BOX_API.SET_CHILD_RECEIVED_BOX_LIST, data });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'error',
        page: 'box',
        data: generateReadableError(error),
      },
    });
  }
}

// CREATED BOX DETAILS ----
export function* fetchCreatedBoxDetails({ payload }) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(getCreatedBoxDetails, payload);
    yield put({ type: BOX_API.SET_CREATED_BOX_DETAILS, data });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'error',
        page: 'box',
        data: generateReadableError(error),
      },
    });
  }
}

export function* uploadBoxFiles({ payload }) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(updateFilesService, payload);
    yield put({ type: BOX_API.UPLOAD_BOX_FILES_SUCCESS, data });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'error',
        page: 'box',
        data: generateReadableError(error),
      },
    });
  }
}

// GENERATOR FUNCTION FOR FOLDER INSIDE CREATED BOX (Ex:Images,Vidoes,Messages... etc)----
export function* fetchUploadedFilesByBoxIdandFolderId({payload}) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(getUploadedFilesByBoxIdandFolderId, payload);
    yield put({type: BOX_API.SET_UPLOADED_FILES_DETAILS, data});
  }catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'error',
        page: 'box',
        data: generateReadableError(error),
      },
    });
  }
}

// DELETE UPLOADED FILES BY ITS ID----
export function* fetchdeleteUploadedFilesByFileId({payload}) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(deleteUploadedFilesByFileId, payload);
    yield put({type: BOX_API.DELETE_UPLOADED_FILES_SUCCESS, data});
  }catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'error',
        page: 'box',
        data: generateReadableError(error),
      },
    });
  }
}

// SCHEDULED BOX ---------
export function* fetchScheduledBox({ payload }) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(setScheduledBoxService, payload);
    yield put({ type: BOX_API.SET_SCHEDULE_BOX_TO_STATE, data });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'error',
        page: 'box',
        data: generateReadableError(error),
      },
    });
  }
}

export function* fetchBoxById({ payload }) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(getBoxByIdService, payload);
    yield put({ type: BOX_API.SET_BOX_BY_ID_TO_STATE, data });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'error',
        page: 'box',
        data: generateReadableError(error),
      },
    });
  }
}

export function* fetchUpdateBox({ payload }) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(updateBoxService, payload);
    yield put({ type: BOX_API.SET_UPDATE_BOX_TO_STATE, data });
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'success',
        page: 'update_box',
        data: '',
      },
    });} catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'error',
        page: 'update_box',
        data: generateReadableError(error),
      },
    });
  }
}
export function* fetchDeleteBox({ payload }) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(deleteBoxService, payload);
    yield put({ type: BOX_API.SET_DELETE_BOX_TO_STATE, data });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'error',
        page: 'box',
        data: generateReadableError(error),
      },
    });
  }
}

export function* fetchBoxDetails({ payload }) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(getBoxDetailService, payload);
    yield put({ type: BOX_API.SET_LIST_BOX_CONTENT_TO_STATE, data });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'error',
        page: 'box-preview',
        data: generateReadableError(error),
      },
    });
  }
}

// Detail page for particular received box---
export function* fetchRecievedBoxDetails({ payload }) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(getReceivedBoxDetailService, payload);
    yield put({ type: BOX_API.SET_RECEIVED_BOX_DETAILS, data });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'error',
        page: 'received-box',
        data: generateReadableError(error),
      },
    });
  }
}

export function* fetchBoxRecordsListingWithSearchKeyword({ payload }) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(getBoxRecordsListingWithSearchKeywordService, payload);
    yield put({ type: BOX_API.SET_BOX_RECORDS_TO_STATE, data });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'error',
        page: 'box_records',
        data: generateReadableError(error),
      },
    });
  }
}

export function* fetchPublicBoxContent({ payload }) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(getPublicBoxDetailContent, payload);
    yield put({ type: BOX_API.SET_PUBLIC_BOX_DETAIL_CONTENT, data });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'error',
        page: 'box_records',
        data: generateReadableError(error),
      },
    });
  }
}

export function* uploadBoxCoverByUser({ payload }) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(uploadSearvice_forBoxCoverImageByUser, payload);
    yield put({ type: BOX_API.GET_UPLOADED_COVER_PHOTO_BY_USER, data });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'error',
        page: 'edit_box',
        data: generateReadableError(error),
      },
    });
  }
}

// CREATED BOX RECIPIENT DETAILS UPDATE GENERATOR FUNCTION
export function* sendRecipientDetailsforUpdate({ payload }) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(updateRecipientDetailService, payload);
    yield put({ type: BOX_API.GET_RESPONSE_AFTER_RECIPIENT_UPDATE, data });
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'success',
        page: 'createdbox_page',
        data: data.message,
      },
    });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'error',
        page: 'createdbox_page',
        data: generateReadableError(error),
      },
    });
  }
}

// CATEGORYWISE SCHEDULER ON UPLOADED FILES--------------------
export function* setCategorywise_scheduler_on_uploadedFiles({ payload }) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(setCategorywise_scheduler, payload);
    yield put({ type: BOX_API.GET_RESPONSE_CATEGORYWISE_SCHEDULER_ON_UPLOADED_FILES, data });
  
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'success',
        page: 'image_page',
        data: data.message,
      },
    });

  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'error',
        page: 'image_page',
        data: generateReadableError(error),
      },
    });
  }
}

// MESSAGE VIEW PAGE--------------------
export function* updateMessages({ payload }) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(updateMessagesService, payload);
    yield put({ type: BOX_API.GET_UPDATED_MESSAGE, data });
      if(data && data.code === 404){
        yield put({
          type: COMMON_MESSAGE,
          data: {
            status: data.status,
            code : data.code,
            page: 'message_page',
            data: data.message,
          },
        });
      }else{
      yield put({
        type: COMMON_MESSAGE,
        data: {
          status: 'success',
          page: 'message_page',
          data: data.message,
        },
      });
    } 
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'error',
        page: 'message_page',
        data: generateReadableError(error),
      },
    });
  }
}

// CREATE ? EDIT BOX UPLOAD COVER PHOTO BY LOGGED IN USER--------------------
export function* uploadCoverPhotoByloggedInUser({ payload }) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(uploadCoverPhotoByloggedInUserService, payload);
    yield put({ type: BOX_API.GET_RESPONSE_AFTER_COVERPHOTO_UPLOAD, data });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'error',
        page: 'create_or_edit_page',
        data: generateReadableError(error),
      },
    });
  }
}

// SAVE AUDIO/VIDEO LINKS--------------------
export function* saveAudioOrVideoLinks({ payload }) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(saveAudioOrVideoLinksService, payload);
    yield put({ type: BOX_API.SAVED_AUDIO_OR_VIDEO_LINK_RESPONSE, data });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'error',
        page: 'create_or_edit_page',
        data: generateReadableError(error),
      },
    });
  }
}

// RELATIONSHIP with RECIPIENTS (Old/New)--------------------
export function* getRelationshipMasterData() {
  try {
    yield put(clearErrorMessage);
    const data = yield call(getRelationshipMasterDataService);
    yield put({ type: BOX_API.SET_RELATIONSHIP_MASTER_DATA, data });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'error',
        page: 'create_or_edit_page',
        data: generateReadableError(error),
      },
    });
  }
}

// RECIPIENT PROFILE PIC
export function* saveRecipientProfilePicTemproray({ payload }){
  try {
    yield put(clearErrorMessage);
    const data = yield call(saveRecipientProfilePicTemprorayService, payload);
    yield put({ type: BOX_API.SET_UPLOAD_RECIPIENT_PROFILE_PIC, data });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'error',
        page: 'create_edit_box',
        data: generateReadableError(error),
      },
    });
  }
}

// REMOVE SCHEDULER
export function* removeScheduler({ payload }){
  try {
    yield put(clearErrorMessage);
    const data = yield call(removeScheduler_service, payload);
    yield put({ type: BOX_API.GET_RESP_AFTER_SCHEDULER_REMOVE, data });
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'success',
        page: '',
        data: "Scheduler removed successfully!",
      },
    });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'error',
        page: '',
        data: generateReadableError(error),
      },
    });
  }
}

// RELEASE BOXES FOR DIFF EMAIL
export function* releaseBoxes({ payload }){
  try {
    yield put(clearErrorMessage);
    const data = yield call(releaseBoxes_service, payload);
    yield put({ type: BOX_API.GET_RELEASE_BOX_CONFIRMATION_RESPONSE, data });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'error',
        page: 'user-box-release',
        data: generateReadableError(error),
      },
    });
  }
}