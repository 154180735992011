import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Stack,
  Table,
} from "react-bootstrap";
import { connect } from "react-redux";
import Layout from "../../../layout/Admin/layout";
import {
  adminCountryById,
  adminCountryList,
  adminStateList,
  removeAdminCountry,
  updateAdminCountry,
} from "../../../redux/actions/admin/adminCountryAction";
import { BsEyeFill, BsPencilFill, BsTrashFill } from "react-icons/bs";
import { addAdminCountry } from "../../../redux/actions/admin/adminCountryAction";
import Swal from "sweetalert2";
const AdminCountry = (props: any) => {
  useEffect(() => {
    props.adminCountryList();
  }, []);
  useEffect(() => {
    if (props.countryAdminData) {
      setCountryData(props.countryAdminData);
    }
  }, [props.countryAdminData]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [countryData, setCountryData] = useState([]);
  const [countryValue, setCountryValue] = useState({ name: "" });
  const [updateCountryValue, setUpdateCountryValue] = useState({ updateName: "" });
  const [updateCountryModalOpen, setUpdateCountryModalOpen] = useState(false);
  const [updateCountryModalShow, setUpdateCountryModalShow] = useState(false);
  const [updateCountryId,setUpdateCountryId]=useState('')
  const handleChange = (event: any) => {
    setCountryValue({
      ...countryValue,
      [event.target.name]: event.target.value,
    
    });
    setUpdateCountryValue({
      ...updateCountryValue,
      [event.target.name]: event.target.value,
    })
  };

 
  const handleCountryModalClose = () => {
    setUpdateCountryModalShow(false);
    setUpdateCountryModalOpen(false);
  };
  const handleDeleteCountry = (e: any) => {
      
    let countryId = e;
    Swal.fire({
      title: `Are you sure you want to delete ? `,
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "YES",
      denyButtonText: `NO`,
      confirmButtonColor: "green",
      showCloseButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        // payload.id=userId;
        // payload.isActive=0;
      
        props.removeAdminCountry({ id: countryId });
         
      }
    });
  };
useEffect(()=>{
if(props.removeAdminCountryData){
  props.adminCountryList();
}
},[props.removeAdminCountryData])
  const handleSubmit = (e: any) => {
    e.preventDefault();
    let payload = {
      name: "",
    };
   
    payload.name = countryValue && countryValue.name;
    props.addAdminCountry(payload);
  };
  useEffect(() => {
    if (props.addCountryAdminData) {
      props.adminCountryList();
      handleClose();
    }
  }, [props.addCountryAdminData]);

  useEffect(() => {
    if (props.countryByIdAdminData && updateCountryModalOpen) {
      var result = props.countryByIdAdminData? props.countryByIdAdminData: "";
      updateCountryValue.updateName=result.name?result.name:"";
      setUpdateCountryModalShow(true);
    }
  }, [props.countryByIdAdminData]);

  const handleUpdate = (e: any) => {
   
    let countryId = e;
    if (countryId) {
      setUpdateCountryId(countryId)
      props.adminCountryById({ id: countryId });
      setUpdateCountryModalOpen(true);
    }
  };

  const updateCountrySubmit=(e:any)=>{
    e.preventDefault();
    let payload={
      id:0,
      name:""
    }
    payload.id=(updateCountryId ? parseInt(updateCountryId):0);
    payload.name=(updateCountryValue?updateCountryValue.updateName:"")
    props.updateAdminCountry(payload)
  }
  useEffect(()=>{
if(props.updateAdminCountryData){
  props.adminCountryList();
  handleCountryModalClose();

}
  },[props.updateAdminCountryData])
  return (
    <div>
      <Layout>
        <Modal
          {...props}
          show={show}
          size="lg"
          backdrop="static"
          onHide={handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "whitesmoke" }}>
              Add Country
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmit}>
              <Container>
                <Row>
                  <Col md={6}>
                    <Form.Group
                      className="mb-3 mt-3 "
                      //controlId={data.field2}
                    >
                      <Form.Label style={{ color: "whitesmoke" }}>
                        Country Name
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        value={countryValue.name}
                        onChange={handleChange}
                        autoFocus
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Container>
              <Row className="justify-content-md-center mt-10">
                <Col className="" md="auto">
                  <Button
                    variant="primary"
                    type="submit"
                    // disabled={!isValid}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
        </Modal>

        <Modal
          {...props}
          show={updateCountryModalShow}
          size="lg"
          backdrop="static"
          onHide={handleCountryModalClose}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "whitesmoke" }}>
              Edit Country
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={updateCountrySubmit}>
              <Container>
                <Row>
                  <Col md={6}>
                    <Form.Group
                      className="mb-3 mt-3 "
                      //controlId={data.field2}
                    >
                      <Form.Label style={{ color: "whitesmoke" }}>
                        Country Name
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="updateName"
                        value={updateCountryValue.updateName}
                        onChange={handleChange}
                        autoFocus
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Container>
              <Row className="justify-content-md-center mt-10">
                <Col className="" md="auto">
                  <Button
                    variant="primary"
                    type="submit"
                    // disabled={!isValid}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
        </Modal>

        <Stack direction="horizontal" gap={3}>
          <Button variant="info" className="ms-auto" onClick={handleShow}>
            {" "}
            Add{" "}
          </Button>{" "}
        </Stack>
        <Table responsive>
          <thead>
            <tr>
              <th>S.No.</th>
              <th>Country Name</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {countryData &&
              countryData.map((list: any, index: number) => {
                return (
                  <>
                    <tr>
                      <td>{index + 1}</td>
                      <td> {list.name}</td>
                      <td>
                        <button
                          type="submit"
                          className="btn btn-outline-warning m-2"
                          value={list.id}
                          onClick={()=>handleUpdate(list.id)}
                          // onClick={handleAddCountryModalShow}
                        >
                          <BsPencilFill />
                        </button>
                        <button
                          type="submit"
                          className="btn btn-outline-danger"
                          value={list.id}
                         onClick={()=>handleDeleteCountry(list.id)}
                          //id={list.planType}
                          //onClick={removePlan}
                        >
                          <BsTrashFill />
                        </button>
                      </td>
                    </tr>
                  </>
                );
              })}
          </tbody>
        </Table>
      </Layout>
    </div>
  );
};
const mapDispatchToProps = (dispatch: any) => ({
  adminCountryList: (data: any) => dispatch(adminCountryList(data)),
  adminStateList: (data: any) => dispatch(adminStateList(data)),
  addAdminCountry: (data: any) => dispatch(addAdminCountry(data)),
  adminCountryById: (data: any) => dispatch(adminCountryById(data)),
  updateAdminCountry:(data: any) => dispatch(updateAdminCountry(data)),
  removeAdminCountry:(data: any) => dispatch(removeAdminCountry(data)),
});

const mapStateToProps = (state: any) => {
  return {
    countryAdminData:state.AdminCountryReducer && state.AdminCountryReducer.countryAdminData
        ? state.AdminCountryReducer.countryAdminData
        : null,
    message: state.CommonMessageReducer && state.CommonMessageReducer.message
        ? state.CommonMessageReducer.message
        : null,
    stateAdminData:state.AdminCountryReducer && state.AdminCountryReducer.stateAdminData
        ? state.AdminCountryReducer.stateAdminData
        : null,
    addCountryAdminData: state.AdminCountryReducer && state.AdminCountryReducer.addCountryAdminData
        ? state.AdminCountryReducer.addCountryAdminData
        : null,
    countryByIdAdminData:state.AdminCountryReducer &&
      state.AdminCountryReducer.countryByIdAdminData
        ? state.AdminCountryReducer.countryByIdAdminData
        : null,
    updateAdminCountryData:state.AdminCountryReducer &&
      state.AdminCountryReducer.updateAdminCountryData
        ? state.AdminCountryReducer.updateAdminCountryData
        : null,
    removeAdminCountryData: state.AdminCountryReducer &&
      state.AdminCountryReducer.removeAdminCountryData
        ? state.AdminCountryReducer.removeAdminCountryData
        : null,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AdminCountry);
