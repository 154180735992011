import {WEB_API} from "../constant/index";

const initialState = {
    loading             : false,
    contactus_res       : null, 
    countryDialCodeList : null,
}
const webReducer = (state = initialState, action) => {
    switch (action.type) {    
        
        case WEB_API.SET_CONTACTUS:
            return { ...state, contactus_res: action.data, loading: false, };
        
        case WEB_API.SET_COUNTRY_DAIL_CODE:
            return { ...state, countryDialCodeList: action.data, loading: false, };    
            
        default:
            return state;
    }
}
export default webReducer; 