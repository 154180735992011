import { put, call } from "redux-saga/effects";
import {
  getAdminCountryService,
  getAdminStatesService,
  getAdminCitiesService,
  getAdminCountryByIdService,
  addAdminCountryService,
  updateAdminCountryService,
  deleteAdminCountryService,
  addAdminStatesService,
  updateAdminStatesService,
  deleteAdminStatesService,
  getAdminStatesByIdService,
  addAdminCityService,
  updateAdminCityService,
  deleteAdminCityService,
  getAdminCityByIdService

} from "../../services/admin/adminCountryService";
import { ADMIN_COUNTRY_DATA, COMMON_MESSAGE } from "../../constant/index";
import { generateReadableError } from "../../../hooks/messages";
const clearErrorMessage = {
  type: COMMON_MESSAGE,
  data: {
    status: "",
    page: "",
    data: [],
  },
};

export function* fetchAdminCountryList({ payload }) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(getAdminCountryService, payload);
    yield put({ type: ADMIN_COUNTRY_DATA.SET_ADMIN_COUNTRIES_TO_STATE, data });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: "error",
        page: "country api",
        data: generateReadableError(error),
      },
    });
  }
}

export function* fetchAdminStateList({ payload }) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(getAdminStatesService, payload);
    yield put({ type: ADMIN_COUNTRY_DATA.SET_ADMIN_STATES_TO_STATE, data });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: "error",
        page: "states api",
        data: generateReadableError(error),
      },
    });
  }
}

export function* fetchAdminCityList({ payload }) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(getAdminCitiesService, payload);
    yield put({ type: ADMIN_COUNTRY_DATA.SET_ADMIN_CITIES_TO_STATE, data });
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: "success",
        page: "cities api",
        data: "",
      },
    });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: "error",
        page: "cities api",
        data: generateReadableError(error),
      },
    });
  }
}

export function* fetchAddAdminCountryList({ payload }) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(addAdminCountryService, payload);
    yield put({
      type: ADMIN_COUNTRY_DATA.SET_ADD_ADMIN_COUNTRY_TO_STATE,
      data,
    });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: "error",
        page: "country api",
        data: generateReadableError(error),
      },
    });
  }
}

export function* fetchAdminCountryById({ payload }) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(getAdminCountryByIdService, payload);
    yield put({
      type: ADMIN_COUNTRY_DATA.SET_ADMIN_COUNTRIES_BY_ID_TO_STATE,
      data,
    });
    //localStorage.setItem('countryID',JSON.stringify(data))
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: "error",
        page: "country api",
        data: generateReadableError(error),
      },
    });
  }
}

export function* fetchUpdateAdminCountryList({ payload }) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(updateAdminCountryService, payload);
    yield put({
      type: ADMIN_COUNTRY_DATA.SET_UPDATE_ADMIN_COUNTRIES_TO_STATE,
      data,
    });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: "error",
        page: "country api",
        data: generateReadableError(error),
      },
    });
  }
}


export function* fetchRemoveAdminCountryList({ payload }) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(deleteAdminCountryService, payload);
    yield put({
      type: ADMIN_COUNTRY_DATA.SET_REMOVE_ADMIN_COUNTRIES_TO_STATE,
      data,
    });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: "error",
        page: "country api",
        data: generateReadableError(error),
      },
    });
  }
}

export function* fetchAddAdminStates({ payload }) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(addAdminStatesService, payload);
    yield put({
      type: ADMIN_COUNTRY_DATA.SET_ADD_ADMIN_STATES_TO_STATE,
      data,
    });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: "error",
        page: "country api",
        data: generateReadableError(error),
      },
    });
  }
}

export function* fetchUpdateAdminStates({ payload }) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(updateAdminStatesService, payload);
    yield put({
      type: ADMIN_COUNTRY_DATA.SET_UPDATE_ADMIN_STATES_TO_STATE,
      data,
    });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: "error",
        page: "country api",
        data: generateReadableError(error),
      },
    });
  }
}

export function* fetchDeleteAdminStates({ payload }) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(deleteAdminStatesService, payload);
    yield put({
      type: ADMIN_COUNTRY_DATA.SET_REMOVE_ADMIN_STATES_TO_STATE,
      data,
    });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: "error",
        page: "country api",
        data: generateReadableError(error),
      },
    });
  }
}

export function* fetchAdminStatesById({ payload }) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(getAdminStatesByIdService, payload);
    yield put({
      type: ADMIN_COUNTRY_DATA.SET_ADMIN_STATE_BY_ID_TO_STATE,
      data,
    });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: "error",
        page: "country api",
        data: generateReadableError(error),
      },
    });
  }
}
export function* fetchAddAdminCity({ payload }) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(addAdminCityService, payload);
    yield put({
      type: ADMIN_COUNTRY_DATA.SET_ADD_ADMIN_CITY_TO_STATE,
      data,
    });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: "error",
        page: "country api",
        data: generateReadableError(error),
      },
    });
  }
}

export function* fetchUpdateAdminCity({ payload }) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(updateAdminCityService, payload);
    yield put({
      type: ADMIN_COUNTRY_DATA.SET_UPDATE_ADMIN_CITY_TO_STATE,
      data,
    });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: "error",
        page: "country api",
        data: generateReadableError(error),
      },
    });
  }
}

export function* fetchDeleteAdminCity({ payload }) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(deleteAdminCityService, payload);
    yield put({
      type: ADMIN_COUNTRY_DATA.SET_REMOVE_ADMIN_CITY_TO_STATE,
      data,
    });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: "error",
        page: "country api",
        data: generateReadableError(error),
      },
    });
  }
}

export function* fetchAdminCityById({ payload }) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(getAdminCityByIdService, payload);
    yield put({
      type: ADMIN_COUNTRY_DATA.SET_ADMIN_CITY_BY_ID_TO_STATE,
      data,
    });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: "error",
        page: "country api",
        data: generateReadableError(error),
      },
    });
  }
}