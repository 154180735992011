import { ADMIN_MARKETER } from "../../constant";

export const addAdminMarketer = data => {
  
    return {
      type:ADMIN_MARKETER.ADD_ADMIN_MARKETER,
      payload: data,
    };
  };
  export const getMarketerList = data => {
    return{
      type:ADMIN_MARKETER.GET_MARKETER_LIST,
      payload:data,
    }
  }
  
  export const deleteAdminMarketer = data => {
    return{
      type:ADMIN_MARKETER.DELETE_ADMIN_MARKETER,
      payload:data,
    }
  }
  
  export const getMarketerDetailById = data => {
    return{
      type:ADMIN_MARKETER.GET_MARKETER_DETAIL_BY_ID,
      payload:data,
    }
  }

export const updateAdminMarketer = data => {
  return{
    type:ADMIN_MARKETER.UPDATE_ADMIN_MARKETER,
    payload:data,
  }
}

export const getMarketerPromoCode = data => {
  return{
    type:ADMIN_MARKETER.GET_MARKETER_PROMOCODE,
    payload:data,
  }
}
export const getMarketerPromoCodeById = data => {
  return{
    type:ADMIN_MARKETER.GET_MARKETER_PROMOCODE_BY_ID,
    payload:data,
  }
}

export const getUserThroughMarkters = data => {
  return{
    type:ADMIN_MARKETER.GET_USER_THROUGH_MARKETERS,
    payload:data,
  }
}

export const getMarketerUsersById = data => {
  return{
    type:ADMIN_MARKETER.GET_MARKETER_USERS_BY_ID,
    payload:data,
  }
}
export const getUnsubscribedUsers = data => {
  return{
    type:ADMIN_MARKETER.GET_UNSUBSCRIBED_USERS,
    payload:data,
  }
}
export const getMTHistory = data => {
  return{
    type:ADMIN_MARKETER.GET_MT_HISTORY,
    payload:data,
  }
}

export const downloadSubscribedCSV = data => {
  return{
    type:ADMIN_MARKETER.DOWNLOAD_SUBSCRIBED_CSV,
    payload:data,
  }
}