import {AUTH} from "../constant/index";

const initialState = {
    loading      : false,
    loginData    : null,
    OTPResponse  : null,
    registerData : null, 
    verifyUserData:null,
}
const AuthReducer = (state = initialState, action) => {
    switch (action.type) {    

      case AUTH.REGISTER_SUCCESS:
        return { ...state, registerData: action.data, loading: false, };
      
      case AUTH.LOGIN_SUCCESS:
        return { ...state, loginData: action.data, loading: false, };
      
      case AUTH.SET_LOGIN_OTP_RESPONSE:
        return { ...state, OTPResponse: action.data, loading: false, };  
      
      case AUTH.SET_DETAILS_USERNAME:
        return { ...state, usernameData: action.data, loading: false, };

      case AUTH.GET_RESPONSE_USER_FOR_BOX_RELEASE_FROM_DIFF_EMAIL:
          return { ...state, verifyUserData: action.data, loading: false, };  
      
      default:
        return state;
    }
   };

   export default AuthReducer;