
 export function checkExtension(url:any, extensionsArray:any) {
    var extension = getExtensionFromUrl(url);
    if (extension === '') {
      return false;
    } else {
      if (extensionsArray.indexOf(extension) === -1) {
        return false;
      } else {
        return true;
      }
    }
  } 
  
 function getExtensionFromUrl (url:any) {
    var extension = '';
    var pattern = /\.([0-9a-z]+)(?:[\?#]|$)/i;
    if (url.match(pattern) && url.match(pattern)[1]) {
       extension = url.match(pattern)[1];
    }
    return extension;
  }
  
  
  
  
  
  
  
  
  
  