export function editProfileFormValidation(value: any) {

    const emregex             = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    // const phoneRegex          = /^((?:\+27|27)|0)(\d{2})-?(\d{3})-?(\d{4})$/;
    const alpharegex          = /^[A-Za-z ]+$/;
    const identityNumberRegex = /^(?=.*[0-9\b])(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]+$/;
    const numRegex            = /[0-9]/;
    const newRegex            = /[^a-z0-9 .]/gi;
    
    const userData         = localStorage.getItem('profileData');
    const loggeduserEmail  = userData ? JSON.parse(`${userData}`).email : '';
    const loggeduserMobile = userData ? JSON.parse(`${userData}`).mobile : '';
    
    var minimumAge18 = new Date();
    minimumAge18.setFullYear(minimumAge18.getFullYear() -18);
    
    var selectedDob  = (new Date(value.dob));

    let errors:any={};

    if (!value.first_name) {
        errors['first_name'] = 'First name is required';
    } else if(!alpharegex.test(value.first_name)){
        errors['first_name'] = 'Enter a valid name';
    }

    if (!value.last_name||value.last_name=='') {
        errors['last_name'] = 'Last name is required';
    }else if(!alpharegex.test(value.last_name)){
        errors['last_name'] = 'Enter a valid name';
    }

    if (!value.identity_number) {
        errors['identity_number'] = 'Identity number is required';
    } else if (value.identity_number.length < 7){
        errors['identity_number'] = 'Identity number must between 7 to 20 characters only';
    } 
    
    if(newRegex.test(value.identity_number)){
        errors['identity_number'] = 'Special characters are not allowed';
    }
    // else {
    //     if(!numRegex.test(value.identity_number)){
    //         errors['identity_number'] = 'Identity number must be numeric or alpha-numeric';
    //     }else if(!numRegex.test(value.identity_number) && !identityNumberRegex.test(value.identity_number)){
    //         errors['identity_number'] = 'Identity number must be numeric or alpha-numeric';
    //     }
    // }
   
    if (value.city == 0 || value.city == '') {
        errors['city'] = 'City is required';
    }
    
    if(value.dob==''){
        errors['dob']='Date of birth is required';
    }else if(selectedDob > minimumAge18){
        errors['dob']='You must be 18 years old or above';
    }

    //----------------------------------------------------//
    // Kin Validation:--- 

    if (value.kinEmail==='') {
        errors['kinEmail'] = 'Email address is required';
    } else if (!emregex.test(value.kinEmail)) {
        errors['kinEmail'] = 'Enter a valid email address';
    }
    
    if(loggeduserEmail === value.kinEmail){
        errors['kinEmail'] = 'Your personal email id can\'t be used!';
    }

    if (!value.kinName) {
        errors['kinName'] = 'Kin detail is required';
    }else if(!alpharegex.test(value.kinName)){
        errors['kinName'] = 'Enter a valid name';
    }

    if(!value.kinCountryDialCode){
        errors['kinCountryDialCode'] = 'Country dial code is required';
    }else if(value.kinName && value.kinEmail && !value.kinCountryDialCode){
        errors['kinCountryDialCode'] = 'Country dial code is required';
    }else if(value.kinPhoneNumber && !value.kinCountryDialCode){
        errors['kinCountryDialCode'] = 'Country dial code is required';
    }

    if (value.kinPhoneNumber==='') {
        errors['kinPhoneNumber'] = 'Phone number is required';
    } 
    // else if(!phoneRegex.test(value.kinPhoneNumber)) {
    //     errors['kinPhoneNumber'] = 'Enter a valid South Africa mobile number';
    // }

    if (loggeduserMobile === value.kinPhoneNumber) {
        errors['kinPhoneNumber'] = 'Your personal phone number can\'t be used!';
    }

    if (value.kinEmailOpt !=='' && !emregex.test(value.kinEmailOpt)) {
        errors['kinEmailOpt'] = 'Enter a valid email address';
    }

    if(loggeduserEmail === value.kinEmailOpt){
        errors['kinEmailOpt'] = 'Your personal email id can\'t be used!';
    }

    if((value.kinEmailOpt && value.kinEmail) && (value.kinEmailOpt === value.kinEmail)){
        errors['kinEmailOpt'] = 'No duplicate email allowed';
    }

    if(value.kinNameOpt !='' && !alpharegex.test(value.kinNameOpt)){
        errors['kinNameOpt'] = 'Enter a valid name';
    }

    // if(value.kinPhoneNumberOpt !='' && !phoneRegex.test(value.kinPhoneNumberOpt)) {
    //     errors['kinPhoneNumberOpt'] = 'Enter a valid South Africa mobile number';
    // }

    if (loggeduserMobile === value.kinPhoneNumberOpt) {
        errors['kinPhoneNumberOpt'] = 'Your personal phone number can\'t be used!';
    }

    if(value.kinNameOpt && !value.kinEmailOpt && !value.kinCountryDialCodeOpt && !value.kinPhoneNumberOpt){
        errors['kinEmailOpt'] = 'Email is required';
        errors['kinCountryDialCodeOpt'] = 'Country dial code is required';
        errors['kinPhoneNumberOpt'] = 'Phone number is required';
    }else if(!value.kinNameOpt && value.kinEmailOpt && !value.kinCountryDialCodeOpt && !value.kinPhoneNumberOpt){
        errors['kinNameOpt'] = 'Name is required';
        errors['kinCountryDialCodeOpt'] = 'Country dial code is required';
        errors['kinPhoneNumberOpt'] = 'Phone number is required';
    }else if(!value.kinNameOpt && !value.kinEmailOpt && value.kinCountryDialCodeOpt && !value.kinPhoneNumberOpt){
        errors['kinNameOpt'] = 'Name is required';
        errors['kinEmailOpt'] = 'Email is required';
        errors['kinPhoneNumberOpt'] = 'Phone number is required';
    }else if(!value.kinNameOpt && !value.kinEmailOpt && !value.kinCountryDialCodeOpt && value.kinPhoneNumberOpt){
        errors['kinNameOpt'] = 'Name is required';
        errors['kinEmailOpt'] = 'Email is required';
        errors['kinCountryDialCodeOpt'] = 'Country dial code is required';
    }else if(value.kinNameOpt && value.kinEmailOpt && !value.kinCountryDialCodeOpt && !value.kinPhoneNumberOpt){
        errors['kinCountryDialCodeOpt'] = 'Country dial code is required';
        errors['kinPhoneNumberOpt'] = 'Phone number is required';
    }else if(value.kinNameOpt && !value.kinEmailOpt && value.kinCountryDialCodeOpt && !value.kinPhoneNumberOpt){
        errors['kinEmailOpt'] = 'Email is required';
        errors['kinPhoneNumberOpt'] = 'Phone number is required';
    }else if(value.kinNameOpt && !value.kinEmailOpt && !value.kinCountryDialCodeOpt && value.kinPhoneNumberOpt){
        errors['kinEmailOpt'] = 'Email is required';
        errors['kinCountryDialCodeOpt'] = 'Country dial code is required';
    }else if(!value.kinNameOpt && value.kinEmailOpt && value.kinCountryDialCodeOpt && !value.kinPhoneNumberOpt){
        errors['kinNameOpt'] = 'Name is required';
        errors['kinPhoneNumberOpt'] = 'Phone number is required';
    }else if(!value.kinNameOpt && value.kinEmailOpt && !value.kinCountryDialCodeOpt && value.kinPhoneNumberOpt){
        errors['kinNameOpt'] = 'Name is required';
        errors['kinCountryDialCodeOpt'] = 'Country dial code is required';
    }else if(value.kinNameOpt && value.kinEmailOpt && value.kinCountryDialCodeOpt && !value.kinPhoneNumberOpt){
        errors['kinPhoneNumberOpt'] = 'Phone number is required';
    }else if(value.kinNameOpt && value.kinEmailOpt && !value.kinCountryDialCodeOpt && value.kinPhoneNumberOpt){
        errors['kinCountryDialCodeOpt'] = 'Country dial code is required';
    }   

    if((value.kinPhoneNumberOpt && value.kinPhoneNumber) && (value.kinPhoneNumberOpt === value.kinPhoneNumber)){
        errors['kinPhoneNumberOpt'] = 'No duplicate number allowed';
    }

    return errors;
   
}
