import { ADMIN_USER } from "../../constant/index";

const initialState = {
  loading: false,
  adminUserData: null,
  adminUserDataById: null,
  adminUserDataByIdentity: null,
  updateAdminUserData: null,
  disableAdminUserData: null,
  unsubscribedMailUserData:null,
};
const AdminUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADMIN_USER.SET_ADMIN_USER_LIST_TO_STATE:
      return {
        ...state,
        adminUserData: action.data,
        loading: false,
      };
      case ADMIN_USER.SET_ADMIN_USER_ADMIN_LIST_TO_STATE:
      return {
        ...state,
        adminUserAdminData: action.data,
        loading: false,
      };
      case ADMIN_USER.SET_ADMIN_USER_BY_ID_TO_STATE:
        return {
          ...state,
          adminUserDataById: action.data,
          loading: false,
        };
        case ADMIN_USER.SET_ADMIN_USER_PAYMENT_DETAILS_TO_STATE:
        return {
          ...state,
          adminUserPaymentDetailsData: action.data,
          loading: false,
        };
        case ADMIN_USER.SET_ADMIN_USER_BY_IDENTITY_TO_STATE:
        return {
          ...state,
          adminUserDataByIdentity: action.data,
          loading: false,
        };
        case ADMIN_USER.SET_DISABLE_ADMIN_USER_BY_ID_TO_STATE:
          return {
            ...state,
            disableAdminUserData: action.data,
            loading: false,
          };
          case ADMIN_USER.SET_ADMIN_ACTIVATE_DEACTIVATE_USER_BY_ID_TO_STATE :
        return {
          ...state,
          adminActivateDeactivateData: action.data,
          loading: false,
        };

        case ADMIN_USER.SET_UNSUBSCRIBED_MAIL_USER_LIST:
          return {...state,unsubscribedMailUserData:action.data,loading:false,}
      default:
        return state;
    }
  };
  
  export default AdminUserReducer;
  