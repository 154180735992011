export function addFolderValidation(value: any) {
  
    

    let errors:any={};
   if(!value.fName){
    errors['fName']='Folder name is required';
   }
   if(!value.fIcon){
    errors['fIcon']='Folder Icon is required';
   }
   if(!value.fType){
    errors['fType']='Folder type is required';
   }
   if(!value.fiExtension){
    errors['fiExtension']='File Extension is required';
   }

    return errors;
}