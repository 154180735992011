export function Email_n_MobileOTPFormValidation(value: any) {

    const regex         = /^[0-9\b]+$/;
    
    let errors:any={};

    if (value.emailOTP==='') {
        errors['emailOTPErr'] = 'Email OTP is required';
    }else if(!regex.test(value.emailOTP)){
        errors['emailOTPErr'] = 'Invalid input, OTP must contain only numbers';
    }else if((Object.keys(value.emailOTP).length < 4)){
        errors['emailOTPErr'] = 'OTP must be 4 digits'
    }

    if (value.mobileOTP==='') {
        errors['mobileOTPErr'] = 'Mobile OTP is required';
    }else if(!regex.test(value.mobileOTP)){
        errors['mobileOTPErr'] = 'Invalid input, OTP must contain only numbers';
    }else if((Object.keys(value.mobileOTP).length < 4)){
        errors['mobileOTPErr'] = 'OTP must be 4 digits'
    }

    return errors;


}

