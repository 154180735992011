export function createBoxFormValidation(value: any) {

    // const alpharegex=/^[A-Za-z A-Za-z0-9]+$/;
    // const alpharegexDesc = /^[ A-Za-z0-9_@,./#&+-]*$/;
   
    let errors:any={};
    
    if (!value.boxName) {
        errors['boxName'] = 'Box name is required';
    }
    // else if(!alpharegex.test(value.boxName)){
    //     errors['boxName'] = 'Valid name is required';
    // }

    if(!value.boxPicture){
        errors['boxPicture'] = 'Cover picture not selected';
    }
    
    // if(!alpharegexDesc.test(value.boxDescription)){
    //     errors['boxDescription'] = 'Enter a valid description';
    // }

    return errors;
}

export function createBoxAddRecipientValidation(value: any,childRecipientFlag:boolean = false) {

    const emregex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const alpharegex=/^[A-Za-z A-Za-z0-9]+$/;
    
    //DATE RANGE FOR CHILD UNDER 18
    const minimumAgeRange = new Date();
    minimumAgeRange.setFullYear(minimumAgeRange.getFullYear() -18); 
    // let minDate = minimumAgeRange.getDate() + "-" + (minimumAgeRange.getMonth()+1) + "-" + minimumAgeRange.getFullYear()
    
    const maximumAgeRange = new Date();
    maximumAgeRange.setFullYear(maximumAgeRange.getFullYear()); 
    // let maxDate = maximumAgeRange.getDate() + "-" + (maximumAgeRange.getMonth()+1) + "-" + maximumAgeRange.getFullYear()
    // DATE RANGE FOR CHILD UNDER 18

    var selectedDob  = (new Date(value.newRecipientDob));
    // let selectedDate = selectedDob.getDate() + "-" + (selectedDob.getMonth()+1) + "-" + selectedDob.getFullYear()

    let errors:any={};
    
    // if(value.email!=""){
    //  if (!emregex.test(value.email) ) {
    //     errors['email'] = 'Enter a valid email address';
    // }}
   
    if(value.newRecipientFname!=''){
        if(!alpharegex.test(value.newRecipientFname))
        {
            errors['newRecipientFname'] = 'Enter a valid name';
        }
    }else{
        errors['newRecipientFname'] = 'First Name is required';
    }
   
    if( value.newRecipientLname!='')
        { if(!alpharegex.test(value.newRecipientLname))
        {
            errors['newRecipientLname'] = 'Enter a valid name';
        }
    }else{
        errors['newRecipientLname'] = 'Last Name is required';
    }
   
   
    if(value.newRecipientDob === ''){
        errors['newRecipientDob'] = 'DOB is required';
    }else if(
        (
            (((new Date(selectedDob.toDateString())) < (new Date(minimumAgeRange.toDateString()))) && (selectedDob.getFullYear() != minimumAgeRange.getFullYear())) || 
            (((new Date(selectedDob.toDateString())) > (new Date(maximumAgeRange.toDateString()))) && (selectedDob.getFullYear() != maximumAgeRange.getFullYear())) ||
            (((new Date(selectedDob.toDateString())) < (new Date(minimumAgeRange.toDateString()))) && (selectedDob.getFullYear() == minimumAgeRange.getFullYear())) ||
            (((new Date(selectedDob.toDateString())) > (new Date(maximumAgeRange.toDateString()))) && (selectedDob.getFullYear() == maximumAgeRange.getFullYear()))
        ) && childRecipientFlag){
        errors['newRecipientDob'] = 'Invalid DOB';
    }else if((selectedDob > minimumAgeRange) && !childRecipientFlag){
        errors['newRecipientDob'] = 'Invalid DOB';
    }

    if((value.newRecipientRelation === 0 || value.newRecipientRelation === "0") && !childRecipientFlag){
        errors['newRecipientRelation'] = 'Relation is required';
    }
    
    
    
    // if(value){
    //     if (!emregex.test(value)) {
    //         errors['newRecipientEmail'] = 'Enter a valid email address';
    //     }
    // }else if(value.newRecipientEmail){
    //     if (!emregex.test(value.newRecipientEmail)) {
    //         errors['newRecipientEmail'] = 'Enter a valid email address';
    //     }
    // }
    // else{
    //     errors['newRecipientEmail'] = 'Email is required';
    // }

    return errors;


}
