import { WEB_API } from "../constant";

export const contactus = data => {
  return {
    type: WEB_API.GET_CONTACTUS,
    payload: data,
  };
};

export const getCountryDialCode = data => {
  return {
    type: WEB_API.GET_COUNTRY_DAIL_CODE,
    payload: data,
  };
};