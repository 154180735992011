import {put, call} from 'redux-saga/effects';
import {getUserPeopleService,confirmMailUnSubscription_Service} from '../services/userService'
import {COMMON_MESSAGE, LIST_USER,USER } from '../constant/index';
import {generateReadableError} from '../../hooks/messages';

const clearErrorMessage = {
  type: COMMON_MESSAGE,
  data: {
    status: '',
    page: '',
    data: [],
  }}

export function* fetchUserPeople({payload}) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(getUserPeopleService, payload);
   
    yield put({type: LIST_USER.SET_USER_PEOPLE_TO_STATE, data});
    

  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'error',
        page: 'plans',
        data: generateReadableError(error),
      },
    });
  }
}

export function* mailUnSubscribed({payload}) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(confirmMailUnSubscription_Service, payload);
    yield put({type: USER.GET_MAIL_UNSUBSCRIBED_CONFRIMATION, data});
    
    if(data.code == 404){
      yield put({
        type: COMMON_MESSAGE,
        data: {
          status: 'error',
          page: 'mail-unsubscribe',
          data: data.message,
        },
      });  
    }

    if(data.code == 422){
      yield put({
        type: COMMON_MESSAGE,
        data: {
          status: 'error',
          page: 'mail-unsubscribe',
          data: data.message,
        },
      });
    }
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'error',
        page: 'mail-unsubscribe',
        data: generateReadableError(error),
      },
    });
  }
}

