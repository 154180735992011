import React, { useEffect, useState } from 'react'
import {getUnsubscribedMailUsersList} from '../../../../redux/actions/admin/adminUserAction';
import { connect } from 'react-redux';
import { Table } from 'react-bootstrap';

function UnsubscribedMailUsers(props:any) {
    const [loader, setLoader] = useState(true)
    useEffect(() =>{
        props.getUnsubscribedMailUsersList();
    },[])

    useEffect(() =>{
        if(props.unsubscribedMailUserData){
            setLoader(false)
        }
    },[props.unsubscribedMailUserData])
// ---------------------------------------------//
console.log(props.unsubscribedMailUserData);

  return (
    <div className={loader ? `text-center scrollBar` : `scrollBar`}>
      {loader ?
            <div className="lds-dual-ring mt-5">
                <span className="text-warning">Loading... please wait!</span>
            </div>
        :  
            <>
            {props.unsubscribedMailUserData && props.unsubscribedMailUserData.length !=0 &&
            <Table responsive>
                <thead>
                <tr>
                    <th className="text-warning text-center">S.No.</th>
                    <th className="text-warning text-center">User Name</th>
                    <th className="text-warning text-center">Plan Type</th>
                    <th className="text-warning text-center">Action</th>
                    <th className="text-warning text-center">Payment Details</th>
                </tr>
                </thead>
                <tbody>
                    {props.unsubscribedMailUserData.map((list: any, index: number) => {
                        {
                        if(list.isActive==1) 
                            {
                                return (
                                <>
                                    <tr>
                                    <td className="text-center">{index + 1}</td>
                                    <td className="text-center"> {list.firstName}  {list.lastName}</td>
                                    <td className="text-center"> 
                                        {list.userHMpayments && list.userHMpayments[0].boxPlans && list.userHMpayments[0].boxPlans.planType ? list.userHMpayments[0].boxPlans.planType :"N/A" }
                                    </td>
                                    {/* <td className="text-center">
                                        <button type="submit" className="btn btn-outline-warning m-2" onClick={()=>handleUserView(list.id)} ><BsFillEyeFill /></button>
                                        <button type="submit" className="btn btn-outline-danger" onClick={()=>handleUserDisable(list.id)} ><BsTrashFill/></button>
                                    </td>
                                    <td className="text-center">
                                        <button  className="btn btn-outline-warning m-2" onClick={()=>handlePaymentDetails(list.id)} > <BsFillEyeFill /></button> 
                                    </td> */}
                                    </tr>
                                </>
                                );
                            }
                        }})}
                </tbody>
            </Table>
            }
            </>
        }
    </div> 
);
}



const mapDispatchToProps = (dispatch: any) => ({
    getUnsubscribedMailUsersList : () => dispatch(getUnsubscribedMailUsersList()),
  });
  
  const mapStateToProps = (state: any) => {
    return {
      unsubscribedMailUserData : (state.AdminUserReducer && state.AdminUserReducer.unsubscribedMailUserData) ? state.AdminUserReducer.unsubscribedMailUserData : null,
    };
  };
export default connect(mapStateToProps,mapDispatchToProps)(UnsubscribedMailUsers)