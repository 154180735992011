import { put, call } from "redux-saga/effects";
import {
  getAdminBoxCoverPhotoService,
  getAddAdminBoxCoverPhotoService,
  adminReleaseBox,
  getAdminBoxCoverPhotoByIdService,
  removeAdminBoxCoverPhotoService,
} from "../../services/admin/adminBoxApiService";
import { COMMON_MESSAGE, ADMIN_BOX_API } from "../../constant/index";
import { generateReadableError } from "../../../hooks/messages";

const clearErrorMessage = {
  type: COMMON_MESSAGE,
  data: {
    status: "",
    page: "",
    data: [],
  },
};

export function* fetchAdminBoxCoverPhotos({ payload }) {
  try {
    yield put({
      type: ADMIN_BOX_API.SET_ADMIN_COVER_PHOTOS_TO_STATE,
      data: null,
    });
    yield put(clearErrorMessage);
    const data = yield call(getAdminBoxCoverPhotoService, payload);
    yield put({ type: ADMIN_BOX_API.SET_ADMIN_COVER_PHOTOS_TO_STATE, data });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: "error",
        page: "admin box cover",
        data: generateReadableError(error),
      },
    });
  }
}
export function* fetchAddAdminBoxCoverPhotos({ payload }) {
  try {
    yield put({
      type: ADMIN_BOX_API.SET_ADD_ADMIN_COVER_PHOTOS_TO_STATE,
      data: null,
    });
    yield put(clearErrorMessage);
    const data = yield call(getAddAdminBoxCoverPhotoService, payload);
    yield put({
      type: ADMIN_BOX_API.SET_ADD_ADMIN_COVER_PHOTOS_TO_STATE,
      data,
    });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: "error",
        page: "admin box cover",
        data: generateReadableError(error),
      },
    });
  }
}
export function* fetchAdminReleaseBox({ payload }) {
  try {
    yield put({
      type: ADMIN_BOX_API.SET_PUT_ADMIN_RELEASE_BOX_TO_STATE,
      data: null,
    });
    yield put(clearErrorMessage);
    const data = yield call(adminReleaseBox, payload);
    yield put({
      type: ADMIN_BOX_API.SET_PUT_ADMIN_RELEASE_BOX_TO_STATE,
      data,
    });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: "error",
        page: "admin box cover",
        data: generateReadableError(error),
      },
    });
  }
}

export function* fetchAdminBoxCoverPhotoById({ payload }) {
  try {
    yield put({
      type: ADMIN_BOX_API.SET_ADMIN_COVER_PHOTO_BY_ID_TO_STATE,
      data: null,
    });
    yield put(clearErrorMessage);
    const data = yield call(getAdminBoxCoverPhotoByIdService, payload);
    yield put({
      type: ADMIN_BOX_API.SET_ADMIN_COVER_PHOTO_BY_ID_TO_STATE,
      data,
    });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: "error",
        page: "admin box cover",
        data: generateReadableError(error),
      },
    });
  }
}

export function* fetchRemoveAdminBoxCoverPhotos({ payload }) {
  try {
    yield put({
      type: ADMIN_BOX_API.SET_REMOVE_ADMIN_COVER_PHOTOS_TO_STATE,
      data: null,
    });
    yield put(clearErrorMessage);
    const data = yield call(removeAdminBoxCoverPhotoService, payload);
    yield put({
      type: ADMIN_BOX_API.SET_REMOVE_ADMIN_COVER_PHOTOS_TO_STATE,
      data,
    });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: "error",
        page: "admin box cover",
        data: generateReadableError(error),
      },
    });
  }
}
