
import React from "react";
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row, Stack, Table, } from "react-bootstrap";
import { BsEyeFill, BsPencilFill, BsTrashFill } from "react-icons/bs";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import Layout from "../../../layout/Admin/layout";
import { addAdminFolder, deleteAdminFolder, getFolderDetailById, updateAdminFolder, } from "../../../redux/actions/admin/adminFolderManagementAction";
import { getBoxIcon } from "../../../redux/actions/boxAction";
import { BASE_URLS } from "../../../redux/constant";
import { addFolderValidation } from "../../../validations/adminPage/addFolder-form";

const ManageFolders = (props: any) => {
  const [adminDetail, setAdminDetail] = useState([]);
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const handleClose = () => {
    setShow(false);
    setNewPlan({
      fName: "",
      fIcon: "",
      fType: "",
      fiExtension: "",
    });
    setCheckValidation({
      fName: false,
      fIcon: false,
      fType: false,
      fiExtension: false,
    });
    setProfileImage("");
  };
  const handleShow = () => setShow(true);
  let [isValid, setIsValid] = useState(true);
  //remove file name
  const [fileName,            setFileName]            = useState("");
  const [viewModalShow,       setViewModalShow]       = useState(false);
  const [viewModalOpen,       setViewModalOpen]       = useState(false);
  const [disabledCheck,       setDisabledCheck]       = useState(true);
  const [addedFolderResult,   setAddedFolderResult]   = useState(false);
  const [folderDetailResult,  setFolderDetailResult]  = useState(false);
  const [updatedFolderResult, setUpdatedFolderResult] = useState(false);
  const [deleteFolderResult,  setDeleteFolderResult]  = useState(false);
  const [updateIconChange,    setUpdateIconChange]    = useState(false);
  const [profileImage,        setProfileImage]        = React.useState("");
  const [image,               setImage]               = useState({ preview: "", raw: "" });
  const [newPlan,             setNewPlan]             = useState({
    fName: "",
    fIcon: "",
    fType: "",
    fiExtension: "",
  });
  const [updatePlan, setUpdatePlan] = useState({
    folderName: "",
    folderIcon: "",
    folderType: "",
    filesExtension: "",
  });
  const [viewModalDetails, setViewModalDetails] = useState<any>({
    folderName: "",
    folderIcon: "",
    folderType: "",
    filesExtension: "",
  });
  let [errors, setErrors] = useState<any>({
    fName: "",
    fIcon: "",
    fType: "",
    fiExtension: "",
  });
  let [checkValidation, setCheckValidation] = useState<any>({
    fName: false,
    fIcon: false,
    fType: false,
    fiExtension: false,
  });
  let inputRef: any;

  useEffect(() => {
    props.getBoxIcon();
  }, []);

  useEffect(() => {
    if (props.boxIconData) setAdminDetail(props.boxIconData);
  }, [props.boxIconData]);

  useEffect(() => {
    if (props.addAdminFolderData) {
      handleAddPopup();
    }
  }, [props.addAdminFolderData]);

  const handleAddPopup = () => {
    if (addedFolderResult) {
      setLoader(false);
      Swal.fire({
        title: "Folder Added Successfully",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "green",
        showCloseButton: true,
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          setShow(false);

          props.getBoxIcon();
          setProfileImage("");
          setAddedFolderResult(false);
        }
      });
      setAdminDetail(props.boxIconData);
    }
  };
  const handleUpdateChange = (event: any) => {
    setCheckValidation({
      ...checkValidation,
      [event.target.name]: true,
    });
    setUpdatePlan({
      ...updatePlan,
      [event.target.name]: event.target.value,
    });
  };
  const handleImageChange = (e: any) => {
    if (e.target.files.length) {
      setUpdateIconChange(true);

      setProfileImage(URL.createObjectURL(e.target.files[0]));
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };

  const handleChange = (event: any) => {
    if (event.target.files && event.target.files.length) {
      setProfileImage(URL.createObjectURL(event.target.files[0]));
      setImage({
        preview: URL.createObjectURL(event.target.files[0]),
        raw: event.target.files[0],
      });
    }
    setCheckValidation({
      ...checkValidation,
      [event.target.name]: true,
    });
    setNewPlan({
      ...newPlan,
      [event.target.name]: event.target.value,
    });
    // setFileName(inputRef.files[0].name);
  };
  useEffect(() => {
    const validationErrors = addFolderValidation(newPlan);

    isValid = Object.keys(validationErrors).length === 1;

    setIsValid(isValid);
    setErrors(validationErrors);
  }, [newPlan]);

  const handleViewModalClose = () => {
    setFolderDetailResult(false);
    setProfileImage("");
    setViewModalShow(false);
    setViewModalOpen(false);
  };
  const handleViewModalShow = (e: any) => {
    setDisabledCheck(true);
    openModal(e);
  };
  const handleUpdatePlanShow = (e: any) => {
    setDisabledCheck(false);
    openModal(e);
  };
  useEffect(() => {
    if (props.getFolderDetailByIdData) {
      let result1 = props.getFolderDetailByIdData
        ? props.getFolderDetailByIdData
        : "";

      // view
      viewModalDetails.folderName = result1.folderName
        ? result1.folderName
        : "";
      viewModalDetails.folderIcon = result1.folderIcon
        ? result1.folderIcon
        : "";
      viewModalDetails.folderType = result1.folderType
        ? result1.folderType
        : "";
      viewModalDetails.filesExtension = result1.filesExtension
        ? result1.filesExtension
        : "";

      // update
      updatePlan.folderName = result1.folderName ? result1.folderName : "";
      updatePlan.folderIcon = result1.folderIcon ? result1.folderIcon : "";
      updatePlan.folderType = result1.folderType ? result1.folderType : "";
      updatePlan.filesExtension = result1.filesExtension
        ? result1.filesExtension
        : "";

      if (folderDetailResult) {
        setViewModalOpen(true);
        setViewModalShow(true);
      }
    }
  }, [props.getFolderDetailByIdData]);

  const openModal = (e: any) => {
    let folderId = e;
    // api call for particular folder id
    props.getFolderDetailById(folderId);
    setFolderDetailResult(true);
  };
  const formDataUpdate = new FormData();
  const handleUpdateSubmit = (e: any) => {
    e.preventDefault();

    // formDataUpdate
    formDataUpdate.append("folderName", updatePlan.folderName);
    formDataUpdate.append("folderType", updatePlan.folderType);
    if (updateIconChange) {
      formDataUpdate.append("folderIcon", image.raw);
    }

    formDataUpdate.append("filesExtension", updatePlan.filesExtension);
    let updateId = props.getFolderDetailByIdData.id;
    let updatePayload = {
      id: updateId,
      data: formDataUpdate,
    };
    props.updateAdminFolder(updatePayload);
    setUpdatedFolderResult(true);
    setLoader(true);
  };
  useEffect(() => {
    if (props.updateAdminFolderData && updatedFolderResult) {
      setLoader(false);
      Swal.fire({
        title: "Updated Successfully",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "green",
        showCloseButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          props.getBoxIcon();
          setUpdatedFolderResult(false);
          handleViewModalClose();
        }
      });
    }
  }, [props.updateAdminFolderData]);

  const handleRemoveFolder = (e: any, name: any) => {
    let deleteFolderId = e;
    let removeFolderName = name;
    Swal.fire({
      title: `Do you want to remove ${removeFolderName} folder? `,
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "YES",
      denyButtonText: `NO`,
      confirmButtonColor: "green",
      showCloseButton: true,
    }).then((result) => {
      if (result.isConfirmed) {

        props.deleteAdminFolder({ id: deleteFolderId });
        setLoader(true);
        setDeleteFolderResult(true);
      }
    });
  };

  useEffect(() => {
    if (props.deleteAdminFolderData && deleteFolderResult) {
      setLoader(false);
      let deteleResult = props.deleteAdminFolderData.message;
      Swal.fire({
        title: ` ${deteleResult}`,
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "green",
        showCloseButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          props.getBoxIcon();
          setDeleteFolderResult(false);
        }
      });
    }
  }, [props.deleteAdminFolderData]);

  let result: any;
  useEffect(() => {
    if (viewModalOpen) {
      result = props.boxIconData ? props.boxIconData : "";
    }
  });

  const formDataAdd = new FormData();
  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (image && image.raw) {
      e.preventDefault();
      formDataAdd.append("folderName", newPlan.fName);
      formDataAdd.append("folderType", newPlan.fType);
      formDataAdd.append("folderIcon", image.raw);
      formDataAdd.append("filesExtension", newPlan.fiExtension);

      if (isValid) {
        props.addAdminFolder(formDataAdd);
        setLoader(true);

        setAddedFolderResult(true);
      }
    }

    setImage({
      preview: "",
      raw: "",
    });
  };

  const modalDetail = [
    {
      field1: "Folder Name",
      field2: "fName",
    },
    {
      field1: "Folder Type",
      field2: "fType",
    },
    {
      field1: "Folder Icon",
      field2: "fIcon",
    },
    {
      field1: "Files Extension",
      field2: "fiExtension",
    },
  ];

  return (
    <>
      <Layout>
        {loader ? (
          <div className="adminLoader">
            <span className="text-warning text-center lds-dual-ring"></span>
          </div>
        ) : (
          ""
        )}

          <Row>
            <Col md={10}><h3 style={{color:"#E79E1F"}}>Manage Folders</h3></Col>
            <Col md="auto" className="ms-auto">
              <Stack direction="horizontal" gap={3}>
                <Button variant="info" className="ms-auto" onClick={handleShow}> Add </Button>
              </Stack>
            </Col>
          </Row>
          <hr style={{border:'1px solid #E79E1F'}}/>
        <div className="usersManageFolders scrollBar">
          <Table responsive>
            <thead>
              <tr>
                <th className="text-warning text-center">S.No</th>
                <th className="text-warning text-center">Folder Name</th>
                <th className="text-warning text-center">Folder Icon</th>
                <th className="text-warning text-center">Folder Id</th>
                <th className="text-warning text-center">Files Extension</th>
                <th className="text-warning text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {adminDetail && adminDetail.map((list: any, index: number) => {
                return (
                  <>
                    <tr>
                      <td className="text-center">{index + 1}</td>
                      <td className="text-center">{list.folderName}</td>
                      <td className="text-center"><img className="img" src={ list && list.folderIcon && `${BASE_URLS.BASE_URL}/user-box-files/resource?key=${list.folderIcon}` } alt="" /></td>
                      <td className="text-center">{list.id}</td>
                      <td className="text-center">{list.filesExtension}</td>
                      <td className="text-center">
                        <button type="submit" className="btn btn-outline-success" value={list.id} onClick={() => handleViewModalShow(list.id)} > <BsEyeFill /> </button>
                        <button type="submit" className="btn btn-outline-warning m-2" value={list.id} onClick={() => handleUpdatePlanShow(list.id)} > <BsPencilFill /> </button>
                        <button type="submit" className="btn btn-outline-danger" value={list.id} id={list.planType} onClick={() => handleRemoveFolder(list.id, list.folderName) } > <BsTrashFill /> </button>
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </Table>
        </div>
        {/* -------------ADD FOLDER MODAL---------------  */}
        <Modal show={show} size="lg" backdrop="static" onHide={handleClose} className="adminModal">
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#E79E1F" }}>
              Add Folder
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmit}>
              <Container>
                <Row>
                  {modalDetail &&
                    modalDetail.map((data: any, index) => {
                      return (
                        <>
                          <Col className="col-md-auto" md={6}>
                            {data.field1 == "Folder Icon" ? (
                              <div
                              // className="file-upload-input"
                              >
                                <Form.Group
                                  className="mb-3 mt-3 "
                                  //controlId={data.field2}
                                >
                                  <Form.Label style={{ color: "whitesmoke" }}>
                                    {data.field1}
                                  </Form.Label>
                                  <input
                                    className="fileInput form-control pr-3"
                                    type="file"
                                    id="file"
                                    name={"selectedFile"}
                                    accept={
                                      props.selectedMedia === "Documents"
                                        ? "image/jpeg, application/pdf"
                                        : ""
                                    }
                                    ref={(refParam) => (inputRef = refParam)}
                                    onChange={handleChange}
                                  />
                                </Form.Group>
                                <strong
                                  className="text-warning"
                                  title={fileName}
                                  style={{
                                    display: "block",
                                    whiteSpace: "break-spaces",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {fileName}
                                </strong>
                                {profileImage ? (
                                  <img
                                    src={profileImage}
                                    alt=""
                                    width={"150"}
                                    height={"150"}
                                    style={{ borderRadius: "10px" }}
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                            ) : (
                              <Form.Group
                                className="mb-3 mt-3 "
                                //controlId={data.field2}
                              >
                                <Form.Label style={{ color: "whitesmoke" }}>
                                  {data.field1}
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name={data.field2}
                                  /*  
                            value={newPlan.} */
                                  onInput={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    e.target.value = e.target.value.trimStart();
                                  }}
                                  onChange={handleChange}
                                  autoFocus
                                />

                                {checkValidation[data.field2] &&
                                  errors[data.field2] && (
                                    <p className="text-danger">
                                      {errors[data.field2]}
                                    </p>
                                  )}
                              </Form.Group>
                            )}
                          </Col>
                        </>
                      );
                    })}
                </Row>
                {loader ? (
                  <div className="adminLoader">
                    <span className="text-warning text-center lds-dual-ring"></span>
                  </div>
                ) : (
                  ""
                )}
              </Container>
              <Row className="justify-content-md-center mt-10">
                <Col className="" md="auto" style={!isValid && !profileImage ? {cursor:"not-allowed"} :{}}>
                  <Button
                    className="backBtn"
                    type="submit"
                    disabled={isValid && profileImage ? false : true}
                    // onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
          {/*  <Modal.Footer></Modal.Footer> */}
        </Modal>

        {/* ACTION - VIEW ,UPDATE AND DELETE */}

        <Modal
          show={viewModalShow}
          size="lg"
          backdrop="static"
          onHide={handleViewModalClose}
          className="adminModal"
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#E79E1F" }}>
              {disabledCheck ? "Folder View Details" : "Folder Update Details"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleUpdateSubmit}>
              <Container>
                <Row>
                  <Col className="col-md-auto" md={6}>
                    <Form.Group className="mb-3 mt-3 ">
                      <Form.Label style={{ color: "whitesmoke" }}>
                        Folder Name
                      </Form.Label>

                      <Form.Control
                        type="text"
                        name="folderName"
                        onChange={handleUpdateChange}
                        value={
                          disabledCheck
                            ? viewModalDetails.folderName
                            : updatePlan.folderName
                        }
                        autoFocus
                        disabled={disabledCheck}
                      />
                    </Form.Group>
                  </Col>
                  <Col className="col-md-auto" md={6}>
                    <Form.Group className="mb-3 mt-3 ">
                      <Form.Label style={{ color: "whitesmoke" }}>
                        Folder Type
                      </Form.Label>

                      <Form.Control
                        type="text"
                        name="folderType"
                        onChange={handleUpdateChange}
                        value={
                          disabledCheck
                            ? viewModalDetails.folderType
                            : updatePlan.folderType
                        }
                        autoFocus
                        disabled={disabledCheck}
                      />
                    </Form.Group>
                  </Col>
                  <Col className="col-md-auto" md={6}>
                    <Form.Group className=" mt-3 ">
                      <Form.Label style={{ color: "whitesmoke" }}>
                        Folder Icon
                      </Form.Label>
                      <Col className="col-md-auto" md={2}>
                        {disabledCheck ? (
                          ""
                        ) : (
                          <div
                            className="file-upload-input"
                            style={{ marginTop: "-10px", marginLeft: "-10px" }}
                          >
                            <Form.Group
                              className="mb-3  "
                              //controlId={data.field2}
                            >
                              <input
                                className="fileInput form-control pr-3"
                                type="file"
                                id="file"
                                name={"selectedFile"}
                                accept={
                                  props.selectedMedia === "Documents"
                                    ? "image/jpeg, application/pdf"
                                    : ""
                                }
                                ref={(refParam) => (inputRef = refParam)}
                                onChange={handleImageChange}
                              />
                            </Form.Group>
                            <strong
                              className="text-warning"
                              title={fileName}
                              style={{
                                display: "block",
                                whiteSpace: "break-spaces",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {fileName}
                            </strong>
                            {profileImage ? (
                              <div
                                className="updateIcon"
                                style={{ width: "150px" }}
                              >
                                <img
                                  src={profileImage}
                                  alt=""
                                  width={"400"}
                                  height={"150"}
                                  style={{ borderRadius: "10px" }}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        )}
                        {!profileImage ? (
                          <img
                            onChange={handleUpdateChange}
                            className="adminFolderManageModal"
                            src={
                              disabledCheck
                                ? viewModalDetails &&
                                  viewModalDetails.folderIcon &&
                                  `${BASE_URLS.BASE_URL}/user-box-files/resource?key=${viewModalDetails.folderIcon}`
                                : updatePlan &&
                                  updatePlan.folderIcon &&
                                  `${BASE_URLS.BASE_URL}/user-box-files/resource?key=${updatePlan.folderIcon}`
                            }
                            alt=""
                          />
                        ) : (
                          ""
                        )}
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col className="col-md-auto" md={6}>
                    <Form.Group className="mb-3 mt-3 ">
                      <Form.Label style={{ color: "whitesmoke" }}>
                        File Extension
                      </Form.Label>

                      <Form.Control
                        type="text"
                        name="filesExtension"
                        onChange={handleUpdateChange}
                        value={
                          disabledCheck
                            ? viewModalDetails.filesExtension
                            : updatePlan.filesExtension
                        }
                        autoFocus
                        disabled={disabledCheck}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                {loader ? (
                  <div className="adminLoader">
                    <span className="text-warning text-center lds-dual-ring"></span>
                  </div>
                ) : (
                  ""
                )}
              </Container>
              <Row className="justify-content-md-center mt-10">
                <Col className="" md="auto">
                  {!disabledCheck ? (
                    <Button
                      variant="primary"
                      type="submit"
                      // onClick={handleSubmit}
                    >
                      Update
                    </Button>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
            </Form>
          </Modal.Body>
          {/*  <Modal.Footer></Modal.Footer> */}
        </Modal>
      </Layout>
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  getBoxIcon: (data: any) => dispatch(getBoxIcon(data)),
  addAdminFolder: (data: any) => dispatch(addAdminFolder(data)),
  getFolderDetailById: (data: any) => dispatch(getFolderDetailById(data)),
  updateAdminFolder: (data: any) => dispatch(updateAdminFolder(data)),
  deleteAdminFolder: (data: any) => dispatch(deleteAdminFolder(data)),
});
const mapStateToProps = (state: any) => {
  return {
    boxIconData:
      state.BoxReducer && state.BoxReducer.boxIconData
        ? state.BoxReducer.boxIconData
        : null,

    addAdminFolderData:
      state.AdminFolderManagementReducer &&
      state.AdminFolderManagementReducer.addAdminFolderData
        ? state.AdminFolderManagementReducer.addAdminFolderData
        : null,
    deleteAdminFolderData:
      state.AdminFolderManagementReducer &&
      state.AdminFolderManagementReducer.deleteAdminFolderData
        ? state.AdminFolderManagementReducer.deleteAdminFolderData
        : null,
    updateAdminFolderData:
      state.AdminFolderManagementReducer &&
      state.AdminFolderManagementReducer.updateAdminFolderData
        ? state.AdminFolderManagementReducer.updateAdminFolderData
        : null,
    getFolderDetailByIdData:
      state.AdminFolderManagementReducer &&
      state.AdminFolderManagementReducer.getFolderDetailByIdData
        ? state.AdminFolderManagementReducer.getFolderDetailByIdData
        : null,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageFolders);
