import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Spinner, Table } from "react-bootstrap";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Layout from "../../../layout/Admin/layout";
import { getMarketerDetailById, getMarketerPromoCodeById, getMarketerUsersById, getMTHistory, getUnsubscribedUsers,downloadSubscribedCSV } from "../../../redux/actions/admin/adminMarketerAction";
import payfastImage from "../../../assets/images/payfast.png";
import MyImage from "../../../layout/ImageComponent/myimage";
import { BASE_URLS } from "../../../redux/constant";
import { FaDownload } from "react-icons/fa";
var converter = require('number-to-words');

var md5 = require('md5');

const AdminMarketeruserList = (props:any)=>{
  const [userDetailData,        setUserDetailData]        = useState<any>([]);
  const [unsubscriedUserResult, setUnsubscriedUserResult] = useState<any>([]);
  const [particularPc,          setParticularPc]          = useState<any>([]);
  const [mTHistory,             setMTHistory]             = useState<any>([]);
  const [paidOrFree,            setPaidOrFree]            = useState('Subscribed');
  const [showFreeUser,          setShowFreeUser]          = useState(false);
  const [showTHistory,          setShowTHistory]          = useState(false);
  const [marketerName,          setMarketerName]          = useState('');
  const [marketerMId,           setMarketerMId]           = useState('');
  const [marketerMKey,          setMarketerMKey]          = useState('');
  const [marketerPassphrase,    setMarketerPassphrase]    = useState('');
  const [finalPayment,          setFinalPayment]          = useState<any>('0');
  const [csvLoader,             setCSVLoader]             = useState(false);
  const [pageHeading,           setPageHeading]           = useState('');
  const marketerIdLocation                                = useLocation();
  const navTo = useNavigate();

  // payment 
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const amtPayble   = finalPayment;
  const item_Name   = 'Marketer Payment';
  const fname       = 'BeyondBox';
  const lname       = 'Admin';
  const email       = 'vcvaibhavchauhan98@gmail.com'; // beyond box email or admins
  const userId      = marketerIdLocation.state.id;
  const commonURL   = `${BASE_URLS.APP_BASE_URL}/admin-marketer`;
  const itnURL      = `${BASE_URLS.BASE_URL}/payfast/marketer/payments`;
  const passphrase  = process.env.REACT_APP_ADMIN_PAYFAST_MERCHANT_PASSPHRASE;
  const payfastUrl  = process.env.REACT_APP_ADMIN_PAYMENT_PAGE_URL;

  useEffect(()=>{
    props.getMarketerUsersById(marketerIdLocation.state.id);
    props.getMarketerPromoCodeById(marketerIdLocation.state.id);
    props.getMarketerDetailById(marketerIdLocation.state.id);
  },[])
    
  useEffect(()=>{
    if(props.getMarketerUsersByIdData ){
      setUserDetailData(props.getMarketerUsersByIdData);
    }
  },[props.getMarketerUsersByIdData]);

  useEffect(()=>{
    if(props.getMarketerPromoCodeByIdData){
      { props.getMarketerPromoCodeByIdData.length > 0 ? 
        setParticularPc(props.getMarketerPromoCodeByIdData[0].promoCode ) :
        setParticularPc('NA')}
    }
  },[props.getMarketerPromoCodeByIdData])

  useEffect(()=>{
    if(props.getMTHistoryData){
      setMTHistory(props.getMTHistoryData)
    }
  },[props.getMTHistoryData])

  useEffect(()=>{
    if(props.getMarketerDetailByIdData){
      let result = props.getMarketerDetailByIdData ? props.getMarketerDetailByIdData : "";
      let fP:any  = (result.marketerCommissionAmt == null  ? 0 : result.marketerCommissionAmt );
      setFinalPayment(fP);
      setMarketerName(result.marketerName);
      setMarketerMId(result.marketerMerchantId);
      setMarketerMKey(result.marketerMerchantKey);
      setMarketerPassphrase(result.marketerPassphrase)
    }
  },[props.getMarketerDetailByIdData])

  const params: any = {
    merchant_id      :  process.env.REACT_APP_ADMIN_PAYFAST_MERCHANT_ID,
    merchant_key     :  process.env.REACT_APP_ADMIN_PAYFAST_MERCHANT_KEY,
    return_url       : `${commonURL}`,
    cancel_url       : `${commonURL}`,
    notify_url       : `${itnURL}`,  
    name_first       : `${fname}`,
    name_last        : `${lname}`,
    email_address    : `${email}`,
    amount           : `${amtPayble}`,
    item_name        : `${item_Name}`,
    item_description : 'Marketer Commission',
    custom_str1      : `${userId}`,
    passphrase       :  passphrase
  }

  const serachParams: any = new URLSearchParams(params);
  const MD5Signature: any = md5(serachParams.toString());   
  const handleUnsubscribed =(e:any)=>{
    setShowFreeUser(true)
    setPaidOrFree('Unsubscribed');
    props.getUnsubscribedUsers(marketerIdLocation.state.id);
  }

  const handleSubscribed =(e:any)=>{
    setPaidOrFree('Subscribed');
    setShowFreeUser(false)
  }

  useEffect(()=>{
    if(props.getUnsubscribedUsersData){
      setUnsubscriedUserResult(props.getUnsubscribedUsersData);
    }
  },[props.getUnsubscribedUsersData])

  const requestPayment = () =>{
    if(isClicked){
      setIsClicked(!isClicked);
    }else{
      setIsClicked(!isClicked);
    }
  }

  const handleTransactionHistory = () =>{
    props.getMTHistory(marketerIdLocation.state.id);
    setShowTHistory(!showTHistory)
  }

  const handleFilters = (e:any) =>{
    let ddValue = '';
    if(e.target.value == 'Subscribed'){
      ddValue = 'Subscribed Users'
      setShowFreeUser(false)
    }else if(e.target.value == 'Unsubscribed'){
      ddValue = 'Unsubscribed Users'
      setShowFreeUser(true)
      props.getUnsubscribedUsers(marketerIdLocation.state.id);
    }else{
      ddValue = 'Subscribed Users'
    }
    setPaidOrFree(e.target.value);
    setPageHeading(ddValue);
  }

  const handleCSV = () =>{
    setCSVLoader(true);
    props.downloadSubscribedCSV(marketerIdLocation.state.id);
  }

  useEffect(() =>{
    if(csvLoader){
      setTimeout(() =>{
        setCSVLoader(false);
      },2000)
    }
  },[csvLoader])
//----------------------------------------------------------------------// 

  return(
    <>
      <Layout>
        <div className="col-auto" >
          <Button className='backBtn float-end '  onClick={() => navTo(-1)}>Back</Button>
        </div>
        <Container>
          <div className="myProfile p-3 mb-3 bg-transparent">
            <div className="heading">
              <h2 className="m-5 d-flex justify-content-center"> User Details</h2>
            </div>
            <div className="paymentDetailData ">
              <Form>
                <Row> 
                  <Col md={6}>
                    <Form.Group className="">
                      <Form.Label>Marketer Name :</Form.Label>
                      <Form.Text> {marketerName} </Form.Text>
                    </Form.Group>
                  </Col>
                  <Col md="auto">
                    <Form.Group className="">
                      <Form.Label>Marketer PromoCode :</Form.Label>
                      <Form.Text> {particularPc} </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
                <Row> 
                  <Col md={6}>
                    <Form.Group className="">
                      <Form.Label>Total Users&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;</Form.Label>
                      <Form.Text> {userDetailData.length} ( {converter.toWords(userDetailData.length).charAt(0).toUpperCase() + converter.toWords(userDetailData.length).slice(1)} ) </Form.Text>
                    </Form.Group>
                  </Col>
                  <Col md="auto">
                    <Form.Group className="">
                      <Form.Label>Total Due :</Form.Label>
                      <Form.Text> R {finalPayment} </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
              
              <Row>
                <Col md={9}>
                  <div style={{float:"right"}}>
                    <div className="payfastImage">
                      <form action={payfastUrl} method="POST">
                        {Object.keys(params).map((item: any, key: any) => <input type="hidden" name={item} value={params[item]} />)}
                        <input type="hidden" name="signature" value={MD5Signature} />
                          <div className="row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div className="">
                              { finalPayment == 0 ? 
                                <p  style={{border:'1px solid white', borderRadius:'20px'}}>All due cleared </p>
                              :
                                <>
                                  <span className="text-light">Pay with</span><br/>
                                  <Button className='bg-light pointer' type="submit" style={{width:"100px",height:"35px"}}>
                                    <img alt="payfast" src={payfastImage} width="100" height="20"  className={isClicked ? `blink rounded` : `rounded`} onClick={requestPayment}/>
                                  </Button>
                                </> 
                              }
                              <br/>
                            </div>
                          </div>
                        </form>
                    </div>
                    {isClicked && 
                      <div>
                        <Spinner className='text-light'/><br/>
                        <span className='text-danger'>
                          Please do not close window or click on Back button or Refresh(F5) page
                        </span>
                      </div> 
                    }
                  </div>
                </Col>
                <Col className="ms-auto">
                  <div className="marketerPaymentHistory mt-4">
                    <label className="mr-2 mb-2 mt-3 float-center pointer text-light"  onClick={handleTransactionHistory} >
                      <u>{ showTHistory ? "Back " : "Transaction History "}</u>
                    </label>
                  </div>
                </Col>
              </Row>

            { showTHistory ? 
                <Table responsive>
                  <thead>
                    <tr>
                      <th className="text-warning text-center">S.No.</th>
                      <th className="text-warning text-center">Amount Paid</th>
                      <th className="text-warning text-center">Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {mTHistory && mTHistory.map((list: any, index: number) => {
                        {
                          return (
                          <>
                            <tr>
                              <td className="text-center">{index+1}</td>
                              <td className="text-center"> {list.amount_gross} </td>
                              <td className="text-center">
                                { new Date (list.created).toLocaleDateString()} 
                              </td>
                            </tr>
                          </>
                          );
                        }
                      })}
                  </tbody>
              </Table>
              : ""}
              
              <hr style={{color:'grey',height:'2px'}}></hr>
              <Row>
                <Col md={6}>
                {/* <div className="paidOrFreeUsers"> */}
                  <h3 style={{color:"#E79E1F"}}> {pageHeading ? pageHeading : 'Subscribed Users'} </h3>
                {/* </div> */}
                </Col>
                <Col md={3}>
                  <div className="userPayment">
                    <select className='form-control' value={paidOrFree} onChange={handleFilters}>
                      <option value='Subscribed'>Subscribed Users</option>
                      <option value='Unsubscribed'>Unsubscribed Users</option>
                    </select>
                    {/* { (paidOrFree == 'Subscribed') ? 
                      <Button variant="info" className="mr-2 mb-2  float-start" onClick={handleUnsubscribed} >Show Unsubscribed Users  </Button>
                    :
                      <Button variant="info" className="mr-2 mb-2  float-start" onClick={handleSubscribed} >Show Subscribed Users </Button>
                    } */}
                  </div>
                </Col>
                <Col md="auto" className="pt-2">
                  {paidOrFree == 'Subscribed' ? 
                    <label className='bg-warning text-dark rounded p-1 pointer text-center' onClick={handleCSV}>{csvLoader ? 'Downloading...' : 'Download CSV '}{!csvLoader && <FaDownload style={{fontSize:"12px",marginBottom:"10px"}}/>}</label>
                  :
                    <label className='bg-secondary text-light rounded p-1 pointer text-center' style={{cursor:"not-allowed",visibility:"hidden"}}>Download CSV <FaDownload style={{fontSize:"12px",marginBottom:"10px",cursor:"not-allowed"}}/></label>
                  }
                </Col>
              </Row>
              {paidOrFree == 'Subscribed' ? 
               (
                <> 
                  { showFreeUser ?'': 
                    <div className="payfastImage">
                      <form action={payfastUrl} method="POST">
                        {Object.keys(params).map((item: any, key: any) => <input type="hidden" name={item} value={params[item]} />)}
                        <input type="hidden" name="signature" value={MD5Signature} />
                      </form>
                    </div>
                  }
                  { showFreeUser ?
                    (<> 
                      <p style={{marginRight:'110px',fontSize:"28px", border:'2px solid red'}}> Unsubscribed Users - With free or no plan</p>
                      <div className="paymentDetailTable scrollBar">
                        <Table responsive>
                          <thead>
                            <tr>
                              <th className="text-warning text-center">S.No.</th>
                              <th className="text-warning text-center">User </th>
                              <th className="text-warning text-center">Mobile</th>
                              <th className="text-warning text-center">Date Joined</th>
                            </tr>
                          </thead>
                          <tbody>
                            {unsubscriedUserResult && unsubscriedUserResult.map((list: any, index: number) => {
                              {
                                if(1==1) 
                                  {return (
                                    <>
                                      <tr>
                                        <td className="text-center">{index + 1}</td>
                                        <td className="text-center"> {list.firstName +" "+ list.lastName} </td>
                                        <td className="text-center"> {list.mobile} </td>
                                        <td className="text-center"> { new Date (list.created).toLocaleDateString()}  </td>
                                      </tr>
                                    </>
                                  );}
                              }
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </>)
                  :
                    (<> 
                      <div className="paymentDetailTable scrollBar">
                        <Table responsive>
                          <thead>
                            <tr>
                              <th className="text-warning text-center">S.No.</th>
                              <th className="text-warning text-center">User </th>
                              <th className="text-warning text-center">Plan Type </th>
                              <th className="text-warning text-center">Commission</th>
                              <th className="text-warning text-center">Mobile</th>
                              <th className="text-warning text-center">Date Joined</th>
                            </tr>
                          </thead>
                          <tbody>
                            {userDetailData && userDetailData.map((list: any, index: number) => {
                              {
                                if(1==1) 
                                  {return (
                                    <>
                                      <tr>
                                        <td className="text-center">{index + 1}</td>
                                        <td className="text-center"> {list.firstName +" "+ list.lastName}</td>
                                        <td className="text-center">{list.userHMpayments[0].planCode}</td>
                                        <td className="text-center">{list.userHMpayments[0].boxPlans.marketerCommission}</td>
                                        <td className="text-center">{list.mobile}</td>
                                        <td className="text-center">{ new Date (list.created).toLocaleDateString()}</td>
                                      </tr>
                                    </>
                                  );}
                                }
                              })}
                            </tbody>
                          </Table>
                        </div>
                      </>)}
                    </>)
                  :  
                    (<div className="paymentDetailTable scrollBar">
                      <Table responsive>
                        <thead>
                          <tr>
                            <th className="text-warning text-center">S.No.</th>
                            <th className="text-warning text-center">User </th>
                            <th className="text-warning text-center">Email</th>
                            <th className="text-warning text-center">Date Joined</th>
                          </tr>
                        </thead>
                        <tbody>
                          {unsubscriedUserResult && unsubscriedUserResult.map((list: any, index: number) => {
                            {
                              if(1==1) 
                                {return (
                                  <>
                                    <tr>
                                      <td className="text-center">{index + 1}</td>
                                      <td className="text-center">{list.firstName +" "+ list.lastName}</td>
                                      <td className="text-center">{list.email}</td>
                                      <td className="text-center">{ new Date (list.created).toLocaleDateString()} </td>
                                    </tr>
                                  </>
                                );}
                            }
                          })}
                        </tbody>
                      </Table>
                    </div>) 
                  }
                </div>
              </div>
            </Container>
          </Layout>
        </>
      )
    }

const mapDispatchToProps = (dispatch: any) => ({
  getMarketerUsersById     : (data: any) => dispatch(getMarketerUsersById(data)),
  getUnsubscribedUsers     : (data: any) => dispatch(getUnsubscribedUsers(data)),
  getMarketerPromoCodeById : (data: any) => dispatch(getMarketerPromoCodeById(data)),
  getMarketerDetailById    : (data: any) => dispatch(getMarketerDetailById(data)),
  getMTHistory             : (data: any) => dispatch(getMTHistory(data)),
  downloadSubscribedCSV    : (data: any) => dispatch(downloadSubscribedCSV(data)),
});
const mapStateToProps = (state: any) => {
  return {
    getMarketerUsersByIdData     : state.AdminMarketerReducer && state.AdminMarketerReducer.getMarketerUsersByIdData ? state.AdminMarketerReducer.getMarketerUsersByIdData : null,
    getMarketerPromoCodeByIdData : state.AdminMarketerReducer && state.AdminMarketerReducer.getMarketerPromoCodeByIdData ? state.AdminMarketerReducer.getMarketerPromoCodeByIdData : null,
    getUnsubscribedUsersData     : state.AdminMarketerReducer && state.AdminMarketerReducer.getUnsubscribedUsersData ? state.AdminMarketerReducer.getUnsubscribedUsersData : null,
    getMarketerDetailByIdData    : state.AdminMarketerReducer && state.AdminMarketerReducer.getMarketerDetailByIdData ? state.AdminMarketerReducer.getMarketerDetailByIdData: null,
    getMTHistoryData             : state.AdminMarketerReducer && state.AdminMarketerReducer.getMTHistoryData ? state.AdminMarketerReducer.getMTHistoryData : null,   
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminMarketeruserList) ;