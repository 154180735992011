import React, { Component } from 'react';
import { Nav, Navbar, Button, Form, Container } from 'react-bootstrap';
import logo from '../../assets/images/beyondBoxLogo.svg';
import { Link } from 'react-router-dom';

export default function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <footer className='admin_footer mt-5'>
    <Container>
          {/* <div className="footerlogo text-center" style={{"color":"#fff"}}><img src={logo} className="App-logo" alt="logo" /></div> */}
          <p className="copyright m-0 text-center">BeyondBox &copy; {currentYear}</p>
    </Container>
</footer>
  )
}
