import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Stack,
  Table,
} from "react-bootstrap";
import { BsPencilFill, BsTrashFill } from "react-icons/bs";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import Layout from "../../../layout/Admin/layout";
import {
  addAdminCity,
  adminCityById,
  adminCityList,
  adminCountryList,
  adminStateList,
  removeAdminCity,
  updateAdminCity,
} from "../../../redux/actions/admin/adminCountryAction";

const AdminCountryCity = (props: any) => {
  const [countryData, setCountryData] = useState([]);
  const [statesData, setStatesData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [stateId, setStateId] = useState("");
  const [cityId,setCityId]=useState("");
  const [addCityModalShow, setAddCityModalShow] = useState(false);

  const [addCityValue, setAddCityValue] = useState({ cityName: "" });
  const [updateCityValue, setUpdateCityValue] = useState({ updateCityName: "" });
  const [updateCityModalShow, setUpdateCityModalShow] = useState(false);
  const [updateCityModalOpen, setUpdateCityModalOpen] = useState(false);
  useEffect(() => {
    props.adminCountryList();
  }, []);
  useEffect(() => {
    if (props.countryAdminData) {
      setCountryData(props.countryAdminData);
    }
  }, [props.countryAdminData]);

  const handleShow = () => {
    setAddCityModalShow(true);
  };
  const handleClose = () => {
    setAddCityModalShow(false);
  };
  const selectCountry = (e: any) => {
    e.preventDefault();

    props.adminStateList({ id: e.target.value });
  };
  const selectStates = (e: any) => {
    e.preventDefault();
    setStateId(e && e.target.value);
    props.adminCityList({ id: e.target.value });
  };

  const handleChange = (event: any) => {
    event.preventDefault();
    setAddCityValue({
      ...addCityValue,
      [event.target.name]: event.target.value,
    });
    setUpdateCityValue({
      ...updateCityValue,
      [event.target.name]: event.target.value,
    });
  };
  const handleUpdate = (e: any) => {
   
    let cityId = e ;

    if (cityId) {
      setCityId(cityId);
      props.adminCityById({ id: cityId });
      setUpdateCityModalOpen(true);
    }
  };
  const handleUpdateCityClose = () => {
    setUpdateCityModalShow(false);
    setUpdateCityModalOpen(false);
  };

  useEffect(() => {
    if (props.cityByIdAdminData && updateCityModalOpen) {
      var result = props.cityByIdAdminData ? props.cityByIdAdminData : "";
      
      updateCityValue.updateCityName= (result.name ? result.name : "");
    setUpdateCityModalShow(true)
    }
  }, [props.cityByIdAdminData]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    let payload = {
      stateId: 0,
      name: "",
    };
    payload.stateId = stateId ? parseInt(stateId) : 0;
    payload.name = addCityValue.cityName;
    props.addAdminCity(payload);
  };

  const handleUpdateSubmit=(e:any)=>{
    e.preventDefault();
    let payload = {
      id: 0,
      name: "",
      stateId:0
    };
    payload.id = cityId ? parseInt(cityId) : 0;
    payload.name = updateCityValue ? updateCityValue.updateCityName : "";
    payload.stateId=stateId?parseInt(stateId) : 0;
    props.updateAdminCity(payload);
  }


  useEffect(()=>{
if(props.addCityAdminData)
{
  props.adminCityList({ id:stateId});
  handleClose();
}
  },[props.addCityAdminData])
  useEffect(()=>{
    if(props.updateCityAdminData)
    {
      props.adminCityList({ id:stateId});
      handleUpdateCityClose();
    }
      },[props.updateCityAdminData])
      
  useEffect(() => {
    if (props.stateAdminData) {
      setStatesData(props.stateAdminData);
    }
  }, [props.stateAdminData]);
  useEffect(() => {
    if (props.cityAdminData) {
      setCityData(props.cityAdminData);
    }
  }, [props.cityAdminData]);

  const handleDeleteCity = (e: any) => {
    let cityId = e;
    Swal.fire({
      title: `Are you sure you want to delete ? `,
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "YES",
      denyButtonText: `NO`,
      confirmButtonColor: "green",
      showCloseButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        props.removeAdminCity({ id: cityId });
         
      }
    });
   
  };
  useEffect(()=>{
    if(props.removeCityAdminData){
      let statesID = stateId ? parseInt(stateId) : 0;
          props.adminCityList({ id: statesID});
    }
    },[props.removeCityAdminData])
  return (
    <div>
      <Layout>
        <Modal
          {...props}
          show={updateCityModalShow}
          size="lg"
          backdrop="static"
          onHide={handleUpdateCityClose}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "whitesmoke" }}>
              Update City
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form  onSubmit={ handleUpdateSubmit} >
              <Container>
                <Row>
                  <Col md={6}>
                    <Form.Group
                      className="mb-3 mt-3 "
                      //controlId={data.field2}
                    >
                      <Form.Label style={{ color: "whitesmoke" }}>
                        City Name
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="updateCityName"
                        value={updateCityValue.updateCityName}
                        onChange={handleChange}
                        autoFocus
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Container>
              <Row className="justify-content-md-center mt-10">
                <Col className="" md="auto">
                  <Button
                    variant="primary"
                    type="submit"
                    // disabled={!isValid}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
        </Modal>
        {/* --------------ADD CITY MODAL------------ */}
        <Modal
          {...props}
          show={addCityModalShow}
          size="lg"
          backdrop="static"
          onHide={handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "whitesmoke" }}>Add City</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmit}>
              <Container>
                <Row>
                  <Col md={6}>
                    <Form.Group
                      className="mb-3 mt-3 "
                      //controlId={data.field2}
                    >
                      <Form.Label style={{ color: "whitesmoke" }}>
                        City Name
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="cityName"
                        value={addCityValue.cityName}
                        onChange={handleChange}
                        autoFocus
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Container>
              <Row className="justify-content-md-center mt-10">
                <Col className="" md="auto">
                  <Button
                    variant="primary"
                    type="submit"
                    // disabled={!isValid}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
        </Modal>

        <Stack direction="horizontal" gap={3}>
          <Form.Select
            name="country"
            className="col-md"
            aria-label="Default select example"
            onChange={selectCountry}
          >
            <option value="0">Select Country</option>
            {countryData &&
              countryData.map((country: any) => (
                <option value={country.id} key={country.id}>
                  {country.name}{" "}
                </option>
              ))}
          </Form.Select>
          <Form.Select
            name="country-state"
            className="col-md"
            aria-label="Default select example"
            onChange={selectStates}
          >
            <option value="0">Select State</option>
            {statesData &&
              statesData.map((states: any) => (
                <option value={states.id} key={states.id}>
                  {states.name}{" "}
                </option>
              ))}
          </Form.Select>
          <Button variant="info" className="ms-auto" onClick={handleShow}>
            {" "}
            Add{" "}
          </Button>{" "}
        </Stack>
        <Table responsive>
          <thead>
            <tr>
              <th>S.No.</th>
              <th>City Name</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {cityData &&
              cityData.map((city: any, index: any) => {
                return (
                  <>
                    <tr>
                      <td>{index + 1}</td>
                      <td> {city.name}</td>
                      <td>
                        <button
                          type="submit"
                          className="btn btn-outline-warning m-2"
                          //value={city.id}
                          onClick={()=>handleUpdate(city.id)}
                        >
                          <BsPencilFill />
                        </button>
                        <button
                          type="submit"
                          className="btn btn-outline-danger"
                          value={city.id}
                          //id={state.planType}
                          onClick={()=>handleDeleteCity(city.id)}
                        >
                          <BsTrashFill />
                        </button>
                      </td>
                    </tr>
                  </>
                );
              })}
          </tbody>
        </Table>
      </Layout>
    </div>
  );
};
const mapDispatchToProps = (dispatch: any) => ({
  adminCountryList: (data: any) => dispatch(adminCountryList(data)),
  adminStateList: (data: any) => dispatch(adminStateList(data)),
  adminCityList: (data: any) => dispatch(adminCityList(data)),
  adminCityById: (data: any) => dispatch(adminCityById(data)),
  addAdminCity: (data: any) => dispatch(addAdminCity(data)),
  updateAdminCity: (data: any) => dispatch(updateAdminCity(data)),
  removeAdminCity: (data: any) => dispatch(removeAdminCity(data)),
});

const mapStateToProps = (state: any) => {
  return {
    countryAdminData:
      state.AdminCountryReducer && state.AdminCountryReducer.countryAdminData
        ? state.AdminCountryReducer.countryAdminData
        : null,
    message:
      state.CommonMessageReducer && state.CommonMessageReducer.message
        ? state.CommonMessageReducer.message
        : null,
    stateAdminData:
      state.AdminCountryReducer && state.AdminCountryReducer.stateAdminData
        ? state.AdminCountryReducer.stateAdminData
        : null,
    cityAdminData:
      state.AdminCountryReducer && state.AdminCountryReducer.cityAdminData
        ? state.AdminCountryReducer.cityAdminData
        : null,
    cityByIdAdminData:
      state.AdminCountryReducer && state.AdminCountryReducer.cityByIdAdminData
        ? state.AdminCountryReducer.cityByIdAdminData
        : null,
    addCityAdminData:
      state.AdminCountryReducer && state.AdminCountryReducer.addCityAdminData
        ? state.AdminCountryReducer.addCityAdminData
        : null,
    updateCityAdminData:
      state.AdminCountryReducer && state.AdminCountryReducer.updateCityAdminData
        ? state.AdminCountryReducer.updateCityAdminData
        : null,
    removeCityAdminData:
      state.AdminCountryReducer && state.AdminCountryReducer.removeCityAdminData
        ? state.AdminCountryReducer.removeCityAdminData
        : null,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AdminCountryCity);
