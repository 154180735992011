import {ADMIN_COUNTRY_DATA} from '../../constant/index';

export const adminCountryList = data => {
  return {
    type: ADMIN_COUNTRY_DATA.GET_ADMIN_COUNTRIES,
    payload: data,
  };
};

export const adminStateList = data => {
  return {
    type: ADMIN_COUNTRY_DATA.GET_ADMIN_STATES,
    payload: data,
  };
};

export const adminCityList = data => {
  return {
    type: ADMIN_COUNTRY_DATA.GET_ADMIN_CITIES,
    payload: data,
  };
};

export const adminCountryById = data => {
  return {
    type: ADMIN_COUNTRY_DATA.GET_ADMIN_COUNTRIES_BY_ID,
    payload: data,
  };
};

export const addAdminCountry = data => {
  return {
    type: ADMIN_COUNTRY_DATA.ADD_ADMIN_COUNTRY,
    payload: data,
  };
};

export const updateAdminCountry = data => {
  return {
    type: ADMIN_COUNTRY_DATA.UPDATE_ADMIN_COUNTRIES,
    payload: data,
  };
};

export const removeAdminCountry = data => {
  return {
    type: ADMIN_COUNTRY_DATA.REMOVE_ADMIN_COUNTRIES,
    payload: data,
  };
};

export const addAdminStates = data => {
  return {
    type: ADMIN_COUNTRY_DATA.ADD_ADMIN_STATES,
    payload: data,
  };
};

export const updateAdminStates = data => {
  return {
    type: ADMIN_COUNTRY_DATA.UPDATE_ADMIN_STATES,
    payload: data,
  };
};

export const removeAdminStates = data => {
  return {
    type: ADMIN_COUNTRY_DATA.REMOVE_ADMIN_STATES,
    payload: data,
  };
};

export const adminStateById = data => {
  return {
    type: ADMIN_COUNTRY_DATA.GET_ADMIN_STATE_BY_ID,
    payload: data,
  };
};

export const addAdminCity = data => {
  return {
    type: ADMIN_COUNTRY_DATA.ADD_ADMIN_CITY,
    payload: data,
  };
};

export const updateAdminCity = data => {
  return {
    type: ADMIN_COUNTRY_DATA.UPDATE_ADMIN_CITY,
    payload: data,
  };
};

export const removeAdminCity = data => {
  return {
    type: ADMIN_COUNTRY_DATA.REMOVE_ADMIN_CITY,
    payload: data,
  };
};

export const adminCityById = data => {
  return {
    type: ADMIN_COUNTRY_DATA.GET_ADMIN_CITY_BY_ID,
    payload: data,
  };
};