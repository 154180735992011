import Api from "../apiCall";
import { API_URLS } from "../../constant/index";

export const getAddAdminMarketerService = async (params) => {
  const result = await Api.post(API_URLS.ADD_ADMIN_MARKETER_URL, params);
  return result;
};

export const getMarketerListService = async (params) => {
  const result = await Api.get(API_URLS.GET_MARKETER_LIST_URL, params);
  return result;
};

export const deleteAdminMarketerService = async (params) => {
  const deleteMarketerId = params.id;
  let url = API_URLS.DELETE_ADMIN_MARKETER_URL;
  let newurl = url.replace("{id}", deleteMarketerId);
  const result = await Api.delete(newurl, params);
  return result;
};
export const getMarketerDetailByIdService = async (params) => {
  const marketerDetailById = params;
  let url = API_URLS.GET_MARKETER_DETAIL_BY_ID_URL;
  let newurl = url.replace("{id}", marketerDetailById);
  const result = await Api.get(newurl, params);
  return result;
};
export const updateAdminMarketerService = async (params) => {
  const updateMarketerId = params.id;
  let url = API_URLS.GET_MARKETER_DETAIL_BY_ID_URL;
  let newurl = url.replace("{id}", updateMarketerId);
  const result = await Api.patch(newurl, params.data);
  return result;
};

export const getMarketerPromoCodeService = async (params) => {
  const result = await Api.post(API_URLS.GET_MARKETER_PROMOCODE_URL, params);
  return result;
};

export const getMarketerPromoCodeByIdService = async (params) => {
  const MarketerIdPc = params;
  let url = API_URLS.GET_MARKETER_PROMOCODE_BY_ID_URL;
  let newurl = url.replace("{id}", MarketerIdPc);
  const result = await Api.get(newurl, params);
  return result;
};
export const getUserThroughMarketersService = async (params) => {
  const result = await Api.get(API_URLS.GET_USER_THROUGH_MARKETERS_URL, params);
  return result;
};
export const getMarketerUsersByIdService = async (params) => {
  const MarketerId = params;
  let url = API_URLS.GET_MARKETER_USERS_BY_ID_URL;
  let newurl = url.replace("{id}", MarketerId);
  const result = await Api.get(newurl, params);
  return result;
};
export const getUnsubscribedUserService = async (params) => {
  const MarketerId = params;
  let url = API_URLS.GET_UNSUBSCRIBED_USERS_URL;
  let newurl = url.replace("{id}", MarketerId);
  const result = await Api.get(newurl, params);
  return result;
};

export const getMTHistoryService = async (params) => {
  const MarketerId = params;
  let url = API_URLS.GET_MT_HISTORY_URL;
  let newurl = url.replace("{id}", MarketerId);
  const result = await Api.get(newurl, params);
  return result;
};

export const downloadSubscribedCSV_Service = async (params) => {
  const MarketerId = params;
  let url = API_URLS.GET_SUBSCRIBED_CSV_DOWNLOAD_URL;
  let newurl = url.replace("{id}", MarketerId);
  const result = await Api.get(newurl, params);
  return result
};