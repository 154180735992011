import React from "react";
import { Container } from "react-bootstrap";
import Layout from "../../layout/Layout";

const FAQ = (props: any) => {
  return (
    <Layout>
      <div className={localStorage.length ? "" : "charity_publicBox"}>
        <Container>
          <div className="text-light">
            <div className="faqMainContent">
              <div className="heading"><h2> Frequently Asked Questions </h2></div>
              <p>Welcome to BeyondBox, a unique digital communication platform that allows you to share content with your loved ones after your death. Here are some of the most common questions we get from our users. If you need more help, please reach out to us via our Contact us page.</p>
              <p>
                <h4 className="text-warning">What is BeyondBox?</h4>
                BeyondBox is a platform that lets you create digital boxes that contain your final words of love, guidance, and important information for the people you care about. You can leave these boxes to individuals or groups of recipients, and they will be delivered after your death. The purpose of BeyondBox is to bridge the gap in communication that exists when life ends abruptly and provide comfort and closure to the loved ones you leave behind. Each BeyondBox that you create will hold immeasurable meaning and value to the recipients who will be fortunate enough to receive one. 
              </p>
              <p>
              <h4 className="text-warning">Does BeyondBox have a Free plan?</h4>
              Yes, it does. You can sign up for a BeyondBox Family membership for free. The Family membership allows you to create 3 private boxes and add one recipient per box. With the Family membership, the box content you can add is limited to text messages only. You can also view received boxes for up to 12 months. To unlock the premium features of BeyondBox, you have the option to upgrade to the Family Plus plan for a minimal monthly fee.
              </p>
              <p>
              <h4 className="text-warning">What are the features of the Family Plus subscription plan?</h4>
              With a BeyondBox Family Plus membership, you can enjoy all the best features BeyondBox has to offer. You can create and receive an unlimited number of boxes, record or upload video and audio, capture images and add files and text messages. You also get total plan storage of 2GB and you can add five recipients per box. You get to keep your received boxes forever and can downgrade to the Free plan at any time. A portion of your subscription fee goes toward the BeyondBox Foundation which helps existing charities and people in need.
              </p>
              <p>
              <h4 className="text-warning">Tell me more about the BeyondBox Foundation?</h4>
                The BeyondBox Foundation was created as the philanthropic arm of the BeyondBox platform. We have pledged a third of all net revenue generated from the BeyondBox Messaging platform toward our Foundation. The primary purpose of the foundation is to provide financial assistance to existing charities in support of the vital humanitarian work they do.
              </p>
              <p>
              <h4 className="text-warning">Are the boxes I create encrypted and secure?</h4>
              Boxes are end-to-end encrypted and only you and your intended recipients will be able to view what you add to your box. The security of your content is of utmost importance to us, and we have implemented various security measures using AWS, SSL, and 2FA to ensure that your boxes are secure. AWS provides a secure cloud infrastructure that protects your data from unauthorized access. SSL encrypts the data in transit between your device and our servers. 2FA requires two forms of identification to access your account, such as a password and a verification code sent to your phone and registered email. Please refer to our Privacy Policy and Terms and Conditions for more information.
              </p>
              <p>
              <h4 className="text-warning">Is there a BeyondBox mobile app?</h4>
                Yes, there is. You can download the BeyondBox mobile app from the Apple App Store or Google Play. The BeyondBox app makes it easy for you to access and create your timeless treasure boxes at a time and place of your choosing, discretely and conveniently. You can upload or record content on your mobile device, such as photos, videos, audio messages, or text notes, and add them to your boxes. You can also view the boxes that you have received from others and manage your account settings. The BeyondBox app gives you the freedom and flexibility to express yourself and share your legacy with your loved ones.
              </p>
              <p>
              <h4 className="text-warning">How do I create a box and add recipients?</h4>
                Creating a box and adding recipients is easy. Just follow these steps: 
                <ul style={{listStyleType:"disc"}}>
                    <li>Sign-in to your account via the BeyondBox website or mobile app.</li>
                    <li>From your "My Boxes" page, click the add box button to open the Create Box page.</li>
                    <li>From the Create Box page, you can add a box cover or choose from the pre-set covers as well as give your box a name and description.</li>
                    <li>You can then add one or multiple recipients (up to 5 per box).</li>
                    <li>You can add new recipients or select a recipient from "My People" if they have already been added on a previous box.</li>
                    <li>Click the Save button and your box will now be ready to be filled with your treasured content.</li>
                </ul>
              </p>
              <p>
              <h4 className="text-warning">What information will I need to add a recipient?</h4>
                You will need to enter the recipient's name, surname, email address, date of birth and relation to you.
              </p>
              <p>
              <h4 className="text-warning">Can I leave a box to a person who is below the age of 18?</h4>
                Yes, you may create a box for a child under the age of 18. In this instance you will use the email address of the child's parent or guardian. The parent/guardian will be responsible for sharing the box with the intended recipient.
              </p>
              <p>
              <h4 className="text-warning">How will the BeyondBox team be notified of my passing?</h4>
                When you create your BeyondBox profile, you will be asked to nominate a Trustee or Next of Kin. This person must be notified that you have a BeyondBox account and instructed to advise us of your passing and furnish us with your valid Notice of Death issued by your country's government for us to activate the release of your created boxes.
              </p>
              <p>
              <h4 className="text-warning">When will my recipient be notified that I have left them a box?</h4>
                Your recipient will not know about the box until after you have passed away. We will only send them a notification and instructions to access the box when we receive and verify your official Notice of Death from your Trustee or next of kin.
              </p>
              <p>
                <h4 className="text-warning">Does a recipient need to sign up for a paid plan to receive the contents of a box?</h4>
                No, they don't. A recipient can sign up for a Family membership which is completely free, in order to view the boxes that you have left for them. Received boxes will be viewable for a period of 12 months with the BeyondBox Free plan.
              </p>
              <p>
                <h4 className="text-warning">Can I schedule the release of content?</h4>
                Yes, you can. Each piece of content that you add to a box can be scheduled for release for up to 10 years after your death.
              </p>
              <p>
                <h4 className="text-warning">Can I make changes to a box after it has been created?</h4>
                Yes, you can edit a created box at any time. Simply click on edit box and you will be able to update the box cover, name, description, and recipients.
              </p>
              <p>
                <h4 className="text-warning">Can I delete items in a box?</h4>
                Yes, you can. You can delete individual pieces of content or entire boxes. Deleted items will remain in your Recycle Bin for a period of 30 days. You can visit your Recycle Bin to restore content within this period.
              </p>
              <p>
                <h4 className="text-warning">What is a Public Box?</h4>
                A Public Box allows you to leave a final message to the world. It will be displayed on our Box Records page and posted on our social accounts to honour and celebrate the life you lived.
              </p>
              <p>
                <h4 className="text-warning">Why does BeyondBox have a suicide exclusion?</h4>
                All users of BeyondBox must accept a Suicide Exclusion when signing up to the platform. This Exclusion states that if your death is because of suicide, we will not release your boxes. This is because we do not want the platform to be used as a tool to compile suicide notes and inadvertently discourage the user from seeking the help they need. We care and appreciate the lives of all our users and want to encourage them to plan toward the inevitability of their passing while always remembering the preciousness of the gift of life.
              </p>
            </div>
          </div>
        </Container>
      </div>
    </Layout>
  );
};

export default FAQ;
