import React, { useCallback, useState, Component, useEffect} from "react";
import { connect } from "react-redux";
import { Button, Form } from 'react-bootstrap';
import logo from '../../assets/images/BB.png';
import eyeLogo from '../../assets/images/eye.png';
import {forgetPasswordFormValidation} from '../../validations/forgetPassword-form'
import { forgotPassword } from "../../redux/actions/authAction";
import Footer from "../../layout/elements/footer";
import { Link, useNavigate } from "react-router-dom";
import { commonMessageAction } from "../../redux/actions/messageAction";

const ForgotPassword = (props:any) => {
  localStorage.clear();
  const navTo=useNavigate();
  useEffect(()=>{
    props.commonMessageAction();
        
  },[])
    let [forgetPasswordForm, setForgetPasswordForm] = React.useState({
        email: ''
        });
    
      let [errors, setErrors] = React.useState({
        email: ''
       });
    
      let [checkValidation, setCheckValidation] = React.useState({
        email: false       
      });
    
      let [isSubmitted, setIsSubmitted] = React.useState(false);
      let [isValid, setIsValid] = React.useState(true);
    
    const handleChange = (event: any) => {
        setCheckValidation({
          ...checkValidation, [event.target.name]: true
        });
        setForgetPasswordForm({
          ...forgetPasswordForm, [event.target.name]: event.target.value
        })
      };
    
      React.useEffect(() => {
        const validationErrors = forgetPasswordFormValidation(forgetPasswordForm);
        isValid = Object.keys(validationErrors).length === 0;
        setIsValid(isValid);
        setErrors(validationErrors);
        
      }, [forgetPasswordForm]);
        
      const handleSubmit = () => {
        let senData={"email":forgetPasswordForm.email}
        setIsSubmitted(true);
        if (isValid) {
          props.forgotPassword(senData);
        }
      }

      useEffect(() =>{
        if(props.message){
          localStorage.setItem('email',forgetPasswordForm.email);
          navTo('/otp');
        }
      },[props.message])

//----------------------------------------------------------------------//      

  return (
    <>
      <div className="createAccount">
        <div className="userDetailBlock">
          <div className="formWrap align-items-center d-md-flex flex-column h-100 justify-content-center">
            <div className="text-center">
              <Link to="/"><img src={logo} className="App-logo" alt="logo" /></Link>
            </div><br/>
            <h2 className="text-center">Forgot Password</h2>
            <Form className="w-100">
              <Form.Group className="formRow">
                <Form.Label>Email Address</Form.Label>
                <Form.Control type="text" name="email" id="forgotPassword_email" placeholder="Enter your email" onChange={handleChange} value={(forgetPasswordForm && forgetPasswordForm.email) ? forgetPasswordForm.email : ''}/>
                {(checkValidation.email && errors.email) && <p className="text-danger">{errors.email}</p>}
              </Form.Group>

              {props.message && <div className={(props.message && props.message.status==='success')?'text-success':'text-danger'}>{props.message.data}</div>}
              
              <div className="d-flex  justify-content-between" style={isValid === false ? {cursor:'not-allowed'} : {}}>
                <Button onClick={()=>{navTo('/login')}} className="darkBtn"  variant="primary" type="button">CANCEL</Button>
                <Button onClick={handleSubmit} className="yellowBtn" disabled={!isValid} variant="primary" type="button">CONTINUE</Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
  }
  const mapDispatchToProps = (dispatch: any) => ({
    forgotPassword: (data: any) => dispatch(forgotPassword(data)),
    commonMessageAction: (data: any) => dispatch(commonMessageAction(data)),
});

const mapStateToProps = (state: any) => {
    return {
        message: (state.CommonMessageReducer && state.CommonMessageReducer.message) ? state.CommonMessageReducer.message : null,
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);