import { Container } from 'react-bootstrap'
import Layout from '../../layout/Layout';

function PageNotFound() {
    let userData = localStorage.getItem('profileData');
  return (
    <Layout>
        <div className={userData ? 'boxes' : ''}>
            <div className={`charity ${!userData ? `charity_publicBox`:``}`}>
                <Container>
                    <div className={`text-center`}>
                        <div className=''>
                            <h1>Oops!</h1><span style={{fontSize:"100px"}} className="text-warning">404</span>
                            <div className='text-danger'>
                                <b style={{fontSize:"44px"}}>Page Not Found</b>
                            </div>
                        </div>   
                    </div>
                </Container>
            </div>
        </div>
    </Layout>
  )
}

export default PageNotFound