import { ADMIN_BOX_PLAN } from "../../constant";
export const getAdminPlans = data => {
    return {
      type: ADMIN_BOX_PLAN.GET_ADMIN_BOX_PLAN,
      payload: data,
    };
  };
 
  export const getAdminPlanById = data => {
    return {
      type: ADMIN_BOX_PLAN.GET_ADMIN_BOX_PLAN_BY_ID,
      payload: data,
    };
  };
  export const addAdminPlans = data => {
    return {
      type: ADMIN_BOX_PLAN.ADD_ADMIN_BOX_PLAN,
      payload: data,
    };
  };
  export const updateAdminPlan = data => {
    return {
      type: ADMIN_BOX_PLAN.UPDATE_ADMIN_BOX_PLAN,
      payload: data,
    };
  };
  export const deleteAdminPlan = data => {
    return {
      type: ADMIN_BOX_PLAN.DELETE_ADMIN_BOX_PLAN,
      payload: data,
    };
  };