import {put, call} from 'redux-saga/effects';
import {USER_PROFILE, COMMON_MESSAGE} from '../constant/index';
import {generateReadableError} from '../../hooks/messages';
import {getEditProfileService, getProfileService,setProfileImageService,updateUserProfileService,getKinDetailsService,changePasswordService,getUserStorageService,getOTP_toEditPhoneNoService,verifyOTP_toEditPhoneNoService} from '../services/profileApiService'

const clearErrorMessage = {
  type: COMMON_MESSAGE,
  data: {
    status: '',
    page: '',
    data: [],
  }}

export function* fetchProfile({payload}) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(getProfileService, payload);
    yield put({type: USER_PROFILE.SET_PROFILE_PAGE_TO_STATE, data});
    

  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'error',
        page: 'profile_page',
        data: generateReadableError(error),
      },
    });
  }
}

export function* fetchEditProfile({payload}) {
  try {
    yield put(clearErrorMessage);
    const response = yield call(getEditProfileService, payload);
     yield put({type: USER_PROFILE.SET_PROFILE_PAGE_TO_STATE, data: response}); 
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status:'success',
        page: 'edit_profile',
        data: 'Registration successful',
      },
    });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'error',
        page: 'edit_profile_error',
        data: generateReadableError(error),
      },
    });
  }
}

export function* fetchProfileImage({payload}) {
  try {
    yield put(clearErrorMessage);
    const response = yield call(setProfileImageService, payload);
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status:'success',
        page: 'edit_profile_picture',
        data: response,
      },
    });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'error',
        page: 'edit_profile',
        data: generateReadableError(error),
      },
    });
  }
}

export function* updateProfile({payload}) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(updateUserProfileService, payload);
    yield put({type: USER_PROFILE.SET_PROFILE_PAGE_TO_STATE, data}); 
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status:'success',
        page: 'update_details',
        data: '',
      },
    });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'error',
        page: 'update_details',
        data: generateReadableError(error),
      },
    });
  }
}

export function* fetchKinDetails({payload}) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(getKinDetailsService, payload);
    yield put({type: USER_PROFILE.SET_KIN_DETAILS_TO_STATE, data}); 
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status:'success',
        page: 'kin_details',
        data: '',
      },
    });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'error',
        page: 'kin_details',
        data: generateReadableError(error),
      },
    });
  }
}

export function* request_changePassword({payload}) {
  try {
    const data = yield call(changePasswordService, payload);
    yield put({type: USER_PROFILE.RESPONSE_CHANGE_PASSWORD, data}); 
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status : 'error',
        page   : '',
        data   : generateReadableError(error),
      },
    });
  }
}

export function* fetchStorage({}) {
  try {
    const data = yield call(getUserStorageService);
    yield put({type: USER_PROFILE.SET_STORAGE, data}); 
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status : 'error',
        page   : '',
        data   : generateReadableError(error),
      },
    });
  }
}

export function* getOTP_toEditPhoneNo({payload}) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(getOTP_toEditPhoneNoService,payload);
    yield put({type: USER_PROFILE.SET_OTP_TO_EDIT_PHONE_NO, data}); 
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status : 'error',
        page   : 'edit-profile',
        data   : generateReadableError(error),
      },
    });
  }
}

export function* verifyOTP_toEditPhoneNo({payload}) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(verifyOTP_toEditPhoneNoService,payload);
    yield put({type: USER_PROFILE.SET_OTP_VERIFICATION_TO_EDIT_PHONE_NO, data}); 
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status : 'error',
        page   : 'edit-profile',
        data   : generateReadableError(error),
      },
    });
  }
}