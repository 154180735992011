import Api from './apiCall';
import {API_URLS} from '../constant/index';

export const getProfileService = async params => {
      const result = await Api.get(API_URLS.GET_PROFILE_URL, params);
       return result;
  };


export const getEditProfileService = async params => {
      const userId = params.id;
      let url = API_URLS.EDIT_PROFILE_URL;
      let newurl = url.replace("{id}", userId);
      return await Api.put(newurl, params);
      
  };

  export const setProfileImageService = async params => {
    const userId = params.get('id');
    let url = API_URLS.UPLOAD_PROFILE_IMAGE_URL;
    let newurl = url.replace("{id}", userId);
    let result = await Api.put(newurl, params);
    return result
  };

export const updateUserProfileService = async params => {
     
    const userId = params.id
  
    let url = API_URLS.UPDATE_USER_PROFILE_URL;
    let newurl = url.replace("{id}", userId);
    const result = await Api.put(newurl, params);
    return result;
};

export const getKinDetailsService = async params => {
     
    const userId = params.id
    let url = API_URLS.GET_KIN_DETAILS_URL;
    let newurl = url.replace("{id}", userId);
    const result = await Api.get(newurl, params);
    return result;
};

export const changePasswordService = async params => {
    let url = API_URLS.GET_CHANGE_PASSWORD_URL;
    const result = await Api.patch(url, params);
    return result;
};

export const getUserStorageService = async params => {
    let url = API_URLS.GET_USER_STORAGE_URL;
    const result = await Api.get(url, params);
    return result;
};

export const getOTP_toEditPhoneNoService = async params => {
    let url = API_URLS.GET_OTP_TO_EDIT_PHONE_NO_URL;
    const result = await Api.put(url, params);
    return result;
};

export const verifyOTP_toEditPhoneNoService = async params => {
    let url = API_URLS.GET_OTP_VERIFICATION_URL_TO_EDIT_PHONE_NO;
    const result = await Api.patch(url, params);
    return result;
};