import { put, call } from "redux-saga/effects";
import {
  getAdminUserService,
  getAdminUserAdminService,
  getAdminUserByIdService,
  getAdminUserPaymentDetailsService,
  disableAdminUserByIdService,
  getAdminUserByIdentityService,
  activateDeactivateUserByIdService,
  CSVdownloadService,
  getUnsubscribedMailUsersList_Service
} from "../../services/admin/adminUserService";
import { ADMIN_USER, COMMON_MESSAGE } from "../../constant/index";
import { generateReadableError } from "../../../hooks/messages";
const clearErrorMessage = {
  type: COMMON_MESSAGE,
  data: {
    status: "",
    page: "",
    data: [],
  },
};

export function* fetchAdminUserList({ payload }) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(getAdminUserService, payload);
    yield put({ type: ADMIN_USER.SET_ADMIN_USER_LIST_TO_STATE, data });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: "error",
        page: "country api",
        data: generateReadableError(error),
      },
    });
  }
}

export function* fetchAdminUserAdminList({ payload }) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(getAdminUserAdminService, payload);
    yield put({ type: ADMIN_USER.SET_ADMIN_USER_ADMIN_LIST_TO_STATE, data });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: "error",
        page: "country api",
        data: generateReadableError(error),
      },
    });
  }
}

export function* fetchAdminUserById({ payload }) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(getAdminUserByIdService, payload);
    yield put({ type: ADMIN_USER.SET_ADMIN_USER_BY_ID_TO_STATE, data });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: "error",
        page: "country api",
        data: generateReadableError(error),
      },
    });
  }
}
//getAdminUserPaymentDetails
export function* fetchAdminUserPaymentDetails({ payload }) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(getAdminUserPaymentDetailsService, payload);
    yield put({ type: ADMIN_USER.SET_ADMIN_USER_PAYMENT_DETAILS_TO_STATE, data });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: "error",
        page: "country api",
        data: generateReadableError(error),
      },
    });
  }
}
export function* fetchAdminUserByIdentity({ payload }) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(getAdminUserByIdentityService, payload);
    yield put({ type: ADMIN_USER.SET_ADMIN_USER_BY_IDENTITY_TO_STATE, data });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: "error",
        page: "user api",
        data: generateReadableError(error),
      },
    });
  }
}

export function* fetchDisableAdminUserById({ payload }) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(disableAdminUserByIdService, payload);
    yield put({ type: ADMIN_USER.SET_DISABLE_ADMIN_USER_BY_ID_TO_STATE, data });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: "error",
        page: "country api",
        data: generateReadableError(error),
      },
    });
  }
}

export function* fetchActivateDeactivateUserById({ payload }) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(activateDeactivateUserByIdService, payload);
    yield put({ type: ADMIN_USER.SET_ADMIN_ACTIVATE_DEACTIVATE_USER_BY_ID_TO_STATE, data });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: "error",
        page: "country api",
        data: generateReadableError(error),
      },
    });
  }
}

export function* download_csv({ payload }) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(CSVdownloadService, payload);
    yield put({ type: ADMIN_USER.RESPONSE_DOWNLOAD_CSV, data });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: "error",
        page: "Admin user list",
        data: generateReadableError(error),
      },
    });
  }
}

export function* getUnsubscribedMailUsersList({ payload }) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(getUnsubscribedMailUsersList_Service, payload);
    yield put({ type: ADMIN_USER.SET_UNSUBSCRIBED_MAIL_USER_LIST, data });
  } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: "error",
        page: "Unsubscribed-Mail-User-List",
        data: generateReadableError(error),
      },
    });
  }
}