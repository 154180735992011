import React from "react";
import { useEffect, useState } from "react";
import { Button, Col, Container, Dropdown, Form, Modal, Row, Stack, Table, } from "react-bootstrap";
import { BsEyeFill, BsPencilFill, BsTrashFill } from "react-icons/bs";
import {RiCoupon2Line } from "react-icons/ri";
import {FaUsers } from "react-icons/fa";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import Layout from "../../../layout/Admin/layout";
import { addAdminMarketer, deleteAdminMarketer, getMarketerDetailById, getMarketerList, getMarketerPromoCode, getMarketerPromoCodeById, updateAdminMarketer, } from "../../../redux/actions/admin/adminMarketerAction";
import { addMarketerValidation } from "../../../validations/adminPage/marketer-form";
import { useNavigate } from "react-router-dom";
import AdminMaketerUserList from "./adminMaketerUserList";

const AdminMarketer = (props: any) => {
  const navTo                                       =useNavigate();
  const [adminDetail,        setAdminDetail]        = useState([]);
  const [promoCode,          setPromoCode]          = useState([]);
  const [dropdownSelected,   setDropdownSelected]   = useState('Select');
  const [upDropdownSelected, setUpDropdownSelected] = useState('Select');
  const [show,               setShow]               = useState(false);
  const [showPc,             setShowPc]             = useState(false);
  const [promocode,          setPromocode]          = useState('');
  const [loader,             setLoader]             = useState(false);
  const [copySuccess,        setCopySuccess]        = useState('');

  const handleClose = () => {
    setShow(false);
    setNewPlan({ mName: "", mContactNumber: "", mPayfastPassphrase: "", mEmail: "", mMerchantId:"", mMerchantKey:"", });
    setCheckValidation({ mName: false, mPayfastPassphrase: false, mContactNumber: false, mEmail: false, mMerchantId:false, mMerchantKey:false, });
    setDropdownSelected('Select')
  };
  
  const handleClosePc = ()=>  {
    setPromocode('')
    setPromoCode([])
    setShowPc(false)
    setCopySuccess('')
  }

  const handleShow = () => setShow(true);
  let   [isValid,             setIsValid]             = useState(true);
  const [viewModalShow,       setViewModalShow]       = useState(false);
  const [viewModalOpen,       setViewModalOpen]       = useState(false);
  const [disabledCheck,       setDisabledCheck]       = useState(true);
  const [addedFolderResult,   setAddedFolderResult]   = useState(false);
  const [folderDetailResult,  setFolderDetailResult]  = useState(false);
  const [updatedFolderResult, setUpdatedFolderResult] = useState(false);
  const [deleteFolderResult,  setDeleteFolderResult]  = useState(false);
  const [newPlan,             setNewPlan]             = useState({ mName: "", mPayfastPassphrase: "", mContactNumber: "", mEmail: "", mMerchantId:"", mMerchantKey:"", });
  const [updatePlan,          setUpdatePlan]          = useState({ mName: "", mPayfastPassphrase: "", mContactNumber: "", mEmail: "", mMerchantId:"", mMerchantKey:"", });
  const [viewModalDetails,    setViewModalDetails]    = useState<any>({ marketerName: "", marketerPassphrase: "", marketerPhone: "", marketerEmail: "", marketerMerchantId:"", marketerMerchantKey:"", });
  let   [errors,              setErrors]              = useState<any>({ mName: "", mPayfastPassphrase: "", mContactNumber: "", mEmail: "", mMerchantId:"", mMerchantKey:"", });
  let   [checkValidation,     setCheckValidation]     = useState<any>({ mName: false, mPayfastPassphrase: false, mContactNumber: false, mEmail: false, mMerchantId:false, mMerchantKey:false, });

  useEffect(() => {
    props.getMarketerList();
  }, []);

  useEffect(() => {
    if (props.marketerListData) {
      setAdminDetail(props.marketerListData);
    }
  }, [props.marketerListData]);

  useEffect(() => {
    if (props.addAdminMarketerData) {
      handleAddPopup();
    }
  }, [props.addAdminMarketerData]);

  const handleAddPopup = () => {
    if (addedFolderResult) {
      setLoader(false);
      Swal.fire({
        title             : "Marketer added successfully",
        showCancelButton  : false,
        confirmButtonText : "OK",
        confirmButtonColor: "green",
        allowOutsideClick : false,
      }).then((result) => {
        if (result.isConfirmed) {
          setShow(false);
          props.getMarketerList();
          setAddedFolderResult(false);
          setNewPlan({ mName: "", mPayfastPassphrase: "", mContactNumber: "", mEmail: "", mMerchantId:"", mMerchantKey:"", });
          setCheckValidation({
            mName             : false,
            mPayfastPassphrase: false,
            mContactNumber    : false,
            mEmail            : false,
            mMerchantId       : false,
            mMerchantKey      : false,
          });
        }
      });
      setAdminDetail(props.marketerListData);
    }
  };
  const handleUpdateChange = (event: any) => {
    setCheckValidation({ ...checkValidation, [event.target.name]: true, });
    setUpdatePlan({ ...updatePlan, [event.target.name]: event.target.value, });
  };

  const handleChange = (event: any) => {
    if(event.target.innerHTML){
      setDropdownSelected(event.target.innerHTML);
    }
    
    if(event.target.name == 'mAccountType'){
      setCheckValidation({ ...checkValidation, [event.target.name]: true, });
      setNewPlan({ ...newPlan, [event.target.name]: event.target.innerHTML,
      });
    }
   else {
      setCheckValidation({ ...checkValidation, [event.target.name]: true, });
      setNewPlan({ ...newPlan, [event.target.name]: event.target.value, });
    }
  };

  useEffect(() => {
    const validationErrors = addMarketerValidation(newPlan);
    isValid = Object.keys(validationErrors).length === 0;
    setIsValid(isValid);
    setErrors(validationErrors);
  }, [newPlan]);

  useEffect(() => {
    const updateValidationErrors = addMarketerValidation(updatePlan);
    isValid = Object.keys(updateValidationErrors).length === 0;
    setIsValid(isValid);
    setErrors(updateValidationErrors);
  }, [updatePlan,upDropdownSelected]);

  const handleViewModalClose = () => {
    setCheckValidation({ mName: false, mPayfastPassphrase: false, mContactNumber: false, mEmail: false, mMerchantId:false, mMerchantKey:false, });
    setFolderDetailResult(false);
    setViewModalShow(false);
    setViewModalOpen(false);
  };

  const handleViewModalShow = (e: any) => {
    setDisabledCheck(true);
    openModal(e);
  };
  
  const handleUpdatePlanShow = (e: any) => {
    setDisabledCheck(false);
    openModal(e);
  };
  
  let promoCodePayload = {
    marketerId : 0
  }
  
  const [promoCodePay,setPromoCodePay]=useState(0);
  
  const handleGeneratePC =(event:any) =>{
    promoCodePayload.marketerId=event
    if(event){
      props.getMarketerPromoCodeById(event);
      setPromoCodePay(event);
      setShowPc(true)
    }
  }

  const handleUserList = (event:any)=>{
    let userId=event;
    navTo('/admin-marketer-user-list', {state:{id:userId}})
  }

  const handleAllUserList =(event:any)=>{
    let userId=event;
    navTo('/admin-marketer-all-user-list', {state:{id:userId}})
  }
  
  useEffect(()=>{
    if(props.getMarketerPromoCodeByIdData&&props.getMarketerPromoCodeByIdData.length>0){
      setPromoCode(props.getMarketerPromoCodeByIdData[0].promoCode)
      let pmcode= props.getMarketerPromoCodeByIdData&&props.getMarketerPromoCodeByIdData[0]&&props.getMarketerPromoCodeByIdData[0].promoCode;
      setPromocode(pmcode);
    }else {
    if(promoCodePay>0){
        promoCodePayload.marketerId=promoCodePay;
        props.getMarketerPromoCode(promoCodePayload);
      }
    }
  },[props.getMarketerPromoCodeByIdData])

  useEffect(() => {
    if (props.getMarketerDetailByIdData) {
      let result1 = props.getMarketerDetailByIdData ? props.getMarketerDetailByIdData : "";
      
      // view
      viewModalDetails.marketerName        = result1.marketerName        ? result1.marketerName        : "";
      viewModalDetails.marketerPassphrase  = result1.marketerPassphrase  ? result1.marketerPassphrase  : "";
      viewModalDetails.marketerPhone       = result1.marketerPhone       ? result1.marketerPhone       : "";
      viewModalDetails.marketerEmail       = result1.marketerEmail       ? result1.marketerEmail       : "remove";
      viewModalDetails.marketerMerchantId  = result1.marketerMerchantId  ? result1.marketerMerchantId  : "";
      viewModalDetails.marketerMerchantKey = result1.marketerMerchantKey ? result1.marketerMerchantKey : "";

      // update
      updatePlan.mName              = result1.marketerName        ? result1.marketerName        : "";
      updatePlan.mPayfastPassphrase = result1.marketerPassphrase  ? result1.marketerPassphrase  : "";
      updatePlan.mContactNumber     = result1.marketerPhone       ? result1.marketerPhone       : "";
      updatePlan.mEmail             = result1.marketerEmail       ? result1.marketerEmail       : "";
      updatePlan.mMerchantId        = result1.marketerMerchantId  ? result1.marketerMerchantId  : "";
      updatePlan.mMerchantKey       = result1.marketerMerchantKey ? result1.marketerMerchantKey : "";

      if (folderDetailResult) {
        setViewModalOpen(true);
        setViewModalShow(true);
      }
    }
  }, [props.getMarketerDetailByIdData]);

  const openModal = (e: any) => {
    let marketerId = e;

    // api call for particular marketer id
    props.getMarketerDetailById(marketerId);
    setFolderDetailResult(true);
  };

  const handleUpdateSubmit = (e: any) => {
    e.preventDefault();
    let formDataUpdate = {
      marketerName       : "",
      marketerPassphrase : "",
      marketerPhone      : "",
      marketerEmail      : "",
      marketerMerchantId :"",
      marketerMerchantKey:"",
    };

    formDataUpdate.marketerName        = updatePlan.mName;
    formDataUpdate.marketerPassphrase  = updatePlan.mPayfastPassphrase;
    formDataUpdate.marketerPhone       = updatePlan.mContactNumber;
    formDataUpdate.marketerEmail       = updatePlan.mEmail;
    formDataUpdate.marketerMerchantId  = updatePlan.mMerchantId;
    formDataUpdate.marketerMerchantKey = updatePlan.mMerchantKey;
     
    let updateId = props.getMarketerDetailByIdData.id;
    let updatePayload = { id: updateId, data: formDataUpdate, };

    props.updateAdminMarketer(updatePayload);
    setUpdatedFolderResult(true);
    setLoader(true);
  };

  useEffect(() => {
    if (props.updateAdminMarketerData && updatedFolderResult) {
      setLoader(false);
      Swal.fire({
        title              : "Updated successfully",
        showCancelButton   : false,
        confirmButtonText  : "OK",
        confirmButtonColor : "green",
        allowOutsideClick  : false,
      }).then((result) => {
        if (result.isConfirmed) {
          props.getMarketerList();
          setUpdatedFolderResult(false);
          handleViewModalClose();
        }
      });
    }
  }, [props.updateAdminMarketerData]);

  const handleRemoveFolder = (e: any, name: any) => {
    let deleteMarketerId = e;
    let removeMarketerName = name;
    Swal.fire({
      title             : `Do you want to remove "${removeMarketerName}" marketer? `,
      showDenyButton    : true,
      showCancelButton  : false,
      confirmButtonText : "YES",
      denyButtonText    : `NO`,
      confirmButtonColor: "green",
      showCloseButton   : true,
    }).then((result) => {
      if (result.isConfirmed) {
        props.deleteAdminMarketer({ id: deleteMarketerId });
        setLoader(true);
        setDeleteFolderResult(true);
      }
    });
  };

  const copyToClipboard = async (event:any) => {
    try {
      await navigator.clipboard.writeText(`${process.env.REACT_APP_BASE_URL}/signup-with-promocode/${event}`);
      setCopySuccess('Copied!');
    } catch (err) {
      setCopySuccess('Failed to copy!');
    }
  };

  useEffect(() => {
    if (props.deleteAdminMarketerData && deleteFolderResult) {
      setLoader(false);
      let deleteResult = props.deleteAdminMarketerData.message;
      Swal.fire({
        title             : 'Marketer deleted successfully',
        showCancelButton  : false,
        confirmButtonText : "OK",
        confirmButtonColor: "green",
        allowOutsideClick : false,
      }).then((result) => {
        if (result.isConfirmed) {
          props.getMarketerList();
          setDeleteFolderResult(false);
        }
      });
    }
  }, [props.deleteAdminMarketerData]);

  let result: any;
  useEffect(() => {
    if (viewModalOpen) {
      result = props.marketerListData ? props.marketerListData : "";
    }
  });

  const handleSubmit = (e: any) => {
    e.preventDefault();
    let payload = {
      marketerName       : "",
      marketerPassphrase : "",
      marketerPhone      : "",
      marketerEmail      : "",
      marketerMerchantId :"",
      marketerMerchantKey:"",
    };

    payload.marketerName        = newPlan.mName;
    payload.marketerPassphrase  = newPlan.mPayfastPassphrase;
    payload.marketerPhone       = newPlan.mContactNumber;
    payload.marketerEmail       = newPlan.mEmail;
    payload.marketerMerchantId  = newPlan.mMerchantId;
    payload.marketerMerchantKey = newPlan.mMerchantKey;


    if (true) {
      props.addAdminMarketer(payload);
      setLoader(true);

      setAddedFolderResult(true);
    }
  };

  const modalDetail = [
    {
      field1: "Marketer Name",
      field2: "mName",
    },
    {
      field1: "Contact Number",
      field2: "mContactNumber",
    },
    {
      field1: "Email",
      field2: "mEmail",
    },
  
    {
      field1: "Merchant Id",
      field2: "mMerchantId",
    },
    {
      field1: " Payfast Passphrase",
      field2: "mPayfastPassphrase",
    },
    {
      field1: "Merchant Key",
      field2: "mMerchantKey",
    },
  ];
  
//------------------------------------------------------------------------------------------------//

  return (
    <>
      <Layout>
        {loader ? 
          (
            <div className="adminLoader">
              <span className="text-warning text-center lds-dual-ring"></span>
            </div>
          ) 
        : 
          ( "" )
        }
          
        { showPc ? 
          (
            <Modal show={true} size="lg" backdrop="static" onHide={handleClosePc} className="adminModal">
              <Modal.Header closeButton>
                <Modal.Title style={{ color: "#E79E1F", marginLeft:'300px' }}>Promo Code</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="promocode">
                  <Form.Group className="formRow">
                    <Form.Label style={{ color: "whitesmoke", marginTop:'20px' }}>
                      <h4> Promo Code :</h4> 
                    </Form.Label>
                    <Form.Text style={{ marginLeft:'20px', fontSize:'20px',color:'#E79E1F' }}>
                      {" "}{promocode}  
                    </Form.Text>
                  </Form.Group>
                  <Form.Group className="formRow">
                    <Form.Label style={{ color: "whitesmoke", marginTop:'20px' }}><h4>  SignUp Link : </h4></Form.Label>
                    <Form.Text style={{ marginLeft:'20px', fontSize:'20px',color:'#E79E1F' }}>
                    {process.env.REACT_APP_BASE_URL}/signup-with-promocode/{promocode}  
                    </Form.Text>
                  </Form.Group>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button className="backBtn" style={{ marginRight:'360px' }} onClick={ ()=>{copyToClipboard(promocode)}}> Copy Link</Button>
                <span style={{ color:'white' }}> {copySuccess}</span>
              </Modal.Footer>
            </Modal>
          )
        :
         (" ")
         }

        <Stack direction="horizontal" gap={2}>
          <Button variant="info" type="submit" onClick={ handleAllUserList}>All User List</Button>{" "}
          <Button variant="info" className="ms-auto" onClick={handleShow}>{" "}Add{" "}</Button>{" "}
        </Stack>
        <div className="usersManageFolders">
          <Table responsive>
            <thead>
              <tr>
                <th className="text-warning text-center">S.No</th>
                <th className="text-warning text-center">Marketer Name</th>
                <th className="text-warning text-center">Contact Number</th>
                <th className="text-warning text-center">Payfast Passphrase</th>
                <th className="text-warning text-center">Merchant Id </th>
                <th className="text-warning text-center">Action</th>
                <th className="text-warning text-center">PromoCode</th>
                <th className="text-warning text-center">User List</th>
              </tr>
            </thead>
            <tbody>
              {adminDetail &&
                adminDetail.map((list: any, index: number) => {
                  return (
                    <>
                      <tr>
                        <td className="text-center">{index + 1}</td>
                        <td className="text-center">{list.marketerName}</td>
                        <td className="text-center">{list.marketerPhone}</td>
                        <td className="text-center">
                          <div className="marketerDetailColumn">{list.marketerPassphrase}</div>
                        </td>
                        <td className="text-center">{list.marketerMerchantId}</td>
                        <td className="text-center">
                          <button type="submit" className="btn btn-outline-success" value={list.id} onClick={() => handleViewModalShow(list.id)}><BsEyeFill /></button>
                          <button type="submit" className="btn btn-outline-warning m-2" value={list.id} onClick={() => handleUpdatePlanShow(list.id)} > <BsPencilFill /> </button>
                          <button type="submit" className="btn btn-outline-danger" value={list.id} id={list.planType} onClick={() => handleRemoveFolder(list.id, list.marketerName) } > <BsTrashFill /> </button>
                        </td>
                        <td className="text-center"> 
                          <button type="submit" className="btn btn-outline-success m-2" value={list.id} onClick={() => handleGeneratePC(list.id)} ><RiCoupon2Line /></button>
                        </td>
                        <td className="text-center"> 
                          <button type="submit" className="btn btn-outline-warning m-2" value={list.id} onClick={() => handleUserList(list.id)} > < FaUsers/> </button>
                        </td>
                      </tr>
                    </>
                  );
                })}
            </tbody>
          </Table>
        </div>

        {/* -------------ADD FOLDER MODAL---------------  */}
        <Modal show={show} size="lg" backdrop="static" onHide={handleClose} className="adminModal">
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#E79E1F" }}>Add Marketer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmit}>
              <Container>
                <Row>
                  {modalDetail && modalDetail.map((data: any, index) => {
                    return (
                      <>
                        <Col className="col-md-auto" md={6}>
                          <Form.Group className="mb-3 mt-3 " >
                            <Form.Label style={{ color: "whitesmoke" }}>
                              {data.field1}
                            </Form.Label>
                            { data.field1 == "Account Type" ?
                              ( 
                                <Dropdown align="start">
                                  <Dropdown.Toggle id="dropdown-basic"  >
                                    <span className="">{dropdownSelected}</span>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu  >
                                    <Dropdown.Item onClick={handleChange} name = 'mAccountType'>Savings</Dropdown.Item>
                                    <Dropdown.Item onClick={handleChange} name = 'mAccountType'>Current</Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              )
                            :
                              <Form.Control type={ data.field1 == "Contact Number" || data.field1 == "Bank Account Number" ? "number" : "text" } name={data.field2} onInput={( e: React.ChangeEvent<HTMLInputElement> ) => { e.target.value = e.target.value.trimStart(); }} onChange={handleChange} autoFocus />
                            }

                            {checkValidation[data.field2] && errors[data.field2] && ( <p className="text-danger"> {errors[data.field2]} </p> )}
                          </Form.Group>
                        </Col>
                      </>
                    );
                  })}
                </Row>
                {loader ? 
                  (
                    <div className="adminLoader">
                      <span className="text-warning text-center lds-dual-ring"></span>
                    </div> 
                  ) 
                : ( "" )
                }
              </Container>
              <Row className="justify-content-md-center mt-10">
                <Col className="" md="auto" style={!isValid ? {cursor:"not-allowed"} : {}}>
                  <Button className="backBtn" type="submit" disabled={isValid ? false : true} > Submit </Button>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
        </Modal>

        {/* ACTION - VIEW ,UPDATE AND DELETE */}
        <Modal show={viewModalShow} size="lg" backdrop="static" onHide={handleViewModalClose} className="adminModal">
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#E79E1F" }}>
              {disabledCheck ? "Marketer View Details" : "Marketer Update Details"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleUpdateSubmit}>
              <Container>
                <Row>
                  <Col className="col-md-auto" md={6}>
                    <Form.Group className="mb-3 mt-3 ">
                      <Form.Label style={{ color: "whitesmoke" }}>
                        Marketer Name
                      </Form.Label>
                      <Form.Control type="text" name="mName" onChange={handleUpdateChange} value={ disabledCheck ? viewModalDetails.marketerName : updatePlan.mName } autoFocus disabled={disabledCheck} />
                    </Form.Group>
                    {checkValidation["mName"] && errors["mName"] && ( <p className="text-danger">{errors["mName"]}</p> )}
                  </Col>

                  <Col className="col-md-auto" md={6}>
                    <Form.Group className="mb-3 mt-3 ">
                      <Form.Label style={{ color: "whitesmoke" }}>
                       Contact Number
                      </Form.Label>
                      <Form.Control type="number" name="mContactNumber" onChange={handleUpdateChange} value={ disabledCheck ? viewModalDetails.marketerPhone : updatePlan.mContactNumber } autoFocus disabled={disabledCheck} />
                    </Form.Group>
                    {checkValidation["mContactNumber"] && errors["mContactNumber"] && ( <p className="text-danger"> {errors["mContactNumber"]} </p> )}
                  </Col>

                  <Col className="col-md-auto" md={6}>
                    <Form.Group className="mb-3 mt-3 ">
                      <Form.Label style={{ color: "whitesmoke" }}> Email </Form.Label>
                      <Form.Control type="text" name="mEmail" onChange={handleUpdateChange} value={ disabledCheck ? viewModalDetails.marketerEmail : updatePlan.mEmail } autoFocus disabled={disabledCheck} />
                    </Form.Group>
                    {checkValidation["mEmail"] && errors["mEmail"] && ( <p className="text-danger">{errors["mEmail"]}</p> )}
                  </Col>

                  <Col className="col-md-auto" md={6}>
                    <Form.Group className="mb-3 mt-3 ">
                      <Form.Label style={{ color: "whitesmoke" }}> Payfast Passphrase </Form.Label>
                      <Form.Control type="text" name="mPayfastPassphrase" onChange={handleUpdateChange} value={ disabledCheck ? viewModalDetails.marketerPassphrase : updatePlan.mPayfastPassphrase } autoFocus disabled={disabledCheck} />
                    </Form.Group>
                    {checkValidation["mPayfastPassphrase"] && errors["mPayfastPassphrase"] && ( <p className="text-danger"> {errors["mPayfastPassphrase"]} </p> )}
                  </Col>

                  <Col className="col-md-auto" md={6}>
                    <Form.Group className="mb-3 mt-3 ">
                      <Form.Label style={{ color: "whitesmoke" }}> Merchant Id </Form.Label>
                      <Form.Control type="number" name="mMerchantId" onChange={handleUpdateChange} value={ disabledCheck ? viewModalDetails.marketerMerchantId : updatePlan.mMerchantId } autoFocus disabled={disabledCheck} />
                    </Form.Group>
                    {checkValidation["mMerchantId"] && errors["mMerchantId"] && ( <p className="text-danger"> {errors["mMerchantId"]} </p> )}
                  </Col>

                  <Col className="col-md-auto" md={6}>
                    <Form.Group className="mb-3 mt-3 ">
                      <Form.Label style={{ color: "whitesmoke" }}> Merchant Key </Form.Label>
                      <Form.Control type="text" name="mMerchantKey" onChange={handleUpdateChange} value={ disabledCheck ? viewModalDetails.marketerMerchantKey : updatePlan.mMerchantKey } autoFocus disabled={disabledCheck} />
                    </Form.Group>
                    {checkValidation["mMerchantKey"] && errors["mMerchantKey"] && ( <p className="text-danger">{errors["mMerchantKey"]}</p> )}
                  </Col>
                </Row>
                {loader ? 
                  (
                    <div className="adminLoader">
                      <span className="text-warning text-center lds-dual-ring"></span>
                    </div>
                  ) 
                : 
                  ( "" )}
              </Container>
              <Row className="justify-content-md-center mt-10">
                <Col className="" md="auto">
                  {!disabledCheck ? ( <Button className="backBtn" type="submit" disabled={!isValid} > Update </Button> ) : ( "" )}
                </Col>
              </Row>
            </Form>
          </Modal.Body>
        </Modal>
      </Layout>
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  getMarketerList          : (data: any) => dispatch(getMarketerList(data)),
  addAdminMarketer         : (data: any) => dispatch(addAdminMarketer(data)),
  deleteAdminMarketer      : (data: any) => dispatch(deleteAdminMarketer(data)),
  getMarketerDetailById    : (data: any) => dispatch(getMarketerDetailById(data)),
  updateAdminMarketer      : (data: any) => dispatch(updateAdminMarketer(data)),
  getMarketerPromoCode     : (data: any) => dispatch(getMarketerPromoCode(data)),
  getMarketerPromoCodeById : (data: any) => dispatch(getMarketerPromoCodeById(data)),
});
const mapStateToProps = (state: any) => {
  return {
    marketerListData            : state.AdminMarketerReducer && state.AdminMarketerReducer.marketerListData             ? state.AdminMarketerReducer.marketerListData             : null,
    addAdminMarketerData        : state.AdminMarketerReducer && state.AdminMarketerReducer.addAdminMarketerData         ? state.AdminMarketerReducer.addAdminMarketerData         : null,
    deleteAdminMarketerData     : state.AdminMarketerReducer && state.AdminMarketerReducer.deleteAdminMarketerData      ? state.AdminMarketerReducer.deleteAdminMarketerData      : null,
    getMarketerDetailByIdData   : state.AdminMarketerReducer && state.AdminMarketerReducer.getMarketerDetailByIdData    ? state.AdminMarketerReducer.getMarketerDetailByIdData    : null,
    updateAdminMarketerData     : state.AdminMarketerReducer && state.AdminMarketerReducer.updateAdminMarketerData      ? state.AdminMarketerReducer.updateAdminMarketerData      : null,
    getMarketerPromoCodeData    : state.AdminMarketerReducer && state.AdminMarketerReducer.getMarketerPromoCodeData     ? state.AdminMarketerReducer.getMarketerPromoCodeData     : null,
    getMarketerPromoCodeByIdData: state.AdminMarketerReducer && state.AdminMarketerReducer.getMarketerPromoCodeByIdData ? state.AdminMarketerReducer.getMarketerPromoCodeByIdData : null,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminMarketer);

