import React from 'react';
import { Navigate } from 'react-router-dom';
import NotAuthorized from '../pages/frontend/error_pages/not_authorised';

const PrivateRoutes = (props:any) => {
    const userData    = JSON.parse(localStorage.getItem('userData')||'{}');
    const profileData = JSON.parse(localStorage.getItem('profileData')||'{}');
    const token       = (userData && userData.Token)?userData.Token:'';
    const role        = (profileData && profileData.roles ? profileData.roles[0]:'');
    
    if(!token){
        return <Navigate to="/login" />
    }
    
    return (role && role == 'User')? props.children : <NotAuthorized />;
}

export default PrivateRoutes;

