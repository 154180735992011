import Api from "../apiCall";
import { API_URLS } from "../../constant/index";

export const getAddAdminFoundationService = async (params) => {
  const result = await Api.post(API_URLS.ADD_ADMIN_FOUNDATION_URL, params);
  return result;
};

export const getFoundationListService = async (params) => {
  const result = await Api.get(API_URLS.GET_FOUNDATION_LIST_URL, params);
  return result;
};

export const deleteAdminFoundationService = async (params) => {
  const deleteFoundationId = params.id;
  let url = API_URLS.DELETE_ADMIN_FOUNDATION_URL;
  let newurl = url.replace("{id}", deleteFoundationId);
  const result = await Api.delete(newurl, params);
  return result;
};
export const getFoundationDetailByIdService = async (params) => {
  const foundationDetailById = params;
  let url = API_URLS.GET_FOUNDATION_DETAIL_BY_ID_URL;
  let newurl = url.replace("{id}", foundationDetailById);
  const result = await Api.get(newurl, params);
  return result;
};
export const updateAdminFoundationService = async (params) => {
  const updateFoundationId = params.id;
  let url = API_URLS.GET_FOUNDATION_DETAIL_BY_ID_URL;
  let newurl = url.replace("{id}", updateFoundationId);
  const result = await Api.put(newurl, params.data);
  return result;
};
