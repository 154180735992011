import { ADMIN_FOUNDATION } from "../../constant/index";
const initialState = {
    loading: false,
    addAdminFoundationData:null,
    foundationListData:null,
  
  }
  const AdminFoundationReducer = (state= initialState, action)=>{
    
    switch (action.type){
        
        case ADMIN_FOUNDATION.SET_ADD_ADMIN_FOUNDATION_TO_STATE:
          
          return {
            ...state,
            addAdminFoundationData: action.data,
            loading: false,
            
          };
          case ADMIN_FOUNDATION.SET_GET_FOUNDATION_LIST_TO_STATE:
          
          return {
            ...state,
            foundationListData: action.data,
            loading: false,
            
          };
          //DELETE_ADMIN_FOUNDATION,
          case ADMIN_FOUNDATION.SET_DELETE_ADMIN_FOUNDATION_TO_STATE:
          
            return {
              ...state,
              deleteAdminFoundationData: action.data,
              loading: false,
              
            };
            //GET_FOUNDATION_DETAIL_BY_ID
            case ADMIN_FOUNDATION.SET_GET_FOUNDATION_DETAIL_BY_ID_TO_STATE:
          
            return {
              ...state,
              getFoundationDetailByIdData: action.data,
              loading: false,
              
            };
            //UPDATE_ADMIN_FOUNDATION
            case ADMIN_FOUNDATION.SET_UPDATE_ADMIN_FOUNDATION_TO_STATE:
          
            return {
              ...state,
              updateAdminFoundationData: action.data,
              loading: false,
              
            };
          default:
            return state;
    }

  }

  export default AdminFoundationReducer;