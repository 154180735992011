// Imports: Dependencies
import { takeLatest } from "redux-saga/effects";
import { AUTH, BOX_API,COUNTRY_DATA,SUBSCRIPTION_PLAN, USER_PROFILE,FOUNDATION_DATA, ADMIN_BOX_PLAN,ADMIN_COUNTRY_DATA, ADMIN_BOX_API, ADMIN_USER,ADMIN_FOLDER_MANAGEMENT, ADMIN_FOUNDATION, ADMIN_MARKETER,LIST_USER,USER, RECYCLE_BIN,WEB_API } from "../constant/index";

import * as authSaga                          from "./authSaga";
import * as profileSaga                       from "./profileSaga";
import * as countrySaga                       from "./countrySaga";
import * as plansSaga                         from "./plansSaga";
import * as boxSaga                           from "./boxSaga";
import * as foundationSaga                    from "./foundationSaga";
import * as userSaga                          from './userSaga';
import * as recycleBinSaga                    from './recycleBinSaga';
import * as webSaga                           from './webSaga';
//-----------------------------------------------------------//
import * as adminBoxPlanSaga                  from "./admin/adminBoxPlanSaga";
import * as adminCountrySaga                  from "./admin/adminCountrySaga";
import * as adminBoxSaga                      from "./admin/adminBoxApiSaga";
import * as adminUserSaga                     from './admin/adminUserSaga';
import * as adminFolderManagementSaga         from './admin/adminFolderManagementSaga';
import * as adminFoundationSaga               from './admin/adminFoundationSaga';
import * as adminMarketerSaga                 from './admin/adminMarketerSaga';

// Redux Saga: Root Saga
export function* rootSaga() {
  yield takeLatest(AUTH.LOGIN_REQUEST,                              authSaga.loginUser);
  yield takeLatest(AUTH.GET_LOGIN_OTP,                              authSaga.authoriseUser);
  yield takeLatest(AUTH.REGISTER_REQUEST,                           authSaga.registerUser);
  yield takeLatest(AUTH.GET_DETAILS_USERNAME,                       authSaga.fetchByUsername);
  yield takeLatest(AUTH.FORGOT_PASSWORD,                            authSaga.forgotPassword);
  yield takeLatest(AUTH.CHECK_USER_OTP,                             authSaga.checkOtp);
  yield takeLatest(AUTH.GET_OTP_TO_VERIFY_USER,                     authSaga.checkOtpToVerifyUser);
  yield takeLatest(AUTH.GET_USERVERIFICATION_RESEND_OTP,            authSaga.getResendOtpToVerifyUser);

  yield takeLatest(COUNTRY_DATA.GET_COUNTRIES,                      countrySaga.fetchCountryList);
  yield takeLatest(COUNTRY_DATA.GET_COUNTRY_ID,                     countrySaga.fetchCountryById);
  yield takeLatest(COUNTRY_DATA.GET_STATES,                         countrySaga.fetchStateList);
  yield takeLatest(COUNTRY_DATA.GET_CITIES,                         countrySaga.fetchCityList);
  yield takeLatest(COUNTRY_DATA.GET_LOCATION,                       countrySaga.fetchUserLocation);
  yield takeLatest(COUNTRY_DATA.GET_STATE_LOCATION,                 countrySaga.fetchUserStateLocation);
  yield takeLatest(COUNTRY_DATA.GET_COUNTRY_LOCATION,               countrySaga.fetchUserCountryLocation);
  
  yield takeLatest(USER_PROFILE.GET_PROFILE_PAGE,                   profileSaga.fetchProfile);
  yield takeLatest(USER_PROFILE.GET_EDIT_PROFILE,                   profileSaga.fetchEditProfile);
  yield takeLatest(USER_PROFILE.UPLOAD_PROFILE_IMAGE,               profileSaga.fetchProfileImage);
  yield takeLatest(USER_PROFILE.UPDATE_USER_PROFILE,                profileSaga.updateProfile);
  yield takeLatest(USER_PROFILE.GET_KIN_DETAILS,                    profileSaga.fetchKinDetails);
  yield takeLatest(USER_PROFILE.REQUEST_CHANGE_PASSWORD,            profileSaga.request_changePassword);
  yield takeLatest(USER_PROFILE.GET_STORAGE,                        profileSaga.fetchStorage);
  
  yield takeLatest(SUBSCRIPTION_PLAN.GET_SUBSCRIPTION_PLAN,         plansSaga.fetchPlans);
  yield takeLatest(SUBSCRIPTION_PLAN.SELECT_SUBSCRIPTION_PLAN,      plansSaga.fetchSelectedPlans);
  yield takeLatest(SUBSCRIPTION_PLAN.SELECT_PAID_SUBSCRIPTION_PLAN, plansSaga.fetchSelectedPaidPlans);
  yield takeLatest(SUBSCRIPTION_PLAN.GET_CURRENT_PLAN,              plansSaga.fetchCurrentPlan);
  yield takeLatest(SUBSCRIPTION_PLAN.CANCEL_PLAN,                   plansSaga.fetchCancelPlans);
  yield takeLatest(SUBSCRIPTION_PLAN.GET_ALL_PLAN,                  plansSaga.fetchAllPlans);
  
  yield takeLatest(BOX_API.GET_BOX_COVER_PHOTO,                     boxSaga.fetchBoxCover);
  yield takeLatest(BOX_API.GET_USER_BY_NAME,                        boxSaga.fetchUserByName);
  yield takeLatest(BOX_API.GET_USER_BY_EMAIL,                       boxSaga.fetchUserByEmail);
  yield takeLatest(BOX_API.GET_BOX_ICON,                            boxSaga.fetchBoxIcons);
  yield takeLatest(BOX_API.CREATE_BOX,                              boxSaga.fetchCreateBox);
  yield takeLatest(BOX_API.GET_BOX_LIST,                            boxSaga.fetchBoxList);
  yield takeLatest(BOX_API.GET_PUBLIC_BOX_LIST,                     boxSaga.fetchPublicBoxList);
  yield takeLatest(BOX_API.UPLOAD_BOX_FILES,                        boxSaga.uploadBoxFiles);
  yield takeLatest(BOX_API.UPLOAD_BOXCOVER_IMAGE_BY_USER,           boxSaga.uploadBoxCoverByUser);
  // RECEIVED BOX---
  yield takeLatest(BOX_API.GET_RECEIVED_BOX_LIST,                   boxSaga.fetchReceiedBoxList);
  yield takeLatest(BOX_API.GET_RECEIVED_BOX_DETAILS,                boxSaga.fetchRecievedBoxDetails);

  yield takeLatest(BOX_API.GET_CHILD_RECEIVED_BOX_LIST,             boxSaga.fetchChildReceiedBoxList);

  // CREATED BOX DETAILS---
  yield takeLatest(BOX_API.SEND_RECIPIENT_DETAILS_FOR_UPDATE,       boxSaga.sendRecipientDetailsforUpdate);
  yield takeLatest(BOX_API.GET_CREATED_BOX_DETAILS,                 boxSaga.fetchCreatedBoxDetails);
  // FOLDER DETAILS INSIDE CREATED BOX (Ex: Images,Videos,Messages... etc)
  yield takeLatest(BOX_API.GET_UPLOADED_FILES_DETAILS,              boxSaga.fetchUploadedFilesByBoxIdandFolderId);
  //DELETE UPLOADED FILES BY ITS ID---
  yield takeLatest(BOX_API.DELETE_UPLOADED_FILES,                   boxSaga.fetchdeleteUploadedFilesByFileId);
  yield takeLatest(BOX_API.SCHEDULE_BOX,                            boxSaga.fetchScheduledBox);
  yield takeLatest(BOX_API.GET_BOX_BY_ID,                           boxSaga.fetchBoxById);
  yield takeLatest(BOX_API.UPDATE_BOX,                              boxSaga.fetchUpdateBox);
  yield takeLatest(BOX_API.DELETE_BOX,                              boxSaga.fetchDeleteBox);
  yield takeLatest(BOX_API.GET_USER_BY_ID,                          boxSaga.fetchUserById);
  yield takeLatest(BOX_API.LIST_BOX_CONTENT,                        boxSaga.fetchBoxDetails);
  //PUBLIC BOX RECORDS--------------------
  yield takeLatest(BOX_API.GET_BOX_RECORDS,                         boxSaga.fetchBoxRecordsListingWithSearchKeyword);
  yield takeLatest(BOX_API.GET_PUBLIC_BOX_DETAIL_CONTENT,           boxSaga.fetchPublicBoxContent);

  // CATEGORYWISE SCHEDULER ON UPLOADED FILES--------------------
  yield takeLatest(BOX_API.SET_CATEGORYWISE_SCHEDULER_ON_UPLOADED_FILES,boxSaga.setCategorywise_scheduler_on_uploadedFiles);
  
  // MESSAGE VIEW PAGE--------------------
  yield takeLatest(BOX_API.UPDATE_MESSAGE,                          boxSaga.updateMessages);
  
  // UPLOAD COVER PICTURE BY USER--------------------
  yield takeLatest(BOX_API.UPLOAD_COVERPHOTO_BY_USER,               boxSaga.uploadCoverPhotoByloggedInUser);

  // RELATIONSHIP with RECIPIENTS (Old/New)
  yield takeLatest(BOX_API.GET_RELATIONSHIP_MASTER_DATA,            boxSaga.getRelationshipMasterData);

  // Foundation Page
  yield takeLatest(FOUNDATION_DATA.GET_FOUNDATION_PAGE,             foundationSaga.fetchGetFoundationPage);

  // PEOPLE & RECYCLE BIN -----------------------------------
  yield takeLatest(LIST_USER.GET_USER_PEOPLE,                         userSaga.fetchUserPeople);

  yield takeLatest(RECYCLE_BIN.GET_RECYCLE_BIN_BOXES,                 recycleBinSaga.fetchRecycleBox);
  yield takeLatest(RECYCLE_BIN.GET_RECYCLE_BIN_FILES,                 recycleBinSaga.fetchRecycleFile);
  yield takeLatest(RECYCLE_BIN.RESTORE_ITEM,                          recycleBinSaga.fetchRestoreItem);
  yield takeLatest(RECYCLE_BIN.DELETE_ITEM_PERMANENTLY,               recycleBinSaga.deleteItemPermanently);

  yield takeLatest(WEB_API.GET_CONTACTUS,                             webSaga.contactus);

  yield takeLatest(WEB_API.GET_COUNTRY_DAIL_CODE,                     webSaga.countryDailCode);

  yield takeLatest(USER_PROFILE.GET_OTP_TO_EDIT_PHONE_NO,             profileSaga.getOTP_toEditPhoneNo);
  yield takeLatest(USER_PROFILE.GET_OTP_VERIFICATION_TO_EDIT_PHONE_NO, profileSaga.verifyOTP_toEditPhoneNo);

  //SAVE AUDIO/VIDEO LINKS
  yield takeLatest(BOX_API.SAVE_AUDIO_OR_VIDEO_LINKS,               boxSaga.saveAudioOrVideoLinks);

  // RECIPIENT FLOW
  yield takeLatest(BOX_API.UPLOAD_RECIPIENT_PROFILE_PIC,            boxSaga.saveRecipientProfilePicTemproray);

  // REMOVE SCHEDULER
  yield takeLatest(BOX_API.REMOVE_FILE_SCHEDULER,                   boxSaga.removeScheduler);

  // UNSUBSCRIBE MAIL
  yield takeLatest(USER.CONFIRM_MAIL_UNSUBSCRIPTION,                userSaga.mailUnSubscribed);

  // UNSUBSCRIBE MAIL
  yield takeLatest(AUTH.VERIFY_USER_FOR_BOX_RELEASE_FROM_DIFF_EMAIL,authSaga.verifyUser);

  // RELEASE BOXES FOR DIFF EMAIL
  yield takeLatest(BOX_API.SUBMIT_RELEASE_BOX_CONFIRMATION,       boxSaga.releaseBoxes);

  //ADMIN-----------------------------------------------------------------------------------------------//
  yield takeLatest(ADMIN_BOX_PLAN.GET_ADMIN_BOX_PLAN,               adminBoxPlanSaga.fetchAdminPlans);
  yield takeLatest(ADMIN_BOX_PLAN.GET_ADMIN_BOX_PLAN_BY_ID,         adminBoxPlanSaga.fetchAdminPlansById);
  yield takeLatest(ADMIN_BOX_PLAN.ADD_ADMIN_BOX_PLAN,               adminBoxPlanSaga.fetchAddAdminPlans);
  yield takeLatest(ADMIN_BOX_PLAN.UPDATE_ADMIN_BOX_PLAN,            adminBoxPlanSaga.fetchUpdateAdminPlans);
  yield takeLatest(ADMIN_BOX_PLAN.DELETE_ADMIN_BOX_PLAN,            adminBoxPlanSaga.fetchDeleteAdminPlans);

  yield takeLatest(ADMIN_COUNTRY_DATA.GET_ADMIN_COUNTRIES,          adminCountrySaga.fetchAdminCountryList);
  yield takeLatest(ADMIN_COUNTRY_DATA.GET_ADMIN_STATES,             adminCountrySaga.fetchAdminStateList);
  yield takeLatest(ADMIN_COUNTRY_DATA.ADD_ADMIN_COUNTRY,            adminCountrySaga.fetchAddAdminCountryList);
  yield takeLatest(ADMIN_COUNTRY_DATA.GET_ADMIN_COUNTRIES_BY_ID,    adminCountrySaga.fetchAdminCountryById);
  yield takeLatest(ADMIN_COUNTRY_DATA.GET_ADMIN_CITIES,             adminCountrySaga.fetchAdminCityList);
  yield takeLatest(ADMIN_COUNTRY_DATA.UPDATE_ADMIN_COUNTRIES,       adminCountrySaga.fetchUpdateAdminCountryList);
  yield takeLatest(ADMIN_COUNTRY_DATA.REMOVE_ADMIN_COUNTRIES,       adminCountrySaga.fetchRemoveAdminCountryList);
  yield takeLatest(ADMIN_COUNTRY_DATA.ADD_ADMIN_STATES,             adminCountrySaga.fetchAddAdminStates);
  yield takeLatest(ADMIN_COUNTRY_DATA.UPDATE_ADMIN_STATES,          adminCountrySaga.fetchUpdateAdminStates);
  yield takeLatest(ADMIN_COUNTRY_DATA.REMOVE_ADMIN_STATES,          adminCountrySaga.fetchDeleteAdminStates);
  yield takeLatest(ADMIN_COUNTRY_DATA.GET_ADMIN_STATE_BY_ID,        adminCountrySaga.fetchAdminStatesById);
  yield takeLatest(ADMIN_COUNTRY_DATA.ADD_ADMIN_CITY,               adminCountrySaga.fetchAddAdminCity);
  yield takeLatest(ADMIN_COUNTRY_DATA.UPDATE_ADMIN_CITY,            adminCountrySaga.fetchUpdateAdminCity);
  yield takeLatest(ADMIN_COUNTRY_DATA.REMOVE_ADMIN_CITY,            adminCountrySaga.fetchDeleteAdminCity);
  yield takeLatest(ADMIN_COUNTRY_DATA.GET_ADMIN_CITY_BY_ID,         adminCountrySaga.fetchAdminCityById);

  yield takeLatest(ADMIN_BOX_API.GET_ADMIN_COVER_PHOTOS,            adminBoxSaga.fetchAdminBoxCoverPhotos);
  yield takeLatest(ADMIN_BOX_API.ADD_ADMIN_COVER_PHOTOS,            adminBoxSaga.fetchAddAdminBoxCoverPhotos);
  yield takeLatest(ADMIN_BOX_API.PUT_ADMIN_RELEASE_BOX,             adminBoxSaga.fetchAdminReleaseBox);
  yield takeLatest(ADMIN_BOX_API.GET_ADMIN_COVER_PHOTO_BY_ID,       adminBoxSaga.fetchAdminBoxCoverPhotoById);
  yield takeLatest(ADMIN_BOX_API.REMOVE_ADMIN_COVER_PHOTOS,         adminBoxSaga.fetchRemoveAdminBoxCoverPhotos);
  
  yield takeLatest(ADMIN_USER.GET_ADMIN_USER_LIST,                  adminUserSaga.fetchAdminUserList);
  yield takeLatest(ADMIN_USER.GET_ADMIN_USER_ADMIN_LIST,            adminUserSaga.fetchAdminUserAdminList);
  yield takeLatest(ADMIN_USER.GET_ADMIN_USER_BY_ID,                 adminUserSaga.fetchAdminUserById);
  yield takeLatest(ADMIN_USER.GET_ADMIN_USER_BY_IDENTITY,           adminUserSaga.fetchAdminUserByIdentity);
  yield takeLatest(ADMIN_USER.GET_ADMIN_USER_PAYMENT_DETAILS,       adminUserSaga.fetchAdminUserPaymentDetails);
  yield takeLatest(ADMIN_USER.DISABLE_ADMIN_USER_BY_ID,             adminUserSaga.fetchDisableAdminUserById);
  yield takeLatest(ADMIN_USER.ACTIVATE_DEACTIVATE_USER_BY_ID,       adminUserSaga.fetchActivateDeactivateUserById);
  yield takeLatest(ADMIN_USER.REQUEST_DOWNLOAD_CSV,                 adminUserSaga.download_csv);
  yield takeLatest(ADMIN_USER.GET_UNSUBSCRIBED_MAIL_USER_LIST,      adminUserSaga.getUnsubscribedMailUsersList);

  yield takeLatest(ADMIN_FOLDER_MANAGEMENT.ADD_ADMIN_FOLDER,        adminFolderManagementSaga.fetchAddAdminFolder);
  yield takeLatest(ADMIN_FOLDER_MANAGEMENT.UPDATE_ADMIN_FOLDER,     adminFolderManagementSaga.fetchUpdateAdminFolder);
  yield takeLatest(ADMIN_FOLDER_MANAGEMENT.DELETE_ADMIN_FOLDER,     adminFolderManagementSaga.fetchDeleteAdminFolder);
  yield takeLatest(ADMIN_FOLDER_MANAGEMENT.GET_FOLDER_DETAIL_BY_ID, adminFolderManagementSaga.fetchGetFolderDetailById);
 
  yield takeLatest(ADMIN_FOUNDATION.ADD_ADMIN_FOUNDATION,           adminFoundationSaga.fetchAddAdminFoundation);
  yield takeLatest(ADMIN_FOUNDATION.GET_FOUNDATION_LIST,            adminFoundationSaga.fetchGetFoundationList);
  yield takeLatest(ADMIN_FOUNDATION.DELETE_ADMIN_FOUNDATION,        adminFoundationSaga.fetchDeleteAdminFoundation);
  yield takeLatest(ADMIN_FOUNDATION.GET_FOUNDATION_DETAIL_BY_ID,    adminFoundationSaga.fetchGetFoundationDetailById);
  yield takeLatest(ADMIN_FOUNDATION.UPDATE_ADMIN_FOUNDATION,        adminFoundationSaga.fetchUpdateAdminFoundation);

  yield takeLatest(ADMIN_MARKETER.ADD_ADMIN_MARKETER,               adminMarketerSaga.fetchAddAdminMarketer);
  yield takeLatest(ADMIN_MARKETER.GET_MARKETER_LIST,                adminMarketerSaga.fetchGetMarketerList);
  yield takeLatest(ADMIN_MARKETER.DELETE_ADMIN_MARKETER,            adminMarketerSaga.fetchDeleteAdminMarketer);
  yield takeLatest(ADMIN_MARKETER.GET_MARKETER_DETAIL_BY_ID,        adminMarketerSaga.fetchGetMarketerDetailById);
  yield takeLatest(ADMIN_MARKETER.UPDATE_ADMIN_MARKETER,            adminMarketerSaga.fetchUpdateAdminMarketer);
  yield takeLatest(ADMIN_MARKETER.GET_MARKETER_PROMOCODE,           adminMarketerSaga.fetchGetMarketerPromoCode);
  yield takeLatest(ADMIN_MARKETER.GET_MARKETER_PROMOCODE_BY_ID,     adminMarketerSaga.fetchGetMarketerPromoCodeById);
  yield takeLatest(ADMIN_MARKETER.GET_USER_THROUGH_MARKETERS,       adminMarketerSaga.fetchGetUserThroughMarketers);
  yield takeLatest(ADMIN_MARKETER.GET_MARKETER_USERS_BY_ID,         adminMarketerSaga.fetchGetMarketerUsersById);
  yield takeLatest(ADMIN_MARKETER.GET_UNSUBSCRIBED_USERS,           adminMarketerSaga.fetchGetUnsubscribedUser);
  yield takeLatest(ADMIN_MARKETER.GET_MT_HISTORY,                   adminMarketerSaga.fetchGetMTHistory);
  yield takeLatest(ADMIN_MARKETER.DOWNLOAD_SUBSCRIBED_CSV,          adminMarketerSaga.downloadSubscribedCSV);
}
