export function recipientValidation(value: any) {
    
    const emregex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    
    let errors:any={};

    if(value.Fname === '' && value.Lname === '' && value.email === ''){
        errors = 'All fields are required';
    }else if(value.Fname === '' && value.Lname === ''){
        errors = 'First name & last name are required';
    }else if(value.Fname === '' && value.email === ''){
        errors = 'First name & email are required';
    }else if(value.Lname === '' && value.email === ''){
        errors = 'Last name & email are required';
    }else if(value.Fname === ''){
        errors = 'First name is required';
    }else if(value.Lname === ''){
        errors = 'Last name is required';
    }else if(value.email === '' && value.parentEmail === ''){
        errors = 'email is required'
    }else if ((value.email && !emregex.test(value.email)) || (value.parentEmail && !emregex.test(value.parentEmail))) {
        errors = 'Enter a valid email address';
    }

    return errors;
}

export function recipientEditValidationForMyPeople(value: any) {
    
    const emregex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    
    let errors:any={};

    if(!value.profilePic && !value.Fname && !value.Lname && !value.email && value.relation == 0){
        errors['commonError'] = 'All fields are required';
    }else if(!value.profilePic && !value.Fname && !value.Lname && !value.email){
        errors['commonError'] = 'Profile pic, first name, last name and email are required';
    }else if(!value.profilePic && !value.Fname && !value.Lname ){
        errors['commonError'] = 'Profile pic, fist name and last are required';
    }else if(!value.profilePic && !value.Fname){
        errors['commonError'] = 'Profile pic and first name are required';
    }else if(!value.profilePic && !value.Lname && !value.email && value.relation == 0){
        errors['commonError'] = 'Profile pic, last name, email and relation are required';
    }else if(!value.profilePic && !value.Lname && !value.email){
        errors['commonError'] = 'Profile pic, last name and email are required';
    }else if(!value.profilePic && !value.Lname){
        errors['commonError'] = 'Profile pic and last name are required';
    }else if(!value.profilePic && !value.email && value.relation == 0){
        errors['commonError'] = 'Profile pic, email and relation are required';
    }else if(!value.profilePic && !value.email){
        errors['commonError'] = 'Profile pic and email are required';
    }else if(!value.profilePic && value.relation == 0){
        errors['commonError'] = 'Profile pic and relarion are required';
    }else if(!value.Fname && !value.Lname && !value.email && value.relation == 0){
        errors['commonError'] = 'First name,last name,email and reltion are required';
    }else if(!value.Fname && !value.Lname && !value.email){
        errors['commonError'] = 'First name,last name and email are required';
    }else if(!value.Fname && !value.Lname && value.relation == 0){
        errors['commonError'] = 'First name, last name and relation are required';
    }else if(!value.Fname && !value.Lname){
        errors['commonError'] = 'First name and last name are required';
    }else if(!value.Lname && !value.email && value.relation == 0){
        errors['commonError'] = 'Last name,email and reltion are required';
    }else if(!value.Lname && !value.email){
        errors['commonError'] = 'Last name and email are required';
    }else if(!value.email && value.relation == 0){
        errors['commonError'] = 'Email and reltion are required';
    }else if(!value.Fname && !value.email && value.relation == 0){
        errors['commonError'] = 'First name, email and reltion are required';
    }else if(!value.Fname && !value.email){
        errors['commonError'] = 'First name and email are required';
    }else if(!value.Fname && value.relation == 0){
        errors['commonError'] = 'First name and relation are required';
    }else if(!value.profilePic){
        errors['commonError'] = 'Profile pic is required';
    }else if(!value.Fname){
        errors['commonError'] = 'First name is required';
    }else if(!value.Lname){
        errors['commonError'] = 'Last name is required';
    }else if(!value.email){
        errors['commonError'] = 'Email is required';
    }else if (value.relation == 0){
        errors['commonError'] = 'Relation is required';
    }else if(value.email){
        if(!emregex.test(value.email)){
            errors['commonError'] ='Enter a valid email address';
        }  
    }

    return errors;
}