import {AUTH,COMMON_MESSAGE} from '../constant/index';

export const loginUser = data => {
  return {
    type: AUTH.LOGIN_REQUEST,
    payload: data,
  };
};

export const registerUser = data => {
  return {
    type: AUTH.REGISTER_REQUEST,
    payload: data,
  };
};

export const forgotPassword = data => {
  return {
    type: AUTH.FORGOT_PASSWORD,
    payload: data,
  };
};

export const checkUserOtp = data => {
  return {
    type: AUTH.CHECK_USER_OTP,
    payload: data,
  };
};

export const changePassword = data => {
  return {
    type: AUTH.CHANGE_PASSWORD,
    payload: data,
  };
};

export const logoutUser = data => {
  return {
    type: AUTH.USER_LOGOUT,
    payload: data,
  };
};


export const getUserName = data => {
  return {
    type: AUTH.GET_DETAILS_USERNAME,
    payload: data,
  };
};

export const verifyUserByOTP = (data) => {
  return {
    type: AUTH.GET_OTP_TO_VERIFY_USER,
    payload: data,
  };
};

export const authResend = (data) => {
  return {
    type: AUTH.GET_USERVERIFICATION_RESEND_OTP,
    payload: data,
  };
};

// OTP FOR LOGIN
export const authoriseUserByOTP = (data) => {
  return {
    type: AUTH.GET_LOGIN_OTP,
    payload: data,
  };
};

// Clear Common Message variable for Box
export const clearCommoneMessage = (data) => {
  return {
    type: COMMON_MESSAGE,
    payload: data,
  };
};

export const verifyUserForBoxReleaseFromDifferentEmail = (data) =>{
  return {
    type : AUTH.VERIFY_USER_FOR_BOX_RELEASE_FROM_DIFF_EMAIL,
    payload:data
  }
}

