import { ADMIN_MARKETER } from "../../constant/index";
const initialState = {
    loading: false,
    addAdminMarketerData:null,
    marketerListData:null,
    getMarketerPromoCodeData:null,
    getMarketerUsersByIdData:null,
    getUnsubscribedUsersData:null,
  
  }
  const AdminMarketerReducer = (state= initialState, action)=>{
    
    switch (action.type){
        
        case ADMIN_MARKETER.SET_ADD_ADMIN_MARKETER_TO_STATE:
          
          return {
            ...state,
            addAdminMarketerData: action.data,
            loading: false,
            
          };
          case ADMIN_MARKETER.SET_GET_MARKETER_LIST_TO_STATE:
          
          return {
            ...state,
            marketerListData: action.data,
            loading: false,
            
          };
          //DELETE_ADMIN_MARKETER,
          case ADMIN_MARKETER.SET_DELETE_ADMIN_MARKETER_TO_STATE:
          
            return {
              ...state,
              deleteAdminMarketerData: action.data,
              loading: false,
              
            };
            //GET_MARKETER_DETAIL_BY_ID
            case ADMIN_MARKETER.SET_GET_MARKETER_DETAIL_BY_ID_TO_STATE:
          
            return {
              ...state,
              getMarketerDetailByIdData: action.data,
              loading: false,
              
            };
            //UPDATE_ADMIN_MARKETER
            case ADMIN_MARKETER.SET_UPDATE_ADMIN_MARKETER_TO_STATE:
          
            return {
              ...state,
              updateAdminMarketerData: action.data,
              loading: false,
              
            };
            //GET_MARKETER_PROMOCODE
            case ADMIN_MARKETER.SET_GET_MARKETER_PROMOCODE_TO_STATE:
          
            return {
              ...state,
              getMarketerPromoCodeData: action.data,
              loading: false,
              
            };
            case ADMIN_MARKETER.SET_GET_MARKETER_PROMOCODE_BY_ID_TO_STATE:
          
            return {
              ...state,
              getMarketerPromoCodeByIdData: action.data,
              loading: false,
              
            };
            case ADMIN_MARKETER.SET_GET_USER_THROUGH_MARKETERS_TO_STATE:
          
            return {
              ...state,
              getUserThroughMarketersData: action.data,
              loading: false,
              
            };
            
            case ADMIN_MARKETER.SET_GET_MARKETER_USERS_BY_ID_TO_STATE:
          
            return {
              ...state,
              getMarketerUsersByIdData: action.data,
              loading: false,
              
            };
            case ADMIN_MARKETER.SET_GET_UNSUBSCRIBED_USERS_TO_STATE:
          
            return {
              ...state,
              getUnsubscribedUsersData: action.data,
              loading: false,
              
            };
            case ADMIN_MARKETER.SET_GET_MT_HISTORY_TO_STATE:
          
            return {
              ...state,
              getMTHistoryData: action.data,
              loading: false,
              
            };

            
          default:
            return state;
    }

  }

  export default AdminMarketerReducer;