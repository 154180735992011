import {put, call}                from 'redux-saga/effects';
import {COMMON_MESSAGE, WEB_API } from '../constant/index';
import {generateReadableError}    from '../../hooks/messages';
import { getContactUsService,getCountryDailCodeService } from '../services/webService'

const clearErrorMessage = {
  type: COMMON_MESSAGE,
  data: {
    status: '',
    page: '',
    data: [],
  }}

export function* contactus({payload}) {
  try {
    yield put(clearErrorMessage);
    const data = yield call(getContactUsService, payload);
    yield put({type: WEB_API.SET_CONTACTUS, data});
   } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'error',
        page: 'contact-us',
        data: generateReadableError(error),
      },
    });
  }
}

export function* countryDailCode() {
  try {
    yield put(clearErrorMessage);
    const data = yield call(getCountryDailCodeService);
    yield put({type: WEB_API.SET_COUNTRY_DAIL_CODE, data});
   } catch (error) {
    yield put({
      type: COMMON_MESSAGE,
      data: {
        status: 'error',
        page: '',
        data: generateReadableError(error),
      },
    });
  }
}
