import {FOUNDATION_DATA} from '../constant/index';

const initialState = {
    loading : false,
    getFoundationPageData : null,
}
const FoundationReducer = (state = initialState, action) => {
  
    switch (action.type) {    

      case FOUNDATION_DATA.SET_GET_FOUNDATION_PAGE_TO_STATE:
        return {
          ...state,
          getFoundationPageData: action.data,
          loading: false,
        };
      default:
        return state;
    }
   };

   export default FoundationReducer;