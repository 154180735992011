import { Modal } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { getBoxIcon } from '../../redux/actions/boxAction';
import { connect } from 'react-redux';
import { BASE_URLS } from '../../redux/constant';
import UploadFilesModal from './upload-files-modal';
import Swal             from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const UploadModal = (props: any) => {
    const [fileUploadShow, setFileUploadShow] = useState(false);
    const [selectedMedia, setSelectedMedia] = useState('');
    const [selectedMediaFolder, setSelectedMediaFolder] = useState('');
    const [iconList, setIconList] = useState([]);
    const isDisabled = localStorage.getItem('isPublicBox');
    const [warning,        setWarning]        = useState(false);
    const redirectTo                          = useNavigate();
    
    useEffect(() => {
        props.getBoxIcon();
    }, [])

    useEffect(() => {
        if (props.boxIconData)
            setIconList(props.boxIconData);
    }, [props.boxIconData])

    useEffect(() =>{
        if(props.currentPlanData && props.currentPlanData.planisActive === 1 && props.currentPlanData.planCode === 'FREE'){
            setWarning(true);
        }
      },[props.currentPlanData])
    
      const handleWarning = () =>{
        Swal.fire({
            title             : 'Your current plan is <span class="text-danger">FREE!</span>',
            html              : '<div class="pt-2" style="font-weight:800">Please upgrade to the <span class="text-success">Family Plus plan</span> <br>to add all media types to your box</span></div>',
            showDenyButton    : false,
            showCancelButton  : true,
            confirmButtonText : 'View Plans!',
            confirmButtonColor: 'red',
            cancelButtonColor : 'blue',
            width             : '450px',
            customClass       : {
                                  actions       : 'my-actions',
                                  confirmButton : 'order-1',
                                  cancelButton  : 'order-2',
                                }
          }).then((result) => {
            if (result.isConfirmed) {
             redirectTo('/select-plans');       
            }
          });
      }

//----------------------------------------------------------------//    

    return (
        <>
            <Modal backdrop="static" keyboard={false} className="uploadModal" {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={(fileUploadShow === true ? false : props && props.show)} >
                <Modal.Header closeButton className="border-0"></Modal.Header>
                <Modal.Body>
                    <h4>Uploads</h4>
                    <div className="uploadItems">
                        {iconList && iconList.map((icon: any) => (
                            <>
                            {isDisabled && icon.folderName === 'Messages' ?
                                <div className="upload-item-list" onClick={() => { setFileUploadShow(true); setSelectedMedia(icon.folderName); setSelectedMediaFolder(icon.id); } }>
                                    <div className={icon.folderName === 'Videos'  || icon.folderName === 'Audio' ? `mt-3 mb-3` :`m-3`} key={icon.id}>
                                        <img src={(icon && icon.folderIcon) && `${BASE_URLS.BASE_URL}/user-box-files/resource?key=${icon.folderIcon}`} className="uploadModalIcons" alt="" />
                                    </div>
                                    <h5 className="icon-lbl">{icon.folderName}</h5>
                                </div>
                            :(!isDisabled) ?
                                <div className="upload-item-list" onClick={() => { warning && icon.folderName !== 'Messages' ? handleWarning() : setFileUploadShow(true); setSelectedMedia(icon.folderName); setSelectedMediaFolder(icon.id); } }>
                                    <div className={icon.folderName === 'Videos'  || icon.folderName === 'Audio' ? `mt-3 mb-3` :`m-3`} key={icon.id}>
                                        <img src={(icon && icon.folderIcon) && `${BASE_URLS.BASE_URL}/user-box-files/resource?key=${icon.folderIcon}`} className="uploadModalIcons" alt="" />
                                    </div>
                                    <h5 className="icon-lbl">{icon.folderName}</h5>
                                </div>
                            :
                                <div className="upload-item-list" style={{cursor:"not-allowed",opacity:'0.3'}}>
                                    <div className={icon.folderName === 'Videos'  || icon.folderName === 'Audio' ? `mt-3 mb-3` :`m-3`} key={icon.id}>
                                        <img src={(icon && icon.folderIcon) && `${BASE_URLS.BASE_URL}/user-box-files/resource?key=${icon.folderIcon}`} className="uploadModalIcons" alt="" />
                                    </div>
                                    <h5 className="icon-lbl">{icon.folderName}</h5>
                                </div>
                            }    
                            </>
                        ))}
                    </div>
                </Modal.Body>
            </Modal>
            <UploadFilesModal selectedMediaFolder={selectedMediaFolder} selectedMedia={selectedMedia} show={fileUploadShow} onHide={() => { setFileUploadShow(false); setSelectedMedia(''); setSelectedMediaFolder(''); } } boxId={parseInt(props.boxId)} />
        </>
    );
}
const mapDispatchToProps = (dispatch: any) => ({
    getBoxIcon: (data: any) => dispatch(getBoxIcon(data)),
});

const mapStateToProps = (state: any) => {
    return {
        boxIconData : (state.BoxReducer && state.BoxReducer.boxIconData) ? state.BoxReducer.boxIconData : null,
        message     : (state.CommonMessageReducer && state.CommonMessageReducer.message) ? state.CommonMessageReducer.message : null,
        currentPlanData  : (state.PlansReducer         && state.PlansReducer.currentPlanDetailsData)  ? state.PlansReducer.currentPlanDetailsData : null,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadModal);
