import React, { useEffect, useState } from 'react';
import Layout from '../../layout/Layout';
import { formValidation } from '../../validations/contactus-validation';
import {contactus} from "../../redux/actions/webAction";
import { connect } from 'react-redux';
import { WEB_API } from '../../redux/constant';
import { BsEmojiSmile } from 'react-icons/bs';

function ContactUs(props:any) {
    let userData = localStorage.getItem('profileData');
    const [contactUsForm, setContactUsForm] = useState({firstName:'', lastName:'',email:'',message:''});
    const [errors,        setErrors]        = useState({firstName:'', lastName:'',email:'',message:''});
    const [counter,       setCounter]       = useState<boolean>(false);
    const [loader,        setLoader]        = useState<boolean>(false);
    const [successFlag,   setSuccessFlag]   = useState<boolean>(false);
    const [notification,  setNotification]  = useState({bgcolor:'',msg:''});
   
    const handleOnChange = (e:any) =>{
        setCounter(true);
        setContactUsForm({...contactUsForm,[e.target.name]:e.target.value});
    }

    useEffect(() =>{
        if(counter){
            let validation = formValidation(contactUsForm);
            let isValid    = Object.entries(validation).length === 0; 
            if(!isValid){
                setErrors(validation);
            }else{
                setErrors({firstName:'', lastName:'',email:'',message:''});
            }
        }
    },[contactUsForm])

    const handleSubmit = (e:any) =>{
        e.preventDefault();
        let validation = formValidation(contactUsForm);
        let isValid    = Object.entries(validation).length === 0;
        if(!isValid){
            setErrors(validation);
        }else{
            setErrors({firstName:'', lastName:'',email:'',message:''});
            setLoader(true);
            props.contactus(contactUsForm);
        }
    }

    useEffect(() =>{
        if(props.contactus_res && props.contactus_res.code === 200 && props.contactus_res.status === 'Success'){
            setLoader(false);
            setSuccessFlag(true);
            setNotification({bgcolor:'success',msg:props.contactus_res.message});
            props.clearNotification(null);
        }
    },[props.contactus_res])

//-----------------------------------------------------------------------//    

  return (
    <Layout>
        <div className="boxes pb-0">
            <div className={`contactUs ${!userData ? 'charity_publicBox': ''}`}>
                <div className={`container ${loader ? `text-center` : ``}`}>
                    <div className="contactForm">
                        <div className="row justify-content-center">
                            <div className={`${successFlag ? `col-md-10` : `col-md-6`} contactBg`} style={{padding:'3%'}}>
                                {loader ?
                                    <div className="lds-dual-ring">
                                        <span className="text-warning">Loading... please wait!</span>
                                    </div>
                                :    
                                    <>   
                                        {!successFlag ?
                                            <>    
                                                <h2 className="text-center">Contact Us</h2>
                                                <form>
                                                    <div className="formRow">
                                                        <label className="form-label">First Name</label>
                                                        <input type="text" autoComplete='off' name='firstName' className="form-control" placeholder="Enter first name here..." onChange={handleOnChange} />
                                                        {errors && errors.firstName && <p className='text-danger'>{errors.firstName}</p>}
                                                    </div>
                                                    <div className="formRow">
                                                        <label className="form-label">Last Name</label>
                                                        <input type="text" autoComplete='off' name='lastName' className="form-control" placeholder="Enter last name here..." onChange={handleOnChange} />
                                                        {errors && errors.lastName && <p className='text-danger'>{errors.lastName}</p>}
                                                    </div>
                                                    <div className="formRow">
                                                        <label className="form-label">Email</label>
                                                        <input type="text" autoComplete='off' name='email' className="form-control" placeholder="Enter your email here..." onChange={handleOnChange} />
                                                        {errors && errors.email && <p className='text-danger'>{errors.email}</p>}
                                                    </div>
                                                    <div className="formRow">
                                                        <label className="form-label">Message</label>
                                                        <textarea className="form-control" autoComplete='off' name='message' placeholder="Enter your message here..." onChange={handleOnChange} ></textarea>
                                                        {errors && errors.message && <p className='text-danger'>{errors.message}</p>}
                                                    </div>
                                                    <div className="text-center">
                                                        <button type="submit" className="darkBtn btn btn-primary mx-auto" onClick={handleSubmit}>Submit</button>
                                                    </div>
                                                </form>
                                            </>
                                        :
                                            successFlag && 
                                            <div className='text-center text-warning'>
                                                <BsEmojiSmile style={{fontSize:'40px'}}/>
                                                <p className='text-warning text-center'> THANK YOU!</p>
                                                <p className={`rounded p-2 text-light text-center`} style={{fontSize:'20px',fontWeight:'700'}}>
                                                    {notification.msg}
                                                </p>
                                            </div>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    </div>{/* <div class="map"> </div>  */}
                </div>
            </div>
        </div>
    </Layout>
  )
}

const mapDispatchToProps = (dispatch: any) => ({
    contactus : (data: any) => dispatch(contactus(data)),
    clearNotification : (data: any) => dispatch({type:WEB_API.SET_CONTACTUS,payload:data})
});

const mapStateToProps = (state: any) => {
    return {
        contactus_res: (state.webReducer && state.webReducer.contactus_res) ? state.webReducer.contactus_res : null,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);