import React, { useCallback, useState, Component, useEffect } from "react";
import { connect } from "react-redux";
import { Button, Form } from 'react-bootstrap';

import logo from '../../assets/images/beyondBoxLogo.png';
import eyeLogo from '../../assets/images/eye.png';
import { resetPasswordFormValidation } from "../../validations/resetPassword-form";
import { changePassword } from "../../redux/actions/authAction";
import { useParams, Link } from "react-router-dom";
import Footer from "../../layout/elements/footer";
import { commonMessageAction } from "../../redux/actions/messageAction";


const ResetPassword = (props: any) => {
    useEffect(()=>{
        props.commonMessageAction();
            
      },[])
    const {key} = useParams();

    let [resetPasswordForm, setResetPasswordForm] = React.useState({
        password: '',
        confirmPassword: '',
        resetKey: key,
    });

    let [errors, setErrors] = React.useState({
        password: '',
        confirmPassword: '',
    });

    let [checkValidation, setCheckValidation] = React.useState({
        password: '',
        confirmPassword: '',
    });

    let [isSubmitted, setIsSubmitted] = React.useState(false);
    let [isValid, setIsValid] = React.useState(true);

    const handleChange = (event: any) => {
        setCheckValidation({
            ...checkValidation, [event.target.name]: true
        });
        setResetPasswordForm({
            ...resetPasswordForm, [event.target.name]: event.target.value
        })
    };
    const [passwordType, setPasswordType] = useState("password");
    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }
    const [confirmPasswordType, setConfirmPasswordType] = useState("password");
    const toggleConfirmPassword = () => {
        if (confirmPasswordType === "password") {
            setConfirmPasswordType("text")
            return;
        }
        setConfirmPasswordType("password")
    }
    React.useEffect(() => {
        const validationErrors = resetPasswordFormValidation(resetPasswordForm);
        isValid = Object.keys(validationErrors).length === 0;
        setIsValid(isValid);
        setErrors(validationErrors);

    }, [resetPasswordForm]);


    const handleSubmit = () => {
        setIsSubmitted(true);
        if (isValid) {
            props.changePassword(resetPasswordForm);

        }
    }
    return (
       <>
       <div className="createAccount">
            <div className="userDetailBlock">
                <div className="formWrap align-items-center d-md-flex flex-column h-100 justify-content-center">
                    <div className="text-center">
                   <Link to="/">
                        <img src={logo} className="App-logo" alt="logo" />
                   </Link>    
                    </div>
                    <h2 className="text-center">Reset Password</h2>
                    <Form className="w-100">
                                      
                        <Form.Group className="formRow passwordHide">
                        {(checkValidation.password && errors.password) && <p className="text-success">Password must be eight characters including one uppercase letter, one special character (@#$%^&+=) and alphanumeric characters</p>}
                            <Form.Label>Password</Form.Label>
                            <Form.Control type={passwordType}
                            name="password"
                             placeholder="Enter password"
                            value={(resetPasswordForm && resetPasswordForm.password) ? resetPasswordForm.password : ''}
                            onChange={handleChange} />
                            <Button className="hideIcon" onClick={togglePassword} ><img src={eyeLogo} alt="" /></Button>
                           </Form.Group>
                        {(checkValidation.password && errors.password) && <p className="text-danger">{errors.password}</p>}
                        <Form.Group className="formRow passwordHide">
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control type={confirmPasswordType} 
                            name="confirmPassword"
                            placeholder="Enter password"
                            value={(resetPasswordForm && resetPasswordForm.confirmPassword) ? resetPasswordForm.confirmPassword : ''}
                            onChange={handleChange} />
                            <Button className="hideIcon" onClick={toggleConfirmPassword} ><img src={eyeLogo} alt="" /></Button>
                           
                        </Form.Group>
                        {(checkValidation.confirmPassword && errors.confirmPassword) && <p className="text-danger">{errors.confirmPassword}</p>}
                        {props.message && <div className={(props.message && props.message.status==='success')?'text-success':'text-danger'}>{props.message.data} <br /><Link style={{color:'#fff'}} to={'/login'}>Click here to login</Link></div>}
                       
                        <div className="d-flex justify-content-center">
                            <Button onClick={handleSubmit} className="yellowBtn" variant="primary" type="button">
                                CONTINUE
                            </Button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
        <Footer/>
        </>
    );
}

const mapDispatchToProps = (dispatch: any) => ({
    changePassword: (data: any) => dispatch(changePassword(data)),
    commonMessageAction: (data: any) => dispatch(commonMessageAction(data)),
});

const mapStateToProps = (state: any) => {
    return {
        message: (state.CommonMessageReducer && state.CommonMessageReducer.message) ? state.CommonMessageReducer.message : null,
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);