import { connect } from "react-redux";
import Layout from "../../../layout/Admin/layout";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import { useEffect, useState } from "react";
import { getAdminUserByIdentity } from "../../../redux/actions/admin/adminUserAction";
import UploadDocFilesModal from "../../box/upload-document-file";
import Swal from "sweetalert2";
import { BsFillEyeFill } from "react-icons/bs";
import DisplayModal from "../../box/uploaded_folder/display-modal";
import { BASE_URLS } from "../../../redux/constant";
import defaultImg  from "../../../assets/Admin/images/no-image-available.png";
import Axios from "axios";
import { FaDownload } from "react-icons/fa";
import pdf from '../../../assets/Admin/images/pdf.png';
import docx from '../../../assets/Admin/images/docx.png';
import file from '../../../assets/Admin/images/file.jpg';

const AdminReleaseBox = (props: any) => {
  const [searchNumber, setSearchNumber] = useState();
  const [userData,     setUserData]     = useState<any>(false);
  const [isOpen,       setIsOpen]       = useState(false);
  const [modalShow,    setModalShow]    = useState<boolean>(false);
  const [dodModalShow, setDODModalShow] = useState<boolean>(false);
  const [docLoader,    setDocLoader]    = useState<boolean>(false);

  const handleChange = (event: any) => {
    let targetValue = event.target.value;
    targetValue.trimStart();
    setSearchNumber(event.target.value);
  };

  const handleSubmit = () => {
    let payload = searchNumber;
    props.getAdminUserByIdentity({ id: payload });
  };

  const clearData = () => {
    setIsOpen(false);
  };

  const handleUpload = () => {
    setModalShow(true);
  };

  useEffect(() => {
    if (props.adminUserDataByIdentity) {
      if (props.adminUserDataByIdentity.length == 0) {
        setIsOpen(false);
        Swal.fire({
          title: "No user with this identity number",
          showCancelButton: false,
          confirmButtonText: "OKAY",
          confirmButtonColor: "green",
          showCloseButton: true,
        });
      } else {
        setUserData(props.adminUserDataByIdentity);
        setIsOpen(true);
      }
    }
  }, [props.adminUserDataByIdentity]);

  const handleDODModal = (e:any) =>{
    e.preventDefault();
    setDODModalShow(true);
  }

  const closeDODModal = () =>{
    setDODModalShow(false);
}

const handleDownload = (filePath:any) =>{
  setDocLoader(true);
  const userData     = JSON.parse(localStorage.getItem('userData')||'{}');
  const access_token = (userData && userData.Token)?userData.Token:'';
  if (access_token) {
    const config = { headers: {'Authorization': 'Bearer '+access_token} }
    let url      = `${BASE_URLS.BASE_URL}/user-box-files/resource/download?key=${filePath}`;
    Axios.get(url,config).then(function (response:any) {
      const link = document.createElement('a');
      link.href  = response.data;
      document.body.appendChild(link);
      link.click();
      setDocLoader(false);
    }).catch(function (error) {
      console.log(error);
    });
  }
}
// --------------------------------------------------------------------------------------/
  
  return (
    <>
      <div className="page">
        <Layout>
          <div className="searchBox">
            <InputGroup className="mb-3">
              <Form.Control type="text" placeholder="Enter User Identity Number" onChange={handleChange} onInput={(e: React.ChangeEvent<HTMLInputElement>) => { if (e.target.value.length > e.target.maxLength) e.target.value = e.target.value.slice( 0, e.target.maxLength ); }} maxLength={20} min="0" onWheel={(e) => e.currentTarget.blur()} />
              <InputGroup.Text id="basic-addon1">
                <Button className="yellowBtn" onClick={handleSubmit} type="submit" > Search </Button>
              </InputGroup.Text>
            </InputGroup>
          </div>

          {isOpen ? (
            <Container>
              <div className="myProfile p-3 mb-3 m-0 bg-transparent">
                <Container>
                  <Row>
                    <Col md={10}>
                      <h3 className="text-center" style={{color:"#E79E1F"}}><u>User's Detail</u></h3>
                    </Col>
                    <Col md="auto">
                      <div className="row justify-content-end">
                        <div className="col-auto">
                          <Button className="backBtn" onClick={clearData}>
                            Clear
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <hr style={{color:'grey',height:'2px'}}></hr>
                  <Form>
                    <Row>
                      <Col md={5}>
                        <Form.Group className="">
                          <Form.Label>Name :</Form.Label>
                          <Form.Text>
                            {" "}
                            {userData &&
                            isOpen &&
                            userData[0].firstName &&
                            userData[0].lastName
                              ? userData[0].firstName +
                                " " +
                                userData[0].lastName
                              : "N/A"}
                          </Form.Text>
                        </Form.Group>
                      </Col>
                      <Col md="auto">
                        <Form.Group className="">
                          <Form.Label>Email Address :</Form.Label>
                          <Form.Text>
                            {" "}
                            {userData && isOpen && userData[0].email
                              ? userData[0].email
                              : "N/A"}
                          </Form.Text>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={5}>
                        <Form.Group className="">
                          <Form.Label>Date of Birth :</Form.Label>
                          <Form.Text>
                            {" "}
                            {userData && isOpen && userData[0].dob
                              ? userData[0].dob
                              : "N/A"}
                          </Form.Text>
                        </Form.Group>
                      </Col>
                      <Col md="auto">
                        <Form.Group className="">
                          <Form.Label>UserId :</Form.Label>
                          <Form.Text>
                            {" "}
                            {userData && isOpen && userData[0].id
                              ? userData[0].id
                              : "N/A"}
                          </Form.Text>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={5}>
                        <Form.Group className="">
                          <Form.Label>Mobile Number :</Form.Label>
                          <Form.Text>
                            {" "}
                            {userData && isOpen && userData[0].mobile
                              ? userData[0].mobile
                              : "N/A"}
                          </Form.Text>
                        </Form.Group>
                      </Col>
                      <Col md="auto">
                        <Form.Group className="">
                          <Form.Label>Identity Number :</Form.Label>
                          <Form.Text>
                            {" "}
                            {userData && isOpen && userData[0].identity_number
                              ? userData[0].identity_number
                              : "N/A"}
                          </Form.Text>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={5}>
                        <Form.Group className="">
                          <Form.Label>User Registration Date :</Form.Label>
                          <Form.Text>
                            {" "}
                            {userData && isOpen && userData[0].created
                              ? new Date(
                                  userData[0].created
                                ).toLocaleDateString()
                              : "N/A"}
                          </Form.Text>
                        </Form.Group>
                      </Col>
                      <Col md="auto">
                        <Form.Label>Death Certificate :&nbsp;</Form.Label>
                          <Form.Text>
                            { userData && userData.length != 0 && userData[0] && userData[0].dod_document ?
                              <button className="btn btn-outline-warning" onClick={handleDODModal}><BsFillEyeFill /></button>
                            : 
                              "N/A"}
                          </Form.Text>
                      </Col>
                    </Row>
                  </Form>
                  <hr style={{color:'grey',height:'2px'}}></hr>
                  <div className="uploadForm">
                    <UploadDocFilesModal selectedMediaFolder={4} selectedMedia={"Documents"} show={modalShow} onHide={() => { setModalShow(false); }} boxId={parseInt(props.boxId)} />
                    <div className="uploadDC d-flex justify-content-center" style={userData && userData.length != 0 && userData[0] && userData[0].dod_document ? {cursor:"not-allowed"} : {}}>
                    { userData && userData.length != 0 && userData[0] && userData[0].dod_document ?
                      <Button className="backBtn" disabled={true} style={{color:'lightgrey'}} > Upload Death Certificate </Button>
                    :
                      <Button className="backBtn" onClick={handleUpload} type="submit" > Upload Death Certificate </Button>
                      }
                    </div>
                  </div>
                </Container>
              </div>
            </Container>
          ) : 
          ( "" )}
          <DisplayModal backdrop="static" show={dodModalShow} onHide={closeDODModal} name='Uploaded Death Certificate'>
          <hr style={{color:'grey',height:'2px'}}></hr>
            { userData && userData.length != 0 && userData[0] && userData[0].dod_document && 
              <>
                {userData[0].dod_document.split('.').pop() == 'pdf' || userData[0].dod_document.split('.').pop() == 'doc' || userData[0].dod_document.split('.').pop() == 'docx' ?
                  <>
                    {docLoader ?
                      <span className='text-warning text-center lds-dual-ring mb-4' style={{fontSize:"14px",marginLeft: "11%",borderLeft: "1px solid",borderRight: "1px solid",borderRadius: "14px"}}>File downloading<br/>please wait...</span>
                    :
                      <Row className="align-items-center">
                        <Col md="auto"><img className="ms-auto" width={320} height={250} src={userData[0].dod_document.split('.').pop() === 'pdf' ? pdf : (userData[0].dod_document.split('.').pop() === 'docx' || userData[0].dod_document.split('.').pop() === 'doc' ? docx : file) } alt=""/></Col>
                        <Col className="ms-auto">
                          <div>
                            <label className="text-warning"><u className="pt-1">CERTIFICATE DETAILS</u></label><br/><br/>
                            <label className="text-warning">Name&nbsp;:&nbsp;<span className="text-light">{userData[0].username}</span></label><br/>
                            <label className="text-warning">Expired on&nbsp;:&nbsp;<span className="text-light">{new Date(userData[0].dod).toLocaleDateString()}</span></label><br/><br/>
                            <label className="bg-warning text-dark p-1 rounded" onClick={()=>handleDownload(userData[0].dod_document)} style={{cursor:'pointer'}}>Download file&nbsp;&nbsp;<FaDownload style={{fontSize:"12px",marginBottom:"10px"}}/></label>
                          </div>
                        </Col>
                      </Row>
                    }
                  </>
                :
                  <img className="rounded" height="300" width="100%" src={`${BASE_URLS.BASE_URL}/user-box-files/resource?key=${userData[0].dod_document}`} onError={({ currentTarget } : { currentTarget:any }) => {currentTarget.onerror = null; currentTarget.src=defaultImg; }}/>   
                }  
              </>
            }
            <hr style={{color:'grey',height:'2px'}}></hr>
            <Button style={{border:"1px solid #E79E1F"}} className='backBtn' onClick={closeDODModal}>Close</Button>
          </DisplayModal>
        </Layout>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  getAdminUserByIdentity: (payload: any) =>
    dispatch(getAdminUserByIdentity(payload)),
});

const mapStateToProps = (state: any) => {
  return {
    adminUserDataByIdentity:
      state.AdminUserReducer && state.AdminUserReducer.adminUserDataByIdentity
        ? state.AdminUserReducer.adminUserDataByIdentity
        : null,

    state,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminReleaseBox);
